import { PartLifeClassification } from '#models/PartLifeClassification';
import { spGetPart } from '#models/spGetPart';
import { spGetRepairs } from '#models/spGetRepairs';
import { spGetSerialNumbers } from '#models/spGetSerialNumbers';
import { spPartHistory } from '#models/spPartHistory';
import { PartEntryService } from '#services/http/part-entry.service';
import { Component, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Link } from '#models/Link';
import { TopsHomeService } from '#services/http/tops-home.service';
import { MatDialog } from '@angular/material/dialog';
import { RepairInformationDialogComponent } from 'app/core/modals/repair-information-dialog/repair-information-dialog.component';
import { PtdynamicLink } from '#models/PtdynamicLink';
import { OutageDialogComponent } from 'app/core/modals/outage-dialog/outage-dialog.component';
import { SerialnoHistoryDialogComponent } from 'app/core/modals/serialno-history-dialog/serialno-history-dialog.component';
import { vOutage } from '#models/VOutage';
import { NotificationService } from '#services/notification.service';
import { spInsertLink } from '#models/spInsertLink';
import { spInsertSerialNumber } from '#models/spInsertSerialNumber';
import { DatePipe } from '@angular/common';
import { spGetMoveToSets } from '#models/spGetMoveToSets';

@Component({
  selector: 'app-part-entry',
  templateUrl: './part-entry.component.html',
  styleUrls: ['./part-entry.component.scss']
})

export class PartEntryComponent {
  public displayHistoryColumns = ['company', 'unit', 'type', 'dateIn', 'dateOut', 'interval', 'lifetime', 'firedStarts']
  public displaySrNosColumns = ['check', 'serialNumber', 'partNumber', 'startingHrs', 'start', 'end', 'firedStarts', 'lifetimeHours', 'status', 'edit']
  public displayRepaiesColumns = ['shipped', 'returned', 'plant', 'unit', 'repairFacility', 'internalRef', 'externalRef', 'view', 'delete']

  partEntryInfo = new spGetPart();
  public organizationCode: string = '';
  public componentCode: string;
  public frameType: string = '';
  public setNumber: string = '';
  public id: number = 0;

  partLifeClassification: PartLifeClassification[] = [];
  dynamicLinks: PtdynamicLink[] = [];
  selected: number = 0;
  linkSelected: number = 0;
  history: spPartHistory[] = [];
  public historyDataSource: MatTableDataSource<spPartHistory>;
  outageInfo = new vOutage();
  serialNumbers: spGetSerialNumbers[] = [];
  public serialNumbersDataSource: MatTableDataSource<spGetSerialNumbers>;
  serialNoCount: number = 0;
  repairs: spGetRepairs[] = [];
  public repairsDataSource: MatTableDataSource<spGetRepairs>;
  repairsCount: number = 0;
  userName: string = '';
  links: Link[] = [];
  linkStr: string = '';
  deleteRepair = new spGetRepairs();
  description: string = '';
  linkDescription: string = '';
  link = new spInsertLink();
  selectedLink: string = '';
  selectedLinkUpdate: string = '';
  errorMessage: string = '';
  srNoErrorMessage: string = '';
  isEdit: boolean = false;
  updateLink = new Link();
  isScrap: boolean = false;
  scrapDate: Date;
  isMove: boolean = false;
  moveDate: Date;
  srNumber = new spGetSerialNumbers();
  scrapsrNos: spGetSerialNumbers[] = [];
  deleteSrNos: spGetSerialNumbers[] = [];
  insertSRNO = new spInsertSerialNumber();
  updateSRNO = new spInsertSerialNumber();
  currentDate = new Date();
  addSrNoStartDate: Date;
  updateSrNoStartDate: string = '';
  tabIndex = 0;
  isHeaderRow = false;
  uploadControlsVisible: boolean = false;
  selectedFile: File | null = null;
  selectedMove = 'SPARES'
  moveToSetSrNos: spGetSerialNumbers[] = [];
  moveName: string = '';
  editable: boolean = false;
  isUpdateSrNo = false;
  moveToSetsValues: spGetMoveToSets[] = [];

  @ViewChild(MatTable) table: MatTable<spPartHistory>;
  @ViewChild(MatTable) sNostable: MatTable<spGetSerialNumbers>;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    public dialog: MatDialog, private notifyService: NotificationService,
    private partEntryService: PartEntryService, private topsHomeService: TopsHomeService,
    public datepipe: DatePipe) {
  }

  ngOnInit(): void {

    // // let currentUser = this.authService.getCurrentUser();
    // // this.userName = currentUser.username;

    this.activeRoute.queryParams.subscribe((params) => {
      this.organizationCode = params['OrgCode'];
      this.componentCode = params['CompCode'];
      this.frameType = params['FrType'];
      this.setNumber = params['SetNo'];
      this.id = params['id'];
    });

    this.getPartLifeClassification();
    this.getLinks();


    this.partEntryService.getPartEntryData(this.organizationCode, this.componentCode, this.frameType, this.setNumber).subscribe((res) => {
      this.partEntryInfo = res;
      this.selected = this.partEntryInfo.partLifeClassification === null ? 0 :
        this.partEntryInfo.partLifeClassification;

      // Get History Tab data
      this.partEntryService.getHistoryData(this.organizationCode, this.setNumber, this.componentCode,
        this.frameType).subscribe((res) => {
          this.history = res;
          this.historyDataSource = new MatTableDataSource<spPartHistory>(this.history);
        });

      // Get Serial Numbers data
      this.partEntryService.getSerialNumbersData(this.organizationCode, this.componentCode,
        this.frameType, this.setNumber).subscribe((res) => {
          this.serialNumbers = res;
          this.serialNumbersDataSource = new MatTableDataSource<spGetSerialNumbers>(this.serialNumbers);
          this.serialNoCount = this.serialNumbers.length;
        });

      // Get repairs data
      this.partEntryService.getRepairsData(this.organizationCode, this.setNumber, this.componentCode,
        this.frameType,).subscribe((res) => {
          this.repairs = res;
          this.repairsDataSource = new MatTableDataSource<spGetRepairs>(this.repairs);
          this.repairsCount = this.repairs.length;
        });

      // Get Links data
      this.topsHomeService.getLinksData(this.organizationCode, this.componentCode,
        this.frameType, this.setNumber, 'S').subscribe((res: Link[]) => {
          this.links = res;
          //this.linkStr = this.links.dynamicLink;
        });

      // Get Move to Set Values

      this.getMoveToSetValues(this.partEntryInfo.dln);
    });
  }

  showUploadControls(): void {
    this.uploadControlsVisible = true;
    this.isMove = false;
    this.isScrap = false;
  }

  hideUploadControls(): void { this.uploadControlsVisible = false; this.selectedFile = null; }

  onFileSelected(event: any): void {
    if (event.target.files.length > 0) {
      this.selectedFile = event.target.files[0];
    }
  }

  submitFile(): void {
    if (this.selectedFile) {
      const formData = new FormData();
      formData.append('file', this.selectedFile);

      this.partEntryService.importPartyEntryCSVFile(
        formData,
        this.organizationCode,
        this.componentCode,
        this.frameType,
        this.setNumber,
        this.partEntryInfo.service_Date,
        this.isHeaderRow,
        0
      ).subscribe(
        res => {
          if (res.validationErrors) {
            // window.location.reload();
            this.notifyService.showError(
              res.validationErrors.join('<br/>'),
              'Validation Errors'
            );
            // window.location.reload();
          } else {
            window.location.reload();
            this.notifyService.showSuccess('File uploaded successfully.', 'Success');
          }
        },
        error => {
          if (error.validationErrors) {
            this.notifyService.showError(
              error.validationErrors.join('<br/>'),
              'Validation Errors'
            );
          } else {
            this.notifyService.showError(error.message, 'Error');
          }
        }
      );
    } else {
      this.notifyService.showError('Please select a file to upload.', 'Error');
    }
  }

  getPartLifeClassification() {
    this.partEntryService.getAllPartLifeClassification().subscribe((res) => {
      this.partLifeClassification = res;
    });
  }

  getLinks() {
    this.partEntryService.getAllDynamicLinks().subscribe((res) => {
      this.dynamicLinks = res;
    });
  }

  getMoveToSetValues(dln: number) {
    this.partEntryService.getAllMoveToSetValues(this.organizationCode, this.componentCode, this.frameType,
      this.setNumber, dln).subscribe((res) => {
        this.moveToSetsValues = res;
      });
  }

  public onDateInOutClick(row: spPartHistory) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/outage-entry'], {
        queryParams: {
          OrgCode: row.organization_Code,
          CompCode: row.company_Code,
          Unit: row.unit,
          OutDate: row.outDate
        },
      })
    );

    window.open(url, '_blank');
  }

  ShowRepairInfoDialog(row: spGetRepairs) {
    this.dialog.open(RepairInformationDialogComponent, {
      width: "70%",
      height: "95%",
      data: {
        OrgCode: row.organizationCode, CompCode: row.componentCode,
        FrType: row.frameType, SetNo: row.setNumber, startDate: row.startDate
      },
    });
  }

  ShowOutageInfoDialogInDate(row: spPartHistory) {

    this.partEntryService.getOutageInfoInDate(row.organization_Code, row.company_Code, row.unit,
      row.inDate.toString()).subscribe((res) => {
        this.outageInfo = res;

        if (this.outageInfo != null) {
          this.dialog.open(OutageDialogComponent, {
            width: "70%",
            height: "95%",
            data: { id: this.outageInfo.id },
          });
        }
      });
  }

  ShowOutageInfoDialogOutDate(row: spPartHistory) {

    this.partEntryService.getOutageInfoOutDate(row.organization_Code, row.company_Code, row.unit,
      row.outDate.toString()).subscribe((res) => {
        this.outageInfo = res;

        if (this.outageInfo != null) {
          this.dialog.open(OutageDialogComponent, {
            width: "70%",
            height: "95%",
            data: { id: this.outageInfo.id },
          });
        }
      });
  }

  ShowSerialNumberHistoryDialog(serialNo: spGetSerialNumbers) {
    this.dialog.open(SerialnoHistoryDialogComponent, {
      width: "55%",
      height: "80%",
      data: {
        OrgCode: this.organizationCode, FrameType: this.frameType, CompCode: this.componentCode,
        Dln: serialNo.dln, SerialNumber: serialNo.serial_Number, setNumber: this.setNumber,
        id: this.id
      },
    });
  }

  UpdatePartEntryDetails(part: spGetPart) {

    this.partEntryService.postPartEntryData(part).subscribe(res => {
      window.location.reload();
    });
  }

  OnCancelClick() {
    window.location.reload();
  }

  OnDateChange(part: spGetPart, dateType: string) {
    if (dateType == 'ServiceDate' && part.service_Date != null) {
      const serviceDate = new Date(part.service_Date!);
      part.service_Date = this.notifyService.ConvertDateBeforeSave(serviceDate);
    }

    if (dateType == 'ScrapDate' && part.scrap_Date != null) {
      const scrapDate = new Date(part.scrap_Date!);
      part.scrap_Date = this.notifyService.ConvertDateBeforeSave(scrapDate);
    }
  }

  UpdateIntValues(part: spGetPart, type: string) {
    if (type == 'numberOf') {
      part.numberOf = parseInt(part.numberOf);
    }
    if (type == 'maxRepairs') {
      part.maxNumberOfRepairs = parseInt(part.maxNumberOfRepairs);
    }
    if (type == 'priorRepairs') {
      part.priorNumberOfRepairs = parseInt(part.priorNumberOfRepairs);
    }
    if (type == 'startingHours') {
      part.starting_Hours = parseInt(part.starting_Hours);
    }
    if (type == 'maxHours') {
      part.max_Hours = parseInt(part.max_Hours);
    }
  }

  onPartLifeChange(part: spGetPart, event: any) {
    part.partLifeClassification = event.value;
  }

  onDeleteRepairClick(row: spGetRepairs) {

    this.deleteRepair.organizationCode = this.organizationCode;
    this.deleteRepair.setNumber = this.setNumber;
    this.deleteRepair.componentCode = this.componentCode;
    this.deleteRepair.frameType = this.frameType;
    this.deleteRepair.startDate = row.startDate;

    this.partEntryService.deleteRepairData(this.deleteRepair).subscribe(res => {
      window.location.reload();
    });
  }

  public onNewRepairClick() {
    this.router.navigate(['tops/part-repair'], {
      queryParams: {
        OrgCode: this.organizationCode,
        CompCode: this.componentCode,
        FrameType: this.frameType,
        SetNo: this.setNumber,
        StartDate: null,
        id: this.id
      },
    });
  }

  InsertLink() {

    this.link.field1 = this.organizationCode;
    this.link.field2 = this.componentCode;
    this.link.field3 = this.frameType;
    this.link.field4 = this.setNumber;
    this.link.field5 = null;
    this.link.type = 'S';
    this.link.description = this.description;
    this.link.link = this.linkDescription;
    this.link.subLink = this.selectedLink;

    this.errorMessage = "";
    this.ValidateFields(this.link);

    if (this.errorMessage === "") {
      this.partEntryService.insertLinksData(this.link).subscribe(res => {
        window.location.reload();
      });

      this.link = null;
    }
  }

  onLinkChange(event: any) {
    this.selectedLink = event.value;
  }

  ValidateFields(link: spInsertLink) {
    if (link.description == undefined || link.description == "") {
      this.errorMessage = 'Link description is required.';
    }
    else if (link.link == undefined || link.link == "") {
      this.errorMessage = 'Link required.';
    }
  }

  DeleteLink(recordNumber: number) {
    this.partEntryService.deleteLink(recordNumber).subscribe(res => {
      window.location.reload();
    });
  }

  EditLink(recordNumber: number) {
    this.isEdit = true;
    this.updateLink = this.links.find(x => x.recordNumber == recordNumber);
    this.selectedLinkUpdate = this.updateLink.subLink;
  }

  UpdateLink() {
    this.partEntryService.updateLink(this.updateLink).subscribe(res => {
      window.location.reload();
    });
  }

  onClickCancel() {
    this.updateLink = null;
    this.isEdit = false;
  }

  onAddClickCancel() {
    this.description = '';
    this.linkSelected = 0;
    this.linkDescription = '';
  }

  onLinkUpdateChange(event: any) {
    this.selectedLinkUpdate = event.value;
    this.updateLink.subLink = this.selectedLinkUpdate;
  }

  SelectAll() {
    this.serialNumbers.forEach(element => {
      element.isSelected = true;
    });
  }

  ClearAll() {
    this.serialNumbers.forEach(element => {
      element.isSelected = false;
    });
  }

  onSrNoChange(number: string, event: any) {
    this.serialNumbers.find(x => x.serial_Number == number).isSelected = event;
  }

  onDeleteClick() {

    this.deleteSrNos = this.serialNumbers.filter(x => x.isSelected === true);

    if (this.deleteSrNos.length > 0) {
      this.partEntryService.deleteSrNumbers(this.deleteSrNos).subscribe(res => {
        this.deleteSrNos = null;
        window.location.reload();
      });
    }
    else {
      this.notifyService.showError("No serial numbers selected.", "");
    }
  }

  addSerialNumber(srNo: spInsertSerialNumber) {

    if (this.insertSRNO.serialNumber == '') {
      this.notifyService.showError("Serial number is required.", "");
    }
    else if (this.insertSRNO.start_Date == undefined || this.insertSRNO.start_Date == null) {
      this.notifyService.showError("Start date is required.", "");
    }
    else if (this.insertSRNO.start_Date > this.currentDate) {
      this.notifyService.showError("Start date cannot be greater than today.", "");
    }
    // // else if (this.insertSRNO.starting_Hours == 0) {
    // //   this.notifyService.showError("Starting hours is required.", "");
    // // }
    else if (this.insertSRNO.starting_Hours < 0) {
      this.notifyService.showError("Starting hours cannot be less than zero.", "");
    }
    else {
      this.insertSRNO.organizationCode = this.organizationCode;
      this.insertSRNO.componentCode = this.componentCode;
      this.insertSRNO.frameType = this.frameType;
      this.insertSRNO.setNumber = this.setNumber;
      this.insertSRNO.serialNumber = srNo.serialNumber;
      this.insertSRNO.partNumber = srNo.partNumber == '' ? null : srNo.partNumber;
      this.insertSRNO.starting_Hours = srNo.starting_Hours;
      this.insertSRNO.dln = 0;
      //this.insertSRNO.startDate = srNo.startDate;

      this.partEntryService.insertSerialNumber(this.insertSRNO).subscribe(res => {
        if (res == -1) {
          window.location.reload();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelAddSerialNumber(srNo: spInsertSerialNumber) {
    this.insertSRNO.serialNumber = '';
    this.insertSRNO.partNumber = '';
    this.insertSRNO.starting_Hours = null;
    this.insertSRNO.start_Date = null;
    this.addSrNoStartDate = null;
  }

  onStartDateChange(event: any) {
    // //alert(event.target.value);
    const date = event.target.value;
    this.addSrNoStartDate = date;

    if (date != null && date != "") {
      const startDate = new Date(date);
      this.insertSRNO.start_Date = this.notifyService.ConvertDateBeforeSave(startDate);
    }
    else {
      this.insertSRNO.start_Date = null;
    }
  }

  UnscrapSerialnumber(number: string) {

    this.srNumber = this.serialNumbers.find(x => x.serial_Number == number);

    this.partEntryService.unscrapSerialNumbers(this.srNumber).subscribe(res => {
      window.location.reload();
    });
  }

  OnScrapDateChange(date: Date) {
    if (date != null) {
      const serviceDate = new Date(date!);
      date = this.notifyService.ConvertDateBeforeSave(serviceDate);
    }

    // // this.serialNumbers.forEach(element => {
    // //   if (element.isSelected == true) {
    // //     element.end_Date = date;
    // //   }
    // // });
  }

  onScrapClick() {
    this.isScrap = true;
    this.isMove = false;
    this.uploadControlsVisible = false;
    this.srNoErrorMessage = '';
  }

  onScrapCancelClick() {
    this.isScrap = false;
    this.scrapDate = null;
    this.srNoErrorMessage = '';
  }

  ScrapSerialnumber() {
    this.srNoErrorMessage = '';
    this.scrapsrNos = this.serialNumbers.filter(x => x.isSelected === true);

    if (this.scrapsrNos.length == 0) {
      this.notifyService.showError("No serial numbers selected.", "");
    }
    else if (this.scrapDate == null || this.scrapDate == undefined) {
      this.notifyService.showError("Scrap date is required.", "");
    }
    else if (this.scrapsrNos.length > 0) {
      let scrap_date = this.notifyService.ConvertDateBeforeSave(this.scrapDate);

      this.scrapsrNos.forEach(element => {
        if (element.isSelected == true) {
          element.end_Date = scrap_date;
        }
      });

      // // check if scrap date is less than start date.
      this.scrapsrNos.forEach(element => {

        let sdate = new Date(element.start_Date)
        let scrap_date = new Date(element.end_Date)

        if (sdate > scrap_date) {
          const message = '<p></p>' + this.srNoErrorMessage
            + '\n<li>' + 'Serial Number '
            + element.serial_Number + ': Scrap date cannot be less than start date.</li>';
          this.srNoErrorMessage = message;
        }
      });
      // // end

      if (this.srNoErrorMessage == '' || this.srNoErrorMessage == null) {
        this.partEntryService.scrapSerialNumbers(this.scrapsrNos).subscribe(res => {
          this.scrapsrNos = null;
          // // this.notifyService.showSuccess(this.scrapsrNos.length + "Serial Number(s) scrapped.", "");
          window.location.reload();
        });
      }
    }
  }

  onMoveClick() {
    this.isMove = true;
    this.isScrap = false;
    this.uploadControlsVisible = false;
  }

  onMoveToSetCancelClick() {
    this.isMove = false;
    this.srNoErrorMessage = '';
    this.moveDate = null;
    this.selectedMove = 'SPARES';
    this.moveName = '';
  }

  OnMoveDateChange(date: Date) {
    if (date != null) {
      const moveDate = new Date(date!);
      this.moveDate = this.notifyService.ConvertDateBeforeSave(moveDate);
    }
  }

  onMoveToSetChange(event: any) {
    let value = event.value;
    this.srNoErrorMessage = '';
  }

  MoveToSetSerialnumber() {
    this.srNoErrorMessage = '';
    this.moveToSetSrNos = this.serialNumbers.filter(x => x.isSelected === true);

    if (this.moveToSetSrNos.length == 0) {
      this.notifyService.showError("No serial numbers selected.", "");
    }
    else if (this.moveDate == null || this.moveDate == undefined) {
      this.notifyService.showError("Move date is required.", "");
    }
    else if (this.moveDate > this.currentDate) {
      this.notifyService.showError("Move date cannot be greater than today.", "");
    }
    // else if (this.selectedMove == 'NEWSET' && (this.moveName == '' || this.moveName == undefined)) {
    //   this.notifyService.showError("Set number is required when 'New Set' is selected.", "");
    // }
    // else if (this.moveName.toUpperCase() == 'NEWSET' || this.moveName.toUpperCase() == 'SPARES') {
    //   this.notifyService.showError("Set number cannot be NEWSET or SPARES. These are reserved sets and cannot be used.", "");
    // }

    else if (this.moveToSetSrNos.length > 0) {
      let move_date = this.notifyService.ConvertDateBeforeSave(this.moveDate);

      this.moveToSetSrNos.forEach(element => {
        if (element.isSelected == true) {
          element.end_Date = move_date;
          element.movedTo = this.selectedMove;
          element.status = 'M';
        }
      });

      // // check if move date is less than start date.
      this.moveToSetSrNos.forEach(element => {

        let sdate = new Date(element.start_Date)
        let move_date = new Date(element.end_Date)

        if (sdate > move_date) {
          const message = '<p></p>' + this.srNoErrorMessage
            + '\n<li>' + 'Serial Number '
            + element.serial_Number + ': Move date cannot be less than start date.</li>';
          this.srNoErrorMessage = message;
        }
      });
      // // end

      if (this.srNoErrorMessage == '' || this.srNoErrorMessage == null) {
        this.partEntryService.moveToSetSerialNumbers(this.moveToSetSrNos,
          this.selectedMove == 'NEWSET' ? 1 : 0).subscribe(res => {
            this.moveToSetSrNos = null;
            window.location.reload();
          });
      }
    }
  }

  startEdit(row) {
    row.editable = true;
  }

  EditSerialNumber(srNo: spInsertSerialNumber, index: any) {
    this.updateSrNoStartDate = this.datepipe.transform(srNo.start_Date, 'yyyy-MM-dd'); //srNo.start_Date;
    this.updateSRNO = srNo;
    this.serialNumbers.splice(index, 1);

    const startDate = new Date(srNo.start_Date);
    this.updateSRNO.start_Date = this.notifyService.ConvertDateBeforeSave(startDate);

    this.isUpdateSrNo = true;
  }

  onUpdateStartDateChange(event: any) {
    const date = event.target.value;
    this.addSrNoStartDate = date;

    if (date != null && date != "") {
      const startDate = new Date(date);
      this.updateSRNO.start_Date = this.notifyService.ConvertDateBeforeSave(startDate);
    }
    else {
      this.updateSRNO.start_Date = null;
    }
  }

  updateSerialNumber(srNo: spInsertSerialNumber) {

    if (this.updateSRNO.start_Date == undefined || this.updateSRNO.start_Date == null) {
      this.notifyService.showError("Start date is required.", "");
    }
    else if (this.updateSRNO.start_Date > this.currentDate) {
      this.notifyService.showError("Start date cannot be greater than today.", "");
    }
    // // else if (this.updateSRNO.starting_Hours == 0) {
    // //   this.notifyService.showError("Starting hours is required.", "");
    // // }
    else if (this.updateSRNO.starting_Hours < 0) {
      this.notifyService.showError("Starting hours cannot be less than zero.", "");
    }
    else {
      this.updateSRNO.organizationCode = this.organizationCode;
      this.updateSRNO.componentCode = this.componentCode;
      this.updateSRNO.frameType = this.frameType;
      this.updateSRNO.setNumber = this.setNumber;
      this.updateSRNO.serialNumber = srNo.serialNumber;
      this.updateSRNO.partNumber = srNo.partNumber == '' ? null : srNo.partNumber;
      this.updateSRNO.starting_Hours = srNo.starting_Hours;
      this.updateSRNO.dln = srNo.dln;

      this.partEntryService.updateSerialNumber(this.updateSRNO).subscribe(res => {
        if (res == -1) {
          window.location.reload();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelUpdateSerialNumber(srNo: spInsertSerialNumber) {
    this.updateSRNO = null;
    window.location.reload();
  }

  UpdateStartingHours(part: spInsertSerialNumber, type: string, value: any) {
    if (type == 'startHours') {
      this.insertSRNO.starting_Hours = parseInt(value);
    }
    if (type == 'startHoursUpdate') {
      this.updateSRNO.starting_Hours = parseInt(value);
    }
  }
}
