import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class PartsService {

  GetComponentsUrl = '/Parts/getComponents';
  GetPartsUrl = '/Parts/getParts';
  GetPartsStatusUrl = '/Parts/getPartsStatus';
  deleteUrl = '/Parts/deletePart';
  GetRepairTrackingUrl = '/Parts/getRepairTracking';

  constructor(private http: HttpClient,) { }

  getComponents(organizationCode: string, frameType: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetComponentsUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType,
        }
      });
  }

  getPartsData(organizationCode: string, componentCode: string, frameType: string, status: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetPartsUrl,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType,
          status: status
        }
      });
  }
  getPartsStatus(organizationCode: string, companyCode: string, unit: string, outageDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetPartsStatusUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          outagedate: outageDate,
        }
      });
  }
  deletePart(formData: any) {
    return this.http.post(environment.apiEndpoint + this.deleteUrl, formData);
  }

  getRepairTracking(organizationCode: string, frameType: string, componentCode: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetRepairTrackingUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType,
          componentCode: componentCode
        }
      });
  }

}
