import { spGetSerialNumbers } from '#models/spGetSerialNumbers';
import { PartEntryService } from '#services/http/part-entry.service';
import { Component, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-serial-number-selection',
  templateUrl: './serial-number-selection.component.html',
  styleUrls: ['./serial-number-selection.component.scss']
})

export class SerialNumberSelectionComponent {

  serialNumbers: spGetSerialNumbers[] = [];
  selected: any;
  selectedsrNumbers: spGetSerialNumbers[] = [];
  SrNos: string = '';
  SrNoCount: number = 0;
  public selectedSrNos: EventEmitter<any> = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<SrNoSelectionDialogModel>,
    private router: Router, private partEntryService: PartEntryService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.partEntryService.getSerialNumbersData(this.data.organizationCode, this.data.componentCode,
      this.data.frameType, this.data.setNumber).subscribe((res) => {
        this.serialNumbers = res;
      });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onSrNoChange(number: spGetSerialNumbers) {
    this.serialNumbers.find(x => x.serial_Number == number.serial_Number).isSelected = true;
  }

  SelectAll() {
    this.serialNumbers.forEach(element => {
      element.isSelected = true;
    });
  }

  ClearAll() {
    this.serialNumbers.forEach(element => {
      element.isSelected = false;
    });
  }

  SaveSelection() {
    this.selectedsrNumbers = this.serialNumbers.filter(x => x.isSelected == true);
    this.SrNoCount = this.selectedsrNumbers.length;

    this.selectedsrNumbers.forEach(element => {
      if (this.SrNos == '') {
        this.SrNos = element.serial_Number;
      }
      else {
        this.SrNos = this.SrNos + ', ' + element.serial_Number;
      }
    });
    this.dialogRef.close(true);
    this.selectedSrNos.emit(this.SrNos);
  }
}

export class SrNoSelectionDialogModel {

}
