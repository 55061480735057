import { NgModule } from '@angular/core';
import { MaintenanceLogService } from './maintenance-log.service';
import { CompressorService } from './compressor.service';
import { MeasurementUnitService } from './measurement-unit.service';
import { BusinessUnitService } from './business-unit.service';

@NgModule({
  imports: [
  ],
  providers: [
    MaintenanceLogService,
    CompressorService,
    MeasurementUnitService,
    BusinessUnitService
  ],
  declarations: [
  ],
  exports: [
  ],
})
export class HttpModule { }