<div class="container">
  <form [formGroup]="compressorForm">
    <div class="row">
      <div class="col">
        <mat-card>
          <mat-card-content>
            <div class="row">
              <div class="col">
                <h2>business unit</h2>
                <mat-form-field appearance="outline">
                  <mat-label>select business unit</mat-label>
                  <mat-select name="BU" [(ngModel)]="selectedBU" (selectionChange)="updateBusinessUnit()"
                    formControlName="businessUnit" required>
                    <mat-option *ngFor="let businessUnit of businessUnits" [value]="businessUnit">
                      {{ businessUnit.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="hasError('businessUnit', 'required')">
                    Please select a business unit.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col">
                <h2>facility</h2>
                <mat-form-field appearance="outline">
                  <mat-label>select facility</mat-label>
                  <mat-select name="Facility" [(ngModel)]="selectedFacility" (selectionChange)="updateEquipment()"
                    formControlName="facility" required>
                    <mat-option *ngFor="let facility of facilities" [value]="facility">
                      {{ facility.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="hasError('facility', 'required')">
                    Please select a facility.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-md-10">
                <h2>compressor</h2>
                <mat-form-field appearance="outline" style="width: 95%;">
                  <mat-label>select compressor</mat-label>
                  <mat-select name="Compressor" [(ngModel)]="selectedCompressor"
                    (selectionChange)="updateEventLogs(selectedCompressor.compressorEquipmentId)"
                    formControlName="compressor" required>
                    <mat-option *ngFor="let compressor of compressors" [value]="compressor">
                      {{ compressor.compressorEquipmentId }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="hasError('compressor', 'required')">
                    Please select a compressor.
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-2" style="margin-top: 63px;">
                <span *ngIf="selectedCompressor">
                  <a class="mx-4" href="#" (click)="onViewDrawingClick()" rel="noopener"
                    style="font-size: 16px; font-weight: bold;">view drawing</a>
                </span>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col">
        <mat-card class="namePlateCard">
          <mat-card-content>
            <mat-tab-group mat-stretch-tabs #tabGroup mat-align-tabls="center" animationDuration="0ms"
              *ngIf="selectedCompressor ; else noCompressor">
              <mat-tab label="compressor details">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-6 col-lg-4">
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong">Manufacturer:</div>
                        <div class="col-6">{{selectedCompressor.manufacturer == null ? 'N/A' :
                          selectedCompressor.manufacturer}} </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong">Model: </div>
                        <div class="col-6">{{selectedCompressor.model == null ? 'N/A' : selectedCompressor.model}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong">Serial number: </div>
                        <div class="col-6">{{(selectedCompressor.serialNumber == null ? 'N/A' :
                          selectedCompressor.serialNumber)}} </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong"> Rated power: </div>
                        <div class="col-6">{{selectedCompressor.ratedPower == null ? 'N/A' :
                          selectedCompressor.ratedPower}}
                          <span *ngIf="selectedCompressor.ratedPowerUnit !=null">
                            ({{measurementUnitsMap[selectedCompressor.ratedPowerUnit] || 'N/A'}})
                          </span>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong">Rated speed: </div>
                        <div class="col-6">{{selectedCompressor.ratedSpeed == null ? 'N/A' :
                          selectedCompressor.ratedSpeed}}
                          <span *ngIf="selectedCompressor.ratedSpeedUnit !=null">
                            ({{measurementUnitsMap[selectedCompressor.ratedSpeedUnit] || 'N/A'}})
                          </span>
                        </div>
                        <!-- <div class="col-6">{{selectedCompressor.ratedSpeed == null ? 'N/A' :
                          selectedCompressor.ratedSpeed}} </div> -->
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong">Configuration: </div>
                        <div class="col-6">{{selectedCompressor.configuration == null ? 'N/A' :
                          selectedCompressor.configuration}} </div>
                      </div>
                    </div>
                    <div class="col-md-6 col-lg-4">
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong">Seal gas: </div>
                        <div class="col-6">{{selectedCompressor.sealGas == null ? 'N/A' : selectedCompressor.sealGas}}
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong">Drive end seal no: </div>
                        <div class="col-6">{{selectedCompressor.deSealNumber == null ? 'N/A' :
                          selectedCompressor.deSealNumber}} </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col-6 font-weight-strong">Non drive end seal no: </div>
                        <div class="col-6">{{selectedCompressor.ndeSealNumber == null ? 'N/A' :
                          selectedCompressor.ndeSealNumber}} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </mat-tab>
            </mat-tab-group>
            <ng-template #noCompressor>
              <h1 class="noCompressorMessage"> Please select a compressor to view details.</h1>
            </ng-template>
          </mat-card-content>
        </mat-card>
        <mat-card *ngIf="selectedCompressor">
          <mat-card-header>
            <mat-card-title>filters</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col-3"></div>
              <div class="col-3">
                <!-- <mat-radio-group class="eventLogFiltering" value="All">
                  <mat-radio-button labelPostion='after' disableRipple="true" value="All"
                    (click)="onSealPlacementSelected('All')">All DGS Events</mat-radio-button>
                  <mat-radio-button labelPostion='after' disableRipple="true" value="DE"
                    (click)="onSealPlacementSelected('DE')">Drive End Events</mat-radio-button>
                  <mat-radio-button labelPostion='after' disableRipple="true" value="NDE"
                    (click)="onSealPlacementSelected('NDE')">Non Drive End Events</mat-radio-button>
                </mat-radio-group> -->
                <mat-form-field appearance="outline">
                  <mat-label>select seal placement</mat-label>
                  <mat-select name="filterType" [(value)]="selected"
                    (selectionChange)="onSealPlacementSelected(selected)">
                    <mat-option *ngFor="let option of filterTypes" [value]="option.id">
                      {{ option.name }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="col-5">
                <mat-form-field appearance="fill">
                  <mat-label>Enter a date range</mat-label>
                  <mat-date-range-input [rangePicker]="picker">
                    <input matStartDate placeholder="Start date" (dateChange)="startChange($event)">
                    <input matEndDate placeholder="End date" (dateChange)="endChange($event)">
                  </mat-date-range-input>
                  <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-date-range-picker #picker>
                    <mat-date-range-picker-actions>
                      <button mat-button matDateRangePickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>
                </mat-form-field>
              </div>
              <div class="col-1"></div>
            </div>
          </mat-card-content>
        </mat-card>
        <mat-card class="log-table" *ngIf="selectedCompressor">
          <mat-card-header>
            <mat-card-title style="text-align: center;">
              event logs
            </mat-card-title>
            <mat-card-title style="text-align: center;">
              {{selectedCompressor.compressorEquipmentId}}: {{selectedCompressor.compressorDescription}}
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <table #logSort="matSort" matSort mat-table [dataSource]="eventLogTable" class="results-table w-100"
              aria-describedby="" style="width: 100%;">
              <ng-container matColumnDef="log-date">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Log Date
                </th>
                <td mat-cell *matCellDef="let result">{{ result.entryDate | date: 'MMM d, y'}}</td>
              </ng-container>
              <ng-container matColumnDef="event-date">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Event Date
                </th>
                <td mat-cell *matCellDef="let result">{{ result.dateIn | date: 'MMM d, y'}}</td>
              </ng-container>
              <ng-container matColumnDef="workOrderNo">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Work Order#
                </th>
                <td mat-cell *matCellDef="let result" class="text-center">{{ result.workOrderNumber}}</td>
              </ng-container>
              <ng-container matColumnDef="reason">
                <th mat-header-cell *matHeaderCellDef scope="col">
                  Reason
                </th>
                <td mat-cell *matCellDef="let result" class="text-center">{{ result.reason }}</td>
              </ng-container>
              <ng-container matColumnDef="sealFailure">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Seal Failure
                </th>
                <td mat-cell *matCellDef="let result" class="text-center">{{ result.primarySecondarySealFail }}</td>
              </ng-container>
              <ng-container matColumnDef="failureCause">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Failure Cause
                </th>
                <td mat-cell *matCellDef="let result" class="text-center">{{ result.failureCause}}</td>
              </ng-container>
              <ng-container matColumnDef="serialRemoved">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Serial Removed
                </th>
                <td mat-cell *matCellDef="let result" class="text-center">{{ result.serialRemoved }}</td>
              </ng-container>
              <ng-container matColumnDef="serialInstalled">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Serial Installed
                </th>
                <td mat-cell *matCellDef="let result" class="text-center">{{ result.serialInstalled }}</td>
              </ng-container>
              <ng-container matColumnDef="comments">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Comments
                </th>
                <td mat-cell *matCellDef="let result" class="text-center">{{ result.comments }}</td>
              </ng-container>
              <ng-container matColumnDef="sealPlacement">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  Seal Placement
                </th>
                <td mat-cell *matCellDef="let result" class="text-center">{{ result.sealPlacement }}</td>
              </ng-container>
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef scope="col">
                  Edit Log
                </th>
                <td mat-cell *matCellDef="let row">
                  <button mat-icon-button matTooltip="Edit DGS Event Log" color="primary" (click)="onEditLogClick(row)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </ng-container>
              <!-- View Drawing Column -->
              <!-- <ng-container matColumnDef="view">
                <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
                  View Drawing
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                  <button mat-icon-button matTooltip="View DGS Drawing" color="primary" (click)="onViewDrawingClick(row)">
                    <mat-icon>view</mat-icon>
                  </button>
                </td>
              </ng-container> -->

              <tr mat-header-row *matHeaderRowDef="displayedLogColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedLogColumns"></tr>
            </table>
            <mat-paginator #logPaginator [pageSizeOptions]="[20, 50, 100]" showFirstLastButtons
              aria-label="Select page of results">
            </mat-paginator>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>