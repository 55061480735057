import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FileUploadAdminService } from '#services/http/fileupload-admin.service';
import { UploadResponse } from '#models/upload-response.interface'; // Import the UploadResponse interface
import { NotificationService } from '#services/notification.service';
import { SnackBarComponent } from '#components/SnackBarComponent/snack-bar.component';

@Component({
  selector: 'app-dgs-bulkupload',
  templateUrl: './dgs-bulkupload.component.html',
  styleUrls: ['./dgs-bulkupload.component.scss']
})
export class DgsBulkUploadComponent implements OnInit {
  adminForm: FormGroup;
  selectedFile: File;
  filePath: string;
  currentImportType: string;

  @ViewChild('fileInput') fileInput: any;
  @ViewChild('compressorFileInput') compressorFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('drawingFileInput') drawingFileInput: ElementRef<HTMLInputElement>;
  @ViewChild('eventLogFileInput') eventLogFileInput: ElementRef<HTMLInputElement>;

  constructor(
    private fb: FormBuilder,
    private adminService: FileUploadAdminService,
    private snackBar: MatSnackBar,
    private notifyService: NotificationService
  ) { }

  ngOnInit() {
    this.adminForm = this.fb.group({
      file: [null, Validators.required]
    });
  }

  onFileSelected(input: any, importType: string) {
    this.currentImportType = importType;
    this.selectedFile = input.files[0];
    const formData = new FormData();
    formData.append('file', this.selectedFile);

    this.adminService.uploadFile(formData).subscribe(
      (response: UploadResponse) => {
        this.filePath = response.filePath;
        this.snackBar.open('File uploaded successfully', 'Close', {
          duration: 3000
        });
      },
      error => {
        this.snackBar.open('Error uploading file', 'Close', {
          duration: 3000
        });
      }
    );
  }

  onImport(importType: string) {
    // Check if filePath is set
    if (this.filePath) {
      // Call the appropriate service method based on the import type
      switch (importType) {
        case 'Compressors':
          this.adminService.importCompressors(this.filePath).subscribe(
            (response: any) => {
              let combinedMessage = response.messageText;
              if (response.data && response.data.invalidRowNumbers && response.data.invalidRowNumbers.length > 0) {                
                combinedMessage += ` Row number(s) ${response.data.invalidRowNumbers.join(', ')} not imported/updated.`;
              }
              this.snackBar.openFromComponent(SnackBarComponent,{
                data: combinedMessage,
                duration:0
              });
            },
            error => {
              this.snackBar.open('Error importing compressor data.', 'Close', {
                duration: 3000
              });
            }
          );
          break;
        case 'Drawings':
          this.adminService.importDGSDrawing(this.filePath).subscribe(
            (response: any) => {
              let combinedMessage = response.messageText;
              if (response.data && response.data.invalidRowNumbers && response.data.invalidRowNumbers.length > 0) {                
                combinedMessage += ` Row number(s) ${response.data.invalidRowNumbers.join(', ')} not imported/updated.`;
              }
              this.snackBar.openFromComponent(SnackBarComponent,{
                data: combinedMessage,
                duration:0
              });
            },
            error => {
              this.snackBar.open('Error importing DGS Drawing data.', 'Close', {
                duration: 3000
              });
            }
          );
          break;
        case 'EventLogs':
          this.adminService.importMaintenanceLog(this.filePath).subscribe(
            (response: any) => {
              let combinedMessage = response.messageText;
              if (response.data && response.data.invalidRowNumbers && response.data.invalidRowNumbers.length > 0) {                
                combinedMessage += ` Row number(s) ${response.data.invalidRowNumbers.join(', ')} not imported/updated.`;
              }
              this.snackBar.openFromComponent(SnackBarComponent,{
                data: combinedMessage,
                duration:0
              });
            },
            error => {
              this.snackBar.open('Error importing Maintenance Log data.', 'Close', {
                duration: 3000
              });
            }
          );
          break;
        default:
          break;
      }
    } else {
      this.snackBar.open('Please upload a file first', 'Close', {
        duration: 3000
      });
    }
  }

  resetFileSelection(importType: string) {
    if (this.currentImportType === importType) {
      // Clear the selected file
      this.selectedFile = null;
      // Reset current import type
      this.currentImportType = null;

      // Clear the file input value
      switch (importType) {
        case 'Compressors':
          this.compressorFileInput.nativeElement.value = '';
          break;
        case 'Drawings':
          this.drawingFileInput.nativeElement.value = '';
          break;
        case 'EventLogs':
          this.eventLogFileInput.nativeElement.value = '';
          break;
        default:
          break;
      }
    }
  }


}
