import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UnitTypesService {
  getUnitTypesUrl = '/UnitTypes/getUnitTypes';
  insertUnitTypesUrl = '/UnitTypes/insertUnitType';
  deleteUnitTypesUrl = '/UnitTypes/deleteUnitType';
  updateUnitTypesUrl = '/UnitTypes/updateUnitType';

  // Unit Outage types
  getUnitOutageTypesUrl = '/UnitTypes/getOutageTypes';
  insertUnitOutageTypesUrl = '/UnitTypes/insertOutageType';
  deleteUnitOutageTypesUrl = '/UnitTypes/deleteOutageType';
  updateUnitOutageTypesUrl = '/UnitTypes/updateOutageType';

  // Unit type sections
  getSectionsUrl = '/UnitTypes/getSections';
  getUnitTypeSectionsUrl = '/UnitTypes/GetUnitTypeSections';
  insertUnitTypeSectionUrl = '/UnitTypes/insertUnitTypeSection';
  deleteUnitTypeSectionUrl = '/UnitTypes/deleteUnitTypeSection';
  moveUnitTypeSectionUrl = '/UnitTypes/moveUnitTypeSection';

  // Unit type section components
  getUnitSectionComponentsUrl = '/UnitTypes/getUnitTypeComponents';
  getComponentsUrl = '/UnitTypes/getComponents';
  insertUnitSectionComponentUrl = '/UnitTypes/insertUnitTypeComponent';
  deleteUnitSectionComponentUrl = '/UnitTypes/deleteUnitTypeComponent';
  updateUnitTypeComponentUrl = '/UnitTypes/updateUnitTypeComponent';
  updateComponentOrderUrl = '/UnitTypes/moveUnitTypeComponent';

  constructor(private http: HttpClient) { }

  public getUnitTypesData() {
    return this.http.get<any>(environment.apiEndpoint + this.getUnitTypesUrl);
  }

  insertUnitType(formData: any) {
    return this.http.post(environment.apiEndpoint + this.insertUnitTypesUrl, formData,
      {
        params: {
          unittype: formData
        }
      });
  }

  deleteUnitType(code: string) {
    return this.http.post(environment.apiEndpoint + this.deleteUnitTypesUrl, code,
      {
        params: {
          code: code
        }
      });
  }

  UpdateUnitTypeDetails(formData: any) {
    return this.http.post(environment.apiEndpoint + this.updateUnitTypesUrl, formData,
      {
        params: {
          org: formData
        }
      });
  }


  // Unit Outage types
  public getUnitOutageTypesData(code: string) {
    return this.http.get<any>(environment.apiEndpoint + this.getUnitOutageTypesUrl,
      {
        params: {
          unitType: code
        }
      });
  }

  insertUnitOutageType(formData: any) {
    return this.http.post(environment.apiEndpoint + this.insertUnitOutageTypesUrl, formData,
      {
        params: {
          unittype: formData
        }
      });
  }

  deleteUnitOutageType(formData: any) {
    return this.http.post(environment.apiEndpoint + this.deleteUnitOutageTypesUrl, formData,
      {
        params: {
          formData: formData
        }
      });
  }

  UpdateUnitOutageTypeDetails(formData: any) {
    return this.http.post(environment.apiEndpoint + this.updateUnitOutageTypesUrl, formData,
      {
        params: {
          formData: formData
        }
      });
  }


  // Unit type sections
  public getSectionsData(code: string) {
    return this.http.get<any>(environment.apiEndpoint + this.getSectionsUrl,
      {
        params: {
          frametype: code
        }
      });
  }

  public getUnitTypeSectionsData(code: string) {
    return this.http.get<any>(environment.apiEndpoint + this.getUnitTypeSectionsUrl,
      {
        params: {
          frametype: code
        }
      });
  }

  insertUnitTypeSection(formData: any) {
    return this.http.post(environment.apiEndpoint + this.insertUnitTypeSectionUrl, formData,
      {
        params: {
          unittype: formData
        }
      });
  }

  deleteUnitTypeSection(formData: any) {
    return this.http.post(environment.apiEndpoint + this.deleteUnitTypeSectionUrl, formData,
      {
        params: {
          formData: formData
        }
      });
  }

  UpdateUnitTypeSectionOrder(formData: any) {
    return this.http.post(environment.apiEndpoint + this.moveUnitTypeSectionUrl, formData,
      {
        params: {
          formData: formData
        }
      });
  }


  // Unit type components
  getUnitSectionComponentsData(frametype: string, organizationcode: string, kit: any, section: string, sortbynameonly: any) {
    return this.http.get<any>(environment.apiEndpoint + this.getUnitSectionComponentsUrl,
      {
        params: {
          frameType: frametype,
          organizationCode: organizationcode,
          kit: kit,
          section: section,
          sortbynameonly: sortbynameonly
        }
      });
  }

  public getComponents(code: string) {
    return this.http.get<any>(environment.apiEndpoint + this.getComponentsUrl,
      {
        params: {
          frametype: code
        }
      });
  }

  insertUnitTypeComponent(formData: any) {
    return this.http.post(environment.apiEndpoint + this.insertUnitSectionComponentUrl, formData,
      {
        params: {
          unittypecomponent: formData
        }
      });
  }

  deleteUnitComponent(formData: any) {
    return this.http.post(environment.apiEndpoint + this.deleteUnitSectionComponentUrl, formData,
      {
        params: {
          formData: formData
        }
      });
  }

  UpdateComponentDetails(formData: any) {
    return this.http.post(environment.apiEndpoint + this.updateUnitTypeComponentUrl, formData,
      {
        params: {
          formData: formData
        }
      });
  }

  UpdateComponentOrder(formData: any) {
    return this.http.post(environment.apiEndpoint + this.updateComponentOrderUrl, formData,
      {
        params: {
          formData: formData
        }
      });
  }
}
