import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class OutagesOutageEntryService {

  GetCompaniesUrl = '/OutagesOutageEntry/getCompanies';
  GetunitsUrl = '/OutagesOutageEntry/getUnits';
  GetOutageTypeUrl = '/OutagesOutageEntry/getOutageTypes';
  GetFrameTypeUrl = '/OutagesOutageEntry/getFrameType';
  InsertOutageUrl = '/OutagesOutageEntry/insertOutageEntry';

  constructor(private http: HttpClient,) { }

  getCompanies(organizationCode: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetCompaniesUrl,
      {
        params: {
          organizationCode: organizationCode
        }
      });
  }

  getOutageTypes(organizationCode: string, companyCode: string, unit: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutageTypeUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
        }
      });
  }

  getFrameType(organizationCode: string, companyCode: string, unit: string, type: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetFrameTypeUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          type: type
        }
      });
  }

  insertOutageEntry(formData: any) {
    return this.http.post(environment.apiEndpoint + this.InsertOutageUrl, formData);
  }

}
