<div class="row" style="background-color: white; width: 99%; margin-top: 5px; margin-left: 5px;">
    <div class="mat-elevation-z8">
        <table class="BlackText">
            <tbody>
                <tr style="height: 30px;">
                    <td style="padding-left: 14px;"><b>Search:&nbsp;</b></td>
                    <td width="15">
                        <mat-form-field appearance="outline" style="height: 26px; width: 170px;">
                            <mat-select [(value)]="searchField">
                                <mat-option selected="searchField" value="0">Serial Numbers</mat-option>
                                <mat-option value="1">Part Numbers</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td style="padding-left: 5px; padding-right: 5px;">
                        <mat-form-field appearance="outline" style="height: 26px; width: 170px;">
                            <mat-select [(value)]="searchFor">
                                <mat-option selected="searchFor" value="0">Starting With</mat-option>
                                <mat-option value="1">Ending With</mat-option>
                                <mat-option value="2">Containing</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                    <td width="15">
                        <input type="text" MaxLength="50" size="50" class="BlackText" [(ngModel)]="searchValue">
                    </td>
                    <td style="padding-left: 5px;" width="15">
                        <input type="submit" value="Search" class="BlackText" (click)="getSerialNumberLookupData(searchValue)">
                    </td>
                </tr>
            </tbody>
        </table>

        <div class="ListViewContainer; col-md-10" id="ListViewContainer" *ngIf="serialNumberData?.length > 0"
            style="width: 100%; margin-top: 5px;">
            <table mat-table matSort style="border: 1px solid grey;" [dataSource]="serialNumberDataSource">
                <!-- Serial Number Column -->
                <ng-container matColumnDef="Serial_Number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Serial Number </th>
                    <td mat-cell *matCellDef="let row">
                        <a href="javascript:void(0);" (click)="ShowSerialNumberDialog(row)">
                            {{row.serial_Number}}
                        </a>
                    </td>
                </ng-container>

                <!-- Organization Column -->
                <ng-container matColumnDef="Organization_Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.organization_Description}}
                    </td>
                </ng-container>

                <!-- Unit Type Column -->
                <ng-container matColumnDef="Frame_Type_Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit Type </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.frame_Type_Description}}
                    </td>
                </ng-container>

                <!-- Component Column -->
                <ng-container matColumnDef="Component_Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Component </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.component_Description}}
                    </td>
                </ng-container>

                <!-- DLN Type Column -->
                <ng-container matColumnDef="DLN_Description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> DLN Type </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.dlN_Description}}
                    </td>
                </ng-container>

                <!-- Current Set Column -->
                <ng-container matColumnDef="Set_Number">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Current Set </th>
                    <td mat-cell *matCellDef="let row">
                        <a href="javascript:void(0);" (click)="ShowSetNumberDialog(row)">
                            {{row.set_Number}}
                        </a>
                    </td>
                </ng-container>

                <!-- Part Number Column -->
                <ng-container matColumnDef="PartNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Part Number </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.partNumber}}
                    </td>
                </ng-container>

                <!-- Starting Hrs Column -->
                <ng-container matColumnDef="Starting_Hours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Starting Hrs </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.starting_Hours | number : '1.2-2'}}
                    </td>
                </ng-container>

                <!-- Start Column -->
                <ng-container matColumnDef="Start_Date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Start </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.start_Date | date: 'MM/dd/yyyy'}}
                    </td>
                </ng-container>

                <!-- End Column -->
                <ng-container matColumnDef="End_Date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> End </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.end_Date | date: 'MM/dd/yyyy'}}
                    </td>
                </ng-container>

                <!-- Hours Column -->
                <ng-container matColumnDef="Hours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Hours </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.hours | number : '1.2-2'}}
                    </td>
                </ng-container>

                <!-- LifeTime Column -->
                <ng-container matColumnDef="LifeTimeHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> LifeTime Hours </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.lifeTimeHours | number : '1.2-2'}}
                    </td>
                </ng-container>

                <!-- Status Column -->
                <ng-container matColumnDef="StatusDescription">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
                    <td mat-cell *matCellDef="let row">
                        {{row.statusDescription}}
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedSerialNoColumns" class="header-color"
                    style="color: white;"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedSerialNoColumns;"></tr>
            </table>
            <mat-paginator #paginator [pageIndex]="0" [pageSize]="20" showFirstLastButtons
                [pageSizeOptions]="[20, 50, 100, 200, 500]" aria-label="Select page of users"></mat-paginator>
        </div>
    </div>
</div>