import { spGetOutage } from '#models/spGetOutage';
import { spGetPartsOutForRepair } from '#models/spGetPartsOutForRepair';
import { PartsService } from '#services/http/parts.service'; 
import { OutageEntryService } from '#services/http/outage-entry.service';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'app/core/authentication/auth.service';
import { spGetPartsStatus } from '#models/spGetPartsStatus';

@Component({
  selector: 'app-part-status',
  templateUrl: './part-status.component.html',
  styleUrl: './part-status.component.scss'
})
export class PartStatusComponent {
  outageParam = new spGetOutage();
  public outageColumns = ['OrganizationName', 'CompanyName', 'Unit', 'OutageTypeDescription',
    'OutageDate', 'AsOf']
    partStatusColumns: string[] = ['externalReferenceNumber', 'componentName', 'setNumber', 'qty', 'newUsed', 
      'location', 'lifeTimeHours', 'repairCount', 'customerWantDate', 'repairFacilityCommitmentDate', 
      'repairFacilityShipDate', 'endDate', 'inventoryInspectionDate', 'inspectedBy', 'comments'
    ];
    
  public outageDataSource: MatTableDataSource<spGetOutage>;
  outageInfo: spGetOutage[] = [];
  partsStatusInfo: spGetPartsStatus[] = [];
  currentDate: Date = new Date();
  public partsStatusDataSource: MatTableDataSource<spGetPartsStatus>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<spGetOutage>;

  constructor(private outageEntryService: OutageEntryService,
    private partsService: PartsService,
    public dialog: MatDialog, private authService: AuthService,
    private route: ActivatedRoute,) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.outageParam.organizationCode = params['OrgCode'];
      this.outageParam.companyCode = params['CompCode'];
      // this.outageParam.frameType = params['FrType'];
      this.outageParam.unit = params['Unit'];
      this.outageParam.outageDate = params['OutDate'];
    })

    this.outageEntryService.getOutageEntry(this.outageParam.organizationCode, this.outageParam.companyCode, this.outageParam.unit, this.outageParam.outageDate.toString())
      .subscribe((res: spGetOutage[]) => {
        this.outageInfo = res;
        this.outageDataSource = new MatTableDataSource<spGetOutage>(this.outageInfo);

      });

    this.partsService.getPartsStatus(this.outageParam.organizationCode, this.outageParam.companyCode, this.outageParam.unit, this.outageParam.outageDate.toString())
      .subscribe((res: spGetPartsStatus[]) => {
        this.partsStatusInfo = res;
        this.partsStatusDataSource = new MatTableDataSource<spGetPartsStatus>(this.partsStatusInfo);

      });
  }
}
