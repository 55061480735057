<div class="container">
    <!-- <div class="row">
        <mat-card>
            <mat-card-header>
             <mat-card-title class="pbi-header">dry gas seal metrics</mat-card-title>
            </mat-card-header>
        </mat-card>
    </div> -->
    <iframe title="DRY GAS Seal - Prod" 
        width="1140" height="541.25" 
        src="https://app.powerbi.com/reportEmbed?reportId=57356936-92e5-4113-b507-de49bd44271a&autoAuth=true&ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26"
        frameborder="0" 
        allowFullScreen="true">
    </iframe>
</div>