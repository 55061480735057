<div style="background-color: white; min-height: 450px;">
    <div style="float:left; margin-right:5px; margin-top: 5px;" class="col-md-2">
        <table class="BlackText Border" cellpadding="0" cellspacing="0">
            <tbody>
                <tr class="Header" style="height:18px">
                    <td align="center" style="font-weight:bold; background-color: #009dd0;">
                        <span>Organization</span>
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 190px;">
                            <mat-select [(value)]="selectedOrg" (selectionChange)="onOrganizationChange($event)">
                                <mat-option *ngFor="let row of organizations" [value]="row.code">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Unit Type
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 190px;">
                            <mat-select [(value)]="selectedUnit" (selectionChange)="onUnitTypeChange($event)">
                                <mat-option *ngFor="let row of unitTypes" [value]="row.code">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Component
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 190px;">
                            <mat-select [(value)]="selectedComponent" (selectionChange)="onComponentChange($event)">
                                <!-- <mat-option value="ALL">ALL</mat-option> -->
                                <mat-option *ngFor="let row of components" [value]="row.componentCode">
                                    {{ row.componentName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        DLN
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 190px;">
                            <mat-select [(value)]="selectedDlnNo" (selectionChange)="onDLNChange($event)">
                                <mat-option *ngFor="let row of dlns" [value]="row.id">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Sets
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field appearance="outline" style="height: 20px; width: 190px;">
                            <mat-select [(value)]=" selectedSet" (selectionChange)="onSetsChange($event)">
                                <mat-option value="SPARES">SPARES</mat-option>
                                <mat-option *ngFor="let row of sets" [value]="row.setNumber">
                                    {{ row.setNumber }} </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="submit" value="View Set" class="BlackText" style="width:75px; margin-top: 10px;"
                            (click)="ShowPartInfoDialog()" *ngIf="selectedOrg !== ''
                            && selectedUnit !== null && selectedComponent !== null && selectedSet !== null">
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
    </div>

    <div class="ListViewContainer; col-md-9" style="width: 84%; margin-left: -30px; margin-top: 5px;">
        <div class="errormsg" *ngIf="srNoErrorMessage">
            <p [innerHTML]="srNoErrorMessage" class="ErrorMsgArea" style="margin-bottom: 20px!important;"></p>
        </div>

        <table cellpadding="4" cellspacing="0" class="BlackText Border" style="width: 100%;" *ngIf="selectedOrg !== ''
             && selectedUnit !== null && selectedComponent !== null && selectedSet !== null">
            <tbody>
                <tr>
                    <td colspan="10">
                        <input type="button" id="btnImport" value="Import" class="BlackText"
                            style="width:75px; margin-left: 4px;" (click)="showUploadControls();">
                        <input type="button" id="btnMove" value="Move" class="BlackText"
                            style="width:75px; margin-left: 4px;" (click)="onMoveClick()">
                        <input type="button" id="btnScrap" value="Scrap" class="BlackText"
                            style="width:75px; margin-left: 4px;" (click)="onScrapClick()">
                        <input type="submit" value="Delete" class="BlackText" style="width:75px; margin-left: 4px;"
                            (click)="onDeleteClick()">
                        <input type="submit" value="Report" class="BlackText" style="width:75px; margin-left: 4px;">
                        <input type="button" value="Select All" class="BlackText" style="width:75px; margin-left: 4px;"
                            (click)="SelectAll();">
                        <input type="button" value="Clear All" class="BlackText" style="width:75px; margin-left: 4px;"
                            (click)="ClearAll()">
                    </td>
                </tr>
                <tr id="trScrap">
                    <td colspan="10">
                        <table class="BlackText" style="margin-top: 10px; margin-left: 10px;" *ngIf="isScrap">
                            <tbody>
                                <tr>
                                    <td>
                                        <span style="margin-top: 10px;">Scrap Date:</span>
                                    </td>
                                    <td>
                                        <mat-form-field appearance="outline" style="height: 20px; margin-left: 5px;">
                                            <input matInput [matDatepicker]="scrapPicker" placeholder="MM/dd/yyyy"
                                                [(ngModel)]="scrapDate" (dateChange)="OnScrapDateChange(scrapDate)">
                                            <mat-datepicker-toggle matIconSuffix
                                                [for]="scrapPicker"></mat-datepicker-toggle>
                                            <mat-datepicker #scrapPicker></mat-datepicker>
                                        </mat-form-field>
                                    </td>
                                    <td colspan="2" align="center">
                                        <input type="submit" value="Submit" class="BlackText"
                                            style="margin-left: 5px; margin-top: 6px;" (click)="ScrapSerialnumber()">
                                        <input type="button" class="BlackText" value="Cancel" style="margin-left: 5px;"
                                            (click)="onScrapCancelClick()">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr *ngIf="uploadControlsVisible">
                    <td colspan="10">
                        <label style="margin-left: 4px; font-weight: normal; padding-top: 5px;">Select
                            file to import:</label>
                        <input type="file" id="fileUpload" class="BlackText"
                            style="margin-left: 4px;padding-top: 1px; padding-bottom: 5px;"
                            (change)="onFileSelected($event)">
                    </td>
                </tr>
                <tr id="trMove" *ngIf="isMove">
                    <td colspan="10">
                        <table class="BlackText">
                            <tbody>
                                <tr>
                                    <td>Move To Set:</td>
                                    <td>
                                        <mat-form-field appearance="outline"
                                            style="height: 20px; width: 170px; margin-top: 10px;">
                                            <mat-select [(value)]=" selectedMove"
                                                (selectionChange)="onMoveToSetChange($event)">
                                                <mat-option value="SPARES">SPARES</mat-option>
                                                <mat-option *ngFor="let row of moveToSetsValues"
                                                    [value]="row.setNumber"> {{ row.setNumber }} </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Move Date:</td>
                                    <td>
                                        <mat-form-field appearance="outline" style="height: 20px; margin-top: 6px;">
                                            <input matInput [matDatepicker]="movePicker" placeholder="MM/dd/yyyy"
                                                [(ngModel)]="moveDate" (dateChange)="OnMoveDateChange(moveDate)">
                                            <mat-datepicker-toggle matIconSuffix
                                                [for]="movePicker"></mat-datepicker-toggle>
                                            <mat-datepicker #movePicker></mat-datepicker>
                                        </mat-form-field>
                                    </td>
                                    <td colspan="2" align="center">
                                        <input type="submit" value="Submit" class="BlackText"
                                            style="margin-top: 11px; margin-left: 5px;"
                                            (click)="MoveToSetSerialnumber()">
                                        <input type="button" class="BlackText" value="Cancel" style="margin-left: 5px;"
                                            (click)="onMoveToSetCancelClick()">
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr *ngIf="uploadControlsVisible">
                    <td colspan="10">
                        <input type="button" id="btnSubmit" value="Submit" class="BlackText"
                            style="width:75px; margin-left:4px; " (click)="submitFile();">
                        <input type="button" id="btnCancel" value="Cancel" class="BlackText"
                            style="width:75px; margin-left:4px;" (click)="hideUploadControls();">
                    </td>
                </tr>
                <tr *ngIf="uploadControlsVisible">
                    <td colspan="10">
                        <input type="checkbox" style="margin-left: 4px;padding-top: 5px; padding-bottom: 5px;"
                            id="headerRow" [(ngModel)]="isHeaderRow"> Header Row
                    </td>
                </tr>
                <tr *ngIf="uploadControlsVisible">
                    <td colspan="10">
                        <small style="margin-left: 4px;">Note: File must be in CSV format and have
                            the following columns in
                            this order: Serial Number, Part Number, Starting Hours, Start Date
                        </small>
                    </td>
                </tr>
                <tr colspan="10">
                    <td><br class="BlackText"></td>
                </tr>
                <tr class="Header outage-info" style="text-align: center;
                    font-weight: bold; font-size: 14px;">
                    <th style="width: 3%;"> </th>
                    <th>Serial Number</th>
                    <th>Part Number</th>
                    <th>Starting<br>Hrs</th>
                    <th>Start</th>
                    <th style="width: 7%;">End</th>
                    <th style="width: 7%;">Fired<br>Starts</th>
                    <th style="width: 7%;">Hours</th>
                    <th style="width: 7%;">Lifetime<br>Hours</th>
                    <th style="width: 7%;">Status</th>
                    <th style="width: 19%;"><br></th>
                </tr>
                <tr class="Row1" *ngIf="!isUpdateSrNo">
                    <td><input type="checkbox" class="BlackText" style="display: none;"></td>
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="35" style="margin-top: 5px;"
                            [(ngModel)]="insertSRNO.serialNumber">
                    </td>
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="35"
                            style="margin-top: 5px; margin-left: 3px; margin-right: 3px;"
                            [(ngModel)]="insertSRNO.partNumber">
                    </td>
                    <td>
                        <input type="number" maxlength="7" class="BlackText" size="7" style="margin-top: 5px;"
                            [(ngModel)]="insertSRNO.starting_Hours"
                            (change)="UpdateStartingHours(insertSRNO, 'startHours', insertSRNO.starting_Hours)">
                    </td>
                    <td style="text-align: center;">
                        <input type="date" id="srDate" name="srDate" [(ngModel)]="addSrNoStartDate"
                            (change)="onStartDateChange($event)" style="margin-left: 3px;
                                margin-top: 4px; width: 88px;">
                    </td>
                    <td><br></td>
                    <td><br></td>
                    <td><br></td>
                    <td><br></td>
                    <td><br></td>
                    <td>
                        <input type="submit" value="Add" class="blacktext" (click)="addSerialNumber(insertSRNO)">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            (click)="cancelAddSerialNumber()">
                    </td>
                </tr>
                <tr class="Row2" *ngIf="isUpdateSrNo">
                    <td><br></td>
                    <td style="width: 43%;">
                        <span class="BlackText"> {{ updateSRNO.serial_Number }} </span>
                    </td>
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="35"
                            style="margin-left: 3px; margin-right: 3px;" [(ngModel)]="updateSRNO.partNumber">
                    </td>
                    <td>
                        <input type="number" maxlength="7" class="BlackText" size="7"
                            [(ngModel)]="updateSRNO.starting_Hours"
                            (change)="UpdateStartingHours(updateSRNO, 'startHoursUpdate', updateSRNO.starting_Hours)">
                    </td>
                    <td>
                        <input type="date" id="srDateUpdate" name="srDateUpdate" [value]="updateSrNoStartDate"
                            [(ngModel)]="updateSrNoStartDate" (change)="onUpdateStartDateChange($event)" style="margin-left: 3px;
                                width: 88px;">
                    </td>
                    <td>
                        <span class="BlackText"></span>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><br></td>
                    <td>
                        <input type="submit" value="Update" class="blacktext" (click)="updateSerialNumber(updateSRNO)">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            (click)="cancelUpdateSerialNumber(updateSRNO)">
                    </td>
                </tr>
                <tr *ngFor="let set of serialNumbers let i = index">
                    <td align="left">
                        <input type="checkbox" class="BlackText" [(ngModel)]="set.isSelected"
                            (ngModelChange)="onSrNoChange(set.serial_Number, $event)">
                    </td>
                    <td align="left">
                        <a href="javascript:void(0);"
                            (click)="ShowSerialNumberHistoryDialog(set)">{{set.serial_Number}}</a>
                    </td>
                    <td align="left">
                        {{ set.partNumber }}
                    </td>
                    <td align="center">
                        {{ set.starting_Hours | number:'1.2-2' }}
                    </td>
                    <td align="center">
                        {{ set.start_Date | date: 'MM/dd/yyyy' }}
                    </td>
                    <td align="center">
                        {{ set.end_Date | date: 'MM/dd/yyyy' }}
                    </td>
                    <td align="center">
                        <span *ngIf=" set.firedStarts === -1"> N/A </span>
                        <span *ngIf=" set.firedStarts !== -1"> {{ set.firedStarts }} </span>
                    </td>
                    <td align="center">
                        {{ set.hours | number:'1.2-2' }}
                    </td>
                    <td align="center">
                        {{ set.lifeTimeHours | number:'1.2-2' }}
                    </td>
                    <td align="center">
                        {{ set.statusDescription }}
                    </td>
                    <td align="left">
                        <input type="submit" value="Edit" class="BlackText" style="margin-top: 3px;"
                            (click)="EditSerialNumber(set, i)" [disabled]="isUpdateSrNo">
                        <input type="submit" value="Unscrap" class="BlackText" style="margin-left: 3px;"
                            *ngIf="set.statusDescription === 'Scrapped'"
                            (click)="UnscrapSerialnumber(set.serial_Number)">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>