<div class="container" style="background-color: white;">
    <div class="mat-elevation-z8">
        <table cellpadding="4" cellspacing="0" class="BlackText Border">
            <tbody>
                <tr class="Header outage-info" style="text-align: center;
                font-weight: bold; font-size: 14px;">
                    <th>Code</th>
                    <th>Description</th>
                    <th></th>
                </tr>
                <tr class="Row1" *ngIf="!isUpdateOrg">
                    <td>
                        <input type="text" maxlength="5" class="BlackText" size="15"
                            style="margin-top: 5px; margin-left: 5px;" [(ngModel)]="insertOrg.code">
                    </td>
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="35"
                            style="margin-top: 5px; margin-left: 3px; margin-right: 3px;"
                            [(ngModel)]="insertOrg.description">
                    </td>
                    <td>
                        <input type="submit" value="Insert" class="blacktext" style="margin-top: 5px;"
                            (click)="addOrganization(insertOrg)">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            (click)="cancelAddOrganization()">
                    </td>
                </tr>
                <tr class="Row2" *ngIf="isUpdateOrg">
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="15" style="margin-top: 5px; margin-left: 5px;border: none;
                            cursor: default;
                            background-color: transparent;
                            color: inherit;
                            pointer-events: none;
                            outline: none;" [(ngModel)]="updateOrg.code">
                    </td>
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="35"
                            style="margin-top: 5px; margin-left: 3px; margin-right: 3px;"
                            [(ngModel)]="updateOrg.description">
                    </td>
                    <td>
                        <input type="submit" value="Update" class="blacktext" (click)="updateOrganization(updateOrg)">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            (click)="cancelUpdateOrganization(updateOrg)">
                    </td>
                </tr>
                <tr *ngFor="let set of organizations let i = index">
                    <td align="left" style="padding-left: 6px;">
                        {{set.code}}
                    </td>
                    <td align="left" style="padding-left: 4px;">
                        {{ set.description }}
                    </td>
                    <td align="left">
                        <input type="submit" value="Edit" class="BlackText" style="margin-top: 3px;"
                            (click)="EditSection(set, i)" [disabled]="isUpdateSection">
                        <input type="submit" value="Delete" class="BlackText" style="margin-top: 3px; margin-left: 3px;"
                            (click)="deleteOrganization(set)" *ngIf="set.inUse == 0" [disabled]="isUpdateSection">
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>