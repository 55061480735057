<div>

    <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
        cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
            Unit Outage Types
        </h1>
        <span style="background-color: antiquewhite;">
            <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">
                X
            </button>
        </span>
    </div>

    <div mat-dialog-content>
        <div style="margin-left:7px; margin-top: 5px;">
            <span class="BlackText Bold"> Unit Type: {{ data.unitType }}<br></span>

            <table mat-table matSort style="margin-top: 10px; border: 1px solid grey;" aria-describedby=""
                [dataSource]="unitOutageTypesDataSource">

                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center">
                        Code </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                        <span *ngIf="row.code != '0'">{{ row.code }}</span>
                        <input type="text" maxlength="5" class="BlackText" size="5" *ngIf="row.code == '0'"
                            [(ngModel)]="code">
                    </td>
                    <td mat-cell *matCellDef="let row"></td>
                </ng-container>

                <ng-container matColumnDef="description">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center">
                        Description </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                        <input type="text" maxlength="50" class="BlackText" size="25" *ngIf="row.code == '0'"
                            [(ngModel)]="description">

                        <ng-container *ngIf="!isEditing(row)">{{row.description}}</ng-container>
                        <ng-container *ngIf="isEditing(row) && row.code != '0'">
                            <input type="text" [(ngModel)]="updatedDesc" maxlength="50" size="25" class="BlackText">
                        </ng-container>
                    </td>
                    <td mat-cell *matCellDef="let row"></td>
                </ng-container>

                <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center">
                        Duration (Days) </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                        <input type="text" class="BlackText" size="5" *ngIf="row.code == '0'" [(ngModel)]="duration"
                            step="1" (keypress)="preventDecimal($event)">

                        <ng-container *ngIf="!isEditing(row)">{{row.duration}}</ng-container>
                        <ng-container *ngIf="isEditing(row) && row.code != '0'">
                            <input type="text" [(ngModel)]="updatedDuration" size="5" class="BlackText" step="1"
                                (keypress)="preventDecimal($event)">
                        </ng-container>
                    </td>
                    <td mat-cell *matCellDef="let row"></td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef scope="col" style="width: 30%;"></th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!isEditing(row)">
                            <input type="submit" value="Insert" class="BlackText"
                                (click)="addUnitOutageType(code, description)"
                                style="margin-top: 5px; margin-left: 3px;" *ngIf="row.code == '0'">
                            <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                                *ngIf="row.code == '0'" (click)="cancelInsertUnitOutageType()">

                            <input type="submit" value="Edit" class="BlackText" *ngIf="row.code != '0'"
                                style="margin-left: 3px;" (click)="onEditUnitOutageTypeClick(row)">
                            <input type="submit" value="Delete" class="BlackText"
                                style="margin-left: 3px;" (click)="deleteUnitOutageType(row)"
                                *ngIf="row.inUse == 0 && row.code != '0'">
                        </ng-container>

                        <ng-container *ngIf="isEditing(row)">
                            <input type="submit" value="Update" class="BlackText" *ngIf="row.code != '0'"
                                (click)="UpdateUnitOutageTypeDetails(row, updatedDesc, updatedDuration)">
                            <input type="submit" value="Cancel" class="BlackText" *ngIf="row.code != '0'"
                                style="margin-left: 3px;" (click)="cancelUpdateUnitOutageType(row)">
                        </ng-container>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="unitOutageTypesColumns" style="color: white; height: 20px;"
                    class="header-color">
                </tr>
                <tr mat-row *matRowDef="let row; columns: unitOutageTypesColumns;" style="height: 40px !important;">
                </tr>

                <tr class="mat-row" *matNoDataRow>
                    <td class="mat-cell" colspan="4">No data</td>
                </tr>
            </table>
        </div>
    </div>
</div>