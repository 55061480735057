<div class="container" style="background-color: white;">
    <div style="float:left; margin-right:5px; margin-top: 5px;" class="col-md-2">
        <table class="BlackText Border" cellpadding="0" cellspacing="0">
            <tbody>
                <tr class="Header" style="height:18px">
                    <td align="center" style="font-weight:bold; background-color: #009dd0;">
                        <span>Organization</span>
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 170px;">
                            <mat-select [(value)]="selectedOrg" (selectionChange)="onOrganizationChange($event)">
                                <mat-option *ngFor="let row of organizations" [value]="row.code">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Unit Type
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 170px;">
                            <mat-select [(value)]="selectedUnit" (selectionChange)="onUnitTypeChange($event)">
                                <mat-option *ngFor="let row of unitTypes" [value]="row.code">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Company
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="organizationCode == '' || unitType == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="organizationCode !== '' && unitType !== ''">
                                    <td>
                                        <input type="checkbox" [(ngModel)]="allCompanies"
                                            (change)="onCompanyChange('All', $event)">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Companies
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let company of companies">
                                            <input type="checkbox" [(ngModel)]="company.isSelected"
                                                (change)="onCompanyChange(company.companyCode, $event)">
                                            <span style="margin-left: 1px;"> {{company.companyName}} </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Unit
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="organizationCode == '' || unitType == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedCompaniesBackEnd !== ''">
                                    <td>
                                        <input type="checkbox" value="All Units" [(ngModel)]="allUnits"
                                            (change)="onUnitChange('All', $event)">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Units
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let unit of units">
                                            <input type="checkbox" [(ngModel)]="unit.isSelected"
                                                (change)="onUnitChange(unit.unit, $event)">
                                            <span style="margin-left: 1px;"> {{unit.unit}} </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Outage Type
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="organizationCode == '' || unitType == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedUnitsBackEnd !== ''">
                                    <td>
                                        <input type="checkbox" value="All Outage Types"
                                            (change)="onOutageTypeChange('All', $event)" [(ngModel)]="allOutageTypes">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Outage Types
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let type of outageTypes">
                                            <input type="checkbox" [(ngModel)]="type.isSelected"
                                                (change)="onOutageTypeChange(type.code, $event)">
                                            <span style="margin-left: 1px;"> {{type.description}} </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Outage Status
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field appearance="outline" style="height: 26px; width: 170px;">
                            <mat-select [(value)]="selected" (selectionChange)="onOutageStatusChange($event)">
                                <mat-option selected="selected" value="All">All Outages</mat-option>
                                <mat-option value="C">Completed</mat-option>
                                <mat-option value="F">Future</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>

                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Outage Sections
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="BlackText">
                            <tbody>
                                <tr>
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <input type="checkbox" value="All Outage Sections"
                                            (change)="onOutageSectionChange('All', $event)"
                                            [(ngModel)]="allOutageSections">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Outage Sections
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let sec of outageSections">
                                            <input type="checkbox" [(ngModel)]="sec.isSelected"
                                                (change)="onOutageSectionChange(sec.description, $event)">
                                            <span style="margin-left: 1px;"> {{sec.description}} </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>

            </tbody>
        </table>
        <br />
    </div>

    <div class="ListViewContainer; col-md-9" id="ListViewContainer"
        style="width: 80%; margin-left: 20px; margin-top: 5px; overflow: auto;" *ngIf="outagesDetailsData?.length > 0">
        <table class="BlackText" style="margin-bottom: 3px;">
            <tr>
                <td>Legend:&nbsp;&nbsp;</td>
                <td width="20" style="background-color:green"><br /></td>
                <td>&nbsp;First Used</td>
                <td width="10"><br></td>
                <td width="20" style="background-color:Red"><br /></td>
                <td>&nbsp;Scrapped</td>
                <td width="10"><br></td>
                <td width="20" style="background-color:white; border-color: black;border-width: medium;"><br /></td>
                <td>&nbsp;Hours Exceeded</td>
            </tr>
        </table>
        <table mat-table matSort [dataSource]="outagesDataSource" class="mat-elevation-z8">
            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Date </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <a href="javascript:void(0);" (click)="ShowOutageInfoDialog(row)">
                        {{row.outageDate | date: 'MM/dd/yyyy'}}
                    </a>
                </td>
            </ng-container>

            <!-- Co Column -->
            <ng-container matColumnDef="co">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Co </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.companyCode }}
                </td>
            </ng-container>

            <!-- Unit Column -->
            <ng-container matColumnDef="unit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Unit </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.unit }}
                </td>
            </ng-container>

            <!-- Outage Type Column -->
            <ng-container matColumnDef="outageType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Outage Type
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"> {{row.outageType}} </td>
            </ng-container>

            <!-- Notes Column -->
            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Notes </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <a href="javascript:void(0);" (click)="ShowNotesDialog(row.comments)">
                        <img *ngIf="row.comments !== null" alt="User Guide" src="/assets/images/document.gif" /></a>
                </td>
            </ng-container>

            <!-- Unit Hrs Column -->
            <ng-container matColumnDef="unitHrs">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Unit Hrs
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{row.unitFiredHours | number : '1.2-2'}}
                </td>
            </ng-container>

            <!-- Interval Hrs Column -->
            <ng-container matColumnDef="intervalHrs">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Interval Hrs
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"
                    [ngStyle]="{'color': (row.intervalHours < 0) ? 'red' : black }">
                    {{row.intervalHours | number : '1.2-2'}}
                </td>
            </ng-container>

            <!-- Dynamic Columns Cells -->
            <ng-container *ngFor="let column of thirdLevelHeaders">
                <ng-container [matColumnDef]="column">
                    <th mat-header-cell *matHeaderCellDef>
                        {{ column.split('_')[1] }}
                    </th>
                    <td mat-cell *matCellDef="let row">
                        <a href="javascript:void(0);" (click)="ShowPartInfoDialog(row, row[column])">
                            {{ row[column] }}
                        </a>
                    </td>
                </ng-container>
            </ng-container>

            <!-- Existing dynamic headers -->
            <ng-container *ngFor="let header of dynamicHeaders; let i = index">
                <ng-container [matColumnDef]="header">
                    <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'"
                        [attr.colspan]="(header === 'blankHeader' ? staticColumns.length : subHeaders[i].length * 2)"
                        [class.no-border]="header === 'blankHeader'">
                        <ng-container *ngIf="header !== 'blankHeader'"> {{ header }} </ng-container>
                    </th>
                </ng-container>
            </ng-container>

            <!-- Existing subheaders -->
            <ng-container *ngFor="let subHeaderGroup of subHeaders; let i = index">
                <ng-container *ngFor="let subHeader of subHeaderGroup; let j = index">
                    <ng-container [matColumnDef]="subHeader">
                        <th mat-header-cell *matHeaderCellDef [style.text-align]="'center'"
                            [attr.colspan]="(subHeader === 'blankSubHeader' ? staticColumns.length : 2)"
                            [class.no-border]="subHeader === 'blankSubHeader'">
                            <ng-container *ngIf="subHeader !== 'blankSubHeader'"> {{ subHeader }} </ng-container>
                        </th>
                    </ng-container>
                </ng-container>
            </ng-container>

            <!-- Header rows -->
            <tr mat-header-row *matHeaderRowDef="dynamicHeaders" class="header-row"></tr>
            <tr mat-header-row *matHeaderRowDef="subHeaders.flat()" class="header-row"></tr>
            <tr mat-header-row *matHeaderRowDef="columnDefinitions" class="header-row"></tr>

            <!-- Row Definition -->
            <tr mat-row *matRowDef="let row; columns: columnDefinitions;"></tr>
        </table>
    </div>
</div>
<style>


</style>