export class spGetSerialNumbers {
    organization_Code: string = '';
    serial_Number: string = '';
    end_Date: Date;
    component_Code: string = '';
    frame_Type: string = '';
    set_Number: string = '';
    start_Date: Date;
    starting_Hours?: number = 0;
    status: string = '';
    dln: boolean = false;
    movedTo: string = '';
    movedFrom: string = '';
    partNumber: string = '';
    organization_Description: string = '';
    component_Description: string = '';
    frame_Type_Description: string = '';
    dln_Description: string = '';
    firedStarts?: number = 0;
    hours: number = 0;
    lifeTimeHours?: number = 0;
    statusDescription: string = '';
    lastDate: Date;
    hasHistory?: number = 0;
    isSelected: boolean = false;
}