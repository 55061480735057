import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})

export class FooterComponent {
  public currentYear: number = new Date().getFullYear();
  public reptVersion: string = "v0.0.1";
  @Input() isTops: boolean = false;
}
