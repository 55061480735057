import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { Observable } from 'rxjs';
import { MeasurementUnit } from '#models/measurement-unit';

@Injectable({
  providedIn: 'root'
})
export class MeasurementUnitService {

  constructor(private http: HttpClient) { }

  public getMeasurementUnits(): Observable<MeasurementUnit[]> {

    return this.http.get<MeasurementUnit[]>(`${environment.apiEndpoint}/MeasurementUnits`);
  }

  public getMeasurementUnit(id: number): Observable<MeasurementUnit> {

    return this.http.get<MeasurementUnit>(`${environment.apiEndpoint}/MeasurementUnits/${id}`);
  }

}