<div class="container" style="background-color: white;">
    <!-- <div class="mat-elevation-z8"> -->
        <table class="BlackText" style="width:100%">
            <tbody>
                <tr style="height: 30px; text-align: center;">
                    <th colspan="8" style="background-color: #009dd0; border-bottom:1px solid white; color: white; ">
                        <span style="font-size: 17px; font-weight: bold;">Chevron Major Turbine Part Status
                            Report</span>
                    </th>
                </tr>
            </tbody>
        </table>

        <table mat-table [dataSource]="outageDataSource" matSort>

            <!-- Organization Column -->
            <ng-container matColumnDef="OrganizationName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Organization Name </th>
                <td mat-cell *matCellDef="let row"> {{row.description}} </td>
            </ng-container>

            <!-- Frame Type Column -->
            <ng-container matColumnDef="CompanyName">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Company Name </th>
                <td mat-cell *matCellDef="let row"> {{row.companyName}} </td>
            </ng-container>

            <!-- Component Column -->
            <ng-container matColumnDef="Unit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Unit </th>
                <td mat-cell *matCellDef="let row"> {{row.unit}} </td>
            </ng-container>

            <!-- Set/Part Column -->
            <ng-container matColumnDef="OutageTypeDescription">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> OutageType Description
                </th>
                <td mat-cell *matCellDef="let row"> {{row.outageTypeDescription}} </td>
            </ng-container>

            <!-- Repair Facility Column -->
            <ng-container matColumnDef="OutageDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Outage Date </th>
                <td mat-cell *matCellDef="let row"> {{row.outageDate | date:'MM/dd/yyyy'}} </td>
            </ng-container>

            <!-- Out Date Column -->
            <ng-container matColumnDef="AsOf">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> As Of </th>
                <td mat-cell *matCellDef="let row"> {{currentDate | date: 'MM/dd/yyyy'}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="outageColumns" class="header-back-color" style="color: white;">
            </tr>
            <tr mat-row *matRowDef="let row; columns: outageColumns;"></tr>
        </table>
        <br>
        <div class="table-container">
            <table mat-table [dataSource]="partsStatusDataSource" matSort class="mat-elevation-z8-tbl">
                <!-- Ref # Column -->
                <ng-container matColumnDef="externalReferenceNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Ref # </th>
                    <td mat-cell *matCellDef="let row"> {{row.externalReferenceNumber}} </td>
                </ng-container>
                <!-- Description Column -->
                <ng-container matColumnDef="componentName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Description </th>
                    <td mat-cell *matCellDef="let row"> {{row.componentName}} </td>
                </ng-container>
                <!-- Set/Part # Column -->
                <ng-container matColumnDef="setNumber">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Set/Part # </th>
                    <td mat-cell *matCellDef="let row"> {{row.setNumber}} </td>
                </ng-container>
                <!-- Qty Column -->
                <ng-container matColumnDef="qty">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Qty </th>
                    <td mat-cell *matCellDef="let row"> {{row.qty}} </td>
                </ng-container>
                <!-- New/Used Column -->
                <ng-container matColumnDef="newUsed">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> New/Used </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.lifeTimeHours === 0 ? 'New' : (row.repairCount > 0 ? 'Refurbished' : 'Used') }}
                    </td>
                </ng-container>
                <!-- Location Column -->
                <ng-container matColumnDef="location">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Location </th>
                    <td mat-cell *matCellDef="let row">
                        {{ row.statusDescription === '' ? 'Available' : row.statusDescription }}
                    </td>
                </ng-container>
                <!-- Fired Hours Column -->
                <ng-container matColumnDef="lifeTimeHours">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Fired Hours </th>
                    <td mat-cell *matCellDef="let row"> {{ row.lifeTimeHours | number: '1.2-2':'en-US':'1.0-0' }} </td>
                </ng-container>
                <!-- # of Previous Repairs Column -->
                <ng-container matColumnDef="repairCount">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> # of Previous Repairs
                    </th>
                    <td mat-cell *matCellDef="let row"> {{row.repairCount}} </td>
                </ng-container>
                <!-- Want Date Column -->
                <ng-container matColumnDef="customerWantDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Want Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.customerWantDate | date:'MM/dd/yyyy'}} </td>
                </ng-container>
                <!-- Commitment Date Column -->
                <ng-container matColumnDef="repairFacilityCommitmentDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Commitment Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.repairFacilityCommitmentDate | date:'MM/dd/yyyy'}} </td>
                </ng-container>
                <!-- Ship Date Column -->
                <ng-container matColumnDef="repairFacilityShipDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Ship Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.repairFacilityShipDate | date:'MM/dd/yyyy'}} </td>
                </ng-container>
                <!-- Received Date Column -->
                <ng-container matColumnDef="endDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Received Date </th>
                    <td mat-cell *matCellDef="let row"> {{row.endDate | date:'MM/dd/yyyy'}} </td>
                </ng-container>
                <!-- Inventory & Inspection Date Column -->
                <ng-container matColumnDef="inventoryInspectionDate">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Inventory & Inspection
                        Date </th>
                    <td mat-cell *matCellDef="let row"></td>
                </ng-container>
                <!-- Inspected By Column -->
                <ng-container matColumnDef="inspectedBy">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Inspected By </th>
                    <td mat-cell *matCellDef="let row"></td>
                </ng-container>
                <!-- Comments Column -->
                <ng-container matColumnDef="comments">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Comments </th>
                    <td mat-cell *matCellDef="let row" style="min-width: 250px;"> {{row.comments}} </td>
                </ng-container>
                <!-- Header row first group -->
                <ng-container matColumnDef="header-row-first-group">
                    <th mat-header-cell *matHeaderCellDef [style.text-align]="center" [attr.colspan]="6">
                    </th>
                </ng-container>
                <!-- Header row second group -->
                <ng-container matColumnDef="header-row-second-group">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="2"> Operational Data </th>
                </ng-container>
                <!-- Header row third group -->
                <ng-container matColumnDef="header-row-third-group">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="4"> Repair Data </th>
                </ng-container>
                <!-- Header row fourth group -->
                <ng-container matColumnDef="header-row-fourth-group">
                    <th mat-header-cell *matHeaderCellDef [attr.colspan]="3"></th>
                </ng-container>

                <tr mat-header-row
                    *matHeaderRowDef="['header-row-first-group', 'header-row-second-group','header-row-third-group','header-row-fourth-group']"
                    class="header-back-color" style="color: white;"></tr>
                <tr mat-header-row *matHeaderRowDef="partStatusColumns" class="header-back-color" style="color: white;">
                </tr>
                <tr mat-row *matRowDef="let row; columns: partStatusColumns;"></tr>
            </table>
        </div>
    <!-- </div> -->
</div>