import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-not-found-error',
  templateUrl: './not-found-error.page.html',
  styleUrls: ['./not-found-error.page.scss']
})
export class NotFoundErrorPage implements OnInit {

  constructor(
    private router: Router
  ) {
    // do nothing
  }

  ngOnInit() {
    // do nothing
  }
}