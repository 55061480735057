import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { spInsertRepair } from '#models/spInsertRepair';

@Injectable({
  providedIn: 'root'
})

export class PartRepairService {

  GetOwnerPlantUrl = '/PartRepair/getOwnerPlants';
  GetUnitsUrl = '/PartRepair/getUnits';
  PostRepairUrl = '/PartRepair/updateRepairDetails';
  InsertRepairUrl = '/PartRepair/insertRepair';
  GetRepairOpeningLetterUrl = '/PartRepair/getRepairOpeningLetter';
  GetInsertRepairValidateUrl = '/PartRepair/getInsertRepairValidation';

  constructor(private http: HttpClient,) { }

  getAllOwnerPlants(organizationCode: string, frameType: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOwnerPlantUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType
        }
      });
  }

  getAllUnits(organizationCode: string, frameType: string, companyCode: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetUnitsUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType,
          companyCode: companyCode
        }
      });
  }

  postRepairData(formData: any) {
    return this.http.post(environment.apiEndpoint + this.PostRepairUrl, formData);
  }

  insertRepairData(formData: any) {
    return this.http.post(environment.apiEndpoint + this.InsertRepairUrl, formData);
  }

  getInsertRepairValidate(organizationCode: string, setNumber: string, componentCode: string,
    frameType: string, startDate: string, endDate: string) {

    return this.http.get<string>(environment.apiEndpoint + this.GetInsertRepairValidateUrl,
      {
        params: {
          organizationCode: organizationCode,
          setNo: setNumber,
          componentCode: componentCode,
          frameType: frameType,
          startDate: startDate,
          endDate: endDate
        }
      });
  }

  getRepairOpeningLetterData(organizationCode: string, setNumber: string, componentCode: string, frameType: string, startDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetRepairOpeningLetterUrl,
      {
        params: {
          organizationCode: organizationCode,
          setNo: setNumber,
          componentCode: componentCode,
          frameType: frameType,
          startDate: startDate
        }
      });
  }
}
