<div style="overflow-y: hidden !important;">

  <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
      Repair Information
    </h1>
    <span style="background-color: antiquewhite;">
      <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">
        X
      </button>
    </span>
  </div>

  <div style="margin-left: 10px;">
    <table class="BlackText" cellpadding="4" cellspacing="0">
      <tbody>
        <tr>
          <td>Organization:</td>
          <td>{{ repairInfo?.organization }}</td>
        </tr>
        <tr>
          <td>Component:</td>
          <td>{{ repairInfo?.component }}</td>
        </tr>
        <tr>
          <td>Unit Type:</td>
          <td>{{ repairInfo?.frameTypeDescription }}</td>
        </tr>
        <tr>
          <td>Set Number:</td>
          <td>{{ repairInfo?.setNumber }}</td>
        </tr>
      </tbody>
    </table>

    <input type="button" class="BlackText" value="Edit" style="width:100px; margin-top: 7px;"
      (click)="onEditClick(repairInfo)">
  </div>

  <div mat-dialog-content>

    <div class="row tabbed tabbed-content-control centered" style="margin-top: 10px;">
      <div class="filter-bar tab-filter-bar">
        <mat-tab-group [selectedIndex]="0" animationDuration="0ms" [disableRipple]='true' disablepagination="true">

          <mat-tab label="Repair Information">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr>
                      <td nowrap="">Owner Plant:</td>
                      <td>{{ repairInfo?.companyName }}</td>
                    </tr>
                    <tr>
                      <td nowrap="">Unit:</td>
                      <td>{{ repairInfo?.unit }}</td>
                    </tr>
                    <tr>
                      <td nowrap="">Ship Date:</td>
                      <td>{{ repairInfo?.startDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td nowrap="">Received Date:</td>
                      <td>{{ repairInfo?.endDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td nowrap="">Project Code:</td>
                      <td>{{ repairInfo?.projectCode }}</td>
                    </tr>
                    <tr>
                      <td nowrap="">Internal Reference #:</td>
                      <td>{{ repairInfo?.internalReferenceNumber }}</td>
                    </tr>
                    <tr>
                      <td nowrap="">External Reference #:</td>
                      <td>{{ repairInfo?.externalReferenceNumber }}</td>
                    </tr>
                    <tr>
                      <td nowrap="" valign="top">Serial Numbers:</td>
                      <td>
                        <span id="ctl00_ContentPlaceHolder1_fvRepair_Label1">
                          {{ repairInfo?.serialNumbers }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td nowrap=""># of Serial Numbers:</td>
                      <td valign="top">
                        <span id="ctl00_ContentPlaceHolder1_fvRepair_lblNumberOfSerialNumbers">
                          {{ repairInfo?.numberOfSerialNumbers }}
                        </span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Costs">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>Material:</td>
                      <td align="right">{{ repairInfo?.materialCost | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Material Tax:</td>
                      <td align="right">{{ repairInfo?.materialTax | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Labor:</td>
                      <td align="right">{{ repairInfo?.laborCost | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Labor Tax:</td>
                      <td align="right">{{ repairInfo?.laborTax | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Freight:</td>
                      <td align="right">{{ repairInfo?.freight | number : '1.2-2' }}</td>
                    </tr>
                    <tr>
                      <td>Total Cost:</td>
                      <td align="right" style="border-top:1px solid black">
                        {{ repairInfo?.totalCost }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Notes">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <span id="ctl00_ContentPlaceHolder1_fvRepair_txtNotes" class="BlackText">
                  {{ repairInfo?.notes }}
                </span>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Repair Instructions">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>Customer Want Date:</td>
                      <td>{{ repairInfo?.customerWantDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td>Onsite Customer Want Date:</td>
                      <td>{{ repairInfo?.onsiteCustomerWantDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td>Critically Late Date:</td>
                      <td>{{ repairInfo?.criticallyLateDate | date: 'MM/dd/yyyy'}}</td>
                    </tr>
                    <tr>
                      <td>Warranty Work:</td>
                      <td>{{ repairInfo?.warrantyWork }}</td>
                    </tr>
                    <tr>
                      <td>Repair Reports Required:</td>
                      <td>{{ repairInfo?.repairWorkRequired }}</td>
                    </tr>
                    <tr>
                      <td>Important Job Instructions:</td>
                      <td>{{ repairInfo?.jobInstructions }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Shipping & Receiving">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>Shipping Instructions:</td>
                      <td>{{ repairInfo?.shippingInstructions }}</td>
                    </tr>
                    <tr>
                      <td>Pickup Address if arranged for:</td>
                      <td>{{ repairInfo?.pickUpAddress }}</td>
                    </tr>
                    <tr>
                      <td>Transport Arrangement from Site:</td>
                      <td>{{ repairInfo?.transportArrangement }}</td>
                    </tr>
                    <tr>
                      <td>Transport Date:</td>
                      <td>{{ repairInfo?.transportDateFromSite | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td>Special Requirments for Transport:</td>
                      <td>{{ repairInfo?.specialTransportRequirements }}</td>
                    </tr>
                    <tr>
                      <td>Customer Receiving Requirements (days, hours, etc):</td>
                      <td>{{ repairInfo?.customerReceivingRequirements }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Repair Facility">
            <ng-template matTabContent>
              <div style="margin-top: 10px;">
                <table class="BlackText" cellpadding="4" cellspacing="0">
                  <tbody>
                    <tr>
                      <td>Repair Facility:</td>
                      <td>{{ repairInfo?.repairFacility }}</td>
                    </tr>
                    <tr>
                      <td>Arrival Date:</td>
                      <td>{{ repairInfo?.repairFacilityArrivalDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td>Commitment Date:</td>
                      <td>{{ repairInfo?.repairFacilityCommitmentDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td>Ship Date:</td>
                      <td>{{ repairInfo?.repairFacilityShipDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                    <tr>
                      <td>Inspection Report Date:</td>
                      <td>{{ repairInfo?.inspectionReportDate | date: 'MM/dd/yyyy' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>
  </div>
</div>