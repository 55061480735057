import { NgModule } from '@angular/core';
import { MatButton, MatButtonModule } from '@angular/material/button';
import { CoreModule } from 'app/core/core.module';

// Material Design Modules
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';


// Components
import { DgsMetricsComponent } from './pages/dgs-metrics.component';

@NgModule({
  imports: [
    CoreModule,

    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
 

  ],
  declarations: [
    DgsMetricsComponent,
  ],
  exports: [
  ],
})
export class DgsMetricsModule { }