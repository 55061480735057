import { Component, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '#services/notification.service';
import { OrganizationsService } from '#services/http/organizations.service';
import { AuthService } from 'app/core/authentication/auth.service';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { GetOrganizations } from '#models/GetOrganizations';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})

export class OrganizationsComponent {
  organizations: GetOrganizations[] = [];
  insertOrg = new spGetOrganizations();
  updateOrg = new spGetOrganizations();
  userId: string = '';
  code: string = '';
  description: string = '';
  updatedDesc: string = '';

  public organizationsDataSource: MatTableDataSource<GetOrganizations>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public editState: { [key: string]: boolean } = {}; // creating a JSON key-pair object to track which elements are in 'edit' mode 
  public displayOrgColumns = ['code', 'description', 'actions'];

  constructor(private notifyService: NotificationService, public dialog: MatDialog,
    private organizationService: OrganizationsService, private authService: AuthService) {
  }

  ngOnInit() {
    const userInfo = this.authService.getCurrentUser();
    this.userId = userInfo.username;
    this.getOrganizations();
  }

  getOrganizations() {
    this.organizationService.getOrganizations(this.userId).subscribe((res) => {
      this.organizations = res;

      let type = new GetOrganizations();
      type.code = '0';
      type.description = '';
      this.organizations.unshift(type);

      this.organizationsDataSource = new MatTableDataSource<GetOrganizations>(this.organizations);
      this.organizationsDataSource.paginator = this.paginator;
    });
  }

  public isEditing(row: GetOrganizations): boolean {
    return this.editState[row.description] || false;
  }

  addOrganization(code: string, description: string) {
    if (code == undefined || code == "") {
      this.notifyService.showError("Code is required.", "");
    }
    else if (code.toUpperCase() == 'ALL') {
      this.notifyService.showError("ALL cannot be used as a code.", "");
    }
    else if (description == undefined || description == "") {
      this.notifyService.showError("Description is required.", "");
    }
    else {
      this.insertOrg.code = code.toUpperCase();
      this.insertOrg.description = description;

      this.organizationService.insertOrganization(this.insertOrg, this.userId).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Organization added successfully.", "");
          this.code = null;
          this.description = null;
          code = '';
          description = '';
          this.getOrganizations();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelAddOrganization() {
    this.code = '';
    this.description = '';
  }

  public onEditOrganozationClick(row: spGetOrganizations) {
    this.updatedDesc = row.description;
    this.editState[row.description] = true;
  }

  updateOrganization(orgCode: spGetOrganizations, updatedDesc: string,) {
    if (updatedDesc == '') {
      this.notifyService.showError("Description is required.", "");
    }
    else {
      this.editState[orgCode.description] = false;
      this.updateOrg.code = orgCode.code;
      this.updateOrg.description = updatedDesc;

      this.organizationService.updateOrganization(this.updateOrg, this.userId).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Organizations details modified successfully.", "");
          this.getOrganizations();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelUpdateOrganization(orgCode: spGetOrganizations) {
    this.editState[orgCode.description] = false;
  }

  deleteOrganization(org: spGetOrganizations) {
    this.organizationService.deleteOrganization(org, this.userId).subscribe(res => {
      this.notifyService.showSuccess("Organization deleted successfully.", "");
      this.getOrganizations();
    });
  }

}
