import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SerialNumberLookupService {

  GetSerialNumberLookup = '/SerialNumberLookUp/getSerialNumberLookUp';
 
  constructor(private http: HttpClient,) { }

  getSerialNumberLookup(searchField: string, searchFor: string, userId: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetSerialNumberLookup,
      {
        params: {
          searchField: searchField,
          searchFor: searchFor,
          userId: userId
        }
      });
  }
}
