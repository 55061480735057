import { spGetSerialNumberHistory } from '#models/spGetSerialNumberHistory';
import { PartEntryService } from '#services/http/part-entry.service';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-serialno-history-dialog',
  templateUrl: './serialno-history-dialog.component.html',
  styleUrls: ['./serialno-history-dialog.component.scss']
})

export class SerialnoHistoryDialogComponent {
  serialNos: spGetSerialNumberHistory[] = [];

  constructor(public dialogRef: MatDialogRef<RepairInfoDialogModel>,
    private router: Router,
    private partEntryService: PartEntryService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.partEntryService.getSerialNoHistoryData(this.data.OrgCode, this.data.FrameType,
      this.data.CompCode, this.data.Dln, this.data.SerialNumber).subscribe((res) => {
        this.serialNos = res;
      });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  onClickSerialNo() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/part-entry'], {
        queryParams: {
          OrgCode: this.data.OrgCode,
          CompCode: this.data.CompCode,
          FrType: this.data.FrameType,
          SetNo: this.data.setNumber,
          id: this.data.id
        },
      })
    );

    window.open(url);
  }
}

export class RepairInfoDialogModel {

}
