export class spRepairOpenLetter {
    organizationCode: string = '';
    componentCode: string = '';
    setNumber: string = '';
    frameType: string = '';
    startDate: Date;
    endDate?: Date;
    internalReferenceNumber?: string = '';
    externalReferenceNumber?: string = '';
    notes?: string = '';
    laborCost?: any;
    laborTax?: any;
    materialCost?: any;
    materialTax?: any;
    freight?: any;
    projectCode?: string = '';
    repairFacility?: string = '';
    companyCode?: string = '';
    outageDate?: Date;
    unit?: string = '';
    customerWantDate?: Date;
    onsiteCustomerWantDate?: Date;
    criticallyLateDate?: Date;
    shippingInstructions?: string = '';
    warrantyWork?: string = '';
    repairWorkRequired?: string = '';
    transportArrangement?: string = '';
    transportDateFromSite?: Date;
    pickUpAddress?: string = '';
    customerReceivingRequirements?: string = '';
    specialTransportRequirements?: string = '';
    jobInstructions?: string = '';
    repairFacilityArrivalDate?: Date;
    repairFacilityCommitmentDate?: Date;
    repairFacilityShipDate?: Date;
    inspectionReportDate?: Date;
    serialNumbers?: string = '';
    numberOfSerialNumbers?: number = 0;
    turbineSerialNumber?: string = '';
    firingTemperature?: number = 0;
    fuelSource?: number = 0;
    partNumber?: string = '';
    componentName?: string = '';
    frameTypeDescription?: string = '';
    companyName?: string = '';
    previousRepairs?: number = 0;
    firedHours?: number = 0;
    FiredStarts?: number = 0;
}