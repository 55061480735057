import { AuthService } from './core/authentication/auth.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent {
  title = 'REPT';
  url: string = '';
  loggedin: boolean;
  isTops: boolean = false;

  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
  ) {
    // do nothing
  }

  ngOnInit() {
    this.url = window.location.href;
    this.isTops = this.url.includes('/tops/');
  }

  readonly currentUserProfile$ = this.authService.getCurrentUser();
}
