import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class NewPartService {
  GetDlnUrl = '/Parts/getDln';
  GetHeaderDetailsUrl = '/Parts/getNewPartsHeaderDetails';
  GetPartDetailsUrl = '/Parts/getPartDetails';
  InsertPartEntryUrl = '/Parts/insertPartEntry';

  constructor(private http: HttpClient,) { }

  getNewPartsHeaderDetails(organizationCode: string, componentCode: string, frameType: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetHeaderDetailsUrl,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType
        }
      });
  }

  getAllDln() {
    return this.http.get<any>(environment.apiEndpoint + this.GetDlnUrl);
  }

  getPartDetails(organizationCode: string, componentCode: string, frameType: string, setNo: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetPartDetailsUrl,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType,
          setNo: setNo
        }
      });
  }

  insertPartEntryData(formData: any) {
    return this.http.post(environment.apiEndpoint + this.InsertPartEntryUrl, formData);
  }
}
