


export class Link {
    recordNumber: number;
    type: string;
    description: string;
    link: string;
    field1: string;
    field2: string;
    field3: string;
    field4: string;
    field5: string;
    name: string;
    subLink: string;
    dynamicLink: string;
    length: number;
}
