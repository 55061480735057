import { NgModule } from '@angular/core';
import { CoreModule } from 'app/core/core.module';

// Material Design Modules
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';

// Components
import { NotAuthorizedErrorPage } from './pages/not-authorized-error/not-authorized-error.page';
import { NotFoundErrorPage } from './pages/not-found-error/not-found-error.page';
import { GenericErrorComponent } from './pages/generic-error/generic-error.component';

@NgModule({
  imports: [
    CoreModule,

    // Material design
    MatButtonModule,
    MatCardModule,
  ],
  declarations: [
    NotAuthorizedErrorPage,
    NotFoundErrorPage,
    GenericErrorComponent,
  ],
  exports: [
  ],
})
export class ErrorPageModule { }