import { Component, HostListener, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '#services/notification.service';
import { OutageSummaryService } from '#services/http/outage-summary.service';
import { spGetOutagesSummary } from '#models/spGetOutagesSummary';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NotesComponent } from 'app/modals/notes/notes.component';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { spGetCompaniesByUnitType } from '#models/spGetCompaniesByUnitType';
import { spGetOutagesOutageTypes } from '#models/spGetOutagesOutageTypes';
import { spGetUnits } from '#models/spGetUnits';
import { OutageDialogComponent } from 'app/core/modals/outage-dialog/outage-dialog.component';
import { PartEntryService } from '#services/http/part-entry.service';
import { vOutage } from '#models/VOutage';
import { OutagesBydateRangeService } from '#services/http/outages-bydate-range.service';
import { OutagesByDateRange } from '#models/OutagesByDateRange';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';

@Component({
  selector: 'app-outages-bydate-range',
  templateUrl: './outages-bydate-range.component.html',
  styleUrls: ['./outages-bydate-range.component.scss']
})

export class OutagesBydateRangeComponent {
  //@HostListener('window:scroll')

  public outagesDateRangeColumns = ['date', 'organization', 'company', 'unit', 'serialNumber', 'unitType', 'outageType', 'engineer', 'notes', 'estUnitHours', 'intervalHours']
  organizations: spGetOrganizations[] = [];
  unitTypes: spGetOrganizations[] = [];
  companies: spGetCompaniesByUnitType[] = [];
  units: spGetUnits[] = [];
  outageTypes: spGetOutagesOutageTypes[] = [];
  outageStatuses: spGetOutagesOutageTypes[] = [];
  selectedOrg: string = '';
  selectedUnit: string = '';
  selected = 'All';
  selectedOrgBackEnd: string = '';
  selectedUnitTypesBackEnd: string = '';
  selectedCompaniesBackEnd: string = '';
  selectedUnitsBackEnd: string = '';
  selectedOutagesTypeBackEnd: string = '';
  selectedOutagesStatusBackEnd: string = '';
  allOrganizations: boolean = false;
  allUnitTypes: boolean = false;
  allCompanies: boolean = false;
  allUnits: boolean = false;
  allOutageTypes: boolean = false;
  outagesList: OutagesByDateRange[] = [];
  public outagesDataSource: MatTableDataSource<OutagesByDateRange>;
  outageInfo = new vOutage();
  fromDate?: Date;
  toDate?: any;

  public organizationCode: string = '';
  public unitType: string = '';
  public company: string = '';
  public unit: string = '';
  public outageStatus: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<OutagesByDateRange>;


  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private notifyService: NotificationService, public dialog: MatDialog,
    private outSummaryService: OutageSummaryService, private partEntryService: PartEntryService,
    private upcomingOutagesService: OutagesBydateRangeService,
    @Inject(DOCUMENT) private document: Document) {
  }

  ngOnInit(): void {
    this.getOrganizations();
    this.BuildOutageStatus();
  }

  BuildOutageStatus() {
    this.outageStatuses = [
      { code: 'All', description: 'All Outages', isSelected: false },
      { code: 'C', description: 'Completed', isSelected: false },
      { code: 'F', description: 'Upcoming', isSelected: false }
    ]
  }

  getOrganizations() {
    this.outSummaryService.getOrganizations().subscribe((res) => {
      this.organizations = res;
    });
  }

  onOrganizationChange(org: string, event: any) {
    this.selectedOrgBackEnd = '';

    if (org == 'All' && event.currentTarget.checked == true) {
      this.organizations.forEach(element => {
        element.isSelected = true;
      });
    }
    if (org == 'All' && event.currentTarget.checked == false) {
      this.allOrganizations = false;
      this.organizations.forEach(element => {
        element.isSelected = false;
      });

      this.allUnits = false;
      this.allUnitTypes = false;
      this.unitTypes = null;
      this.units = null;
      this.allOutageTypes = false;
      this.outageTypes = null;
      this.selectedOrgBackEnd = "";
      this.selectedOutagesTypeBackEnd = "";
      this.selectedUnitsBackEnd = "";
      this.selectedUnitTypesBackEnd = '';
    }

    if (org != 'All') {
      this.organizations.find(x => x.code == org).isSelected = event.currentTarget.checked;
    }

    this.organizations.forEach(element => {
      if (element.isSelected == true) {
        this.selectedOrgBackEnd = this.selectedOrgBackEnd + '|' + element.code + '|';
      }
    });

    if (this.selectedOrgBackEnd != "") {
      this.getUnitTypes();
    }

    //clear other lists
    this.companies = null;
    this.units = null;
    this.unitTypes = null;
    this.outageTypes = null;
    this.selectedUnit = '';
    this.unitType = '';
    this.allCompanies = false;
    this.allUnits = false;
    this.allUnitTypes = false;
    this.allOutageTypes = false;
    this.outagesList = null;
    this.selectedCompaniesBackEnd = "";
    this.selectedUnitsBackEnd = "";
  }

  getUnitTypes() {
    this.upcomingOutagesService.getUnitTypes(this.selectedOrgBackEnd).subscribe((res) => {
      this.unitTypes = res;
    });
  }

  onUnitTypeChange(unit: string, event: any) {
    this.selectedUnitTypesBackEnd = '';

    if (unit == 'All' && event.currentTarget.checked == true) {
      this.unitTypes.forEach(element => {
        element.isSelected = true;
      });
    }
    if (unit == 'All' && event.currentTarget.checked == false) {
      this.allUnitTypes = false;
      this.unitTypes.forEach(element => {
        element.isSelected = false;
      });
    }

    this.selectedUnitTypesBackEnd = "";

    if (unit != 'All') {
      this.unitTypes.find(x => x.code == unit).isSelected = event.currentTarget.checked;
    }

    this.unitTypes.forEach(element => {
      if (element.isSelected == true) {
        this.selectedUnitTypesBackEnd = this.selectedUnitTypesBackEnd + '|' + element.code + '|';
      }
    });

    if (this.selectedUnitTypesBackEnd != "") {
      this.getCompanies();
    }

    //clear other lists
    this.companies = null;
    this.units = null;
    this.outageTypes = null;
    this.selectedUnit = '';
    this.unitType = '';
    this.allCompanies = false;
    this.allUnits = false;
    this.allUnitTypes = false;
    this.allOutageTypes = false;
    this.outagesList = null;
    this.selectedCompaniesBackEnd = "";
    this.selectedUnitsBackEnd = "";
  }

  getCompanies() {
    this.allCompanies = false;
    this.upcomingOutagesService.getCompanies(this.selectedOrgBackEnd, this.selectedUnitTypesBackEnd).subscribe((res) => {
      this.companies = res;
    });
  }

  onCompanyChange(company: string, event: any) {
    this.selectedCompaniesBackEnd = '';

    if (company == 'All' && event.currentTarget.checked == true) {
      this.companies.forEach(element => {
        element.isSelected = true;
      });
    }
    if (company == 'All' && event.currentTarget.checked == false) {
      this.allCompanies = false;
      this.companies.forEach(element => {
        element.isSelected = false;
      });
      this.allUnits = false;
      this.outagesList = null;
      this.units = null;
      this.allOutageTypes = false;
      this.outageTypes = null;
      this.selectedCompaniesBackEnd = "";
      this.selectedOutagesTypeBackEnd = "";
      this.selectedUnitsBackEnd = "";
    }

    if (company != 'All') {
      this.companies.find(x => x.companyCode == company).isSelected = event.currentTarget.checked;
    }

    this.companies.forEach(element => {
      if (element.isSelected == true) {
        this.selectedCompaniesBackEnd = this.selectedCompaniesBackEnd + '|' + element.companyCode + '|';
      }
    });

    if (this.selectedCompaniesBackEnd != "") {
      this.getUnits();
    }
  }

  getUnits() {
    this.upcomingOutagesService.getUnits(this.selectedOrgBackEnd, this.selectedCompaniesBackEnd,
      this.selectedUnitTypesBackEnd).subscribe((res) => {
        this.units = res;
      });
  }

  onUnitChange(unit: string, event: any) {
    this.selectedUnitsBackEnd = '';
    if (unit == 'All' && event.currentTarget.checked == true) {
      this.units.forEach(element => {
        element.isSelected = true;
      });
    }
    if (unit == 'All' && event.currentTarget.checked == false) {
      this.units.forEach(element => {
        element.isSelected = false;
      });

      this.allUnits = false;
      this.outageTypes = null;
      this.allOutageTypes = false;
      this.selectedOutagesTypeBackEnd = "";
      this.outagesList = null;
    }

    if (unit != 'All') {
      this.allUnits = false;
      this.outageTypes = null;
      this.allOutageTypes = false;
      this.selectedOutagesTypeBackEnd = "";
      this.outagesList = null;

      this.units.find(x => x.unit == unit).isSelected = event.currentTarget.checked;
    }

    this.units.forEach(element => {
      if (element.isSelected == true) {
        this.selectedUnitsBackEnd = this.selectedUnitsBackEnd + '|' + element.unit + '|';
      }
    });

    if (this.selectedUnitsBackEnd != "") {
      this.getOutageTypes();
    }
  }

  getOutageTypes() {
    this.upcomingOutagesService.getOutageTypes(this.selectedOrgBackEnd, this.selectedCompaniesBackEnd,
      this.selectedUnitsBackEnd).subscribe((res) => {
        this.outageTypes = res;
      });
  }

  onOutageTypeChange(outage: string, event: any) {
    this.selectedOutagesTypeBackEnd = '';
    if (outage == 'All' && event.currentTarget.checked == true) {
      this.outageTypes.forEach(element => {
        element.isSelected = true;
      });
    }
    if (outage == 'All' && event.currentTarget.checked == false) {
      this.allOutageTypes = false;
      this.outageTypes.forEach(element => {
        element.isSelected = false;
      });
      this.selectedOutagesTypeBackEnd = "";
      this.outagesList = null;
    }

    if (outage != 'All') {
      this.allOutageTypes = false;

      this.outageTypes.find(x => x.code == outage).isSelected = event.currentTarget.checked;
    }

    this.outageTypes.forEach(element => {
      if (element.isSelected === true) {
        this.selectedOutagesTypeBackEnd = this.selectedOutagesTypeBackEnd + '|' + element.code + '|';
      }
    });
  }

  onOutageStatusChange(outStatus, event: any) {
    this.outageStatus = null;

    if (outStatus == 'All' && event.currentTarget.checked == true) {
      this.outageStatuses.forEach(element => {
        element.isSelected = true;
      });
      this.outageStatus = null;
    }
    if (outStatus == 'All' && event.currentTarget.checked == false) {
      this.outageStatuses.forEach(element => {
        element.isSelected = false;
      });
      this.outageStatus = null;
    }
    if (outStatus == 'C' && event.currentTarget.checked == true) {
      this.outageStatus = 'C';
    }
    if (outStatus == 'F' && event.currentTarget.checked == true) {
      this.outageStatus = 'F';
    }

    if (outStatus != 'All') {
      this.outageStatuses.find(x => x.code == 'All').isSelected = false;
      this.outageStatuses.find(x => x.code == outStatus).isSelected = event.currentTarget.checked;
    }

    let count = this.outageStatuses.filter(n => n.isSelected).length;

    if (count == 2) {
      this.outageStatuses.forEach(element => {
        element.isSelected = true;
      });
      this.outageStatus = null;
    }
    if (count == 1) {
      this.outageStatus = this.outageStatuses.find(n => n.isSelected == true).code;
    }
  }

  OnFromDateChange(type: string, event: any) {
    if (type == 'From') {
      const startDate = new Date(event.target.value);
      this.fromDate = this.notifyService.ConvertDateBeforeSave(startDate);
    }
    if (type == 'To') {
      const endDate = new Date(event.target.value);
      this.toDate = this.notifyService.ConvertDateBeforeSave(endDate);
    }
  }

  onSubmitClick() {
    this.scrollToTop();

    if (this.fromDate == undefined || this.fromDate == null) {
      this.notifyService.showError("From date required.", "");
    }
    else if (this.toDate == undefined || this.toDate == null) {
      this.notifyService.showError("To date required.", "");
    }
    this.upcomingOutagesService.getOutagesData(this.selectedOrgBackEnd, this.selectedUnitTypesBackEnd,
      this.selectedCompaniesBackEnd, this.selectedUnitsBackEnd, this.selectedOutagesTypeBackEnd,
      this.outageStatus, this.fromDate.toString(), this.toDate).subscribe((res) => {
        this.outagesList = res;
        this.outagesDataSource = new MatTableDataSource<OutagesByDateRange>(this.outagesList);
        setTimeout(() => this.outagesDataSource.paginator = this.paginator);
        if (this.outagesList.length == 0) {
          this.notifyService.showError("No records found.", "");
        }
      });
    this.scrollToTop();
  }

  scrollToTop(): void {
    // scroll to the top of the body
    return this.document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }

  ShowOutageInfoDialog(row: spGetOutagesSummary) {
    this.partEntryService.getOutageInfoOutDate(row.organizationCode, row.companyCode, row.unit,
      row.outageDate.toString()).subscribe((res) => {
        this.outageInfo = res;

        if (this.outageInfo != null) {
          this.dialog.open(OutageDialogComponent, {
            width: "70%",
            height: "95%",
            data: { id: this.outageInfo.id },
          });
        }
      });
  }

  ShowNotesDialog(notes: string) {
    this.dialog.open(NotesComponent, {
      width: "50%",
      height: "55%",

      data: { notes: notes },
    });
  }
}
