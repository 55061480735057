import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

// Models
import { BusinessUnit } from '#models/business-unit';
import { Compressor } from '#models/compressor';
import { Facility } from '#models/facility';
import { MeasurementUnit } from '#models/measurement-unit';
import { MaintenanceLog } from '#models/maintenance-log';

// Services 
import { BusinessUnitService } from '#services/http/business-unit.service';
import { MaintenanceLogService } from '#services/http/maintenance-log.service';
import { CompressorService } from '#services/http/compressor.service';
import { MeasurementUnitService } from '#services/http/measurement-unit.service';

// Table Imports
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { DatePipe } from '@angular/common';
import { TopsHomeService } from '#services/http/tops-home.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.page.html',
  styleUrls: ['./home.page.scss']
})

export class HomePage implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<MaintenanceLog>;

  public businessUnits: BusinessUnit[];
  public selectedBU: BusinessUnit;
  public facilities: Facility[];
  public selectedFacility: Facility;
  public compressors: Compressor[];
  public selectedCompressor: Compressor;
  public measurementUnits: MeasurementUnit[];
  public maintenanceLogs: MaintenanceLog[];
  public eventLogTable: MatTableDataSource<MaintenanceLog>;
  public displayedLogColumns = ['log-date', 'event-date', 'workOrderNo', 'reason', 'sealFailure', 'failureCause', 'serialRemoved', 'serialInstalled', 'comments', 'sealPlacement', 'edit']
  public measurementUnitsMap: { [id: number]: string } = {};
  compressorForm = new FormGroup({
    businessUnit: new FormControl('', Validators.required),
    facility: new FormControl({ value: '', disabled: true }, Validators.required),
    compressor: new FormControl({ value: '', disabled: true }, Validators.required),
    sealPlacement: new FormControl({ value: '', disabled: true }),
  });

  errorSnackBarConfig: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'bottom',
    panelClass: ['snackbar', 'snackbar-error'],
  }

  filterTypes: any[] = [
    { id: 'All', name: 'All' },
    { id: 'DE', name: 'DE' },
    { id: 'NDE', name: 'NDE' }
  ];
  selected = this.filterTypes[0].id;
  filterType = 'All';
  startDate: string;
  endDate: string;

  constructor(
    private maintenanceLogSvc: MaintenanceLogService,
    private compressorSvc: CompressorService,
    private businessUnitSvc: BusinessUnitService,
    private measurementUnitSvc: MeasurementUnitService,
    private router: Router,
    private snackBar: MatSnackBar, public datepipe: DatePipe,
  ) {
    // do nothing
  }

  ngOnInit() {
    this.getBusinessUnits();
    this.getMeasurementUnits();
  }
  getMeasurementUnits(): void {
    this.measurementUnitSvc.getMeasurementUnits().subscribe({
      next: (data: MeasurementUnit[]) => {
        data.forEach(unit => {
          this.measurementUnitsMap[unit.id] = unit.name;
        });
      },
      error: (error: HttpErrorResponse) => {
        this.snackBar.open('Failed to load measurement units.', '', this.errorSnackBarConfig);
        ////console.log(error.error);
        throw error;
      }
    });
  }
  getBusinessUnits(): void {
    this.businessUnitSvc.getAllBusinessUnits()
      .subscribe({
        next:
          (data: BusinessUnit[]) => {
            this.businessUnits = [...data].sort((first, second) => (first.name > second.name ? 1 : -1));
          },
        error: ((error: HttpErrorResponse) => {
          this.snackBar.open('Business units could not be loaded. Please try again later.', '', this.errorSnackBarConfig);
          ////console.log(error.error);
          throw error;
        }),
      });

    // this.topsHomeService.getOutages().subscribe((res: vOutage) => {
    //   //this.outages = res;
    //   console.log(res);
    // });
  }

  getCompressors(): void {
    this.compressorSvc.getCompressors(this.selectedFacility.name)
      .subscribe({
        next: (data: Compressor[]) => {
          ////console.log(data);
          this.compressors = [...data].sort((first, second) => (first.compressorEquipmentId > second.compressorEquipmentId ? 1 : -1));
        },
        error: ((error: HttpErrorResponse) => {
          this.snackBar.open('Equipment data could not be loaded. Please try again later.', '', this.errorSnackBarConfig);
          ////console.log(error.error);
        }),
      });
  }

  updateBusinessUnit(): void {
    this.compressorForm.get('compressor').disable();

    this.selectedFacility = undefined;
    this.selectedCompressor = undefined;

    this.updateFacility();
  }

  updateFacility(): void {
    if (this.selectedBU !== undefined) {
      this.compressorForm.get('facility').enable();

      this.facilities = [...this.selectedBU.facilities].sort((first, second) => (first.name > second.name ? 1 : -1));
    }
  }

  updateEquipment(): void {
    this.selectedCompressor = undefined;

    if (this.selectedFacility !== undefined) {
      this.compressorForm.get('compressor').enable();
      this.getCompressors();
    }
  }

  updateEventLogs(compressorId: string): void {
    this.maintenanceLogSvc.getEventLogs(compressorId).subscribe((data: MaintenanceLog[]) => {
      this.maintenanceLogs = data;

      this.maintenanceLogs.forEach((x: MaintenanceLog) => {
        x.date = this.datepipe.transform(x.entryDate, 'MM-dd-yyyy') //x.entryDate.toString();
      });

      this.eventLogTable = new MatTableDataSource<MaintenanceLog>(this.maintenanceLogs);
      this.eventLogTable.paginator = this.paginator;
      this.sort.sort(({ id: 'date', start: 'desc' }) as MatSortable)
      this.eventLogTable.sort = this.sort;
    })
  }

  public onSealPlacementSelected(sealPlacement: string): void {
    this.filterType = sealPlacement;

    if (sealPlacement === 'Select Filter' || sealPlacement === 'All') {
      this.eventLogTable.data = this.maintenanceLogs;
      this.eventLogTable.data = this.eventLogTable.data.filter((log: MaintenanceLog) =>
        (this.startDate == null ? log.date >= '01-01-1001' : log.date >= this.startDate) &&
        (this.endDate == null ? log.date <= '12-31-2199' : log.date <= this.endDate)
      );
      this.table.renderRows();
    }
    else {
      var filteredLogs: MaintenanceLog[] = [...this.maintenanceLogs];
      filteredLogs = filteredLogs.filter((log: MaintenanceLog) =>
        log.sealPlacement === sealPlacement && (this.startDate == null ? log.date >= '01-01-1001' : log.date >= this.startDate)
        && (this.endDate == null ? log.date <= '12-31-2199' : log.date <= this.endDate)
      );
      this.eventLogTable.data = filteredLogs;
      this.table.renderRows();
    }
  }

  startChange(event: any) {

    this.startDate = this.datepipe.transform(event.value, 'MM-dd-yyyy');
    // //this.startDate = new Date(newDate);

    // // if (this.startDate == null || this.startDate == undefined) {
    // //   this.snackBar.open('Start Date should not be null!', '', this.errorSnackBarConfig)
    // // }

    // // let dd = today.getDate();
    // // let mm = today.getMonth();
    // // let yyyy = today.getFullYear();
    // // if (dd < 10) {
    // //   dd = '0' + dd;
    // // }
    // // if (mm < 10) {
    // //   mm = '0' + mm;
    // // }
    // // today = mm + '-' + dd + '-' + yyyy;

    // //if (this.startDate != null || this.startDate != undefined) {
    if (this.filterType === 'Select Filter' || this.filterType === 'All') {
      this.eventLogTable.data = this.maintenanceLogs.filter((item) =>
        (this.startDate == null ? item.date >= '01-01-1001' : item.date >= this.startDate)
        && (this.endDate == null ? item.date <= '12-31-2199' : item.date <= this.endDate)
      );
      this.table.renderRows();
    }
    else {
      var filteredLogs: MaintenanceLog[] = [...this.maintenanceLogs];
      filteredLogs = filteredLogs.filter((log: MaintenanceLog) =>
        log.sealPlacement === this.filterType && (this.startDate == null ? log.date >= '01-01-1001' : log.date >= this.startDate)
        && (this.endDate == null ? log.date <= '12-31-2199' : log.date <= this.endDate)
      );
      this.eventLogTable.data = filteredLogs;
      this.table.renderRows();
    }
  }

  endChange(event: any) {
    this.endDate = this.datepipe.transform(event.value, 'MM-dd-yyyy');

    // //if (this.endDate != null || this.endDate != undefined) {
    if (this.filterType === 'Select Filter' || this.filterType === 'All') {
      this.eventLogTable.data = this.maintenanceLogs.filter((item) =>
        (this.startDate == null ? item.date >= '01-01-1001' : item.date >= this.startDate)
        && (this.endDate == null ? item.date <= '12-31-2199' : item.date <= this.endDate)
      );
      this.table.renderRows();
    }
    else {
      var filteredLogs: MaintenanceLog[] = [...this.maintenanceLogs];
      filteredLogs = filteredLogs.filter((log: MaintenanceLog) =>
        log.sealPlacement === this.filterType && (this.startDate == null ? log.date >= '01-01-1001' : log.date >= this.startDate)
        && (this.endDate == null ? log.date <= '12-31-2199' : log.date <= this.endDate)
      );
      this.eventLogTable.data = filteredLogs;
      this.table.renderRows();
    }
    // //}
  }

  public hasError(controlName: string, errorName: string): boolean {
    return this.compressorForm.controls[controlName].hasError(errorName) &&
      this.compressorForm.controls[controlName].touched;
  }

  public onEditLogClick(logSelected: MaintenanceLog): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['edit-log'], {
        queryParams: {
          maintenanceLogId: logSelected.id,
          businessUnit: this.selectedBU.name,
          facility: this.selectedFacility.name,
          compressorId: this.selectedCompressor.compressorEquipmentId
        },
      })
    );

    window.open(url, '_blank');
  }

  onViewDrawingClick() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['dgs-drawings'], {
        queryParams: {
          buUnit: this.selectedBU.name,
          facility: this.selectedFacility.name,
          cId: this.selectedCompressor.compressorEquipmentId
        },
      })
    );

    window.open(url, '_blank');
  }

}