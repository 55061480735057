import { Link } from '#models/Link';
import { spGetPart } from '#models/spGetPart';
import { spGetRepairs } from '#models/spGetRepairs';
import { spGetSerialNumbers } from '#models/spGetSerialNumbers';
import { spPartHistory } from '#models/spPartHistory';
import { PartEntryService } from '#services/http/part-entry.service';
import { TopsHomeService } from '#services/http/tops-home.service';
import { DatePipe } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-part-information-dialog',
  templateUrl: './part-information-dialog.component.html',
  styleUrls: ['./part-information-dialog.component.scss']
})
export class PartInformationDialogComponent {

  public displayHistoryColumns = ['company', 'unit', 'type', 'dateIn', 'dateOut', 'interval', 'lifetime', 'firedStarts'];
  public displaySrNosColumns = ['serialNumber', 'partNumber', 'startingHrs', 'start', 'end', 'firedStarts', 'hours', 'lifetimeHours', 'status'];
  public displayRepaiesColumns = ['shipped', 'returned', 'plant', 'unit', 'repairFacility', 'internalRef', 'externalRef', 'view'];

  partEntryInfo = new spGetPart();
  history: spPartHistory[] = [];
  public historyDataSource: MatTableDataSource<spPartHistory>;

  serialNumbers: spGetSerialNumbers[] = [];
  public serialNumbersDataSource: MatTableDataSource<spGetSerialNumbers>;
  serialNoCount: number = 0;

  repairs: spGetRepairs[] = [];
  public repairsDataSource: MatTableDataSource<spGetRepairs>;
  repairsCount: number = 0;

  links = new Link();
  linkStr: string = '';

  @ViewChild(MatTable) table: MatTable<spPartHistory>;
  @ViewChild(MatTable) sNostable: MatTable<spGetSerialNumbers>;

  constructor(public dialogRef: MatDialogRef<PartInfoDialogModel>,
    private router: Router, private activeRoute: ActivatedRoute,
    private partEntryService: PartEntryService, private topsHomeService: TopsHomeService,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.partEntryService.getPartEntryData(this.data.OrgCode, this.data.CompCode,
      this.data.FrType, this.data.SetNo).subscribe((res) => {
        this.partEntryInfo = res;

        // Get History Tab data
        this.partEntryService.getHistoryData(this.data.OrgCode, this.data.SetNo, this.data.CompCode,
          this.data.FrType).subscribe((res) => {
            this.history = res;
            this.historyDataSource = new MatTableDataSource<spPartHistory>(this.history);
          });

        // Get Serial Numbers data
        this.partEntryService.getSerialNumbersData(this.data.OrgCode, this.data.CompCode,
          this.data.FrType, this.data.SetNo).subscribe((res) => {
            this.serialNumbers = res;
            this.serialNumbersDataSource = new MatTableDataSource<spGetSerialNumbers>(this.serialNumbers);
            this.serialNoCount = this.serialNumbers.length;
          });

        // Get repairs data
        this.partEntryService.getRepairsData(this.data.OrgCode, this.data.SetNo, this.data.CompCode,
          this.data.FrType,).subscribe((res) => {
            this.repairs = res;
            this.repairsDataSource = new MatTableDataSource<spGetRepairs>(this.repairs);
            this.repairsCount = this.repairs.length;
          });

        // Get Links data
        this.topsHomeService.getLinksData(this.data.OrgCode, this.data.CompCode,
          this.data.FrType, this.data.SetNo, 'S').subscribe((res: Link) => {
            this.links = res;
            this.linkStr = this.links.dynamicLink;
          });
      });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  public onDateInOutClick(row: spPartHistory) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/outage-entry'], {
        queryParams: {
          OrgCode: row.organization_Code,
          CompCode: row.company_Code,
          Unit: row.unit,
          OutDate: row.outDate
        },
      })
    );

    window.open(url, '_blank');
  }

  public onViewClick(row: spGetRepairs) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/part-repair'], {
        queryParams: {
          OrgCode: row.organizationCode,
          CompCode: row.componentCode,
          FrameType: row.frameType,
          SetNo: row.setNumber,
          StartDate: row.startDate
        },
      })
    );

    window.open(url, '_blank');
  }

  onEditClick(set: spGetPart) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/part-entry'], {
        queryParams: {
          OrgCode: set.organization_Code,
          CompCode: set.component_Code,
          FrType: set.frame_Type,
          SetNo: set.set_Number,
          id: this.data.id
        },
      })
    );

    window.open(url, '_blank');
  }

}

export class PartInfoDialogModel {

}
