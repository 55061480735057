<div class="container" style="background-color: white;">
    <div style="text-align:left">
        <table mat-table matSort style="margin-top: 10px; border: 1px solid grey;" aria-describedby=""
            [dataSource]="sectionDataSource">

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Section </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <input type="text" maxlength="50" class="BlackText" size="50" *ngIf="row.code == '0'"
                        [(ngModel)]="description">

                    <ng-container *ngIf="!isEditing(row)">{{row.description}}</ng-container>
                    <ng-container *ngIf="isEditing(row) && row.code != '0'">
                        <input type="text" [(ngModel)]="updatedDesc" maxlength="50" size="50" class="BlackText">
                    </ng-container>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef scope="col" style="width: 10px;"></th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!isEditing(row)">
                        <input type="submit" value="Insert" class="BlackText" (click)="addSection(description)"
                            style="margin-top: 5px; margin-left: 3px;" *ngIf="row.code == '0'">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            *ngIf="row.code == '0'" (click)="cancelInsertSection(description)">

                        <input type="submit" value="Edit" class="BlackText" *ngIf="row.code != '0'"
                            style="margin-top: 3px; margin-left: 3px;" (click)="onEditSectionClick(row)">
                        <input type="submit" value="Delete" class="BlackText" style="margin-top: 3px; margin-left: 3px;"
                            (click)="deleteSection(row)" *ngIf="row.inUse == 0 && row.code != '0'">
                    </ng-container>

                    <ng-container *ngIf="isEditing(row)">
                        <input type="submit" value="Update" class="BlackText" *ngIf="row.code != '0'"
                            (click)="UpdateSectionDetails(row, updatedDesc)">
                        <input type="submit" value="Cancel" class="BlackText" *ngIf="row.code != '0'"
                            style="margin-left: 3px;" (click)="cancelUpdateSection(row)">
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef scope="col"></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button matTooltip="Delete Component" color="primary"
                        (click)="onDeleteComponentClick(row)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <!-- Secondary Column -->
            <!-- <ng-container matColumnDef="secondary" >
                    <td mat-cell *matCellDef="let row" [attr.colspan]="displaySectionsColumns.length" 
                    [ngStyle]="{'visibility': row.code == 1 ? 'visible' : 'hidden'}">
                        <input type="text" maxlength="50" class="BlackText" size="50"
                            style="margin-top: 5px; margin-left: 5px;">
                    </td>
                    </ng-container> -->

            <tr mat-header-row *matHeaderRowDef="displaySectionsColumns" style="color: white; height: 20px;"
                class="header-color">
            </tr>
            <!-- <tr mat-row *matRowDef="let row; columns: ['secondary'];"></tr> -->
            <tr mat-row *matRowDef="let row; columns: displaySectionsColumns;" style="height: 40px !important;">
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>
        </table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="15" showFirstLastButtons
            [pageSizeOptions]="[15, 30, 50, 100, 200]" aria-label="Select page of users"></mat-paginator>
    </div>
</div>