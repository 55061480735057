export class vOutageEntry {
    id?: number = 0;
    organizationCode?: string = '';
    companyCode?: string = '';
    unit?: string = '';
    outageDate?: Date;
    frameType?: string = '';
    dln: boolean = false;
    outageType?: string = '';
    unitHours?: number = 0;
    planned: boolean = false;
    comments?: string = '';
    outageEndDate?: Date;
    firedStarts?: number = 0;
    projectCode?: string = '';
    duration?: number = 0;
    manualStarts?: number = 0;
    totalStarts?: number = 0;
    emergencyStops?: number = 0;
    peakFiredHours?: number = 0;
    engineer?: string = '';
    plantSupervisor?: string = '';
    contractor?: string = '';
    turnaroundName?: string = '';
    companyName?: string = '';
    outageTypeDescription?: string = '';
    description?: string = '';
    companyColor?: string = '';
    unitFiredHours?: number = 0;
    rowColor?: string = '';
    OrgOutageDate?: Date;
}