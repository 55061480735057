<div style="overflow-y: hidden !important;">

    <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
        cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
            Serial Number Selection
        </h1>
        <span style="background-color: antiquewhite;">
            <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">
                X
            </button>
        </span>
    </div>
</div>

<div style="border-color:Black;border-width:1px;border-style:solid;height:400px;width:520px;overflow-y:scroll;">
    <table id="cbxlSerialNumbers" class="BlackText">
        <tbody>
            <tr>
                <td>
                    <div *ngFor="let number of serialNumbers">
                        <input type="checkbox" [(ngModel)]="number.isSelected" (ngModelChange)="onSrNoChange(number)"
                            style="margin-left: 10px;">
                        <span style="margin-left: 5px;"> {{number.serial_Number}} </span>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div style="margin-top: 7px; margin-bottom: 5px;">
    &nbsp;&nbsp; <input type="button" value="Select All" class="BlackText" (click)="SelectAll();"> &nbsp;
    <input type="button" value="Clear" class="BlackText" (click)="ClearAll()"> &nbsp;
    <input type="button" value="Submit" class="BlackText" (click)="SaveSelection();"> &nbsp;
</div>