import { GetFrameDuration } from '#models/GetFrameDuration';
import { spGetCompaniesByUnitType } from '#models/spGetCompaniesByUnitType';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { spGetOutagesOutageTypes } from '#models/spGetOutagesOutageTypes';
import { spGetUnits } from '#models/spGetUnits';
import { spInsertOutage } from '#models/spInsertOutage';
import { OutageSummaryService } from '#services/http/outage-summary.service';
import { OutagesOutageEntryService } from '#services/http/outages-outage-entry.service';
import { PartEntryService } from '#services/http/part-entry.service';
import { NotificationService } from '#services/notification.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-outages-outage-entry',
  templateUrl: './outages-outage-entry.component.html',
  styleUrls: ['./outages-outage-entry.component.scss']
})

export class OutagesOutageEntryComponent {
  organizations: spGetOrganizations[] = [];
  companies: spGetCompaniesByUnitType[] = [];
  units: spGetUnits[] = [];
  outageTypes: spGetOutagesOutageTypes[] = [];
  durations = new GetFrameDuration();
  outage = new spInsertOutage();
  public organizationCode: string = '';
  public unitType: string = '';
  public company: string = '';
  selectedOrg: string = '';
  selectedCompany: string = '';
  selectedUnit: string = '';
  selectedType: string = '';
  turnaroundName: string = '';
  frameType: string = '';
  duration: number = 0;

  startDate?: any;
  endDate?: any;
  //sDate = new Date();
  errorMessage: string = '';

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private notifyService: NotificationService, private outEntryService: OutagesOutageEntryService,
    private outSummaryService: OutageSummaryService, private partEntryService: PartEntryService,) {
  }

  ngOnInit(): void {
    this.getOrganizations();
  }

  getOrganizations() {
    this.outSummaryService.getOrganizations().subscribe((res) => {
      this.organizations = res;

      if (this.organizations.length > 0) {
        this.selectedOrg = this.organizations[0].code;
        this.organizationCode = this.selectedOrg;
        this.getCompanies();
      }
    });
  }

  onOrganizationChange(event: any) {
    this.organizationCode = event.value;
    this.getCompanies();
  }

  getCompanies() {
    this.outEntryService.getCompanies(this.organizationCode).subscribe((res) => {
      this.companies = res;

      if (this.companies.length > 0) {
        this.selectedCompany = this.companies[0].companyCode;
        this.getUnits();
      }
      else {
        this.selectedCompany = '';
        this.selectedUnit = '';
        this.selectedType = '';
        this.turnaroundName = '';
        this.duration = 0;
      }
    });
  }

  onCompanyChange(event: any) {
    this.selectedCompany = event.value;
    this.getUnits();
  }

  getUnits() {
    this.outSummaryService.getUnits(this.organizationCode, this.selectedCompany, '').subscribe((res) => {
      this.units = res;

      if (this.units.length > 0) {
        this.selectedUnit = this.units[0].unit;
        this.getOutageTypes();
      }
      else {
        this.selectedUnit = '';
        this.selectedType = '';
        this.turnaroundName = '';
        this.duration = 0;
        this.outageTypes = null;
      }
    });
  }

  onUnitTypeChange(event: any) {
    this.selectedUnit = event.value;
    this.getOutageTypes();
  }

  getOutageTypes() {
    this.outEntryService.getOutageTypes(this.organizationCode, this.selectedCompany,
      this.selectedUnit).subscribe((res) => {
        this.outageTypes = res;

        if (this.outageTypes.length > 0) {
          this.selectedType = this.outageTypes[0].code;
          this.turnaroundName = this.organizationCode + '-' + this.selectedCompany + '-' +
            this.selectedUnit + '-' + this.selectedType;

          //fetch value for Frame Type
          this.outEntryService.getFrameType(this.organizationCode, this.selectedCompany,
            this.selectedUnit, this.selectedType).subscribe((res) => {
              this.durations = res;

              if (this.durations != null || this.durations != undefined) {
                this.frameType = this.durations.frameType;
                this.duration = this.durations.duration == null ? 1 : this.durations.duration;
              }
            });
        }
        else {
          this.selectedType = '';
          this.turnaroundName = '';
          this.duration = 0;
        }
      });
  }

  onOutageTypeChange(event: any) {
    this.selectedType = event.value;

    //fetch value for Frame Type
    this.outEntryService.getFrameType(this.organizationCode, this.selectedCompany,
      this.selectedUnit, this.selectedType).subscribe((res) => {
        this.durations = res;

        if (this.durations != null || this.durations != undefined) {
          this.frameType = this.durations.frameType;
          this.duration = this.durations.duration;
        }

        // set turnaround name and end date if start date is not null
        if (this.startDate != undefined) {
          const year = new Date(this.startDate).getFullYear().toString().substr(-2);
          this.turnaroundName = '';
          this.turnaroundName = this.organizationCode + '-' + this.selectedCompany + '-' +
            this.selectedUnit + '-' + this.selectedType + "" + year;

          let date = new Date(this.startDate);
          const calculatedDate = date.setDate(date.getDate() + this.duration);
          this.endDate = new Date(calculatedDate);
          this.endDate = this.notifyService.ConvertDateBeforeSave(this.endDate);
        }
      });

    // set turnaround name if start date is null
    if (this.startDate == null) {
      this.turnaroundName = '';
      this.turnaroundName = this.organizationCode + '-' + this.selectedCompany + '-' +
        this.selectedUnit + '-' + this.selectedType;
    }
  }

  OnStartDateChange(event: any) {
    if (event.target.value != null) {
      const sDate = new Date(event.target.value);
      this.startDate = this.notifyService.ConvertDateBeforeSave(sDate);

      const year = new Date(event.target.value).getFullYear().toString().substr(-2);
      this.turnaroundName = '';
      this.turnaroundName = this.organizationCode + '-' + this.selectedCompany + '-' +
        this.selectedUnit + '-' + this.selectedType + "" + year;

      if (this.duration != 0) {
        const calculatedDate = event.target.value.setDate(event.target.value.getDate() + this.duration);
        this.endDate = new Date(calculatedDate);
        this.endDate = this.notifyService.ConvertDateBeforeSave(this.endDate);
      }
    }

    if (event.target.value == null) {
      this.startDate = null;
      this.turnaroundName = '';
      this.turnaroundName = this.organizationCode + '-' + this.selectedCompany + '-' +
        this.selectedUnit + '-' + this.selectedType;
    }
  }

  OnEndDateChange(event: any) {
    if (event.target.value != null) {
      const eDate = new Date(event.target.value);
      this.endDate = this.notifyService.ConvertDateBeforeSave(eDate);

      if (this.startDate !== undefined && this.startDate !== null) {
        var diffDays: any = Math.floor((this.endDate - this.startDate) / (1000 * 60 * 60 * 24));
        this.duration = diffDays;
      }
    }
    if (event.target.value == null) {
      this.endDate = null;
    }
  }

  onDurationChange(dur: number, date: any) {
    this.duration = Number(dur);

    if (date != null) {
      const calculatedDate = new Date(date);
      this.endDate = new Date(calculatedDate.setDate(calculatedDate.getDate() + this.duration));
      this.endDate = this.notifyService.ConvertDateBeforeSave(this.endDate);
    }
  }

  SaveOutage() {
    if (this.startDate == undefined || this.startDate == null) {
      this.notifyService.showError("Outage date is required.", "");
    }
    else if (this.endDate == undefined || this.endDate == null) {
      this.notifyService.showError("Outage end date is required.", "");
    }
    else if (this.endDate < this.startDate) {
      this.notifyService.showError("Outage end date cannot be before the outage start date.", "");
    }
    else if (this.duration == 0 || this.duration == null) {
      this.notifyService.showError("Duration must be greater than zero.", "");
    }
    else if (this.selectedCompany == undefined || this.selectedCompany == '') {
      this.notifyService.showError("Company is required.", "");
    }
    else if (this.selectedUnit == undefined || this.selectedUnit == '') {
      this.notifyService.showError("Unit is required.", "");
    }
    else if (this.selectedType == undefined || this.selectedType == '') {
      this.notifyService.showError("Outage type is required.", "");
    }
    else if (this.turnaroundName == undefined || this.turnaroundName == '') {
      this.notifyService.showError("Turnaround Name is required.", "");
    }
    else {
      this.outage.organizationCode = this.selectedOrg;
      this.outage.companyCode = this.selectedCompany;
      this.outage.unit = this.selectedUnit;
      this.outage.outageDate = this.startDate;
      this.outage.outageEndDate = this.endDate;
      this.outage.duration = this.duration;
      this.outage.outageType = this.selectedType;
      this.outage.turnaroundName = this.turnaroundName;

      this.outEntryService.insertOutageEntry(this.outage).subscribe(res => {
        if (res == -1) {

          this.router.navigate(['tops/tops-home']);

          // // Navigate to outage entry page - that is not yet developed

          // this.router.navigate(['tops/part-entry'], {
          //   queryParams: {
          //     OrgCode: this.organizationCode,
          //     CompCode: this.componentCode,
          //     FrType: this.frameType,
          //     SetNo: this.setNumber,
          //     id: this.id
          //   },
          // });
        }
        else {
          //this.errorMessage = res.toString();
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

}