import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class OutagesBydateRangeService {

  GetUnitTypesUrl = '/OutagesByDateRange/getUnitTypes';
  GetCompaniesUrl = '/OutagesByDateRange/getCompanies';
  GetunitsUrl = '/OutagesByDateRange/getUnits';
  GetOutageTypeUrl = '/OutagesByDateRange/getOutageTypes';
  GetOutagesUrl = '/OutagesByDateRange/getOutagesList';

  constructor(private http: HttpClient,) { }

  getUnitTypes(organizationCode: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetUnitTypesUrl,
      {
        params: {
          organizationCode: organizationCode
        }
      });
  }

  getCompanies(organizationCode: string, frameType: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetCompaniesUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType,
        }
      });
  }

  getUnits(organizationCode: string, companyCode: string, frameType: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetunitsUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          frameType: frameType,
        }
      });
  }

  getOutageTypes(organizationCode: string, companyCode: string, unit: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutageTypeUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
        }
      });
  }

  getOutagesData(organizationCode: string, frameType: string, companyCode: string, unit: string,
    outagesType: string, status: string, fromdate: string, todate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutagesUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType,
          companyCode: companyCode,
          unit: unit,
          outagesType: outagesType,
          status: status,
          fromDate: fromdate,
          toDate: todate
        }
      });
  }
}
