import { spGetRepair } from '#models/spGetRepair';
import { PartEntryService } from '#services/http/part-entry.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-repair-information-dialog',
  templateUrl: './repair-information-dialog.component.html',
  styleUrls: ['./repair-information-dialog.component.scss']
})
export class RepairInformationDialogComponent {

  repairInfo = new spGetRepair();

  constructor(public dialogRef: MatDialogRef<RepairInfoDialogModel>,
    private router: Router,
    private partEntryService: PartEntryService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.partEntryService.getRepairInfoData(this.data.OrgCode, this.data.SetNo, this.data.CompCode,
      this.data.FrType, this.data.startDate).subscribe((res) => {
        this.repairInfo = res;
      });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  public onEditClick(row: spGetRepair) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/part-repair'], {
        queryParams: {
          OrgCode: row.organizationCode,
          CompCode: row.componentCode,
          FrameType: row.frameType,
          SetNo: row.setNumber,
          StartDate: row.startDate 
        },
      })
    );

    window.open(url, '_blank');
  }
}

export class RepairInfoDialogModel {

}
