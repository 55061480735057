import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { CalAngularService } from '@cvx/cal-angular';
import { AuthService } from '../authentication/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private readonly srvAuth: AuthService, private readonly srvCal: CalAngularService) {
        // do nothing
    }

    intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return from(this.srvCal.getAADToken(this.srvAuth.getScopes())).pipe(
            switchMap((token) => {
                const originFull = req.url.split('/');
                const origin = `${originFull[0]}//${originFull[2]}`;
                const allowedOrigins = ['https://localhost:5100', 'https://rept-api-dev.azure.chevron.com', 'https://rept-api-test.azure.chevron.com', 'https://rept-api.azure.chevron.com'];

                const headers = req.headers
                    .set('Authorization', `Bearer ${token}`)

                if (allowedOrigins.includes(origin)) {
                    headers.append('Access-Control-Allow-Origin', origin);
                }

                const requestClone = req.clone({
                    headers,
                });

                return next.handle(requestClone);
            }),
        );
    }
}
