import { Component, Output, EventEmitter, Input, SimpleChanges, OnInit, OnChanges } from '@angular/core';
interface ColorOption {
  name: string;
  hex: string;
}
@Component({
  selector: 'app-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrl: './color-picker.component.scss'
})

export class ColorPickerComponent implements OnInit, OnChanges {
  @Input() initialColor: string = '';
  @Output() colorSelected = new EventEmitter<string>();

  colors: ColorOption[] = [
    { name: 'Black', hex: '#000000' },
    { name: 'Burnt Orange', hex: '#993300' },
    { name: 'Dark Olive', hex: '#333300' },
    { name: 'Dark Green', hex: '#003300' },
    { name: 'Dark Teal', hex: '#003366' },
    { name: 'Navy', hex: '#000080' },
    { name: 'Dark Purple', hex: '#333399' },
    { name: 'Dark Gray', hex: '#333333' },
    { name: 'Maroon', hex: '#800000' },
    { name: 'Orange', hex: '#ff6600' },
    { name: 'Olive', hex: '#808000' },
    { name: 'Green', hex: '#008000' },
    { name: 'Teal', hex: '#008080' },
    { name: 'Blue', hex: '#0000ff' },
    { name: 'Slate Blue', hex: '#666699' },
    { name: 'Gray', hex: '#808080' },
    { name: 'Red', hex: '#ff0000' },
    { name: 'Bright Orange', hex: '#ff9900' },
    { name: 'Lime Green', hex: '#99cc00' },
    { name: 'Sea Green', hex: '#339966' },
    { name: 'Aqua', hex: '#33cccc' },
    { name: 'Royal Blue', hex: '#3366ff' },
    { name: 'Purple', hex: '#800080' },
    { name: 'Light Gray', hex: '#999999' },
    { name: 'Magenta', hex: '#ff00ff' },
    { name: 'Gold', hex: '#ffcc00' },
    { name: 'Yellow', hex: '#ffff00' },
    { name: 'Lime', hex: '#00ff00' },
    { name: 'Cyan', hex: '#00ffff' },
    { name: 'Sky Blue', hex: '#00ccff' },
    { name: 'Plum', hex: '#993366' },
    { name: 'Silver', hex: '#c0c0c0' },
    { name: 'Light Pink', hex: '#ff99cc' },
    { name: 'Peach', hex: '#ffcc99' },
    { name: 'Light Yellow', hex: '#ffff99' },
    { name: 'Light Green', hex: '#ccffcc' },
    { name: 'Light Cyan', hex: '#ccffff' },
    { name: 'Light Blue', hex: '#99ccff' },
    { name: 'Lavender', hex: '#cc99ff' },
    { name: 'White', hex: '#ffffff' }
  ];
  
  

  selectedColor: ColorOption = { name: '', hex: '' };

  ngOnInit() {
    this.setSelectedColor();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['initialColor']) {
      this.setSelectedColor();
    }
  }
  setSelectedColor() {
    this.selectedColor = this.colors.find(color => color.name === this.initialColor) || { name: '', hex: '' };
  }

  selectColor(color: ColorOption) {
    this.selectedColor = color;
    this.colorSelected.emit(this.selectedColor.name);
  }
}