<div style="overflow-y: hidden !important;">

    <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
        cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
            Notes
        </h1>
        <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">X</button>
    </div>
    
    <div mat-dialog-content style="margin-top: 10px;">
        <table cellspacing="0" style="border-collapse:collapse;">
            <tbody>
                <tr>
                    <td colspan="2">
                        <span class="BlackText">{{ comment }}</span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>