<div style="background-color: white; overflow-x: scroll; min-height: 450px;">
    <div style="margin-top: 20px;" *ngIf="trackingData.length == 0">
        <span style="margin-left: 550px; font-size: 16px;">
            No records found... Search by different values
        </span>
    </div>

    <table cellpadding="0" cellspacing="0" style="margin-left: 10px; width:100%;"
    *ngIf="trackingData.length > 0">
        <tbody>
            <tr>
                <td colspan="23" class="Bold BlackText heading" style="border-right:0px none;">
                    <span style="margin-left: 550px; font-size: 16px;">Repair Tracking Report</span>
                </td>
                <td colspan="23" style="border-right:0px none;"><br><br></td>
            </tr>
            <tr>
                <th class="beginreport" colspan="5" style="text-align: center;">Description</th>
                <th class="beginreport" colspan="9" style="text-align: center;">Repair Facility Information</th>
                <th class="beginreport" colspan="8" style="text-align: center;">Econmic Information</th>
                <th class="beginreport" rowspan="2" style="text-align: center;">Notes</th>
            </tr>
            <tr>
                <th valign="bottom">Component</th>
                <th valign="bottom" nowrap="">Set #</th>
                <th valign="bottom">Qty</th>
                <th valign="bottom">Plant</th>
                <th valign="bottom">Project Code</th>

                <th valign="bottom">Company</th>
                <th valign="bottom">Ext Ref #</th>
                <th valign="bottom">Ship To Date</th>
                <th valign="bottom">Arrival Date</th>
                <th valign="bottom">Insp Report Issued</th>
                <th valign="bottom">Required Date</th>
                <th valign="bottom">Commitment Date</th>
                <th valign="bottom">Ship From Date</th>
                <th valign="bottom">Received Date</th>

                <th valign="bottom">Int Ref #</th>
                <th valign="bottom">Material Cost</th>
                <th valign="bottom">Material Tax</th>
                <th valign="bottom">Labor Cost</th>
                <th valign="bottom">Labor Tax</th>
                <th valign="bottom">Freight</th>
                <th valign="bottom">Total Cost</th>
                <th valign="bottom">Unit Cost</th>
            </tr>

            <tr class="Row1" *ngFor="let repair of trackingData let i = index">
                <td valign="top" nowrap="">{{repair.componentName}}<br></td>
                <td valign="top" nowrap="">{{repair.setNumber}}<br></td>
                <td valign="top" nowrap="">{{repair.numberOfSerialNumbers}}<br></td>
                <td valign="top" nowrap="">{{repair.companyName}}<br></td>
                <td valign="top" nowrap="">{{repair.projectCode}}<br></td>

                <td valign="top" nowrap="">{{repair.repairFacility}}<br></td>
                <td valign="top" nowrap="">{{repair.externalReferenceNumber}}<br></td>
                <td valign="top" nowrap="">{{repair.startDate | date: 'MM/dd/yyyy'}}<br></td>
                <td valign="top" nowrap="">{{repair.repairFacilityArrivalDate | date: 'MM/dd/yyyy'}}<br></td>
                <td valign="top" nowrap="">{{repair.inspectionReportDate | date: 'MM/dd/yyyy'}}<br></td>
                <td valign="top" nowrap="">{{repair.customerWantDate | date: 'MM/dd/yyyy'}}<br></td>
                <td valign="top" nowrap="">{{repair.repairFacilityCommitmentDate | date: 'MM/dd/yyyy'}}<br></td>
                <td valign="top" nowrap="">{{repair.repairFacilityShipDate | date: 'MM/dd/yyyy'}}<br></td>
                <td valign="top" nowrap="">{{repair.endDate | date: 'MM/dd/yyyy'}}<br></td>

                <td valign="top" align="right" nowrap="">{{repair.internalReferenceNumber}}<br></td>
                <td valign="top" align="right" nowrap="">{{repair.materialCost | number:'1.2-2'}}<br></td>
                <td valign="top" align="right" nowrap="">{{repair.materialTax | number:'1.2-2'}}<br></td>
                <td valign="top" align="right" nowrap="">{{repair.laborCost | number:'1.2-2'}}<br></td>
                <td valign="top" align="right" nowrap="">{{repair.laborTax | number:'1.2-2'}}<br></td>
                <td valign="top" align="right" nowrap="">{{repair.freight | number:'1.2-2'}}<br></td>
                <td valign="top" align="right" nowrap="">{{repair.totalCost | number:'1.2-2'}}<br></td>
                <td valign="top" align="right" nowrap="">{{repair.unitCost | number:'1.2-2'}}<br></td>

                <td valign="top" align="left" style="min-width: 250px;">{{repair.notes}}<br></td>
                <!-- <td valign="top"><br></td> nowrap="" -->
            </tr>

            <tr>
                <td class="endreport" colspan="23" style="border-right:0px none; border-left: 0px none;"><br></td>
            </tr>
        </tbody>
    </table>
</div>