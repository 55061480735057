import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/core/authentication/auth.service';

@Component({
  selector: 'app-tops-header',
  templateUrl: './tops-header.component.html',
  styleUrls: ['./tops-header.component.scss']
})
export class TopsHeaderComponent {
  isAdmin: boolean = false;
  userName: string = '';
  greetings: string = '';

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
    // do nothing
  }

  ngOnInit() {
    this.isAdmin = this.authService.hasRole(['Admin']);
    let currentUser = this.authService.getCurrentUser();
    this.userName = currentUser.name;

    let myDate = new Date();
    let hrs = myDate.getHours();

    if (hrs < 12)
    this.greetings = 'Good Morning';
    else if (hrs >= 12 && hrs <= 17)
    this.greetings = 'Good Afternoon';
    else if (hrs >= 17 && hrs <= 24)
    this.greetings = 'Good Evening';
  }

  redirect(url: string): void {
    this.router.navigate([url]);
  }
}
