export class spGetOutage {
    organizationCode?: string ='';
    companyCode?: string = '';
    unit?: string = '';
    outageDate?: Date;
    frameType?: string = '';
    dln?: boolean;
    outageType?: string = '';
    outageTypeDescription?: string = '';
    unitHours?: string = '';
    planned?: string = '';
    comments?: string = '';
    outageEndDate?: Date;
    firedStarts?: number = 0;
    projectCode?: string = '';
    duration?: number = 0;
    manualStarts?: number = 0;
    totalStarts?: number = 0;
    emergencyStops?: number = 0;
    peakFiredHours?: any;
    componentCode?: string = '';
    engineer?: string = '';
    color?: string = '';
    fullName?: string = '';
    piServer?: string = '';
    piUser?: string = '';
    piPassword?: string = '';
    fileLocation?: string = '';
    frameTypeCode?: string = '';
    orgCode?: string = '';
    description?: string = '';
    frameTypeDescription?: string = '';
}