import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { LoadingDialogComponent } from './loading-dialog.component';

@Injectable({ providedIn: 'root' })
export class LoadingDialogService {
  private opened = false;

  private openedCount = 0;

  private dialogRef!: MatDialogRef<LoadingDialogComponent>;

  constructor(private dialog: MatDialog) {
    // do nothing
  }

  openDialog(): void {
    if (!this.opened) {
      this.opened = true;
      this.openedCount += 1;
      this.dialogRef = this.dialog.open(LoadingDialogComponent, {
        data: undefined,
        maxHeight: '100%',
        width: '400px',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
        this.openedCount = 0;
      });
    } else {
      this.openedCount += 1;
    }
  }

  hideDialog() {
    if (this.openedCount > 1) {
      this.openedCount -= 1;
    } else {
      this.dialogRef.close();
    }
  }
}
