<div class="container">
  <form [formGroup]="adminForm">
    <div class="row">
      <div class="col">
        <mat-card>
          <mat-card-content>
            <h2>Compressor Bulk Upload</h2>
            <div class="file-upload-container">
              <div class="row align-items-center">
                <div class="col">
                  <input type="file" #compressorFileInput (change)="onFileSelected(compressorFileInput, 'Compressors')"
                    accept=".xlsx">
                </div>
                <div class="col">
                  <button type="button" mat-icon-button color="warn"
                    *ngIf="selectedFile && currentImportType === 'Compressors'"
                    (click)="resetFileSelection('Compressors')">
                    <mat-icon aria-label="Cancel">cancel</mat-icon>
                  </button>
                </div>
                <div class="col" style="display: flex;">
                  <button type="button" mat-raised-button color="primary"
                    [disabled]="!selectedFile || currentImportType !== 'Compressors'"
                    (click)="onImport('Compressors')">Import</button>
                  &nbsp;
                  <span class="col-1" style="padding-top: 15px; padding-left: 0px;">
                    <img matTooltipPosition='right' height="15" width="15"
                      matTooltip="For compressor bulk upload name the excel sheet 'Compressor Database'" alt=""
                      class="info-logo" src="../assets/images/info.png">
                  </span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-card>
          <mat-card-content>
            <h2>DGS Drawing Bulk Upload</h2>
            <div class="file-upload-container">
              <div class="row align-items-center">
                <div class="col">
                  <input type="file" #drawingFileInput (change)="onFileSelected(drawingFileInput, 'Drawings')"
                    accept=".xlsx">
                </div>
                <div class="col">
                  <button type="button" mat-icon-button color="warn"
                    *ngIf="selectedFile && currentImportType === 'Drawings'" (click)="resetFileSelection('Drawings')">
                    <mat-icon aria-label="Cancel">cancel</mat-icon>
                  </button>
                </div>
                <div class="col" style="display: flex;">
                  <button type="button" mat-raised-button color="primary"
                    [disabled]="!selectedFile || currentImportType !== 'Drawings'"
                    (click)="onImport('Drawings')">Import</button>
                    &nbsp;
                  <span class="col-1" style="padding-top: 15px; padding-left: 0px;">
                    <img matTooltipPosition='right' height="15" width="15"
                      matTooltip="For DGS drawing bulk upload name the excel sheet 'DryGasSealDrawing Database'" alt=""
                      class="info-logo" src="../assets/images/info.png">
                  </span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <mat-card>
          <mat-card-content>
            <h2>Maintenance Log Bulk Upload</h2>
            <div class="file-upload-container">
              <div class="row align-items-center">
                <div class="col">
                  <input type="file" #eventLogFileInput (change)="onFileSelected(eventLogFileInput, 'EventLogs')"
                    accept=".xlsx">
                </div>
                <div class="col">
                  <button type="button" mat-icon-button color="warn"
                    *ngIf="selectedFile && currentImportType === 'EventLogs'" (click)="resetFileSelection('EventLogs')">
                    <mat-icon aria-label="Cancel">cancel</mat-icon>
                  </button>
                </div>
                <div class="col" style="display: flex;">
                  <button type="button" mat-raised-button color="primary"
                    [disabled]="!selectedFile || currentImportType !== 'EventLogs'"
                    (click)="onImport('EventLogs')">Import</button>
                    &nbsp;
                  <span class="col-1" style="padding-top: 15px; padding-left: 0px;">
                    <img matTooltipPosition='right' height="15" width="15"
                      matTooltip="For maintenance log bulk upload name the excel sheet 'MaintenanceLog Database'" alt=""
                      class="info-logo" src="../assets/images/info.png">
                  </span>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </form>
</div>