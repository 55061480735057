import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class SectionsService {

  getSectionsUrl = '/Sections/getSections';
  insertSectionsUrl = '/Sections/insertSection';
  deleteSectionsUrl = '/Sections/deleteSection';
  updateSectionsUrl = '/Sections/updateSection';

  constructor(private http: HttpClient) { }

  public getSections() {
    return this.http.get<any>(environment.apiEndpoint + this.getSectionsUrl);
  }

  insertSection(description: string) {
    return this.http.post(environment.apiEndpoint + this.insertSectionsUrl, description,
      {
        params: {
          description: description,
        }
      });
  }

  deleteSection(section: any) {
    return this.http.post(environment.apiEndpoint + this.deleteSectionsUrl, section,
      {
        params: {
          section: section
        }
      });
  }

  UpdateSectionDetails(formData: any) {
    return this.http.post(environment.apiEndpoint + this.updateSectionsUrl, formData,
      {
        params:{
          org: formData
        }
      });
  }
}
