<h1 mat-dialog-title>{{ data?.message }}</h1>
<div mat-dialog-content>
  <p *ngIf="data.status">
    <strong>Status Code: {{ data.status }}</strong>
  </p>
</div>
<div *ngIf="data.displaySpinner === true" class="mat-spinner">
  <mat-spinner></mat-spinner>
</div>
<div mat-dialog-actions>
  <button mat-raised-button mat-dialog-close color="primary">Close</button>
</div>
