import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ErrorDialogComponent } from './error-dialog.component';

@Injectable({ providedIn: 'root' })
export class ErrorDialogService {
  private opened = false;

  private dialogRef: MatDialogRef<ErrorDialogComponent>;

  constructor(private dialog: MatDialog) {
    // do nothing
  }

  openDialog(message: string, status?: number, displaySpinner?: boolean): void {
    if (!this.opened) {
      this.opened = true;
      this.dialogRef = this.dialog.open(ErrorDialogComponent, {
        data: { message, status, displaySpinner },
        maxHeight: '100%',
        width: '540px',
        maxWidth: '100%',
        disableClose: true,
        hasBackdrop: true,
      });

      this.dialogRef.afterClosed().subscribe(() => {
        this.opened = false;
      });
    }
  }

  hideDialog(): void {
    this.dialogRef.close();
  }

  isOpen(): boolean {
    return this.opened;
  }
}
