import { NgModule } from '@angular/core';

import {MatDialogModule} from '@angular/material/dialog';

import { ErrorDialogComponent } from './error-dialog.component';
import { ErrorDialogService } from './error-dialog.service';


@NgModule({
  imports: [
    MatDialogModule
  ],
  declarations: [
    ErrorDialogComponent,
  ],
  providers: [
    ErrorDialogService,
  ],
  exports: [
  ]
})
export class ErrorDialogModule { }