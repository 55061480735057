import { OwnerPlant } from '#models/ownerPlant';
import { spGetRepair } from '#models/spGetRepair';
import { Units } from '#models/units';
import { PartEntryService } from '#services/http/part-entry.service';
import { PartRepairService } from '#services/http/part-repair.service';
import { NotificationService } from '#services/notification.service';
import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { SerialNumberSelectionComponent } from 'app/core/modals/serial-number-selection/serial-number-selection.component';

@Component({
  selector: 'app-part-repair',
  templateUrl: './part-repair.component.html',
  styleUrls: ['./part-repair.component.scss']
})

export class PartRepairComponent {

  repairInfo = new spGetRepair();
  public organizationCode: string = '';
  public componentCode: string;
  public frameType: string = '';
  public setNumber: string = '';
  public startDate: any;
  ownerPlants: OwnerPlant[] = [];
  units: Units[] = [];
  selected: string;
  errorMessage: string = '';
  date = new Date();
  public id: number = 0;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private partEntryService: PartEntryService,
    private partRepairService: PartRepairService,
    private notifyService: NotificationService, public dialog: MatDialog,) {
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.organizationCode = params['OrgCode'];
      this.setNumber = params['SetNo'];
      this.componentCode = params['CompCode'];
      this.frameType = params['FrameType'];
      this.startDate = params['StartDate'];
      this.id = params['id'];
    });

    // get owner plant data
    this.partRepairService.getAllOwnerPlants(this.organizationCode, this.frameType).subscribe((res) => {
      this.ownerPlants = res;
    });

    if (this.startDate != null) {

      this.partEntryService.getRepairInfoData(this.organizationCode, this.setNumber, this.componentCode,
        this.frameType, this.startDate).subscribe((res) => {
          this.repairInfo = res;

          // get units data
          this.partRepairService.getAllUnits(this.organizationCode, this.frameType,
            this.repairInfo.companyCode).subscribe((res) => {
              this.units = res;
            });
        });
    }
  }

  OnStartDateChange(repair: spGetRepair, event: any) {
    if (repair.startDate != null) {
      const shipDate = new Date(event.target.value);
      repair.newStartDate = this.notifyService.ConvertDateBeforeSave(shipDate);
    }
    if (repair.startDate == null) {
      const shipDate = new Date(event.target.value);
      repair.startDate = this.notifyService.ConvertDateBeforeSave(shipDate);
    }
  }

  OnDateChange(repair: spGetRepair, dateType: string) {
    if (dateType == 'ShipDate' && repair.startDate != null) {
      const shipDate = new Date(repair.startDate!);
      repair.newStartDate = this.notifyService.ConvertDateBeforeSave(shipDate);
    }

    if (dateType == 'ReceivedDate' && repair.endDate != null) {
      const receivedDate = new Date(repair.endDate!);
      repair.endDate = this.notifyService.ConvertDateBeforeSave(receivedDate);
    }

    if (dateType == 'CustomerWantDate' && repair.customerWantDate != null) {
      const customerWantDate = new Date(repair.customerWantDate!);
      repair.customerWantDate = this.notifyService.ConvertDateBeforeSave(customerWantDate);
    }

    if (dateType == 'OnsiteCustomerWantDate' && repair.onsiteCustomerWantDate != null) {
      const onsiteCustomerWantDate = new Date(repair.onsiteCustomerWantDate!);
      repair.onsiteCustomerWantDate = this.notifyService.ConvertDateBeforeSave(onsiteCustomerWantDate);
    }

    if (dateType == 'CriticallyLateDate' && repair.criticallyLateDate != null) {
      const criticallyLateDate = new Date(repair.criticallyLateDate!);
      repair.criticallyLateDate = this.notifyService.ConvertDateBeforeSave(criticallyLateDate);
    }
  }

  OnShippingRepairDateChange(repair: spGetRepair, dateType: string) {
    if (dateType == 'TransportDate' && repair.transportDateFromSite != null) {
      const transportDateFromSite = new Date(repair.transportDateFromSite!);
      repair.transportDateFromSite = this.notifyService.ConvertDateBeforeSave(transportDateFromSite);
    }

    if (dateType == 'ArrivalDate' && repair.repairFacilityArrivalDate != null) {
      const repairFacilityArrivalDate = new Date(repair.repairFacilityArrivalDate!);
      repair.repairFacilityArrivalDate = this.notifyService.ConvertDateBeforeSave(repairFacilityArrivalDate);
    }

    if (dateType == 'CommitmentDate' && repair.repairFacilityCommitmentDate != null) {
      const commitmentDate = new Date(repair.repairFacilityCommitmentDate!);
      repair.repairFacilityCommitmentDate = this.notifyService.ConvertDateBeforeSave(commitmentDate);
    }

    if (dateType == 'RepShipDate' && repair.repairFacilityShipDate != null) {
      const repShipDate = new Date(repair.repairFacilityShipDate!);
      repair.repairFacilityShipDate = this.notifyService.ConvertDateBeforeSave(repShipDate);
    }

    if (dateType == 'InspectionReportDate' && repair.inspectionReportDate != null) {
      const inspectionReportDate = new Date(repair.inspectionReportDate!);
      repair.inspectionReportDate = this.notifyService.ConvertDateBeforeSave(inspectionReportDate);
    }
  }

  UpdateRepair(repair: spGetRepair) {
    this.errorMessage = "";
    this.ValidateFields(repair);

    if (this.errorMessage === "") {

      if (this.startDate == null || this.startDate == undefined) {
        repair.organizationCode = this.organizationCode;
        repair.componentCode = this.componentCode;
        repair.frameType = this.frameType;
        repair.setNumber = this.setNumber;

        if (repair.endDate == undefined || repair.endDate == null) {
          repair.endDate = null;
        }

        // // this.partRepairService.getInsertRepairValidate(this.organizationCode, this.setNumber,
        // //   this.componentCode, this.frameType, repair.startDate, repair.endDate).subscribe(res => {
        // //     this.errorMessage = res;
        // //   });

        // // this.partRepairService.postRepairData(repair).subscribe(res => {
        // //   window.location.reload();
        // // });

        this.partRepairService.insertRepairData(repair).subscribe(res => {
          if (res == -1) {
            // // window.location.reload();

            this.router.navigate(['tops/part-entry'], {
              queryParams: {
                OrgCode: this.organizationCode,
                CompCode: this.componentCode,
                FrType: this.frameType,
                SetNo: this.setNumber,
                id: this.id
              },
            });

          }
          else {
            this.errorMessage = res.toString();
          }
        });
      }

      if (this.startDate != null) {
        this.partRepairService.postRepairData(repair).subscribe(res => {
          if (res == -1) {
            window.location.reload();
          }
          else {
            this.notifyService.showError(res.toString(), "");
          }
        });
      }
    }
  }

  ValidateFields(repair: spGetRepair) {
    if (repair.companyCode == undefined || repair.companyCode == "") {
      this.errorMessage = 'Owner plant is required.';
    }
    else if (repair.unit == undefined || repair.unit == "") {
      this.errorMessage = 'Unit is required.';
    }
    else if (repair.startDate == undefined) {
      this.errorMessage = 'Ship date is required.';
    }
    else if (repair.endDate > this.date) {
      this.errorMessage = 'Return repair date cannot be greater than today.';
    }
    else if (repair.endDate != null && repair.endDate < repair.startDate) {
      this.errorMessage = '"Back from repair date cannot be before Out for repair date.';
    }
    else if (repair.customerWantDate == undefined) {
      this.errorMessage = 'Customer Want Date is required.';
    }
    else if (repair.customerWantDate != null && repair.customerWantDate < repair.startDate) {
      this.errorMessage = 'Customer Want Date cannot be before Out for repair date.';
    }
    else if (repair.onsiteCustomerWantDate != null && repair.onsiteCustomerWantDate < repair.startDate) {
      this.errorMessage = 'Onsite Customer Want Date cannot be before Out for repair date.';
    }
    else if (repair.criticallyLateDate != null && repair.criticallyLateDate < repair.startDate) {
      this.errorMessage = 'Critically Late Date cannot be before Out for repair date.';
    }
    else if (repair.transportDateFromSite != null && repair.transportDateFromSite < repair.startDate) {
      this.errorMessage = 'Transport Date cannot be before Out for repair date.';
    }
    else if (repair.repairFacilityArrivalDate != null && repair.repairFacilityArrivalDate < repair.startDate) {
      this.errorMessage = 'Repair Facility Arrival Date cannot be before Out for repair date.';
    }
    else if (repair.repairFacilityCommitmentDate != null && repair.repairFacilityCommitmentDate < repair.startDate) {
      this.errorMessage = 'Repair Facility Commitment Date cannot be before Out for repair date.';
    }
    else if (repair.repairFacilityShipDate != null && repair.repairFacilityShipDate < repair.startDate) {
      this.errorMessage = 'Repair Facility Ship Date cannot be before Out for repair date.';
    }
    else if (repair.inspectionReportDate != null && repair.inspectionReportDate < repair.startDate) {
      this.errorMessage = 'Inspection Report Date cannot be before Out for repair date.';
    }

    else if (repair.warrantyWork?.length > 4000) {
      this.errorMessage = 'Warranty work is to long. Maximum number of characters allowed is 4000.';
    }
    else if (repair.repairWorkRequired?.length > 4000) {
      this.errorMessage = 'Repair reports required is to long. Maximum number of characters allowed is 4000.';
    }
    else if (repair.jobInstructions?.length > 4000) {
      this.errorMessage = 'Important job instructions is to long. Maximum number of characters allowed is 4000.';
    }
    else if (repair.transportArrangement?.length > 4000) {
      this.errorMessage = 'Transport arrangement from site is to long. Maximum number of characters allowed is 4000.';
    }
    else if (repair.shippingInstructions?.length > 4000) {
      this.errorMessage = 'Shipping instructions is to long. Maximum number of characters allowed is 4000.';
    }
    else if (repair.specialTransportRequirements?.length > 4000) {
      this.errorMessage = 'Special requirments for transport is to long. Maximum number of characters allowed is 4000.';
    }
    else if (repair.customerReceivingRequirements?.length > 4000) {
      this.errorMessage = 'Customer receiving requirements is to long.  Maximum number of characters allowed is 4000.';
    }

  }

  UpdateValue(repair: spGetRepair, type: string, event: any) {
    const value = event.target.value;

    if (type == 'material') {
      repair.materialCost = value == '' ? '' : Number(value).toFixed(2);
    }
    if (type == 'materialTax') {
      repair.materialTax = value == '' ? '' : Number(value).toFixed(2);
    }
    if (type == 'labor') {
      repair.laborCost = value == '' ? '' : Number(value).toFixed(2);
    }
    if (type == 'laborTax') {
      repair.laborTax = value == '' ? '' : Number(value).toFixed(2);
    }
    if (type == 'freight') {
      repair.freight = value == '' ? '' : Number(value).toFixed(2);
    }
  }

  public onOwnerPlantChange(companyCode: string): void {
    if (companyCode == undefined) {
      this.units.length = 0;
    }
    else {
      if (this.units.length == 0 || this.units == undefined) {
        this.partRepairService.getAllUnits(this.organizationCode, this.frameType,
          companyCode).subscribe((res) => {
            this.units = res;
          });
      }
    }
  }

  OnCancelClick() {
    window.location.reload();
  }

  OnReturntoSetClick() {

    this.router.navigate(['tops/part-entry'], {
      queryParams: {
        OrgCode: this.organizationCode,
        CompCode: this.componentCode,
        FrType: this.frameType,
        SetNo: this.setNumber,
        id: this.id
      },
      //queryParamsHandling: 'merge',
    });

    // const url = this.router.serializeUrl(
    //   this.router.createUrlTree(['tops/part-entry'], {
    //     queryParams: {
    //       OrgCode: this.organizationCode,
    //       CompCode: this.componentCode,
    //       FrType: this.frameType,
    //       SetNo: this.setNumber,
    //       id: this.id
    //     },
    //   })
    // );

    // window.open(url);
  }

  public OnReturntoOutageClick() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/outage-entry'], {
        queryParams: {
          OrgCode: this.organizationCode,
          CompCode: this.componentCode,
          Unit: this.repairInfo.unit,
          OutDate: this.startDate
        },
      })
    );

    window.open(url, '_blank');
  }

  OnRepairOpeningLetterClick() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/RepairOpeningLetter'], {
        queryParams: {
          OrganizationCode: this.organizationCode,
          ComponentCode: this.componentCode,
          FrameType: this.frameType,
          SetNumber: this.setNumber,
          StartDate: this.startDate
        },
      })
    );

    window.open(url);
  }

  ShowSerialNumbersDialog() {
    const dialogRef = this.dialog.open(SerialNumberSelectionComponent, {
      width: "40%",
      height: "92%",
      data: {
        organizationCode: this.organizationCode, componentCode: this.componentCode,
        frameType: this.frameType, setNumber: this.setNumber
      },
    });

    let comment = '';
    dialogRef.componentInstance.selectedSrNos.subscribe((data) => {
      this.repairInfo.serialNumbers = data;

      this.repairInfo.serialNumbers == "" ? this.repairInfo.numberOfSerialNumbers = 0 :
        this.repairInfo.numberOfSerialNumbers = data.split(",").length;
    });
  }

}
