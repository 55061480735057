import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UnitInfoService {
  getFuelSourceUrl = '/UnitInformation/getFuelSource';
  insertUnitInfoUrl = '/UnitInformation/insertUnitInfo';
  deleteUnitInfoUrl = '/UnitInformation/deleteUnitInfo';
  updateUnitInfoUrl = '/UnitInformation/updateUnitInfo';

  constructor(private http: HttpClient) { }
  
  //// Get UnitInfo already exists in TopsHomeService
  
  public getFuelSource() {
    return this.http.get<any>(environment.apiEndpoint + this.getFuelSourceUrl);
  }

  insertUnitInfo(formData: any) {
    return this.http.post(environment.apiEndpoint + this.insertUnitInfoUrl, formData,
      {
        params: {
          unit: formData
        }
      });
  }

  deleteUnitInfo(formData: any) {
    return this.http.post(environment.apiEndpoint + this.deleteUnitInfoUrl, formData,
      {
        params: {
          unit: formData
        }
      });
  }

  updateUnitInfo(formData: any) {
    return this.http.post(environment.apiEndpoint + this.updateUnitInfoUrl, formData,
      {
        params: {
          unit: formData
        }
      });
  }
}
