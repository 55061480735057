import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
 
@Component({
  selector: 'app-snack-bar',
  template: `
<div class="snackbar-container">
<span [innerHTML]="data"></span>
<button mat-button class="close-button" (click)="closeSnackbar()">Close</button>
</div>
  `,
  styles: [`
    .snackbar-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
    .close-button {
      color: #1E88E5; /* Button text color */
      background-color: inherit; /* Match button background with snackbar */
      border: none;
      cursor: pointer;
      padding: 0;
      margin-left: 20px; /* Add margin to push the button to the right */
    }
    .mat-snack-bar-container {
      white-space: pre-wrap;
      background-color: #323232; /* Snackbar background color */
      color: #fff; /* Snackbar text color */
    }
    .close-button:hover {
      background-color: #575757; /* Button hover background color */
    }
  `]
})
export class SnackBarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: any,
    private snackBarRef: MatSnackBarRef<SnackBarComponent>
  ) { }
 
  closeSnackbar() {
    this.snackBarRef.dismiss();
  }
}