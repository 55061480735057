export class spInsertSerialNumber {
    organizationCode: string = '';
    frameType: string = '';
    componentCode: string = '';
    setNumber: string = '';
    dln: any;
    serialNumber: string = '';
    start_Date: Date;
    starting_Hours?: any;
    partNumber: string = '';
}