export class spGetRepair {
    organizationCode: string = '';
    componentCode: string = '';
    setNumber: string = '';
    frameType: string = '';
    outageDate?: Date;
    unit?: string = '';
    companyCode?: string = '';
    startDate: Date = null;
    endDate?: Date;
    internalReferenceNumber?: string = '';
    externalReferenceNumber?: string = '';
    notes?: string = '';
    laborCost?: any;
    laborTax?: any;
    materialCost?: any;
    materialTax?: any;
    freight?: any;
    projectCode?: string = '';
    repairFacility?: string = '';
    customerWantDate?: Date;
    onsiteCustomerWantDate?: Date;
    criticallyLateDate?: Date;
    shippingInstructions?: string = '';
    warrantyWork?: string = '';
    repairWorkRequired?: string = '';
    transportArrangement?: string = '';
    transportDateFromSite?: Date;
    pickUpAddress?: string = '';
    customerReceivingRequirements?: string = '';
    specialTransportRequirements?: string = '';
    jobInstructions?: string = '';
    repairFacilityArrivalDate?: Date;
    repairFacilityCommitmentDate?: Date;
    repairFacilityShipDate?: Date;
    inspectionReportDate?: Date;
    serialNumbers?: string = '';
    numberOfSerialNumbers?: number = 0;
    totalCost?: number = 0;
    organization?: string = '';
    component?: string = '';
    companyName?: string = '';
    fullName?: string = '';
    frameTypeDescription?: string = '';
    newStartDate: Date;
}