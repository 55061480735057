import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})

export class NotesComponent {
  comment: string = "";

  constructor(public dialogRef: MatDialogRef<NotesDialogModel>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    dialogRef.disableClose = true;
  }

  ngOnInit() {
    this.comment = this.data.notes;
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

}

export class NotesDialogModel {

}
