<div>
  <div class="nav d-flex justify-content-center">
    <div class="logo d-flex flex-column align-items-center justify-content-center pt-2 ms-auto">
      <img class="hallmark" src="/assets/images/hallmark.png" alt="Chevron Logo" />
      <h1>
        <a class="lh-base light-blue hide-underline" href="/">Rotating Equipment Parts Tracker</a>
      </h1>
    </div>
    <div class="d-flex align-items-center ms-auto text-center">
      <a class="mx-4" href="/tops/tops-home" rel="noopener" style="font-size: 30px; font-weight: bold;">TOPS</a>
      <a class="mx-4" href="https://chevron.sharepoint.com/sites/ReptUserGuides/" target="_blank" rel="noopener">
        <img alt="User Guide" src="/assets/images/guide.png" height="35px" width="30px" />
        <span class="icon-text">User Guide</span>
      </a>
    </div>
  </div>
  <!-- <div style="padding-left: 15px;">
    <h2>
      <a class="lh-base light-blue hide-underline" href="/">
        Rotating Equipment Parts Tracker
      </a>
    </h2>
    <h2>
      <a class="lh-base light-blue hide-underline" href="/tops/tops-home">
        Turbine Outages and Parts System
      </a>
    </h2>
  </div> -->
  <div>
    <mat-toolbar color="primary">
      <button mat-flat-button color="primary" id="navbar-button" routerLink="/">
        View Compressors
      </button>
      <button mat-flat-button color="primary" id="navbar-button" routerLink="/create-log">
        New DGS Event Log
      </button>
      <button mat-flat-button color="primary" id="navbar-button" routerLink="/dgs-metrics">
        DGS Metrics
      </button>
      <button mat-flat-button color="primary" id="navbar-button" routerLink="/dgs-drawings">
        DGS Drawings
      </button>

      <div *ngIf="isAdmin" class="dropdown">
        <button mat-flat-button color="primary" class="dropdown" id="navbar-button">
          Admin
        </button>
        <div class="dropdown-content">
          <a (click)="redirect('admin/dgs-bulkupload')">Bulk Upload</a>
        </div>
      </div>
    </mat-toolbar>
  </div>
</div>