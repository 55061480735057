<div style="overflow-y: hidden !important;">

  <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
      Serial Number History
    </h1>
    <span style="background-color: antiquewhite;">
      <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">
        X
      </button>
    </span>
  </div>

  <div mat-dialog-content>
    <div style="margin-left:10px">
      <br>
      <table class="BlackText" cellpadding="4" cellspacing="0" width="100%">
        <tbody>
          <tr class="Header outage-info" style="text-align: center;">
            <th>Start</th>
            <th>End</th>
            <th>Set</th>
            <th>Fired Starts</th>
            <th>Hours</th>
            <th>Total Hrs</th>
          </tr>
          <tr style="text-align: center;" *ngFor="let srno of serialNos let i = index">
            <td>
              {{ srno.start_Date | date: 'MM/dd/yyyy' }}
            </td>
            <td>
              {{ srno.end_Date | date: 'MM/dd/yyyy' }}
            </td>
            <td>
              <a href="javascript:void(0);" (click)="onClickSerialNo()">{{srno.set_Number}}</a>
            </td>
            <td>
              <span *ngIf=" srno.firedStarts === -1"> N/A </span>
              <span *ngIf=" srno.firedStarts !== -1"> {{ srno.firedStarts }} </span>
            </td>
            <td>{{ srno.hours | number:'1.2-2' }}</td>
            <td>{{ srno.lifeTimeHours | number:'1.2-2' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>