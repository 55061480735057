export class spGetRepairs {
    organizationCode: string = '';
    componentCode: string = '';
    setNumber: string = '';
    frameType: string = '';
    companyCode: string = '';
    unit: string = '';
    startDate: Date;
    endDate?: Date;
    internalReferenceNumber: string = '';
    externalReferenceNumber: string = '';
    notes: string = '';
    laborCost?: number = 0;
    laborTax?: number = 0;
    materialCost?: number = 0;
    materialTax?: number = 0;
    freight?: number = 0;
    projectCode: string = '';
    repairFacility: string = '';
    totalCost?: number = 0;
}