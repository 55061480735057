import { GetNewPartHeader } from '#models/GetNewPartHeader';
import { PartLifeClassification } from '#models/PartLifeClassification';
import { spGetPart } from '#models/spGetPart';
import { spGetParts } from '#models/spGetParts';
import { NewPartService } from '#services/http/new-part.service';
import { PartEntryService } from '#services/http/part-entry.service';
import { PartsService } from '#services/http/parts.service';
import { NotificationService } from '#services/notification.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-new-part',
  templateUrl: './new-part.component.html',
  styleUrls: ['./new-part.component.scss']
})

export class NewPartComponent {

  partInfo = new spGetPart();
  part = new spGetPart();
  partHeader = new GetNewPartHeader();
  public organizationCode: string = '';
  public componentCode: string;
  public frameType: string = '';
  dln: PartLifeClassification[] = [];
  partLifeClassification: PartLifeClassification[] = [];
  selectedDln: number = 0;
  selectedPart: number = null;
  selectedPartLife: number = 0;
  selectedClone: number = 0;
  partsData: spGetParts[] = [];
  organizationName: string = '';
  unitTypeName: string = '';
  componentName: string = '';

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private partEntryService: PartEntryService,
    private newPartService: NewPartService, private partsService: PartsService,
    private notifyService: NotificationService,) {
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.organizationCode = params['OrgCode'];
      this.componentCode = params['CompCode'];
      this.frameType = params['FrameType'];
    });

    this.getHeaderDetails();
    this.getPartsDetails();
    this.getPartLifeClassification();
    this.getDLN();
  }

  getHeaderDetails() {
    this.newPartService.getNewPartsHeaderDetails(this.organizationCode, this.componentCode,
      this.frameType).subscribe((res) => {
        this.partHeader = res;

        this.organizationName = this.partHeader.organization;
        this.unitTypeName = this.partHeader.frameType;
        this.componentName = this.partHeader.component;
      });
  }

  getPartsDetails() {
    this.partsService.getPartsData(this.organizationCode, this.componentCode,
      this.frameType, null).subscribe((res) => {
        this.partsData = res;
      });
  }

  getPartLifeClassification() {
    this.partEntryService.getAllPartLifeClassification().subscribe((res) => {
      this.partLifeClassification = res;
    });
  }

  getDLN() {
    this.newPartService.getAllDln().subscribe((res) => {
      this.dln = res;
    });
  }

  onPartClassificationChange(event: any) {
    this.selectedPart = event.value;
    if (this.selectedPart == 0) {
      this.partInfo.kit = false;
    }
    else if (this.selectedPart == 1) {
      this.partInfo.kit = true;
    }
  }

  onCloneFromChange(event: any) {
    this.selectedClone = event.value;

    this.newPartService.getPartDetails(this.organizationCode, this.componentCode,
      this.frameType, event.value).subscribe((res) => {
        this.part = res;

        if (this.part != null) {
          this.partInfo.partLifeClassification = this.part.partLifeClassification;
          this.partInfo.style = this.part.style;
          this.partInfo.kitNumber = this.part.kitNumber;
          this.partInfo.partNumber = this.part.partNumber;
          this.partInfo.stockId = this.part.stockId;
          this.partInfo._2055compatible = this.part._2055compatible;
          this.partInfo.maxNumberOfRepairs = this.part.maxNumberOfRepairs;
          this.partInfo.max_Hours = this.part.max_Hours;
        }
      });
  }

  onPartLifeClassificationChange(event: any) {
    this.partInfo.partLifeClassification = event.value;
  }

  OnDateChange(part: spGetPart, dateType: string) {
    if (dateType == 'ServiceDate' && part.service_Date != null) {
      const serviceDate = new Date(part.service_Date!);
      part.service_Date = this.notifyService.ConvertDateBeforeSave(serviceDate);
    }

    if (dateType == 'ScrapDate' && part.scrap_Date != null) {
      const scrapDate = new Date(part.scrap_Date!);
      part.scrap_Date = this.notifyService.ConvertDateBeforeSave(scrapDate);
    }
  }

  UpdateIntValues(part: spGetPart, type: string) {
    if (type == 'numberOf') {
      part.numberOf = parseInt(part.numberOf);
    }
    if (type == 'maxRepairs') {
      part.maxNumberOfRepairs = parseInt(part.maxNumberOfRepairs);
    }
    if (type == 'priorRepairs') {
      part.priorNumberOfRepairs = parseInt(part.priorNumberOfRepairs);
    }
    if (type == 'startingHours') {
      part.starting_Hours = parseInt(part.starting_Hours);
    }
    if (type == 'maxHours') {
      part.max_Hours = parseInt(part.max_Hours);
    }
  }

  SavePartDetails() {
    if (this.selectedPart == null) {
      this.notifyService.showError("Part Type is required.", "");
    }
    else if (this.partInfo.set_Number == '' || this.partInfo.set_Number == undefined) {
      this.notifyService.showError("Set number is required.", "");
    }
    else if (this.partInfo.max_Hours == null || this.partInfo.max_Hours == undefined) {
      this.notifyService.showError("Max hours is required.", "");
    }
    else if (this.partInfo.maxNumberOfRepairs < 0) {
      this.notifyService.showError("Max number of repairs must be zero or greater.", "");
    }
    else if (this.partInfo.priorNumberOfRepairs < 0) {
      this.notifyService.showError("Prior number of repairs must be zero or greater.", "");
    }
    else if (this.partInfo.starting_Hours < 0) {
      this.notifyService.showError("Starting hours must be zero or greater.", "");
    }
    else if (this.partInfo.max_Hours < 0) {
      this.notifyService.showError("Max hours must be zero or greater.", "");
    }
    else {
      this.partInfo.component_Code = this.partHeader.componentCode;
      this.partInfo.frame_Type = this.partHeader.frameTypeCode;
      this.partInfo.organization_Code = this.partHeader.organizationCode;

      this.newPartService.insertPartEntryData(this.partInfo).subscribe(res => {
        if (res == -1) {
          this.router.navigate(['tops/part-entry'], {
            queryParams: {
              OrgCode: this.partInfo.organization_Code,
              FrType: this.partInfo.frame_Type,
              CompCode: this.partInfo.component_Code,
              SetNo: this.partInfo.set_Number,
              id: 0
            },
          });
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

}
