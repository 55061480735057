import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { MaintenanceLog } from '#models/maintenance-log';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceLogService {

  constructor(private http: HttpClient) { }

  getDGSLinks(id: number) {
    return this.http.get<any>(`${environment.apiEndpoint}/DgsLinks?EventId=${id}`);
  }

  deleteDGSLink(linkid: number) {
    return this.http.delete<any>(`${environment.apiEndpoint}/DgsLinks/${linkid}`);
  }

  putDGSLink(linkid: number, link: any): Observable<any> {
    return this.http.put(`${environment.apiEndpoint}/DgsLinks/${linkid}`, link);
  }

  postDGSLink(link: any): Observable<any>{
    return this.http.post<MaintenanceLog>(`${environment.apiEndpoint}/DgsLinks`, link);
  }

  public getEventLogs(compressorEquipmentId?: string): Observable<MaintenanceLog[]> {

    const param = {compressorEquipmentId}

    return this.http.get<MaintenanceLog[]>(`${environment.apiEndpoint}/MaintenanceLogs`, {params: param});
  }

  public getEventLog(id: number): Observable<MaintenanceLog> {

    return this.http.get<MaintenanceLog>(`${environment.apiEndpoint}/MaintenanceLogs/${id}`);
  }

  // public createEventLog(log: MaintenanceLog): Observable<MaintenanceLog> {

  //   return this.http.post<MaintenanceLog>(`${environment.apiEndpoint}/MaintenanceLogs`, log );
  // }

  public putEventLog(log: MaintenanceLog): Observable<MaintenanceLog> {

    return this.http.put<MaintenanceLog>(`${environment.apiEndpoint}/MaintenanceLogs/${log.id}`, log);
  }

  
  public createEventLog(workOrder: Number , compressorId: string, entrydate: Date, dateIn: Date, dateOut: Date,
    sealplacement: string, reason: string, failurecause: string, primarySecondarySealFail: string,
    serialInstalled: string, serialRemoved: string, comments: string): Observable<MaintenanceLog> {

    return this.http.post<MaintenanceLog>(`${environment.apiEndpoint}/MaintenanceLogs`, {
      workOrderNumber : workOrder, 
      CompressorEquipmentId: compressorId,
      EntryDate: entrydate,
      dateIn : dateIn,
      dateOut: dateOut,
      SealPlacement:sealplacement,
      reason: reason,
      FailureCause : failurecause,
      primarySecondarySealFail: primarySecondarySealFail,
      serialInstalled : serialInstalled,
      serialRemoved : serialRemoved,
      comments : comments
    } );
  }

}
