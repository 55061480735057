import { NgModule } from '@angular/core';
import { CoreModule } from 'app/core/core.module';

// Material Design Modules
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

// Components
//import { MaintenanceLog } from './pages/maintenance-log.page';crea
import { CreateEventLogComponent } from './pages/create-event-log/create-event-log.component';


@NgModule({
  imports: [
    CoreModule,

      // Material design
      MatButtonModule,
      MatButtonToggleModule,
      MatCardModule,
      MatDividerModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatSnackBarModule,
      MatProgressSpinnerModule,
      MatDialogModule,
      MatListModule,
      MatIconModule,
      MatTooltipModule,
      MatDatepickerModule,
      MatNativeDateModule,
  ],
  declarations: [
    
    CreateEventLogComponent,

  


  ],
  exports: [
  ],
})
export class MaintenanceLogModule { }