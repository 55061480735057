import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { Compressor } from '#models/compressor';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class CompressorService {

  constructor(private http: HttpClient) { }

  public getCompressors(facility?: string): Observable<Compressor[]> {

    const param = {facility}

    return this.http.get<Compressor[]>(`${environment.apiEndpoint}/Compressors`, {params: param});
  }

  public getCompressor(id: number): Observable<Compressor> {

    return this.http.get<Compressor>(`${environment.apiEndpoint}/Compressors/${id}`);
  }

}
