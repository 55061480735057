import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {

  public error_message: string;

  public error_status: number;

  constructor(public activatedRoute: ActivatedRoute) {
    // do nothing
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.error_message = params['message'];
      this.error_status = params['status'];
    });
  }

}
