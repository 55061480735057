<div class="dropdown">
    <button class="dropdown-toggle" type="button" data-toggle="dropdown">
      <span class="selected-color-box" [style.background]="selectedColor.hex"></span>
      <span class="dropdown-arrow">▾</span>
    </button>
    <div class="dropdown-menu">
      <div class="color-grid">
        <div *ngFor="let color of colors" 
             class="color-box" 
             [style.background]="color.hex" 
             (click)="selectColor(color)">
        </div>
      </div>
    </div>
  </div>
  