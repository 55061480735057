export class spInsertOutage {
    organizationCode?: string = '';
    companyCode?: string = '';
    unit?: string = '';
    outageDate: Date;
    outageEndDate: Date;
    duration?: number = 0;
    outageType?: string = '';
    turnaroundName?: string = '';
    userID: string = '';
}