<div class="container" style="background-color: white;">
    <div style="text-align:left; width: 50%;">
        <table mat-table matSort style="margin-top: 10px; border: 1px solid grey;" aria-describedby=""
            [dataSource]="unitTypesDataSource">

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center"> Unit
                    Type </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <span *ngIf="row.code != '0'">{{ row.code }}</span>
                    <input type="text" maxlength="50" class="BlackText" size="25" *ngIf="row.code == '0'"
                        [(ngModel)]="code">
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center">
                    Description </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <input type="text" maxlength="50" class="BlackText" size="25" *ngIf="row.code == '0'"
                        [(ngModel)]="description">

                    <ng-container *ngIf="!isEditing(row)">{{row.description}}</ng-container>
                    <ng-container *ngIf="isEditing(row) && row.code != '0'">
                        <input type="text" [(ngModel)]="updatedDesc" maxlength="50" size="25" class="BlackText">
                    </ng-container>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="cloneFrom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center"> Clone
                    From (Optional) </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <mat-form-field appearance="outline" style="height: 20px; width: 190px;" *ngIf="row.code == '0'">
                        <mat-select [(value)]="selectedOrg" (selectionChange)="onOrganizationChange($event)">
                            <mat-option *ngFor="let row of unitTypes" [value]="row.code">
                                {{ row.description }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef scope="col" style="width: 30%;"></th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!isEditing(row)">
                        <input type="submit" value="Add" class="BlackText" (click)="addUnitType(code, description)"
                            style="margin-top: 5px; margin-left: 3px;" *ngIf="row.code == '0'">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            *ngIf="row.code == '0'" (click)="cancelInsertUnitType()">

                        <input type="submit" value="Section & Components" class="BlackText"
                            style="margin-top: 3px; margin-left: 3px;" *ngIf="row.code != '0'"
                            (click)="ShowUnitSectionsComponentsDialog(row)">

                        <input type="submit" value="Outage Types" class="BlackText"
                            style="margin-top: 3px; margin-left: 3px;" *ngIf="row.code != '0'">

                        <input type="submit" value="Edit" class="BlackText" *ngIf="row.code != '0'"
                            style="margin-top: 3px; margin-left: 3px;" (click)="onEditUnitTypeClick(row)">
                        <input type="submit" value="Delete" class="BlackText" style="margin-top: 3px; margin-left: 3px;"
                            (click)="deleteUnitType(row)" *ngIf="row.inUse == 0 && row.code != '0'">
                    </ng-container>

                    <ng-container *ngIf="isEditing(row)">
                        <input type="submit" value="Update" class="BlackText" *ngIf="row.code != '0'"
                            (click)="UpdateUnitTypeDetails(row, updatedDesc)">
                        <input type="submit" value="Cancel" class="BlackText" *ngIf="row.code != '0'"
                            style="margin-left: 3px;" (click)="cancelUpdateUnitType(row)">
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef scope="col"></th>
                <td mat-cell *matCellDef="let row">
                    <button mat-icon-button matTooltip="Delete Component" color="primary"
                        (click)="onDeleteComponentClick(row)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="unitTypesColumns" style="color: white; height: 20px;"
                class="header-color">
            </tr>
            <tr mat-row *matRowDef="let row; columns: unitTypesColumns;" style="height: 40px !important;">
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>
        </table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="15" showFirstLastButtons
            [pageSizeOptions]="[15, 30, 50, 100, 200]" aria-label="Select page of users"></mat-paginator>
    </div>
</div>