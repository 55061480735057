export class spGetUnit {
    companyCode?: string = '';
    unit?: string = '';
    organizationCode?: string = '';
    startYear?: string = '';
    turbineModel?: string = '';
    turbineRating?: string = '';
    turbineSerialNumber?: string = '';
    drivenEquipmentModel?: string = '';
    drivenEquipmentRating?: string = '';
    drivenEquipmentSerialNumber?: string = '';
    accessoryGearSerialNumber?: string = '';
    loadGearSerialNumber?: string = '';
    pifiredHoursPoint?: string = '';
    pifiredStartsPoint?: string = '';
    pimanualStartsPoint?: string = '';
    pitotalStartsPoint?: string = '';
    piemergencyStopsPoint?: string = '';
    pipeakFiredHoursPoint?: string = '';
    frameType?: string = '';
    dln: boolean = false;
    peaker: boolean = false;
    percentUpTime?: any;
    color?: string = '';
    firingTemperature?: any;
    fuelSource?: number = 0;
    companyName?: string = '';
    organizationDescription?: string = '';
    frameTypeDescription?: string = '';
    fuelSourceDescription?: string = '';
    inUse?: number = 0;
}