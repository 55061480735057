export class MaintenanceLog {
    id?: number; // log id 

    //compressorId?: number; // associated Compressor in log entry 

    compressorEquipmentId?: string; // associated Compressor in log entry 

    entryDate: Date;

    dateIn?: Date;

    dateOut?: Date;

    serialInstalled?: string;

    serialRemoved?: string;

    operatingHours?: number;

    workOrderNumber?: string;

    comments?: string;

    reason?: string;

    primarySecondarySealFail?: string;

    failureCause?: string;

    sealPlacement?: string;
    date?: string

}