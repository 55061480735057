<div class="container" style="background-color: white;">

    <div class="ErrorMsgArea" *ngIf="errorMessage">
        <span class="errormsg"> {{ errorMessage }} </span>
    </div>

    <table class="BlackText" cellpadding="4" cellspacing="0" style="margin-top: 15px;">
        <tbody>
            <tr>
                <td>Organization:</td>
                <td>{{ repairInfo?.organization }}</td>
            </tr>
            <tr>
                <td>Component:</td>
                <td>{{ repairInfo?.component }}</td>
            </tr>
            <tr>
                <td>Unit Type:</td>
                <td>{{ repairInfo?.frameTypeDescription }}</td>
            </tr>
            <tr>
                <td>Set Number:</td>
                <td>{{ repairInfo?.setNumber }}</td>
            </tr>
        </tbody>
    </table>

    <div class="row tabbed tabbed-content-control centered" style="margin-top: 10px;">
        <div class="filter-bar tab-filter-bar">
            <mat-tab-group [selectedIndex]="0" animationDuration="0ms" [disableRipple]='true' disablepagination="true">

                <mat-tab label="Repair Information">
                    <ng-template matTabContent>
                        <div style="margin-top: 10px;">
                            <table class="BlackText" cellpadding="4" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td>Owner Plant:</td>
                                        <td>
                                            <mat-form-field appearance="outline" style="height: 20px; width: 247px;">
                                                <mat-select [(ngModel)]="repairInfo.companyCode"
                                                    (selectionChange)="onOwnerPlantChange(repairInfo.companyCode)">
                                                    <mat-option [value]="NULL"> </mat-option>
                                                    <mat-option *ngFor="let row of ownerPlants"
                                                        [value]="row.companyCode">
                                                        {{ row.companyName }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Unit:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px; width: 247px;">
                                                <mat-select [(ngModel)]="repairInfo.unit">
                                                    <mat-option [value]="0"> </mat-option>
                                                    <mat-option *ngFor="let row of units" [value]="row.unitName">
                                                        {{ row.unitName }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>Ship Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 11px;">
                                                <input matInput [matDatepicker]="picker" placeholder="MM/dd/yyyy"
                                                    [ngModel]="repairInfo.startDate"
                                                    (dateChange)="OnStartDateChange(repairInfo, $event)">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Received Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="picker1" placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="repairInfo.endDate"
                                                    (dateChange)="OnDateChange(repairInfo, 'ReceivedDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker1"></mat-datepicker-toggle>
                                                <mat-datepicker #picker1></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Project Code:</td>
                                        <td>
                                            <input type="text" maxlength="50" class="BlackText" size="50"
                                                style="margin-top: 12px;" [(ngModel)]="repairInfo.projectCode">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Internal Reference #:</td>
                                        <td>
                                            <input type="text" maxlength="50" class="BlackText" size="50"
                                                style="margin-top: 5px;"
                                                [(ngModel)]="repairInfo.internalReferenceNumber">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>External Reference #:</td>
                                        <td>
                                            <input type="text" maxlength="50" class="BlackText" size="50"
                                                style="margin-top: 5px;"
                                                [(ngModel)]="repairInfo.externalReferenceNumber">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top" nowrap="">Serial Numbers:&nbsp;
                                            <a class="mx-4" href="javascript:void(0);"
                                                (click)="ShowSerialNumbersDialog()"
                                                style="margin-left: 0px !important; margin-top: 5px;">
                                                <img alt="User Guide" src="/assets/images/Search_16x16.png"
                                                    height="20px" width="15px" />
                                            </a>
                                        </td>
                                        <td valign="top" width="800">
                                            <span>{{ repairInfo?.serialNumbers }}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td nowrap="">Number of Serial Numbers:</td>
                                        <td valign="top">
                                            <span>{{ repairInfo?.numberOfSerialNumbers }}</span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Costs">
                    <ng-template matTabContent>
                        <div style="margin-top: 10px;">
                            <table class="BlackText" cellpadding="4" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td>Material:</td>
                                        <td>
                                            <input type="number" maxlength="15" class="BlackText text-right" size="15"
                                                [ngModel]="repairInfo.materialCost == '0' ? '' : repairInfo.materialCost | number:'1.2-2'"
                                                (change)="UpdateValue(repairInfo, 'material', $event)">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Material Tax:</td>
                                        <td>
                                            <input type="number" maxlength="15" class="BlackText text-right" size="15"
                                                style="margin-top: 5px;"
                                                [ngModel]="repairInfo.materialTax == '0' ? '' : repairInfo.materialTax | number:'1.2-2'"
                                                (change)="UpdateValue(repairInfo, 'materialTax', $event)">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Labor:</td>
                                        <td>
                                            <input type="number" maxlength="15" class="BlackText text-right" size="15"
                                                style="margin-top: 5px;"
                                                [ngModel]="repairInfo.laborCost == '0' ? '' : repairInfo.laborCost | number:'1.2-2'"
                                                (change)="UpdateValue(repairInfo, 'labor', $event)">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Labor Tax:</td>
                                        <td>
                                            <input type="number" maxlength="15" class="BlackText text-right" size="15"
                                                style="margin-top: 5px;"
                                                [ngModel]="repairInfo.laborTax == '0' ? '' : repairInfo.laborTax | number:'1.2-2'"
                                                (change)="UpdateValue(repairInfo, 'laborTax', $event)">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Freight:</td>
                                        <td>
                                            <input type="number" maxlength="15" class="BlackText text-right" size="15"
                                                style="margin-top: 5px; margin-bottom: 5px;"
                                                [ngModel]="repairInfo.freight == '0' ? '' : repairInfo.freight | number:'1.2-2'"
                                                (change)="UpdateValue(repairInfo, 'freight', $event)">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Total Cost:</td>
                                        <td style="border-top:1px solid black; text-align:right"
                                            class="BlackText text-right">
                                            {{ repairInfo?.totalCost | number:'1.2-2' }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Notes">
                    <ng-template matTabContent>
                        <div style="margin-top: 10px;">
                            <textarea rows="8" cols="160" class="BlackText" [(ngModel)]="repairInfo.notes">
                            </textarea>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Repair Instructions">
                    <ng-template matTabContent>
                        <div style="margin-top: 10px;">
                            <table class="BlackText" cellpadding="4" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td>Customer Want Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="custpicker" placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="repairInfo.customerWantDate"
                                                    (dateChange)="OnDateChange(repairInfo, 'CustomerWantDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="custpicker"></mat-datepicker-toggle>
                                                <mat-datepicker #custpicker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Onsite Customer Want Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="onsitepicker" placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="repairInfo.onsiteCustomerWantDate"
                                                    (dateChange)="OnDateChange(repairInfo, 'OnsiteCustomerWantDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="onsitepicker"></mat-datepicker-toggle>
                                                <mat-datepicker #onsitepicker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Critically Late Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="latepicker" placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="repairInfo.criticallyLateDate"
                                                    (dateChange)="OnDateChange(repairInfo, 'CriticallyLateDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="latepicker"></mat-datepicker-toggle>
                                                <mat-datepicker #latepicker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Warranty Work:</td>
                                        <td>
                                            <textarea rows="3" cols="150" class="BlackText" style="margin-top: 10px;"
                                                [(ngModel)]="repairInfo.warrantyWork"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Repair Reports Required:</td>
                                        <td>
                                            <textarea rows="3" cols="150" class="BlackText"
                                                [(ngModel)]="repairInfo.repairWorkRequired"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Important Job Instructions:</td>
                                        <td>
                                            <textarea rows="3" cols="150" class="BlackText"
                                                [(ngModel)]="repairInfo.jobInstructions"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Shipping & Receiving">
                    <ng-template matTabContent>
                        <div style="margin-top: 10px;">
                            <table class="BlackText" cellpadding="4" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td>Pickup Address if arranged for:</td>
                                        <td>
                                            <input type="text" maxlength="250" class="BlackText" size="148"
                                                [(ngModel)]="repairInfo.pickUpAddress">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Transport Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline" style="height: 20px; margin-top: 6px;">
                                                <input matInput [matDatepicker]="transportpicker"
                                                    placeholder="MM/dd/yyyy"
                                                    (dateChange)="OnShippingRepairDateChange(repairInfo, 'TransportDate')"
                                                    [(ngModel)]="repairInfo.transportDateFromSite">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="transportpicker"></mat-datepicker-toggle>
                                                <mat-datepicker #transportpicker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Transport Arrangement from Site:</td>
                                        <td>
                                            <textarea rows="3" cols="150" class="BlackText" style="margin-top: 10px;"
                                                [(ngModel)]="repairInfo.transportArrangement"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Shipping Instructions:</td>
                                        <td>
                                            <textarea rows="3" cols="150" class="BlackText"
                                                [(ngModel)]="repairInfo.shippingInstructions"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Special Requirments for Transport:</td>
                                        <td>
                                            <textarea rows="3" cols="150" class="BlackText"
                                                [(ngModel)]="repairInfo.specialTransportRequirements"></textarea>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td valign="top">Customer Receiving<br>Requirements (days, hours, etc):</td>
                                        <td>
                                            <textarea rows="3" cols="150" class="BlackText"
                                                [(ngModel)]="repairInfo.customerReceivingRequirements"></textarea>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </mat-tab>

                <mat-tab label="Repair Facility">
                    <ng-template matTabContent>
                        <div style="margin-top: 10px; height: 205px;">
                            <table class="BlackText" cellpadding="4" cellspacing="0">
                                <tbody>
                                    <tr>
                                        <td>Repair Facility:</td>
                                        <td>
                                            <input type="text" value="Ethos Thailand" maxlength="50" class="BlackText"
                                                size="50" [(ngModel)]="repairInfo.repairFacility">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Arrival Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="arrivalpicker" placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="repairInfo.repairFacilityArrivalDate"
                                                    (dateChange)="OnShippingRepairDateChange(repairInfo, 'ArrivalDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="arrivalpicker"></mat-datepicker-toggle>
                                                <mat-datepicker #arrivalpicker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Commitment Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="commitmentpicker"
                                                    placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="repairInfo.repairFacilityCommitmentDate"
                                                    (dateChange)="OnShippingRepairDateChange(repairInfo, 'CommitmentDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="commitmentpicker"></mat-datepicker-toggle>
                                                <mat-datepicker #commitmentpicker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Ship Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="shippicker" placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="repairInfo.repairFacilityShipDate"
                                                    (dateChange)="OnShippingRepairDateChange(repairInfo, 'RepShipDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="shippicker"></mat-datepicker-toggle>
                                                <mat-datepicker #shippicker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>Inspection Report Date:</td>
                                        <td>
                                            <mat-form-field appearance="outline"
                                                style="height: 20px; margin-top: 10px;">
                                                <input matInput [matDatepicker]="inspectionpicker"
                                                    placeholder="MM/dd/yyyy"
                                                    [(ngModel)]="repairInfo.inspectionReportDate"
                                                    (dateChange)="OnShippingRepairDateChange(repairInfo, 'InspectionReportDate')">
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="inspectionpicker"></mat-datepicker-toggle>
                                                <mat-datepicker #inspectionpicker></mat-datepicker>
                                            </mat-form-field>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-template>
                </mat-tab>

            </mat-tab-group>

            <br>
            <input type="submit" value="Save" class="BlackText" (click)="UpdateRepair(repairInfo)">&nbsp;
            <input type="submit" value="Cancel" class="BlackText" (click)="OnCancelClick()">&nbsp;
            <input type="submit" value="Return to Set" class="BlackText" (click)="OnReturntoSetClick()">&nbsp;
            <input type="submit" *ngIf="repairInfo.outageDate === null" value="Return to Outage" class="BlackText"
                (click)="OnReturntoOutageClick()">&nbsp;
            <input type="submit" value="Repair Opening Letter" class="BlackText"
                (click)="OnRepairOpeningLetterClick()">&nbsp;
        </div>
    </div>
</div>