<div>
    <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
        cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
            Unit Sections and Components
        </h1>
        <span style="background-color: antiquewhite;">
            <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">
                X
            </button>
        </span>
    </div>

    <div mat-dialog-content>
        <div style="margin-top: 5px;">
            <span class="BlackText Bold"> Unit Type: {{ data.unitType }}<br /></span>

            <table>
                <tbody>
                    <tr>
                        <td valign="top">
                            <table cellpadding="4" cellspacing="0" class="BlackText Border">
                                <tbody>
                                    <tr class="Header outage-info" style="text-align: center;">
                                        <th>Section</th>
                                        <th style="width: 50px;">Order</th>
                                        <th></th>
                                    </tr>
                                    <tr class="Row1">
                                        <td>
                                            <mat-form-field appearance="outline" style="height: 20px; margin-top: 5px;
                                            margin-bottom: 18px; width: 160px;">
                                                <mat-select [(value)]="selectedSection"
                                                    (selectionChange)="onSectionChange($event)">
                                                    <mat-option *ngFor="let row of sections" [value]="row.code">
                                                        {{ row.description }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </td>
                                        <td></td>
                                        <td>
                                            <input type="submit" value="Add" class="BlackText" style="margin-left: 3px;"
                                                (click)="addSection(selectedSection)">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                style="margin-left: 3px;" (click)="cancelAddSection()">
                                        </td>
                                    </tr>
                                    <tr class="Row2" *ngFor="let section of unitSections let i = index">
                                        <td>
                                            <span style="display: none;">{{ section.section }}</span>
                                            <a href="javascript:void(0);" (click)="onSectionClick(section)">
                                                {{ section.description }}
                                            </a>
                                        </td>
                                        <td>
                                            <a class="BlackText" href="javascript:void(0);" *ngIf="section.order != 1"
                                                (click)="changeUnitTypeSectionOrder(section, -1)">▲</a>&nbsp;&nbsp;
                                            <a class="BlackText" href="javascript:void(0);"
                                                (click)="changeUnitTypeSectionOrder(section, 1)"
                                                *ngIf="section.order != section.sectionMaxOrder">▼</a>
                                        </td>
                                        <td>
                                            <input type="submit" value="Delete" *ngIf="section.inUse == 0"
                                                class="BlackText" (click)="deleteUnitTypeSection(section)"
                                                style="margin-top: 3px;">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>

                        <td valign="top" width="10"><br></td>
                        <td valign="top">
                            <table cellpadding="4" cellspacing="0" class="BlackText Border" style="width: 100%;"
                                *ngIf="isComponentVisible">
                                <tbody>
                                    <tr class="Header outage-info" style="text-align: center; font-weight: 700;
                                    font-weight: 11pt;">
                                        {{ selectedSectionDescription }}
                                    </tr>
                                </tbody>
                            </table>
                            <table mat-table matSort style="border: 1px solid grey;" aria-describedby=""
                                [dataSource]="unitComponentsDataSource" *ngIf="isComponentVisible">

                                <ng-container matColumnDef="component">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Component </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <span *ngIf="row.componentCode != '0'">{{ row.componentName }}</span>

                                        <mat-form-field appearance="outline" style="height: 20px; width: 180px;"
                                            *ngIf="row.componentCode == '0'">
                                            <mat-select [(value)]="selectedComponent"
                                                (selectionChange)="onComponentChange($event)">
                                                <mat-option *ngFor="let row of components" [value]="row.componentCode">
                                                    {{ row.componentName }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="section">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Section </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <mat-form-field appearance="outline" style="height: 20px; width: 110px;"
                                            *ngIf="row.componentCode == '0'">
                                            <mat-select [(value)]="selectedSectionComponent"
                                                [disabled]="isSectionDisabled"
                                                (selectionChange)="onComponentSectionChange($event)">
                                                <mat-option *ngFor="let row of unitSections" [value]="row.section">
                                                    {{ row.description }}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <ng-container *ngIf="!isEditing(row) && row.componentCode != '0'">
                                            <span>{{ row.sectionDescription }}</span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditing(row) && row.componentCode != '0'">
                                            <mat-form-field appearance="outline" style="height: 20px; width: 110px;">
                                                <mat-select [(value)]="updatedSection"
                                                    (selectionChange)="onEditComponentSectionChange($event)">
                                                    <mat-option [value]="NULL"> </mat-option>
                                                    <mat-option *ngFor="let row of unitSections" [value]="row.section">
                                                        {{ row.description }}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="classification">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Classification </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <mat-form-field appearance="outline" style="height: 20px; width: 100px;"
                                            *ngIf="row.componentCode == '0'">
                                            <mat-select [(value)]="selectedPart"
                                                (selectionChange)="onPartClassificationChange($event)">
                                                <mat-option value="0">Single Part</mat-option>
                                                <mat-option value="1">Set/Kit</mat-option>
                                            </mat-select>
                                        </mat-form-field>

                                        <ng-container *ngIf="!isEditing(row) && row.componentCode != '0'">
                                            <span> {{ row.classificationDescription }} </span>
                                        </ng-container>
                                        <ng-container *ngIf="isEditing(row) && row.componentCode != '0'">
                                            <mat-form-field appearance="outline" style="height: 20px; width: 100px;">
                                                <mat-select [(value)]="updatedSelectedPart"
                                                    (selectionChange)="onEditPartClassificationChange($event)">
                                                    <mat-option value="0">Single Part</mat-option>
                                                    <mat-option value="1">Set/Kit</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </ng-container>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="numberOf">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center"> #
                                        Of </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <input type="text" class="BlackText" size="3" maxlength="3" step="1"
                                            [(ngModel)]="numberOf" (keypress)="preventDecimal($event)"
                                            *ngIf="row.componentCode == '0' && isNumberOfVisible">

                                        <ng-container *ngIf="!isEditing(row) && row.classification != 0">
                                            <span *ngIf="row.componentCode != '0'">{{ row.numberOf }}</span>
                                        </ng-container>
                                        <ng-container
                                            *ngIf="isEditing(row) && row.componentCode != '0' && isEditNumberOfVisible">
                                            <input type="text" class="BlackText" size="3" maxlength="3" step="1"
                                                [(ngModel)]="updatedNumberOf" (keypress)="preventDecimal($event)">
                                        </ng-container>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="order">
                                    <th mat-header-cell *matHeaderCellDef style="color: white;" class="text-center">
                                        Order </th>
                                    <td mat-cell *matCellDef="let row" class="text-left">
                                        <a class="BlackText" href="javascript:void(0);"
                                            *ngIf="row.order != 1 && row.componentCode != '0' && !isEditing(row)"
                                            (click)="changeComponentOrder(row, -1)">▲</a>&nbsp;&nbsp;
                                        <a class="BlackText" href="javascript:void(0);"
                                            (click)="changeComponentOrder(row, 1)" *ngIf="row.order != row.sectionMaxOrder
                                            && row.componentCode != '0' && !isEditing(row)">▼</a>
                                    </td>
                                    <td mat-cell *matCellDef="let row"></td>
                                </ng-container>

                                <ng-container matColumnDef="actions">
                                    <th mat-header-cell *matHeaderCellDef scope="col" style="width: 30%;"></th>
                                    <td mat-cell *matCellDef="let row">
                                        <ng-container *ngIf="!isEditing(row)">
                                            <input type="submit" value="Add" class="BlackText"
                                                (click)="addComponent(selectedComponent, selectedSectionComponent, selectedPart, row)"
                                                style="margin-top: 5px; margin-left: 3px;"
                                                *ngIf="row.componentCode == '0'">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                style="margin-left: 3px;" *ngIf="row.componentCode == '0'"
                                                (click)="canceladdComponent()">

                                            <input type="submit" value="Edit" class="BlackText"
                                                *ngIf="row.componentCode != '0'"
                                                style="margin-top: 3px; margin-left: 3px;"
                                                (click)="onEditComponentClick(row)">
                                            <input type="submit" value="Delete" class="BlackText"
                                                style="margin-left: 3px;" (click)="deleteUnitComponent(row)"
                                                *ngIf="row.inUse == 0 && row.componentCode != '0'">
                                        </ng-container>

                                        <ng-container *ngIf="isEditing(row)">
                                            <input type="submit" value="Update" class="BlackText"
                                                *ngIf="row.componentCode != '0'"
                                                (click)="updateComponent(row, updatedSection, updatedSelectedPart, updatedNumberOf)">
                                            <input type="submit" value="Cancel" class="BlackText"
                                                *ngIf="row.componentCode != '0'" style="margin-left: 3px;"
                                                (click)="cancelUpdateComponentClick(row)">
                                        </ng-container>
                                    </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="unitSectionComponentsColumns"
                                    style="color: white; height: 20px; background-color: #75caeb;">
                                </tr>
                                <tr mat-row *matRowDef="let row; columns: unitSectionComponentsColumns;"
                                    style="height: 40px !important;">
                                </tr>

                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">No data</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>