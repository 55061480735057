
// model for 'Component' section of Admin tab in TOPS... compnents can be added and modified by admins to represent equipment in as much granularity as desired. 

export class TopsComponent {

    componentCode: string;

    componentName: string;

    inUse: number; // 0 and 1 used as boolean values to deteremine if in use 
}