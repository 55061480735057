import { spGetUnitTypes } from '#models/spGetUnitTypes';
import { UnitTypesService } from '#services/http/unit-types.service';
import { NotificationService } from '#services/notification.service';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UnitOutageTypesComponent } from 'app/core/modals/unit-outage-types/unit-outage-types.component';
import { UnitSectionsComponentsComponent } from 'app/core/modals/unit-sections-components/unit-sections-components.component';

@Component({
  selector: 'app-unit-types',
  templateUrl: './unit-types.component.html',
  styleUrl: './unit-types.component.scss'
})

export class UnitTypesComponent {

  unitTypes: spGetUnitTypes[] = [];
  public unitTypesDataSource: MatTableDataSource<spGetUnitTypes>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public editState: { [key: string]: boolean } = {}; // creating a JSON key-pair object to track which elements are in 'edit' mode 
  insertUnit = new spGetUnitTypes();
  code: string = '';
  description: string = '';
  updatedDesc: string = '';
  updatedClone: string = '';
  selectedClone: string = '';

  public unitTypesColumns = ['code', 'description', 'cloneFrom', 'actions'];

  constructor(private notifyService: NotificationService, public dialog: MatDialog,
    private unitTypeService: UnitTypesService) {
  }

  ngOnInit() {
    this.getUnitTypesData();
  }

  public isEditing(row: spGetUnitTypes): boolean {
    return this.editState[row.description] || false;
  }

  getUnitTypesData() {
    this.unitTypeService.getUnitTypesData().subscribe((res) => {
      this.unitTypes = res;

      let type = new spGetUnitTypes();
      type.code = '0';
      type.description = '';
      this.unitTypes.unshift(type);

      this.unitTypesDataSource = new MatTableDataSource<spGetUnitTypes>(this.unitTypes);
      this.unitTypesDataSource.paginator = this.paginator;
    });
  }

  addUnitType(code: string, description: string) {
    if (code == undefined || code == "") {
      this.notifyService.showError("Code is required.", "");
    }
    else if (description == undefined || description == "") {
      this.notifyService.showError("Description is required.", "");
    }
    else {
      this.insertUnit.code = code;
      this.insertUnit.description = description;
      this.insertUnit.cloneCode = '';

      this.unitTypeService.insertUnitType(this.insertUnit).subscribe(res => {
        if (res == -1) {

          this.notifyService.showSuccess("Unit Type added successfully.", "");
          this.code = null;
          this.description = null;
          code = '';
          description = '';
          this.getUnitTypesData();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelInsertUnitType() {
    this.code = '';
    this.description = '';
  }

  onCloneFromChange(event: any) {
    this.updatedClone = event.value;
  }

  public onEditUnitTypeClick(row: spGetUnitTypes) {
    this.updatedDesc = row.description;
    this.editState[row.description] = true;
    this.updatedClone = row.cloneCode;
    this.editState[row.cloneCode] = true;
  }

  UpdateUnitTypeDetails(unit: spGetUnitTypes, updatedDesc: string, updatedClone: string) {
    unit.description = updatedDesc;

    if (unit.description == undefined || unit.description == "") {
      this.notifyService.showError("Description is required.", "");
      this.editState[unit.description] = true;
      this.editState[unit.cloneCode] = true;
    }
    else if (unit.code == updatedClone) {
      this.notifyService.showError("Section cannot clone itself.", "");
      this.editState[unit.description] = true;
      this.editState[unit.cloneCode] = true;
    }
    else {
      unit.cloneCode = updatedClone;
      this.editState[unit.description] = false;
      this.editState[unit.cloneCode] = false;

      this.unitTypeService.UpdateUnitTypeDetails(unit).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Section details modified successfully.", "");
          this.getUnitTypesData();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelUpdateUnitType(row: spGetUnitTypes) {
    this.editState[row.description] = false;
    //window.location.reload();
  }

  deleteUnitType(unit: spGetUnitTypes) {
    this.unitTypeService.deleteUnitType(unit.code).subscribe(res => {

      this.notifyService.showSuccess("Unit Type deleted successfully.", "");
      this.getUnitTypesData();
    });
  }

  ShowUnitSectionsComponentsDialog(row: spGetUnitTypes) {
    this.dialog.open(UnitSectionsComponentsComponent, {
      width: "85%",
      height: "95%",
      data: { unitType: row.code },
    });
  }

  ShowUnitOutageTypesDialog(row: spGetUnitTypes) {
    this.dialog.open(UnitOutageTypesComponent, {
      width: "50%",
      height: "80%",
      data: { unitType: row.code },
    });
  }
}
