export class spGetUnitTypeComponents {
    frameType: string = '';
    componentCode?: string = '';
    section?: number = 0;
    order?: number = 0;
    classification?: string = '';
    number?: number = 0;
    componentName?: string = '';
    sectionOrder?: number = 0;
    sectionDescription: string = '';
    classificationDescription?: string = '';
    sectionMaxOrder?: number = 0;
    inUse?: number = 0;
}