

export class spInsertLink {
    recordNumber: number = 0;
    field1: string;
    field2: string;
    field3: string;
    field4: string;
    field5: string;
    type: string;
    description: string;
    link: string;
    subLink: string;
}
