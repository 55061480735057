import {
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {
  catchError,
  finalize,
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';
import { LoadingDialogService } from '#services/errors/loading-dialog/loading-dialog.service';
import { ErrorDialogService } from '#services/errors/error-dialog/error-dialog.service';

// Link to Error Handling Guide https://pkief.medium.com/global-error-handling-in-angular-ea395ce174b1

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private loadingDialogService: LoadingDialogService,
    private errorDialogService: ErrorDialogService,
    private router: Router,
  ) {
    // do nothing
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.loadingDialogService.openDialog();
    // eslint-disable-next-line max-len
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 403) {
          this.loadingDialogService.hideDialog();
          this.router.navigate(['/error/not-authorized']);
          return throwError(() => new Error('test'));
        }
        else if (error.status === 409) {
          this.loadingDialogService.hideDialog();
          return throwError(() => new Error('test'));
        }
        else if (error.status === 400) {
          this.loadingDialogService.hideDialog();
          return throwError(error.error);
        }
        else {
          this.loadingDialogService.hideDialog();

          let navigationExtra: NavigationExtras = {
            queryParams: {
              message: error.message,
              status: error.status
            }
          }
          console.log(error);
          this.router.navigate(['/error/generic-error'], navigationExtra);
          return throwError(() => new Error('test'));
        }
      }),
      finalize(() => {
        this.loadingDialogService.hideDialog();
      })
    );
  }
}
