import { Component, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '#services/notification.service';
import { OrganizationsService } from '#services/http/organizations.service';
import { AuthService } from 'app/core/authentication/auth.service';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { GetOrganizations } from '#models/GetOrganizations';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})

export class OrganizationsComponent {
  isHeaderRow = false;
  organizations: GetOrganizations[] = [];
  insertOrg = new spGetOrganizations();
  updateOrg = new spGetOrganizations();
  editable: boolean = false;
  isUpdateOrg = false;
  public organizationCode: string = '';
  description: string = '';
  userId: string = '';
  errorMessage: string = '';

  @ViewChild(MatTable) table: MatTable<spGetOrganizations>;

  constructor(private notifyService: NotificationService, public dialog: MatDialog,
    private organizationService: OrganizationsService, private authService: AuthService) {
  }

  ngOnInit() {
    const userInfo = this.authService.getCurrentUser();
    this.userId = userInfo.username;

    // Get Organizations data
    this.organizationService.getOrganizations(this.userId).subscribe((res) => {
      this.organizations = res;
    });
  }

  addOrganization(orgCode: spGetOrganizations) {
    this.errorMessage = "";
    this.ValidateFields(this.insertOrg);
    if (this.errorMessage === "") {
      this.insertOrg.code = orgCode.code.toUpperCase();
      this.insertOrg.description = orgCode.description;

      this.organizationService.insertOrganization(this.insertOrg, this.userId).subscribe(res => {
        if (res == -1) {
          window.location.reload();
        }
        else {
          this.errorMessage = res.toString();
          this.notifyService.showError(this.errorMessage, "");
        }
      });
    }
    else {
      this.notifyService.showError(this.errorMessage, "");
    }
  }

  cancelAddOrganization() {
    this.insertOrg.code = '';
    this.insertOrg.description = '';
  }

  updateOrganization(orgCode: spGetOrganizations) {
    if (this.updateOrg.description == '') {
      this.errorMessage = "Description is required.";
      this.notifyService.showError(this.errorMessage, "");
    }
    else {
      this.updateOrg.code = orgCode.code;
      this.updateOrg.description = orgCode.description;

      this.organizationService.updateOrganization(this.updateOrg, this.userId).subscribe(res => {
        if (res == -1) {
          window.location.reload();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelUpdateOrganization(orgCode: spGetOrganizations) {
    //this.updateOrg = null;
    window.location.reload();
  }

  EditOrganization(orgCode: spGetOrganizations, index: any) {
    this.updateOrg = orgCode;
    this.organizations.splice(index, 1);
    this.isUpdateOrg = true;
  }

  deleteOrganization(org: spGetOrganizations) {
    this.organizationService.deleteOrganization(org, this.userId).subscribe(res => {
      window.location.reload();
    });
  }

  ValidateFields(org: spGetOrganizations) {
    if (org.code == undefined || org.code == "") {
      this.errorMessage = 'Code is required.';
    }
    else if (org.code.toUpperCase() == 'ALL') {
      this.errorMessage = 'ALL cannot be used as a code.';
    }
    else if (org.description == undefined || org.description == "") {
      this.errorMessage = 'Description is required.';
    }
  }

}
