import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { formatDate } from '@angular/common';

// Models
import { BusinessUnit } from '#models/business-unit';
import { Compressor } from '#models/compressor';
import { Facility } from '#models/facility';
import { MeasurementUnit } from '#models/measurement-unit';
import { MaintenanceLog } from '#models/maintenance-log';

// Services 
import { BusinessUnitService } from '#services/http/business-unit.service';
import { MaintenanceLogService } from '#services/http/maintenance-log.service';
import { CompressorService } from '#services/http/compressor.service';
import { MeasurementUnitService } from '#services/http/measurement-unit.service';
import { StringDecoder } from 'string_decoder';


@Component({
  selector: 'app-create-event-log',
  templateUrl: './create-event-log.component.html',
  styleUrls: ['./create-event-log.component.scss']
})
export class CreateEventLogComponent implements OnInit {
  public businessUnits: BusinessUnit[];
  public selectedBU: BusinessUnit;
  public facilities: Facility[];
  public selectedFacility: Facility;
  public compressors: Compressor[];
  public selectedCompressor: Compressor;
  public maintenanceLog: MaintenanceLog;
  public workorder: number;
  public serialNumberInstalled: string;
  public serialNumberRemoved: string;
  public today: number = Date.now();
  public entryDate = new Date();
  public dateIn: Date;
  public dateOut: Date;
  //businessUnit: string;
  public currentdate: string;
  //equipment: string;
  public comments: string;
  //facility:string;
  //primarySecondarySeal:string;
  //reasonfailure: string;
  public sealplacement: string;
  public selectedValue: any;
  public selectedfailurecause: any;
  // Creating Form for Maintenance Log

  selectedfailuretype = '';
  selectedreasonforfailure = '';
  selectedsealfailure = '';
  selectedsealplacement = '';
  isDisabled = true;



  logForm = new FormGroup({
    businessUnit: new FormControl('', Validators.required),
    entryDate: new FormControl({ value: formatDate(this.today, "dd-MM-yyyy", "en"), disabled: true }),
    dateIn: new FormControl({ value: formatDate(this.today, "dd-MM-yyyy", "en"), disabled: false }),
    //dateOut: new FormControl({ value: formatDate(this.today, "dd-MM-yyyy", "en"), disabled: false }),
    facility: new FormControl({ value: '', disabled: true }),
    compressor: new FormControl({ value: '', disabled: true }),
    serialInstalled: new FormControl({ value: '', disabled: false }),
    serialRemoved: new FormControl({ value: '', disabled: false }),
    workOrderNumber: new FormControl({ value: 0, disabled: false }),
    comments: new FormControl({ value: '', disabled: false }),
    failureReason: new FormControl({ value: '', disabled: false }),
    primarySecondarySeal: new FormControl({ value: '', disabled: true }),
    failureCause: new FormControl({ value: '', disabled: true }),
    sealPlacement: new FormControl({ value: '', disabled: false }),

  })

  errorSnackBarConfig: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'bottom',
    panelClass: ['snackbar', 'snackbar-error'],
  }

  successSnackBarConfig: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'bottom',
    panelClass: ['snackbar', 'snackbar-success'],
  }


  constructor(
    private router: Router,
    private businessUnitSvc: BusinessUnitService,
    private compressorSvc: CompressorService,
    private measurementUnitSvc: MeasurementUnitService,
    private maintanancelogservice: MaintenanceLogService,
    private snackBar: MatSnackBar,
  ) {
    // do nothing
  }



  ngOnInit() {
    this.getBusinessUnits();
    //this.selectChange();
        //this.updateFailureReasons();
    

  }

  getBusinessUnits(): void {
    this.businessUnitSvc.getAllBusinessUnits()
      .subscribe({
        next:
          (data: BusinessUnit[]) => {
            this.businessUnits = [...data].sort((first, second) => (first.name > second.name ? 1 : -1));
          },
        error: ((error: HttpErrorResponse) => {
          this.snackBar.open('Business units could not be loaded. Please try again later.', '', this.errorSnackBarConfig);
          console.log(error.error);
          throw error;
        }),

      });

  }

  getCompressors(): void {
    this.compressorSvc.getCompressors(this.selectedFacility.name)
      .subscribe({
        next: (data: Compressor[]) => {
          console.log(data);
          this.compressors = [...data].sort((first, second) => (first.compressorEquipmentId > second.compressorEquipmentId ? 1 : -1));
        },
        error: ((error: HttpErrorResponse) => {
          this.snackBar.open('Equipment data could not be loaded. Please try again later.', '', this.errorSnackBarConfig);
          console.log(error.error);
        }),
      });
  }

  updateBusinessUnit(): void {
    this.logForm.get('compressor').disable();
    this.selectedFacility = undefined;
    this.selectedCompressor = undefined;
    this.updateFacility();
  }

  updateFacility(): void {

    if (this.selectedBU !== undefined) {
      this.logForm.get('facility').enable();

      this.facilities = [...this.selectedBU.facilities].sort((first, second) => (first.name > second.name ? 1 : -1));
    }
  }

  updateEquipment(): void {
    this.selectedCompressor = undefined;

    if (this.selectedFacility !== undefined) {
      this.logForm.get('compressor').enable();
      this.getCompressors();
    }
  }

  selectChange(): void {
    this.selectedValue = this.selectedCompressor;
    this.selectedValue = this.selectedValue.compressorEquipmentId;
  }


  enableFailureReasons(): void {

    if (this.selectedreasonforfailure == 'Failure' || this.selectedreasonforfailure == 'Pending Failure') {
            this.isDisabled = false;
     }

      if (this.selectedreasonforfailure == 'PM' || this.selectedreasonforfailure == 'Paired Replacement') 
      {      this.isDisabled = true;
      }


    


  }

  hasError(controlName: string, errorName: string): boolean {
    return this.logForm.controls[controlName].hasError(errorName) &&
      this.logForm.controls[controlName].touched;
  }


  saveeventlog(): void {

    // console.log(this.selectedreasonforfailure);

    // console.log(this.selectedfailuretype);
    // console.log(this.selectedsealfailure);
    // console.log(this.logForm.controls.failureCause.disable());




    if (this.logForm.valid) {
      //let date: Date = new Date(2023, 0O2, 0O2, 17, 23, 42, 11);
      this.maintanancelogservice.createEventLog(this.workorder, this.selectedValue, this.entryDate, this.dateIn, this.dateOut, this.selectedsealplacement,
        this.selectedreasonforfailure, this.selectedfailuretype, this.selectedsealfailure, this.serialNumberInstalled,
        this.serialNumberRemoved, this.comments).subscribe({
          next: (response) => this.snackBar.open('Event Log Created!', '', this.successSnackBarConfig),
          error: (error) => {
            this.snackBar.open(`Failed to create Event Log. ${error.error.text}`, '', this.errorSnackBarConfig);
          }
        })
    }

  }

}
