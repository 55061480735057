<div class="container" style="background-color: white;">
    <div style="float:left; margin-right:5px; margin-top: 5px;" class="col-md-3">
        <table class="BlackText Border" cellpadding="0" cellspacing="0">
            <tbody>
                <tr class="Header" style="height:18px">
                    <td align="center" style="font-weight:bold; background-color: #009dd0;">
                        <span>Organization</span>
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 190px;">
                            <mat-select [(value)]="selectedOrg" (selectionChange)="onOrganizationChange($event)">
                                <mat-option *ngFor="let row of organizations" [value]="row.code">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Unit Type
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 190px;">
                            <mat-select [(value)]="selectedUnitType" (selectionChange)="onUnitTypeChange($event)">
                                <mat-option *ngFor="let row of unitTypes" [value]="row.code">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Component
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 190px;">
                            <mat-select [(value)]="selectedComponent" (selectionChange)="onComponentChange($event)">
                                <mat-option value="ALL">ALL</mat-option>
                                <mat-option *ngFor="let row of components" [value]="row.componentCode">
                                    {{ row.componentName }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Part Location
                    </td>
                </tr>
                <tr>
                    <td>
                        <div *ngFor="let type of locations">
                            <input type="checkbox" [(ngModel)]="type.isSelected"
                                (change)="onOutageTypeChange(type.code, $event)">
                            <span style="margin-left: 1px;"> {{type.description}} </span>
                        </div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <input type="submit" value="New Part" class="BlackText" style="margin-top: 10px;"
                            *ngIf="selectedComponent != '' && selectedComponent != 'ALL' && selectedUnitType != '' && selectedOrg != ''"
                            (click)="onNewPartClick()">&nbsp;

                        <input type="submit" value="Repair Tracking" class="BlackText" *ngIf="selectedComponent != ''
                        && selectedUnitType != '' && selectedOrg != ''" (click)="onRepairTracking()">
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
    </div>

    <div class="ListViewContainer; col-md-8" id="ListViewContainer" style="width: 78%; margin-top: 5px;
    margin-left: -62px;" *ngIf="partsData?.length > 0">

        <table class="BlackText">
            <tr>
                <td>Legend:&nbsp;&nbsp;</td>
                <td width="15" style="background-color:Red"><br /></td>
                <td>&nbsp;Max Hours Exceed</td>
            </tr>
        </table>

        <table mat-table matSort style="border: 1px solid grey;  margin-top: 5px;" [dataSource]="partsDataSource">

            <!-- set Column -->
            <ng-container matColumnDef="set">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"
                    style="width: 14%;"> Set </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <a href="javascript:void(0);" (click)="ShowPartInfoDialog(row)">
                        {{row.set_Number}}
                    </a>
                </td>
            </ng-container>

            <!-- component Column -->
            <ng-container matColumnDef="component">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Component </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.component_Name }}
                </td>
            </ng-container>

            <!-- dln Column -->
            <ng-container matColumnDef="dln">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"
                    style="width: 14%;"> DLN </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.dlnDescription }}
                </td>
            </ng-container>

            <!-- style Column -->
            <ng-container matColumnDef="style">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Style
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{row.style}}
                </td>
            </ng-container>

            <!-- service Date Column -->
            <ng-container matColumnDef="serviceDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Service Date
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{row.service_Date | date: 'MM/dd/yyyy'}}
                </td>
            </ng-container>

            <!-- scrap Date Column -->
            <ng-container matColumnDef="scrapDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Scrap Date
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{row.scrap_Date | date: 'MM/dd/yyyy'}}
                </td>
            </ng-container>

            <!-- lifetime Hrs Column -->
            <ng-container matColumnDef="lifetimeHrs">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Lifetime Hrs
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"
                    [ngClass]="{'PartHoursExceeded': row.lifeTimeHours > row.maxHours && row.maxHours > 0}">
                    {{ row.lifeTimeHours | number : '1.1-1' }}
                </td>
            </ng-container>

            <!-- location Column -->
            <ng-container matColumnDef="location">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Current Location
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.status }}
                </td>
            </ng-container>

            <!-- delete Column -->
            <ng-container matColumnDef="delete">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info">
                </th>
                <td mat-cell *matCellDef="let row let i = index" class="text-center">
                    <a href="javascript:void(0);" (click)="onClickDelete(row)" *ngIf="row.inUse == 0">
                        <img alt="User Guide" src="/assets/images/delete.png" />
                    </a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="partsColumns"
                style="color: white; background-color: #009dd0; height: 30px;">
            </tr>
            <tr mat-row *matRowDef="let row; columns: partsColumns;"></tr>
        </table>

        <mat-paginator #paginator [pageIndex]="0" [pageSize]="15" showFirstLastButtons
            [pageSizeOptions]="[15, 30, 50, 70, 200]" aria-label="Select page of users"></mat-paginator>
    </div>
</div>