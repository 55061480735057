<div class="container">
  <mat-card class="components-table">
    <mat-card-content>
      <table class="BlackText" style="width:100%">
        <tbody>
          <tr style="height: 30px; text-align: center;">
            <th colspan="8" style="background-color: #009dd0; border-bottom:1px solid white; color: white;">
              <span style="font-size: 17px; font-weight: bold;">Components</span>
            </th>
          </tr>
        </tbody>
      </table>
        <table matSort mat-table [dataSource]="componentTable" class="results-table w-100"
          aria-describedby="" style="width: 100%;" [trackBy]="trackByFn"
          style="margin-top: 10px; border: 1px solid grey;">
          <!-- Component Code column -->
          <ng-container matColumnDef="code">
            <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
              Code
            </th>
            <td mat-cell *matCellDef="let result">
              <ng-container>{{result.componentCode}}</ng-container>
              <!-- <ng-container>
                <input type="text" formControlName="componentCode" placeholder="Component Code">
              </ng-container> -->
            </td>
          </ng-container>
          <!-- Component Name Column-->
          <ng-container matColumnDef="description">
            <th mat-sort-header mat-header-cell *matHeaderCellDef scope="col">
              Description
            </th>
            <td mat-cell *matCellDef="let result">
              <ng-container *ngIf="!isEditing(result)">{{result.componentName}}</ng-container>
              <ng-container *ngIf="isEditing(result)">
                <input type="text" [(ngModel)]="componentNameUpdate">
              </ng-container>
              <!-- <ng-container>
                <input type="text" formControlName="componentName" placeholder="Component Name">
              </ng-container> -->
            </td>
          </ng-container>
          <!-- State buttons -->
          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef scope="col">
              <!-- empty on purpose -->
            </th>
            <td mat-cell *matCellDef="let row">
              <ng-container *ngIf="!isEditing(row)">
                <button mat-icon-button matTooltip="Edit Component " color="primary"
                  (click)="onEditComponentClick(row)">
                  Edit
                </button> &nbsp;
                <button *ngIf="!row.inUse" mat-icon-button matTooltip="Delete Component " color="primary"
                  (click)="onDeleteComponentClick(row)">
                  Delete
                </button>
              </ng-container>
              <ng-container *ngIf="isEditing(row)">
                <button mat-icon-button matTooltip="Update Component " color="primary"
                  (click)="onUpdateComponentClick(row)">
                  Update
                </button> &nbsp;
                <button mat-icon-button matTooltip="Cancel Edit " color="primary" (click)="onCancelEditClick(row)">
                  Cancel
                </button>
              </ng-container>
            </td>
          </ng-container>
          <ng-container matColumnDef="codeInput">
            <th mat-header-cell *matHeaderCellDef>
              <input type="text" maxlength="4" [(ngModel)]="componentCodeInput"  class="mat-header-content">
            </th>
          </ng-container>
          <ng-container matColumnDef="descriptionInput">
            <th mat-header-cell *matHeaderCellDef >
              <input type="text" maxlength="50" [(ngModel)]="componentNameInput" class="mat-header-content">
            </th>
          </ng-container>
          <ng-container matColumnDef="inputAction">
            <th mat-header-cell *matHeaderCellDef>
              <ng-container>
                <button mat-icon-button matTooltip="Insert Component " color="primary" class="mat-header-content"
                    (click)="onInsertComponentClick()">
                    Insert
                  </button> &nbsp;
                  <button mat-icon-button matTooltip="Cancel Edit " color="primary" (click)="onCancelInsertComponentClick()" class="mat-header-content">
                    Cancel
                  </button>
              </ng-container>
            </th>
          </ng-container>
          <!-- Defining the header rows of the table -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns" style="color: white; height: 20px;"
            class="header-color">
          </tr>
          <tr mat-header-row *matHeaderRowDef="['codeInput', 'descriptionInput', 'inputAction']"></tr>
          <!-- Inserting input row right after the header columns-->
          <!-- <tr mat-row>
            <td mat-cell>
              <input type="text" [(ngModel)]="componentCodeInput">
            </td>
            <td mat-cell>
              <input type="text" [(ngModel)]="componentNameInput">
            </td>
            <ng-container>
              <button mat-icon-button matTooltip="Insert Component " color="primary"
                  (click)="onInsertComponentClick()">
                  Insert
                </button> &nbsp;
                <button mat-icon-button matTooltip="Cancel Edit " color="primary" (click)="onCancelInsertComponentClick()">
                  Cancel
                </button>
            </ng-container>
          </tr> -->
          <tr mat-row *matRowDef="let row; columns: displayedColumns" style="height: 40px !important;"></tr>
        </table>
      
      <mat-paginator #paginator [pageIndex]="0" [pageSize]="15" showFirstLastButtons
        [pageSizeOptions]="[15, 30, 50, 100, 200]"
        aria-label="Select increment of components to display"></mat-paginator>
    </mat-card-content>
  </mat-card>
</div>