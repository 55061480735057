<div class="container">
  <div class="row">
    <div class="col">
      <mat-card>
        <img
          alt="User Guide"
          src="/assets/images/hazard_orange.png"
          height="140px"
          width="120px"
          class="d-flex center"
        />
        <h1 class="text-center">That page does not exist</h1>
        <button 
          class="d-flex center mt-4"
          mat-flat-button color="primary"
          routerLink="/"
          >
            Go Home
        </button>
      </mat-card>
    </div>
  </div>
</div>
