import { Component, ViewChild } from '@angular/core';
import { vOutage } from '#models/VOutage';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TopsHomeService } from '#services/http/tops-home.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { OutageDialogComponent } from 'app/core/modals/outage-dialog/outage-dialog.component';
import { UnitDialogComponent } from 'app/core/modals/unit-dialog/unit-dialog.component';

@Component({
  selector: 'app-tops-home',
  templateUrl: './tops-home.component.html',
  styleUrls: ['./tops-home.component.scss']
})

export class TopsHomeComponent {
  public displayedOutageColumns = ['color','outageDate', 'organizationCode', 'companyName', 'unit', 'outageTypeDescription', 'unitFiredHours', 'engineer']
  public outagesDataSource: MatTableDataSource<vOutage>;
  outages: vOutage[] = [];
  isColor: boolean = true;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<vOutage>;

  constructor(private topsHomeService: TopsHomeService,
    public dialog: MatDialog,
    private route: ActivatedRoute,) {
  }

  ngOnInit() {
    this.getOutagesData();
  }

  getOutagesData() {
    this.topsHomeService.getOutages().subscribe((res: vOutage[]) => {
      this.outages = res;

      this.outagesDataSource = new MatTableDataSource<vOutage>(this.outages);
      this.outagesDataSource.paginator = this.paginator;
      //this.sort.sort(({ id: 'outageDate', start: 'desc' }) as MatSortable)
      //this.outagesDataSource.sort = this.sort;
    });
  }

  ShowUnitInfoDialog(row: vOutage) {
    this.dialog.open(UnitDialogComponent, {
      width: "60%",
      height: "95%",
      data: { companyCode: row.companyCode, organizationCode: row.organizationCode, unit: row.unit, frameType: row.frameType },
    });
  }

  ShowOutageInfoDialog(id: number) {
    this.dialog.open(OutageDialogComponent, {
      width: "60%",
      height: "95%",
      data: { id: id },
    });
  }
}
