<!-- Site-Specific Navigation -->
<div *ngIf="!isTops">
<app-header></app-header>
</div>
<div *ngIf="isTops">
<app-tops-header></app-tops-header>
</div>

<!-- Main Content -->
<main>
  <router-outlet></router-outlet>
</main>

<!-- Site-Specific Footer-->
<app-footer [isTops]='isTops'></app-footer>
  