import { environment } from '#environments/environment';
import { spGetSerialNumbers } from '#models/spGetSerialNumbers';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class PartEntryService {
  GetPartEntryUrl = '/PartEntry/getPartEntryDetails';
  GetPartLifeClassificationUrl = '/PartEntry/getPartLifeClassification';
  GetDynamicLinksUrl = '/PartEntry/getDynamicLinks';
  GetHistoryUrl = '/PartEntry/getPartHistory';
  GetSerialNumbersUrl = '/PartEntry/getSerialNumbers';
  GetRepairsUrl = '/PartEntry/getRepairs';
  GetOutageInfoInDateUrl = '/PartEntry/getOutageInDate';
  GetOutageInfoOutDateUrl = '/PartEntry/getOutageOutDate';
  GetRepairInfoUrl = '/PartEntry/getRepairInfoDetails';
  PostPartEntryDataUrl = '/PartEntry/updatePartEntryDetails';
  DeleteRepairUrl = '/PartEntry/deleteRepair';
  GetSerialNoHistoryUrl = '/PartEntry/getPartEntrySerialNoHistory';
  InsertLinksDataUrl = '/PartEntry/insertLink';
  DeleteLinkUrl = '/PartEntry/deleteLink';
  UpdateLinkUrl = '/PartEntry/updateLinkDetails';
  DeleteSrNoUrl = '/PartEntry/deleteSrNo';
  ScrapSerialNumberUrl = '/PartEntry/scrapSrNo';
  UnscrapSerialNumberUrl = '/PartEntry/unscrapSrNo';
  InsertSerialNumberUrl = '/PartEntry/insertSerialNumber';
  ImportPartEntryCSVFileUrl = '/PartEntry/partyEntryImportCSVFile';
  MoveToSetSerialNumberUrl = '/PartEntry/moveSerialNumbers';
  UpdateSerialNumberUrl = '/PartEntry/updateSerialNosDetails';
  GetMoveToSetValuesUrl = '/PartEntry/getMoveToSetValues';

  constructor(private http: HttpClient,) { }

  getPartEntryData(organizationCode: string, componentCode: string, frameType: string, setNumber: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetPartEntryUrl,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType,
          setNo: setNumber
        }
      });
  }

  getHistoryData(organizationCode: string, setNumber: string, componentCode: string, frameType: string,) {
    return this.http.get<any>(environment.apiEndpoint + this.GetHistoryUrl,
      {
        params: {
          organizationCode: organizationCode,
          setNo: setNumber,
          componentCode: componentCode,
          frameType: frameType
        }
      });
  }

  getSerialNumbersData(organizationCode: string, componentCode: string, frameType: string, setNumber: string,) {
    return this.http.get<any>(environment.apiEndpoint + this.GetSerialNumbersUrl,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType,
          setNo: setNumber,
        }
      });
  }

  getRepairsData(organizationCode: string, setNumber: string, componentCode: string, frameType: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetRepairsUrl,
      {
        params: {
          organizationCode: organizationCode,
          setNo: setNumber,
          componentCode: componentCode,
          frameType: frameType
        }
      });
  }

  getAllPartLifeClassification() {
    return this.http.get<any>(environment.apiEndpoint + this.GetPartLifeClassificationUrl);
  }

  getAllDynamicLinks() {
    return this.http.get<any>(environment.apiEndpoint + this.GetDynamicLinksUrl);
  }

  getOutageInfoInDate(organizationCode: string, companyCode: string, unit: string, inDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutageInfoInDateUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          inDate: inDate
        }
      });
  }

  getOutageInfoOutDate(organizationCode: string, companyCode: string, unit: string, outDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutageInfoOutDateUrl,
      {
        params: {
          organizationCode: organizationCode,
          companyCode: companyCode,
          unit: unit,
          outDate: outDate
        }
      });
  }

  getRepairInfoData(organizationCode: string, setNumber: string, componentCode: string, frameType: string, startDate: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetRepairInfoUrl,
      {
        params: {
          organizationCode: organizationCode,
          setNo: setNumber,
          componentCode: componentCode,
          frameType: frameType,
          startDate: startDate
        }
      });
  }

  postPartEntryData(formData: any) {
    return this.http.post(environment.apiEndpoint + this.PostPartEntryDataUrl, formData);
  }

  deleteRepairData(formData: any) {
    return this.http.post(environment.apiEndpoint + this.DeleteRepairUrl, formData);
  }

  getSerialNoHistoryData(organizationCode: string, frameType: string, componentCode: string,
    dln: any, serialNumber: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetSerialNoHistoryUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType,
          componentCode: componentCode,
          dln: dln,
          serialNumber: serialNumber
        }
      });
  }

  insertLinksData(formData: any) {
    return this.http.post(environment.apiEndpoint + this.InsertLinksDataUrl, formData);
  }

  deleteLink(recordNumber: number) {
    //return this.http.post(environment.apiEndpoint + this.DeleteLinkUrl, recordNumber);

    const params = new HttpParams()
      .append('recordNumber', recordNumber)
    return this.http.post<any>(environment.apiEndpoint + this.DeleteLinkUrl, '', {
      params: params
    });
  }

  updateLink(link: any) {
    return this.http.post(environment.apiEndpoint + this.UpdateLinkUrl, link);
  }

  deleteSrNumbers(formData: any) {
    return this.http.post(environment.apiEndpoint + this.DeleteSrNoUrl, formData);
  }

  scrapSerialNumbers(formData: spGetSerialNumbers[]) {
    return this.http.post<spGetSerialNumbers[]>(environment.apiEndpoint + this.ScrapSerialNumberUrl, formData);
  }

  unscrapSerialNumbers(formData: any) {
    return this.http.post(environment.apiEndpoint + this.UnscrapSerialNumberUrl, formData);
  }

  insertSerialNumber(formData: any) {
    return this.http.post(environment.apiEndpoint + this.InsertSerialNumberUrl, formData);
  }

  importPartyEntryCSVFile(formData: FormData, organizationCode: string, componentCode: string, frameType: string, setNumber: string, serviceDate, isHeader: boolean, dln: number) {
    return this.http.post<any>(environment.apiEndpoint + this.ImportPartEntryCSVFileUrl, formData,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType,
          setNumber: setNumber,
          serviceDate: serviceDate,
          isHeader: isHeader,
          dln: dln
        }
      });
  }

  moveToSetSerialNumbers(formData: any[], newset: number) {
    return this.http.post<any[]>(environment.apiEndpoint + this.MoveToSetSerialNumberUrl, formData,
      {
        params: {
          srNumber: formData,
          newSet: newset
        }
      });
  }

  updateSerialNumber(formData: any) {
    return this.http.post(environment.apiEndpoint + this.UpdateSerialNumberUrl, formData);
  }

  getAllMoveToSetValues(organizationCode: string, componentCode: string, frameType: string,
    setNumber: string, dln: number) {
    return this.http.get<any>(environment.apiEndpoint + this.GetMoveToSetValuesUrl,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType,
          setNumber: setNumber,
          dln: dln,
        }
      });
  }

}
