import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class UnitTypesService {
  getUnitTypesUrl = '/UnitTypes/getUnitTypes';
  insertUnitTypesUrl = '/UnitTypes/insertUnitType';
  deleteUnitTypesUrl = '/UnitTypes/deleteUnitType';
  updateUnitTypesUrl = '/UnitTypes/';

  constructor(private http: HttpClient) { }

  public getUnitTypesData() {
    return this.http.get<any>(environment.apiEndpoint + this.getUnitTypesUrl);
  }

  insertUnitType(formData: any) {
    return this.http.post(environment.apiEndpoint + this.insertUnitTypesUrl, formData,
      {
        params: {
          unittype: formData
        }
      });
  }

  deleteUnitType(code: string) {
    return this.http.post(environment.apiEndpoint + this.deleteUnitTypesUrl, code,
      {
        params: {
          code: code
        }
      });
  }

  UpdateSectionDetails(formData: any) {
    return this.http.post(environment.apiEndpoint + this.updateUnitTypesUrl, formData,
      {
        params: {
          org: formData
        }
      });
  }
}
