<div class="container" style="background-color: white;">
    <table cellspacing="0" id="fvLetter" style="border-collapse:collapse; width: 100%;">
        <tbody>
            <tr>
                <td colspan="2">
                    <table>
                        <tbody>
                            <tr>
                                <th colspan="2" class="report-heading">Unit Info</th>
                            </tr>
                            <tr>
                                <th align="left" style="width: 27%;">Plant:</th>
                                <td> {{ repairInfo?.companyName }} </td>
                            </tr>
                            <tr>
                                <th align="left">Unit Type:</th>
                                <td> {{ repairInfo?.frameTypeDescription }} </td>
                            </tr>
                            <tr>
                                <th align="left">Turbine Serial No.</th>
                                <td> {{ repairInfo?.turbineSerialNumber }} </td>
                            </tr>
                            <tr>
                                <th align="left">Fuel Type: </th>
                                <td> {{ repairInfo?.fuelSource }} </td>
                            </tr>
                            <tr>
                                <th align="left">Firing Temp:</th>
                                <td> {{ repairInfo?.firingTemperature }} </td>
                            </tr>

                            <tr>
                                <th colspan="2"><br></th>
                            </tr>

                            <tr>
                                <th colspan="2" class="report-heading">Parts Info</th>
                            </tr>
                            <tr>
                                <th align="left">Parts Description</th>
                                <td> {{ repairInfo?.componentName }} </td>
                            </tr>
                            <tr>
                                <th align="left">Set Number:</th>
                                <td> {{ repairInfo?.setNumber }} </td>
                            </tr>
                            <tr>
                                <th align="left">Parts Drawing:</th>
                                <td> {{ repairInfo?.partNumber }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Parts Serial:</th>
                                <td> {{ repairInfo?.serialNumbers }} </td>
                            </tr>
                            <tr>
                                <th align="left"># of Previous repairs:</th>
                                <td> {{ repairInfo?.previousRepairs }} </td>
                            </tr>
                            <tr>
                                <th align="left">Operating Hours Since Last Repair:</th>
                                <td> {{ repairInfo?.firedHours | number:'1.2-2' }} </td>
                            </tr>
                            <tr>
                                <th align="left">Operating Starts Since Last Repair:</th>
                                <td> {{ repairInfo?.FiredStarts }} </td>
                            </tr>

                            <tr>
                                <th colspan="2"><br></th>
                            </tr>

                            <tr>
                                <th colspan="2" class="report-heading">Instructions</th>
                            </tr>
                            <tr>
                                <th align="left">Customer Want Date:</th>
                                <td> {{ repairInfo?.customerWantDate | date: 'MM/dd/yyyy' }} </td>
                            </tr>
                            <tr>
                                <th align="left">Onsite Customer Want Date:</th>
                                <td> {{ repairInfo?.onsiteCustomerWantDate | date: 'MM/dd/yyyy' }} </td>
                            </tr>
                            <tr>
                                <th align="left">Critically Late Date:</th>
                                <td> {{ repairInfo?.criticallyLateDate | date: 'MM/dd/yyyy' }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Warranty Work:</th>
                                <td> {{ repairInfo?.warrantyWork }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Type of repair reports required?:</th>
                                <td> {{ repairInfo?.repairWorkRequired }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Shipping Instructions:</th>
                                <td> {{ repairInfo?.shippingInstructions }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Transportation Arrangement from Site:</th>
                                <td> {{ repairInfo?.transportArrangement }} </td>
                            </tr>
                            <tr>
                                <th align="left">Transport Date from the site:</th>
                                <td> {{ repairInfo?.transportDateFromSite | date: 'MM/dd/yyyy' }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top" nowrap="">Pick Up Address from the site (for arrange pick
                                    up):
                                </th>
                                <td> {{ repairInfo?.pickUpAddress }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Customer Receiving Requirements (days, hrs, etc):</th>
                                <td> {{ repairInfo?.customerReceivingRequirements }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Parts Sent from USA -Title Transfer Terms:</th>
                                <td> </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Special req. for transportation:</th>
                                <td> {{ repairInfo?.specialTransportRequirements }} </td>
                            </tr>
                            <tr>
                                <th align="left" valign="top">Important Job Instructions:</th>
                                <td> {{ repairInfo?.jobInstructions }} </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>