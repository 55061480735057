import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { spGetPartsOutForRepair } from '#models/spGetPartsOutForRepair';
import { TopsHomeService } from '#services/http/tops-home.service';
import { RepairInformationDialogComponent } from 'app/core/modals/repair-information-dialog/repair-information-dialog.component';
import { PartInformationDialogComponent } from 'app/core/modals/part-information-dialog/part-information-dialog.component';
import { spGetRepair } from '#models/spGetRepair';
import { AuthService } from 'app/core/authentication/auth.service';

@Component({
  selector: 'app-items-out-for-repair',
  templateUrl: './items-out-for-repair.component.html',
  styleUrls: ['./items-out-for-repair.component.scss']
})
export class ItemsOutForRepairComponent {
  public itemsForRepairColumns = ['color', 'organizationDescription', 'frameTypeDescription', 'componentDescription',
  'setNumber', 'repairFacility', 'startDate', 'customerWantDate','repairFacilityCommitmentDate', 'View']
  public itemsForRepairDataSource: MatTableDataSource<spGetPartsOutForRepair>;
  itemsForRepair: spGetPartsOutForRepair[] = [];
  
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<spGetPartsOutForRepair>;

  constructor(private topsHomeService: TopsHomeService,
    public dialog: MatDialog, private authService: AuthService,
    private route: ActivatedRoute,) {
  }

  ngOnInit() {
    this.getItemsForRepair();
  }

  getItemsForRepair() {
    //let currentUser = this.authService.getCurrentUser();

    this.topsHomeService.getItemsForRepair().subscribe((res: spGetPartsOutForRepair[]) => {
      this.itemsForRepair = res;

      this.itemsForRepairDataSource = new MatTableDataSource<spGetPartsOutForRepair>(this.itemsForRepair);
      this.itemsForRepairDataSource.paginator = this.paginator;
    });
  }

  ShowRepairInfoDialog(row: spGetPartsOutForRepair) {
    this.dialog.open(RepairInformationDialogComponent, {
      width: "70%",
      height: "95%",
      data: { OrgCode: row.organizationCode, CompCode: row.componentCode,
        FrType: row.frameType, SetNo: row.setNumber, startDate:row.startDate },
    });
  }

  ShowPartInfoDialog(row: spGetPartsOutForRepair) {
    this.dialog.open(PartInformationDialogComponent, {
      width: "65%",
      height: "95%",
      data: { OrgCode: row.organizationCode, CompCode: row.componentCode,
        FrType: row.frameType, SetNo: row.setNumber },
    });
  }
}
