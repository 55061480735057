import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { BusinessUnit } from '#models/business-unit';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessUnitService {

  constructor(private http: HttpClient) { }

  public getAllBusinessUnits(): Observable<BusinessUnit[]> {

    return this.http.get<BusinessUnit[]>(`${environment.apiEndpoint}/BusinessUnit`);
  }

}