<div class="container" style="background-color: white;">
    <div style="float:left; margin-right:5px; margin-top: 5px;" class="col-md-2">
        <table class="BlackText Border" cellpadding="0" cellspacing="0">
            <tbody>
                <tr class="Header" style="height:18px">
                    <td align="center" style="font-weight:bold; background-color: #009dd0;">
                        <span>Organization</span>
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 170px;">
                            <mat-select [(value)]="selectedOrg" (selectionChange)="onOrganizationChange($event)">
                                <mat-option *ngFor="let row of organizations" [value]="row.code">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Unit Type
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <mat-form-field appearance="outline" style="height: 20px; width: 170px;">
                            <mat-select [(value)]="selectedUnit" (selectionChange)="onUnitTypeChange($event)">
                                <mat-option *ngFor="let row of unitTypes" [value]="row.code">
                                    {{ row.description }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Company
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="organizationCode == '' || unitType == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="organizationCode !== '' && unitType !== ''">
                                    <td>
                                        <input type="checkbox" [(ngModel)]="allCompanies"
                                            (change)="onCompanyChange('All', $event)">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Companies
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let company of companies">
                                            <input type="checkbox" [(ngModel)]="company.isSelected"
                                                (change)="onCompanyChange(company.companyCode, $event)">
                                            <span style="margin-left: 1px;"> {{company.companyName}} </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Unit
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="organizationCode == '' || unitType == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedCompaniesBackEnd !== ''">
                                    <td>
                                        <input type="checkbox" value="All Units" [(ngModel)]="allUnits"
                                            (change)="onUnitChange('All', $event)">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Units
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let unit of units">
                                            <input type="checkbox" [(ngModel)]="unit.isSelected"
                                                (change)="onUnitChange(unit.unit, $event)">
                                            <span style="margin-left: 1px;"> {{unit.unit}} </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Outage Type
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="organizationCode == '' || unitType == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedUnitsBackEnd !== ''">
                                    <td>
                                        <input type="checkbox" value="All Outage Types"
                                            (change)="onOutageTypeChange('All', $event)" [(ngModel)]="allOutageTypes">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Outage Types
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let type of outageTypes">
                                            <input type="checkbox" [(ngModel)]="type.isSelected"
                                                (change)="onOutageTypeChange(type.code, $event)">
                                            <span style="margin-left: 1px;"> {{type.description}} </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Outage Status
                    </td>
                </tr>
                <tr>
                    <td>
                        <mat-form-field appearance="outline" style="height: 20px; width: 170px;">
                            <mat-select [(value)]="selected" (selectionChange)="onOutageStatusChange($event)">
                                <mat-option selected="selected" value="All">All Outages</mat-option>
                                <mat-option value="C">Completed</mat-option>
                                <mat-option value="F">Future</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
    </div>

    <div class="ListViewContainer; col-md-9" id="ListViewContainer"
        style="width: 70%; margin-left: 20px; margin-top: 5px;" *ngIf="outagesSummaryData?.length > 0">
        <table mat-table matSort style="border: 1px solid grey;" [dataSource]="outagesDataSource">
            <!-- Color Column -->
            <!-- <ng-container matColumnDef="color">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row" style="width: 1%;">
                    <span class="glyphicon glyphicon-calendar" *ngIf="row.companyColor"
                        [ngStyle]="{'color': row.companyColor}"></span>
                </td>
                <td mat-cell *matCellDef="let row"> 1 </td>
            </ng-container> -->

            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Date </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <a href="javascript:void(0);" (click)="ShowOutageInfoDialog(row)">
                        {{row.outageDate | date: 'MM/dd/yyyy'}}
                    </a>
                </td>
            </ng-container>

            <!-- Co Column -->
            <ng-container matColumnDef="co">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Co </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.companyCode }}
                </td>
            </ng-container>

            <!-- Unit Column -->
            <ng-container matColumnDef="unit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Unit </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.unit }}
                </td>
            </ng-container>

            <!-- Outage Type Column -->
            <ng-container matColumnDef="outageType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Outage Type
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"> {{row.outageType}} </td>
            </ng-container>

            <!-- Notes Column -->
            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Notes </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <a href="javascript:void(0);" (click)="ShowNotesDialog(row.comments)">
                        <img *ngIf="row.comments !== null" alt="User Guide" src="/assets/images/document.gif" /></a>
                </td>
            </ng-container>

            <!-- Unit Hrs Column -->
            <ng-container matColumnDef="unitHrs">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Unit Hrs
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{row.unitFiredHours | number : '1.1-1'}}
                </td>
            </ng-container>

            <!-- Interval Hrs Column -->
            <ng-container matColumnDef="intervalHrs">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Interval Hrs
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"
                    [ngStyle]="{'color': (row.intervalHours < 0) ? 'red' : black }">
                    {{row.intervalHours | number : '1.1-1'}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="outagesSummaryColumns"
                style="color: white; background-color: #009dd0; height: 30px;">
            </tr>
            <tr mat-row *matRowDef="let row; columns: outagesSummaryColumns;"
                [ngStyle]="{'background-color': row.companyColor}"></tr>
        </table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="15" showFirstLastButtons
            [pageSizeOptions]="[15, 30, 50, 70, 200]" aria-label="Select page of users"></mat-paginator>
    </div>
</div>