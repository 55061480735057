import { spGetUnitTypes } from '#models/spGetUnitTypes';
import { UnitTypesService } from '#services/http/unit-types.service';
import { NotificationService } from '#services/notification.service';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UnitSectionsComponentsComponent } from 'app/core/modals/unit-sections-components/unit-sections-components.component';

@Component({
  selector: 'app-unit-types',
  templateUrl: './unit-types.component.html',
  styleUrl: './unit-types.component.scss'
})

export class UnitTypesComponent {

  unitTypes: spGetUnitTypes[] = [];
  public unitTypesDataSource: MatTableDataSource<spGetUnitTypes>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public editState: { [key: string]: boolean } = {}; // creating a JSON key-pair object to track which elements are in 'edit' mode 
  insertUnit = new spGetUnitTypes();
  code: string = '';
  description: string = '';
  updatedDesc: string = '';

  public unitTypesColumns = ['code', 'description', 'cloneFrom', 'actions'];

  constructor(private notifyService: NotificationService, public dialog: MatDialog,
    private unitTypeService: UnitTypesService) {
  }

  ngOnInit() {
    this.getUnitTypesData();
  }

  public isEditing(row: spGetUnitTypes): boolean {
    return this.editState[row.description] || false;
  }

  getUnitTypesData() {
    this.unitTypeService.getUnitTypesData().subscribe((res) => {
      this.unitTypes = res;

      let type = new spGetUnitTypes();
      type.code = '0';
      type.description = '';
      this.unitTypes.unshift(type);

      this.unitTypesDataSource = new MatTableDataSource<spGetUnitTypes>(this.unitTypes);
      this.unitTypesDataSource.paginator = this.paginator;
    });
  }

  addUnitType(code: string, description: string) {
    // if (code == undefined || code == "") {
    //   this.notifyService.showError("Code is required.", "");
    // }
    // else if (description == undefined || description == "") {
    //   this.notifyService.showError("Description is required.", "");
    // }
    // else {
    //   this.insertUnit.code = code;
    //   this.insertUnit.description = description;
    //   this.insertUnit.cloneCode = '';

    //   this.unitTypeService.insertUnitType(this.insertUnit).subscribe(res => {
    //     if (res == -1) {

    //       this.notifyService.showSuccess("Unit Type added successfully.", "");
    //       this.code = null;
    //       this.description = null;
    //       code = '';
    //       description = '';
    //       this.getUnitTypesData();
    //     }
    //     else {
    //       this.notifyService.showError(res.toString(), "");
    //     }
    //   });
    // }
  }

  cancelInsertUnitType() {
    this.code = '';
    this.description = '';
  }

  public onEditUnitTypeClick(row: spGetUnitTypes) {
    // this.updatedDesc = row.description;
    // this.editState[row.description] = true;
  }

  UpdateUnitTypeDetails(unit: spGetUnitTypes, updatedDesc: string) {
    // unit.description = updatedDesc;

    // if (unit.description == undefined || unit.description == "") {
    //   this.notifyService.showError("Description is required.", "");
    //   this.editState[unit.description] = true;
    // }
    // else {
    //   this.editState[unit.description] = false;
    //   this.unitTypeService.UpdateSectionDetails(unit).subscribe(res => {
    //     if (res == -1) {
    //       this.notifyService.showSuccess("Section details modified successfully.", "");
    //       this.getUnitTypesData();
    //     }
    //     else {
    //       this.notifyService.showError(res.toString(), "");
    //     }
    //   });
    // }
  }

  cancelUpdateUnitType(row: spGetUnitTypes) {
    this.editState[row.description] = false;
    //window.location.reload();
  }

  deleteUnitType(unit: spGetUnitTypes) {
    this.unitTypeService.deleteUnitType(unit.code).subscribe(res => {

      this.notifyService.showSuccess("Unit Type deleted successfully.", "");
      this.getUnitTypesData();
    });
  }

  ShowUnitSectionsComponentsDialog(row: spGetUnitTypes) {
    this.dialog.open(UnitSectionsComponentsComponent, {
      width: "70%",
      height: "95%",
      // data: { companyCode: row.companyCode, organizationCode: row.organizationCode, unit: row.unit, frameType: row.frameType },
    });
  }
}
