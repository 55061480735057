<div class="container">
  <div class="row">
    <div class="col">
      <mat-card>
        <img
          alt="User Guide"
          src="/assets/images/hazard_orange.png"
          height="140px"
          width="120px"
          class="d-flex center"
        />
        <h1 class="text-center">Error {{ error_status }}</h1>
        <code>{{ error_message }}</code>
        <h3 class="text-center">Please try again in a few minutes.</h3>
        <h3 class="text-center">
          If the issue persists please reach out to
          <a href="mailto: larizza.galindo@chevron.com" title="larizza.galindo@chevron.com"
            >Larizza Galindo.</a
          >
        </h3>
      </mat-card>
    </div>
  </div>
</div>
