import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '#services/notification.service';
import { OutageSummaryService } from '#services/http/outage-summary.service';
import { spGetOutagesSummary } from '#models/spGetOutagesSummary';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NotesComponent } from 'app/modals/notes/notes.component';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { spGetCompaniesByUnitType } from '#models/spGetCompaniesByUnitType';
import { spGetOutagesOutageTypes } from '#models/spGetOutagesOutageTypes';
import { spGetUnits } from '#models/spGetUnits';
import { OutageDialogComponent } from 'app/core/modals/outage-dialog/outage-dialog.component';
import { PartEntryService } from '#services/http/part-entry.service';
import { vOutage } from '#models/VOutage';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { outagesSummaryModels } from '#models/outages-summary-models';
import { Observable } from 'rxjs';
import { faBanSmoking } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-outages-summary',
  templateUrl: './outages-summary.component.html',
  styleUrls: ['./outages-summary.component.scss']
})

export class OutagesSummaryComponent {

  public outagesSummaryColumns = ['date', 'co', 'unit', 'outageType', 'notes', 'unitHrs', 'intervalHrs']
  organizations: spGetOrganizations[] = [];
  unitTypes: spGetOrganizations[] = [];
  companies: spGetCompaniesByUnitType[] = [];
  units: spGetUnits[] = [];
  outageTypes: spGetOutagesOutageTypes[] = [];
  selectedOrg: string = '';
  selectedUnit: string = '';
  selected = 'All';
  selectedCompaniesBackEnd: string = '';
  selectedUnitsBackEnd: string = '';
  selectedOutagesTypeBackEnd: string = '';
  allCompanies: boolean = false;
  allUnits: boolean = false;
  allOutageTypes: boolean = false;
  outagesSummaryData: spGetOutagesSummary[] = [];
  public outagesDataSource: MatTableDataSource<spGetOutagesSummary>;
  outageInfo = new vOutage();
  public outagesSummaryModel = new outagesSummaryModels();

  public organizationCode: string = '';
  public unitType: string = '';
  public company: string = '';
  public unit: string = '';
  public outageStatus: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<spGetOutagesSummary>;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private notifyService: NotificationService, public dialog: MatDialog,
    private outSummaryService: OutageSummaryService, private partEntryService: PartEntryService,) {
  }

  ngOnInit(): void {
    this.getOrganizations();
    //load data from sessionstorage on refresh
    this.LoadFilterValuesFromSession();
  }

  getOrganizations() {
    this.outSummaryService.getOrganizations().subscribe((res) => {
      this.organizations = res;
    });
  }

  onOrganizationChange(event: any) {
    this.organizationCode = event.value;
    this.selectedOrg = this.organizationCode;
    this.outagesSummaryModel.selectedOrg = this.selectedOrg;
    //clear other lists
    this.companies = null;
    this.units = null;
    this.outageTypes = null;
    this.selectedUnit = '';
    this.unitType = '';
    this.allCompanies = false;
    this.allUnits = false;
    this.allOutageTypes = false;
    this.outagesSummaryData = null;
    this.selectedCompaniesBackEnd = "";
    this.selectedUnitsBackEnd = "";

    this.getUnitTypes();
  }

  getUnitTypes() {
    this.outSummaryService.getUnitTypes(this.organizationCode).subscribe((res) => {
      this.unitTypes = res;
    });
  }

  onUnitTypeChange(event: any) {
    this.unitType = event.value;
    this.selectedUnit = this.unitType;
    this.outagesSummaryModel.selectedUnit = this.selectedUnit;
    //clear other lists
    this.companies = null;
    this.units = null;
    this.outageTypes = null;
    this.allCompanies = false;
    this.allUnits = false;
    this.allOutageTypes = false;
    this.outagesSummaryData = null;
    this.selectedCompaniesBackEnd = "";
    this.selectedUnitsBackEnd = "";
    this.getCompanies();
  }

  getCompanies() {
    this.allCompanies = false;
    this.outSummaryService.getCompanies(this.organizationCode, this.unitType).subscribe((res) => {
      this.companies = res;
    });
  }

  onCompanyChange(company: string, event: any) {

    if (company == 'All' && event.currentTarget.checked == true) {
      this.companies.forEach(element => {
        element.isSelected = true;
      });
      this.outagesSummaryModel.allCompanies = true;
    }
    else {
      this.allCompanies = false;
      this.companies.forEach(element => {
        element.isSelected = false;
      });
      this.allUnits = false;
      this.outagesSummaryData = null;
      this.units = null;
      this.allOutageTypes = false;
      this.outageTypes = null;
      this.selectedCompaniesBackEnd = "";
      this.selectedOutagesTypeBackEnd = "";
      this.selectedUnitsBackEnd = "";
    }

    if (company != 'All') {
      this.companies.find(x => x.companyCode == company).isSelected = event.currentTarget.checked;
    }

    this.companies.forEach(element => {
      if (element.isSelected == true) {
        this.selectedCompaniesBackEnd = this.selectedCompaniesBackEnd + '|' + element.companyCode + '|';
      }
    });

    if (this.selectedCompaniesBackEnd != "") {
      this.outagesSummaryModel.selectedCompaniesBackEnd = this.selectedCompaniesBackEnd;
      this.getUnits();
    }
  }

  getUnits() {
    this.outSummaryService.getUnits(this.organizationCode, this.selectedCompaniesBackEnd, this.unitType).subscribe((res) => {
      this.units = res;
    });
  }

  onUnitChange(unit: string, event: any) {
    this.selectedUnitsBackEnd = '';
    if (unit == 'All' && event.currentTarget.checked == true) {
      this.units.forEach(element => {
        element.isSelected = true;
      });
      this.outagesSummaryModel.allUnits = true;
    }
    if (unit == 'All' && event.currentTarget.checked == false) {
      this.units.forEach(element => {
        element.isSelected = false;
      });
      
      this.allUnits = false;
      this.outageTypes = null;
      this.allOutageTypes = false;
      this.selectedOutagesTypeBackEnd = "";
      this.outagesSummaryData = null;
    }

    if (unit != 'All') {
      this.allUnits = false;
      this.outageTypes = null;
      this.allOutageTypes = false;
      this.selectedOutagesTypeBackEnd = "";
      this.outagesSummaryData = null;

      this.units.find(x => x.unit == unit).isSelected = event.currentTarget.checked;
    }

    this.units.forEach(element => {
      if (element.isSelected == true) {
        this.selectedUnitsBackEnd = this.selectedUnitsBackEnd + '|' + element.unit + '|';
      }
    });

    if (this.selectedUnitsBackEnd != "") {
      this.outagesSummaryModel.selectedUnitsBackEnd = this.selectedUnitsBackEnd;
      this.getOutageTypes();
    }

    if (this.selectedOutagesTypeBackEnd != "") {
      this.outagesSummaryData = null;
      this.getOutageSummaryDetails();
    }
  }

  getOutageTypes() {
    this.outSummaryService.getOutageTypes(this.organizationCode, this.selectedCompaniesBackEnd,
      this.selectedUnitsBackEnd).subscribe((res) => {
        this.outageTypes = res;
      });
  }

  onOutageTypeChange(outage: string, event: any) {
    this.selectedOutagesTypeBackEnd = '';
    if (outage == 'All' && event.currentTarget.checked == true) {
      this.outageTypes.forEach(element => {
        element.isSelected = true;
      });
      this.outagesSummaryModel.allOutageTypes = true;
    }
    if (outage == 'All' && event.currentTarget.checked == false) {
      this.allOutageTypes = false;
      this.outageTypes.forEach(element => {
        element.isSelected = false;
      });
      this.selectedOutagesTypeBackEnd = "";
      this.outagesSummaryData = null;
    }

    if (outage != 'All') {
      this.allOutageTypes = false;

      this.outageTypes.find(x => x.code == outage).isSelected = event.currentTarget.checked;
    }

    this.outageTypes.forEach(element => {
      if (element.isSelected === true) {
        this.selectedOutagesTypeBackEnd = this.selectedOutagesTypeBackEnd + '|' + element.code + '|';
      }
    });

    if (this.selectedOutagesTypeBackEnd != "") {
      this.outagesSummaryModel.selectedOutagesTypeBackEnd = this.selectedOutagesTypeBackEnd;
      this.outagesSummaryData = null;
      this.getOutageSummaryDetails();
    }
  }

  onOutageStatusChange(event: any) {
    if (event.value == 'All') {
      this.outageStatus = null;
    }
    else {
      this.outageStatus = event.value;
    }
    this.outagesSummaryModel.outageStatus = this.selected = this.outageStatus;
    this.getOutageSummaryDetails();
  }

  getOutageSummaryDetails() {
    this.outSummaryService.getOutagesSummaryData(this.organizationCode, this.unitType,
      this.selectedCompaniesBackEnd, this.selectedUnitsBackEnd, this.selectedOutagesTypeBackEnd,
      this.outageStatus).subscribe((res) => {
        this.outagesSummaryData = res;

        this.outagesDataSource = new MatTableDataSource<spGetOutagesSummary>(this.outagesSummaryData);
        setTimeout(() => this.outagesDataSource.paginator = this.paginator);
      });
    sessionStorage.setItem('outagesSummaryFilters', JSON.stringify(this.outagesSummaryModel));
  }

  ShowOutageInfoDialog(row: spGetOutagesSummary) {
    this.partEntryService.getOutageInfoOutDate(row.organizationCode, row.companyCode, row.unit,
      row.outageDate.toString()).subscribe((res) => {
        this.outageInfo = res;

        if (this.outageInfo != null) {
          this.dialog.open(OutageDialogComponent, {
            width: "70%",
            height: "95%",
            data: { id: this.outageInfo.id },
          });
        }
      });
  }

  ShowNotesDialog(notes: string) {
    this.dialog.open(NotesComponent, {
      width: "50%",
      height: "55%",

      data: { notes: notes },
    });
  }

  //Function to get values from sessionstorage
  getStoredValues() {
    const storedValues = sessionStorage.getItem('outagesSummaryFilters');
    if (storedValues) {
      const outageValues = JSON.parse(storedValues);
      return outageValues;
    }
    return new outagesSummaryModels();
  }
  //Load dropdown values from sessionstorage on refresh in same browser session
  async LoadFilterValuesFromSession() {
    this.outagesSummaryModel = this.getStoredValues();
    if (this.outagesSummaryModel.selectedOrg != '') {
      this.selectedOrg = this.organizationCode = this.outagesSummaryModel.selectedOrg;
      this.getUnitTypes();
    }

    if (this.outagesSummaryModel.selectedUnit != '') {
      this.selectedUnit = this.unitType = this.outagesSummaryModel.selectedUnit;
      await this.getCompanies();
    }

    if (this.outagesSummaryModel.selectedCompaniesBackEnd != '') {
      this.selectedCompaniesBackEnd = this.outagesSummaryModel.selectedCompaniesBackEnd;
      if (this.outagesSummaryModel.allCompanies) {
        this.companies.forEach(element => {
          element.isSelected = true;
        });
      }
      else {
        const selectedValues = this.outagesSummaryModel.selectedCompaniesBackEnd.split('|').filter(val => val);
        selectedValues.forEach(val => {
          this.companies.find(x => x.companyCode == val).isSelected = true;
        });
      }
      await this.getUnits();
    }

    if (this.outagesSummaryModel.selectedUnitsBackEnd != '') {
      this.selectedUnitsBackEnd = this.outagesSummaryModel.selectedUnitsBackEnd;
      if (this.outagesSummaryModel.allUnits) {
        this.units.forEach(element => {
          element.isSelected = true;
        });
      }
      else {
        const selectedValues = this.outagesSummaryModel.selectedUnitsBackEnd.split('|').filter(val => val);
        selectedValues.forEach(val => {
          this.units.find(x => x.unit == val).isSelected = true;
        });
      }
      await this.getOutageTypes();
    }

    if (this.outagesSummaryModel.selectedOutagesTypeBackEnd != '') {
      this.selectedOutagesTypeBackEnd = this.outagesSummaryModel.selectedOutagesTypeBackEnd;
      if (this.outagesSummaryModel.allOutageTypes) {
        this.outageTypes.forEach(element => {
          element.isSelected = true;
        });
      }
      else {
        const selectedValues = this.outagesSummaryModel.selectedOutagesTypeBackEnd.split('|').filter(val => val);
        selectedValues.forEach(val => {
          this.outageTypes.find(x => x.code == val).isSelected = true;
        });
      }

      this.getOutageSummaryDetails();
    }

    if (this.outagesSummaryModel.outageStatus != '') {
      this.selected = this.outageStatus = this.outagesSummaryModel.outageStatus;
      this.getOutageSummaryDetails();
    }
  }

}
