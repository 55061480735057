import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OutageDetailsService {

  GetOutagesUrl = '/OutagesDetails/getOutages';
  GetOutageSectionUrl = '/OutagesDetails/getOutageSections';
  GetUnitTypeComponentsUrl = '/OutagesDetails/getUnitTypeComponents';
  constructor(private http: HttpClient,) { }

  getOutagesData(organizationCode: string, frameType: string, companyCode: string, unit: string,
    outagesType: string, status: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutagesUrl,
      {
        params: {
          organizationCode: organizationCode,
          frameType: frameType,
          companyCode: companyCode,
          unit: unit,
          outagesType: outagesType,
          status: status
        }
      });
  }

  getOutageSections() {
    return this.http.get<any>(environment.apiEndpoint + this.GetOutageSectionUrl);
  }

  getUnitTypeComponents(frameType: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetUnitTypeComponentsUrl,
      {
        params: {
          frameType: frameType
        }
      });
  }
}
