<div class="container" style="background-color: white;">
    <div class="mat-elevation-z8">
        <!-- <table cellpadding="4" cellspacing="0" class="BlackText Border">
            <tbody>
                <tr class="Header outage-info" style="text-align: center;
                font-weight: bold; font-size: 14px;">
                    <th>Code</th>
                    <th>Description</th>
                    <th></th>
                </tr>
                <tr class="Row1" *ngIf="!isUpdateOrg">
                    <td>
                        <input type="text" maxlength="5" class="BlackText" size="15"
                            style="margin-top: 5px; margin-left: 5px;" [(ngModel)]="insertOrg.code">
                    </td>
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="35"
                            style="margin-top: 5px; margin-left: 3px; margin-right: 3px;"
                            [(ngModel)]="insertOrg.description">
                    </td>
                    <td>
                        <input type="submit" value="Insert" class="blacktext" style="margin-top: 5px;"
                            (click)="addOrganization(insertOrg)">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            (click)="cancelAddOrganization()">
                    </td>
                </tr>
                <tr class="Row2" *ngIf="isUpdateOrg">
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="15" style="margin-top: 5px; margin-left: 5px;border: none;
                            cursor: default;
                            background-color: transparent;
                            color: inherit;
                            pointer-events: none;
                            outline: none;" [(ngModel)]="updateOrg.code">
                    </td>
                    <td>
                        <input type="text" maxlength="50" class="BlackText" size="35"
                            style="margin-top: 5px; margin-left: 3px; margin-right: 3px;"
                            [(ngModel)]="updateOrg.description">
                    </td>
                    <td>
                        <input type="submit" value="Update" class="blacktext" (click)="updateOrganization(updateOrg)">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            (click)="cancelUpdateOrganization(updateOrg)">
                    </td>
                </tr>
                <tr *ngFor="let set of organizations let i = index">
                    <td align="left" style="padding-left: 6px;">
                        {{set.code}}
                    </td>
                    <td align="left" style="padding-left: 4px;">
                        {{ set.description }}
                    </td>
                    <td align="left">
                        <input type="submit" value="Edit" class="BlackText" style="margin-top: 3px;"
                            (click)="EditSection(set, i)" [disabled]="isUpdateSection">
                        <input type="submit" value="Delete" class="BlackText" style="margin-top: 3px; margin-left: 3px;"
                            (click)="deleteOrganization(set)" *ngIf="set.inUse == 0" [disabled]="isUpdateSection">
                    </td>
                </tr>
            </tbody>
        </table> -->

        <table mat-table matSort style="margin-top: 10px; border: 1px solid grey;" aria-describedby=""
            [dataSource]="organizationsDataSource">

            <ng-container matColumnDef="code">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"
                class="text-center"> Code </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <span *ngIf="row.code != '0'">{{ row.code }}</span>
                    <input type="text" maxlength="50" class="BlackText" size="25" *ngIf="row.code == '0'"
                        [(ngModel)]="code">
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center">
                    Description </th>
                <td mat-cell *matCellDef="let row" class="text-left">
                    <input type="text" maxlength="50" class="BlackText" size="25" *ngIf="row.code == '0'"
                        [(ngModel)]="description">

                    <ng-container *ngIf="!isEditing(row)">{{row.description}}</ng-container>
                    <ng-container *ngIf="isEditing(row) && row.code != '0'">
                        <input type="text" [(ngModel)]="updatedDesc" maxlength="50" size="25" class="BlackText">
                    </ng-container>
                </td>
                <td mat-cell *matCellDef="let row"></td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef scope="col" style="width: 20%;"></th>
                <td mat-cell *matCellDef="let row">
                    <ng-container *ngIf="!isEditing(row)">
                        <input type="submit" value="Insert" class="BlackText"
                            (click)="addOrganization(code, description)" style="margin-top: 5px; margin-left: 3px;"
                            *ngIf="row.code == '0'">
                        <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                            *ngIf="row.code == '0'" (click)="cancelAddOrganization()">

                        <input type="submit" value="Edit" class="BlackText" *ngIf="row.code != '0'"
                            style="margin-top: 3px; margin-left: 3px;" (click)="onEditOrganozationClick(row)">
                        <input type="submit" value="Delete" class="BlackText" style="margin-top: 3px; margin-left: 3px;"
                            (click)="deleteOrganization(row)" *ngIf="row.inUse == 0 && row.code != '0'">
                    </ng-container>

                    <ng-container *ngIf="isEditing(row)">
                        <input type="submit" value="Update" class="BlackText" *ngIf="row.code != '0'"
                            (click)="updateOrganization(row, updatedDesc)">
                        <input type="submit" value="Cancel" class="BlackText" *ngIf="row.code != '0'"
                            style="margin-left: 3px;" (click)="cancelUpdateOrganization(row)">
                    </ng-container>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayOrgColumns" style="color: white; height: 20px;"
                class="header-color">
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayOrgColumns;" style="height: 40px !important;">
            </tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="4">No data</td>
            </tr>
        </table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="15" showFirstLastButtons
            [pageSizeOptions]="[20, 30, 50, 100]" aria-label="Select page of users"></mat-paginator>
    </div>
</div>