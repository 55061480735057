import { spGetUnit } from '#models/spGetUnit';
import { spGetUnitOutageHisotry } from '#models/spGetUnitOutageHisotry';
import { TopsHomeService } from '#services/http/tops-home.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotesComponent } from 'app/modals/notes/notes.component';

@Component({
  selector: 'app-unit-dialog',
  templateUrl: './unit-dialog.component.html',
  styleUrls: ['./unit-dialog.component.scss']
})

export class UnitDialogComponent {

  unitInfo = new spGetUnit();
  outageHistory: spGetUnitOutageHisotry[] = [];
  public outageHistoryColumns = ['date', 'outageType', 'notes', 'unitHrs', 'intervalHrs']
  public outageHistoryDataSource: MatTableDataSource<spGetUnitOutageHisotry>;
  maximize: boolean = false;

  constructor(public dialogRef: MatDialogRef<UnitDialogModel>,
    private router: Router, private topsHomeService: TopsHomeService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.topsHomeService.getUnitInfoData(this.data.companyCode, this.data.organizationCode, this.data.unit).subscribe((res) => {
      this.unitInfo = res[0];

      this.topsHomeService.getUnitOutageHistory(this.data.organizationCode, this.data.frameType,
        this.data.companyCode, this.data.unit).subscribe((resOutageHistory) => {
          this.outageHistory = resOutageHistory;

          this.outageHistoryDataSource = new MatTableDataSource<spGetUnitOutageHisotry>(this.outageHistory);
        });
    });
  }

  onDismiss(): void {
    this.dialogRef.close();
  }

  ShowNotesDialog(notes: string) {
    this.dialog.open(NotesComponent, {
      width: "50%",
      height: "55%",

      data: { notes: notes },
    });
  }

  maxClick() {
    this.maximize = true;
  }
  minClick() {
    this.maximize = false;
  }
}

export class UnitDialogModel {

}
