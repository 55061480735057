import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class SerialNumberMaintenanceService {

  GetDlnUrl = '/SerialNumberMaintenance/getDLNs';
  GetSetsUrl = '/SerialNumberMaintenance/getSets';
  GetSerialNumbersUrl = '/SerialNumberMaintenance/getSerialNumbers';

  constructor(private http: HttpClient,) { }

  getDLNs() {
    return this.http.get<any>(environment.apiEndpoint + this.GetDlnUrl);
  }

  getAllSets(organizationCode: string, componentCode: string, frameType: string, dln: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetSetsUrl,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType,
          dln: dln,
        }
      });
  }

  getSerialNumbersData(organizationCode: string, componentCode: string, frameType: string, setNumber: string, dln: string) {
    return this.http.get<any>(environment.apiEndpoint + this.GetSerialNumbersUrl,
      {
        params: {
          organizationCode: organizationCode,
          componentCode: componentCode,
          frameType: frameType,
          setNo: setNumber,
          dln: dln
        }
      });
  }
}
