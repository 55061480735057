import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message: any, title: any) {

    // this.toastr.success(message, title)

    this.toastr.success(message, title, {
      timeOut: 3000,
      closeButton: true,
    });
  }

  showError(message: any, title: any) {
    //this.toastr.error(message, title)
    this.toastr.error(message, title, { enableHtml: true });

  }

  showInfo(message: any, title: any) {
    //  this.toastr.info(message, title)

    this.toastr.info(message, title, {
      disableTimeOut: true,
      closeButton: true,
      enableHtml: true,
    });
  }

  showWarning(message: any, title: any) {
    this.toastr.warning(message, title)
  }

  ConvertDateBeforeSave(date: Date) {
    date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return date;
  }

  ConvertDateAfterSave(date: Date) {
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    return date;
  }
}
