import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { HomePage } from './modules/home/pages/home.page';
//import { MaintenanceLog } from '#modules/maintenance-log/pages/maintenance-log.page';
import { CreateEventLogComponent } from '#modules/maintenance-log/pages/create-event-log/create-event-log.component';
import { EditEventLogComponent } from '#modules/edit-event-log/pages/edit-event-log.component';
import { NotAuthorizedErrorPage as NotAuthorizedErrorPage } from '#modules/error/pages/not-authorized-error/not-authorized-error.page';
import { NotFoundErrorPage } from '#modules/error/pages/not-found-error/not-found-error.page';
import { GenericErrorComponent } from '#modules/error/pages/generic-error/generic-error.component';
import { AppRoutingGuard } from '#guards/app-routing.guard';
import { AdminAppRoutingGuard } from '#guards/admin-app-routing.guard';
import { DgsMetricsComponent } from '#modules/dgs-metrics/pages/dgs-metrics.component';
import { TopsHomeComponent } from './tops/tops-home/tops-home.component';
import { ItemsOutForRepairComponent } from './tops/items-out-for-repair/items-out-for-repair.component';
import { TopsHeaderComponent } from '#components/tops-header/tops-header.component';
import { DgsDrawingsComponent } from '#modules/dgs-drawings/dgs-drawings.component';
import { DgsBulkUploadComponent } from '#modules/dgs-admin/dgs-bulkupload/dgs-bulkupload.component';
import { PartEntryComponent } from './tops/part-entry/part-entry.component';
import { OutageEntryComponent } from './tops/outage-entry/outage-entry.component';
import { PartRepairComponent } from './tops/part-repair/part-repair.component';
import { PartStatusComponent } from './tops/part-status/part-status.component';
import { RepairOpeningLetterComponent } from './tops/reports/repair-opening-letter/repair-opening-letter.component';
import { OutagesSummaryComponent } from './tops/outages-summary/outages-summary.component';
import { OutagesBydateRangeComponent } from './tops/outages-bydate-range/outages-bydate-range.component';
import { OutagesDetailsComponent } from './tops/outages-details/outages-details.component';
import { PartsComponent } from './tops/parts/parts.component';
import { OutagesOutageEntryComponent } from './tops/outages-outage-entry/outages-outage-entry.component';
import { SerialNumberLookupComponent } from './tops/serial-number-lookup/serial-number-lookup.component';
import { RepairTrackingReportComponent } from './tops/reports/repair-tracking-report/repair-tracking-report.component';
import { NewPartComponent } from './tops/new-part/new-part.component';
import { OrganizationsComponent } from './tops/organizations/organizations.component';
import { SerialNumberMaintenanceComponent } from './tops/serial-number-maintenance/serial-number-maintenance.component';
import { SectionsComponent } from './tops/sections/sections.component';
import { ComponentsComponent } from './tops/components/components.component';
import { UnitTypesComponent } from './tops/unit-types/unit-types.component';

const routes: Routes = [
  {
    path: '',
    component: HomePage,
    canActivate: [AppRoutingGuard]
  },
  {
    path: 'tops-header',
    component: TopsHeaderComponent,
    canActivate: [AppRoutingGuard]
  },
  {
    path: 'create-log',
    component: CreateEventLogComponent,
    canActivate: [AppRoutingGuard]
  },
  {
    path: 'edit-log',
    component: EditEventLogComponent,
    canActivate: [AppRoutingGuard]
  },
  {
    path: 'dgs-metrics',
    component: DgsMetricsComponent,
    canActivate: [AppRoutingGuard]
  },
  {
    path: 'dgs-drawings', component: DgsDrawingsComponent, canActivate: [AppRoutingGuard]
  },
  { path: 'tops/tops-home', component: TopsHomeComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/items-for-repair', component: ItemsOutForRepairComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/part-entry', component: PartEntryComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/outage-entry', component: OutageEntryComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/part-repair', component: PartRepairComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/part-status', component: PartStatusComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/RepairOpeningLetter', component: RepairOpeningLetterComponent, canActivate: [AppRoutingGuard] },

  { path: 'tops/outages-summary', component: OutagesSummaryComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/upcoming-outages', component: OutagesBydateRangeComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/outages-details', component: OutagesDetailsComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/outages-entry', component: OutagesOutageEntryComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/parts', component: PartsComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/serial-number-lookup', component: SerialNumberLookupComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/repair-tracking-report', component: RepairTrackingReportComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/new-part-entry', component: NewPartComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/organizations', component: OrganizationsComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/SerialNumberMaintenance', component: SerialNumberMaintenanceComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/sections', component: SectionsComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/components', component: ComponentsComponent, canActivate: [AppRoutingGuard] },
  { path: 'tops/unit-types', component: UnitTypesComponent, canActivate: [AppRoutingGuard] },
  {
    path: 'admin',
    // Something to consider in the future is that these paths could instead be changed 
    // to something like /admin/edit/(equipment | business-unit | facility) and /admin/create/(equipment | business-unit | facility)
    // This keeps it more generic and reads better as URLs
    children: [
      { path: 'dgs-bulkupload', component: DgsBulkUploadComponent },
    ],
    data: {
      roles: ['Admin'],
    },
    canActivate: [AdminAppRoutingGuard]
  },
  // Error pages
  {
    path: 'error',
    children: [
      { path: 'not-authorized', component: NotAuthorizedErrorPage },
      { path: 'generic-error', component: GenericErrorComponent },
    ],
  },
  { path: '**', component: NotFoundErrorPage },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
