<div class="row" style="background-color: white; width: 100%;">

    <div class="col-md-2" style="float:left; margin-top: 5px; margin-left: 20px;">
        <!-- height: 120vh; overflow-y: scroll; -->
        <table class="BlackText Border" cellpadding="0" cellspacing="0">
            <tbody>
                <tr class="Header" style="height:18px">
                    <td align="center" style="font-weight:bold; background-color: #009dd0;">
                        <span>Organization</span>
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <table class="BlackText">
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="checkbox" value="All Organizations" [(ngModel)]="allOrganizations"
                                            (change)="onOrganizationChange('All', $event)">
                                        <label style="padding-left: 4px; font-weight: normal; color: black;">
                                            All Organizations
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let org of organizations">
                                            <input type="checkbox" [(ngModel)]="org.isSelected"
                                                (change)="onOrganizationChange(org.code, $event)">
                                            <span style="margin-left: 1px;color: black;">
                                                {{org.description}}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Unit Type
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="selectedOrgBackEnd == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedOrgBackEnd !== ''">
                                    <td>
                                        <input type="checkbox" value="All UnitTypes" [(ngModel)]="allUnitTypes"
                                            (change)="onUnitTypeChange('All', $event)">
                                        <label style="padding-left: 4px; font-weight: normal; color: black;">
                                            All Unit Types
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let unit of unitTypes">
                                            <input type="checkbox" [(ngModel)]="unit.isSelected"
                                                (change)="onUnitTypeChange(unit.code, $event)">
                                            <span style="margin-left: 1px; color: black;">
                                                {{unit.description}}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Company
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="selectedUnitTypesBackEnd == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedUnitTypesBackEnd !== ''">
                                    <td>
                                        <input type="checkbox" [(ngModel)]="allCompanies"
                                            (change)="onCompanyChange('All', $event)">
                                        <label style="padding-left: 4px; font-weight: normal; color: black;">
                                            All Companies
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let company of companies">
                                            <input type="checkbox" [(ngModel)]="company.isSelected"
                                                (change)="onCompanyChange(company.companyCode, $event)">
                                            <span style="margin-left: 1px; color: black;">
                                                {{company.companyName}}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Unit
                    </td>
                </tr>
                <tr>
                    <td style="border-bottom:1px solid gray">
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="selectedCompaniesBackEnd == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedCompaniesBackEnd !== ''">
                                    <td>
                                        <input type="checkbox" value="All Units" [(ngModel)]="allUnits"
                                            (change)="onUnitChange('All', $event)">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Units
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let unit of units">
                                            <input type="checkbox" [(ngModel)]="unit.isSelected"
                                                (change)="onUnitChange(unit.unit, $event)">
                                            <span style="margin-left: 1px; color: black;"> {{unit.unit}} </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Outage Type
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="selectedUnitsBackEnd == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedUnitsBackEnd !== ''">
                                    <td>
                                        <input type="checkbox" value="All Outage Types"
                                            (change)="onOutageTypeChange('All', $event)" [(ngModel)]="allOutageTypes">
                                        <label style="padding-left: 4px; font-weight: normal;">
                                            All Outage Types
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div *ngFor="let type of outageTypes">
                                            <input type="checkbox" [(ngModel)]="type.isSelected"
                                                (change)="onOutageTypeChange(type.code, $event)">
                                            <span style="margin-left: 1px; color: black;">
                                                {{type.description}}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Outage Status
                    </td>
                </tr>
                <tr>
                    <td>
                        <table class="BlackText">
                            <tbody>
                                <tr style="height: 22px;" *ngIf="selectedOutagesTypeBackEnd == ''">
                                    <td style="border-bottom:1px solid gray">
                                    </td>
                                </tr>
                                <tr *ngIf="selectedOutagesTypeBackEnd !== ''">
                                    <td>
                                        <div *ngFor="let status of outageStatuses">
                                            <input type="checkbox" [(ngModel)]="status.isSelected"
                                                (change)="onOutageStatusChange(status.code, $event)">
                                            <span style="margin-left: 1px; color: black;">
                                                {{status.description}}
                                            </span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </td>
                </tr>
                <tr class="Header" style="height:18px">
                    <td align="center" valign="top" style="font-weight:bold; background-color: #009dd0;">
                        Date Range
                    </td>
                </tr>
                <tr>
                    <td>
                        <div style="width: 20px;">
                            From:
                            <mat-form-field appearance="outline"
                                style="height: 20px; width: 185px; margin-bottom: 15px;">
                                <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy" [ngModel]="fromDate"
                                    (dateChange)="OnFromDateChange('From', $event)">
                                <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                            </mat-form-field>
                            <br />

                            <span style="margin-top: 5px;">To:</span>
                            <mat-form-field appearance="outline" style="height: 20px; width: 185px;">
                                <input matInput [matDatepicker]="pickerTo" placeholder="mm/dd/yyyy" [(ngModel)]="toDate"
                                    (dateChange)="OnFromDateChange('To', $event)">
                                <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                                <mat-datepicker #pickerTo></mat-datepicker>
                            </mat-form-field>
                            <br>
                            <input type="submit" value="Submit" class="BlackText; to-top" style="margin-top: 15px;"
                                (click)="onSubmitClick()">
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <br />
    </div>

    <div class="ListViewContainer; col-md-10" id="ListViewContainer" *ngIf="outagesList?.length > 0"
        style="width: 80%; margin-top: 5px; margin-left: -50px;">
        <table mat-table matSort style="border: 1px solid grey;" [dataSource]="outagesDataSource">
            <!-- Date Column -->
            <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Date </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <a href="javascript:void(0);" (click)="ShowOutageInfoDialog(row)">
                        {{row.outageDate | date: 'MM/dd/yyyy'}}
                    </a>
                </td>
            </ng-container>

            <!-- Organization Column -->
            <ng-container matColumnDef="organization">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Organization
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.organizationCode }}
                </td>
            </ng-container>

            <!-- Company Column -->
            <ng-container matColumnDef="company">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Company </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.companyCode }}
                </td>
            </ng-container>

            <!-- Unit Column -->
            <ng-container matColumnDef="unit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Unit </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{ row.unit }}
                </td>
            </ng-container>

            <!-- Serial Number Column -->
            <ng-container matColumnDef="serialNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Serial Number
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"> {{row.serialNumber}} </td>
            </ng-container>

            <!-- Unit Type Column -->
            <ng-container matColumnDef="unitType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Unit Type
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"> {{row.frameType}} </td>
            </ng-container>

            <!-- Outage Type Column -->
            <ng-container matColumnDef="outageType">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Outage Type
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"> {{row.outageType}} </td>
            </ng-container>

            <!-- engineer Column -->
            <ng-container matColumnDef="engineer">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Engineer(s)
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"> {{row.engineer}} </td>
            </ng-container>

            <!-- Notes Column -->
            <ng-container matColumnDef="notes">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Notes </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    <a href="javascript:void(0);" (click)="ShowNotesDialog(row.comments)">
                        <img *ngIf="row.comments !== null" alt="User Guide" src="/assets/images/document.gif" /></a>
                </td>
            </ng-container>

            <!-- Est Unit Hrs Column -->
            <ng-container matColumnDef="estUnitHours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Est Unit Hrs
                </th>
                <td mat-cell *matCellDef="let row" class="text-center">
                    {{row.unitFiredHours | number : '1.1-1'}}
                </td>
            </ng-container>

            <!-- Interval Hrs Column -->
            <ng-container matColumnDef="intervalHours">
                <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Interval Hrs
                </th>
                <td mat-cell *matCellDef="let row" class="text-center"
                    [ngStyle]="{'color': (row.intervalHours < 0) ? 'red' : black }">
                    {{row.intervalHours | number : '1.1-1'}}
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="outagesDateRangeColumns"
                style="color: white; background-color: #009dd0; height: 30px;">
            </tr>
            <tr mat-row *matRowDef="let row; columns: outagesDateRangeColumns;"
                [ngStyle]="{'background-color': row.companyColor}"></tr>
        </table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="15" showFirstLastButtons
            [pageSizeOptions]="[15, 30, 50, 70, 200]" aria-label="Select page of users"></mat-paginator>
    </div>
</div>