export class outagesDetailModels{
    selectedOrg: string = '';
    selectedUnit: string = '';
    selectedCompaniesBackEnd: string = '';
    selectedUnitsBackEnd: string = '';
    selectedOutagesTypeBackEnd: string = '';
    allCompanies: boolean = false;
    allUnits: boolean = false;
    allOutageTypes: boolean = false;
    outageStatus: string = '';
    allOutageSections: boolean = false;
    selectedOutageSectionBackEnd: string = '';
}