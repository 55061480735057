<div class="container" style="background-color: white; width: 100%;">

    <table cellspacing="0" style="border-collapse:collapse;">
        <tbody>
            <tr>
                <td colspan="2">
                    <table class="BlackText" cellpadding="4" cellspacing="0">

                        <tbody>
                            <tr>
                                <th align="left">Organization:</th>
                                <td>
                                    <mat-form-field appearance="outline" class="OutagesEntry">
                                        <mat-select [(value)]="selectedOrg"
                                            (selectionChange)="onOrganizationChange($event)">
                                            <mat-option *ngFor="let row of organizations" [value]="row.code">
                                                {{ row.description }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th align="left">Company:</th>
                                <td>
                                    <mat-form-field appearance="outline" class="OutagesEntry">
                                        <mat-select [(value)]="selectedCompany"
                                            (selectionChange)="onCompanyChange($event)">
                                            <mat-option *ngFor="let row of companies" [value]="row.companyCode">
                                                {{ row.companyName }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th align="left">Unit:</th>
                                <td>
                                    <mat-form-field appearance="outline" class="OutagesEntry">
                                        <mat-select [(value)]="selectedUnit"
                                            (selectionChange)="onUnitTypeChange($event)">
                                            <mat-option *ngFor="let row of units" [value]="row.unit">
                                                {{ row.unit }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th align="left">Type:</th>
                                <td>
                                    <mat-form-field appearance="outline" class="OutagesEntry">
                                        <mat-select [(value)]="selectedType"
                                            (selectionChange)="onOutageTypeChange($event)">
                                            <mat-option *ngFor="let row of outageTypes" [value]="row.code">
                                                {{ row.description }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th align="left">Start Date:</th>
                                <td>
                                    <mat-form-field appearance="outline" class="OutagesEntry">
                                        <input matInput [matDatepicker]="picker" placeholder="mm/dd/yyyy"
                                            [ngModel]="startDate" (dateChange)="OnStartDateChange($event)">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th align="left">End Date:</th>
                                <td>
                                    <mat-form-field appearance="outline" class="OutagesEntry">
                                        <input matInput [matDatepicker]="pickerTo" placeholder="mm/dd/yyyy"
                                            [(ngModel)]="endDate" (dateChange)="OnEndDateChange($event)">
                                        <mat-datepicker-toggle matIconSuffix [for]="pickerTo"></mat-datepicker-toggle>
                                        <mat-datepicker #pickerTo></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <th align="left">Duration (Days):</th>
                                <td>
                                    <input type="text" maxlength="3" class="BlackText"
                                        style="width:60px; margin-top: 12px; margin-left: 15px;" [(ngModel)]="duration"
                                        (change)="onDurationChange(duration, startDate)">
                                </td>
                            </tr>
                            <tr>
                                <th align="left">Turnaround Name:</th>
                                <td>
                                    <input type="text" maxlength="50" disabled class="BlackText" allownull="false"
                                        style="width:170px; margin-top: 5px; margin-left: 15px;"
                                        [(ngModel)]="turnaroundName">
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <br>

                    <input type="submit" value="Save" class="BlackText" (click)="SaveOutage()">
                    <a href="/tops/tops-home"><input type="button" class="BlackText" value="Cancel"
                            style="margin-left: 5px;"></a>
                </td>
            </tr>
        </tbody>
    </table>

    <div id="divLinks" style="display:none">

        <span class="BlackText">No links found.</span>

    </div>

</div>