import { spRepairTracking } from '#models/spRepairTracking';
import { PartsService } from '#services/http/parts.service';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-repair-tracking-report',
  templateUrl: './repair-tracking-report.component.html',
  styleUrls: ['./repair-tracking-report.component.scss']
})

export class RepairTrackingReportComponent {

  trackingData: spRepairTracking[] = [];
  public organizationCode: string = '';
  public frameType: string = '';
  public componentCode: string;

  constructor(private partsService: PartsService, private activeRoute: ActivatedRoute,) {
  }

  ngOnInit(): void {

    this.activeRoute.queryParams.subscribe((params) => {
      this.organizationCode = params['OrgCode'];
      this.frameType = params['FrameType'];
      this.componentCode = params['CompCode'];
    });

    this.partsService.getRepairTracking(this.organizationCode, this.frameType, this.componentCode).subscribe((res) => {
      this.trackingData = res;
    });
  }
}
