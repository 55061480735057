import { FuelSource } from '#models/FuelSource';
import { spGetUnit } from '#models/spGetUnit';
import { spGetUnitOutageHisotry } from '#models/spGetUnitOutageHisotry';
import { TopsHomeService } from '#services/http/tops-home.service';
import { UnitInfoService } from '#services/http/unit-info.service';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NotesComponent } from 'app/modals/notes/notes.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationService } from '#services/notification.service';
import { ColorPickerComponent } from '#components/color-picker/color-picker.component';
@Component({
  selector: 'app-unit-edit-dialog',
  templateUrl: './unit-edit-dialog.component.html',
  styleUrls: ['./unit-edit-dialog.component.scss']
})

export class UnitEditDialogComponent {

  selectedColor: string = '';
  fuelSource: FuelSource[] = [];
  unitInfo = new spGetUnit();
  selectedFuelSource: number;
  outageHistory: spGetUnitOutageHisotry[] = [];
  public outageHistoryColumns = ['date', 'outageType', 'notes', 'unitHrs', 'intervalHrs']
  public outageHistoryDataSource: MatTableDataSource<spGetUnitOutageHisotry>;
  maximize: boolean = false;
  constructor(public dialogRef: MatDialogRef<UnitDialogModel>,
    private router: Router, private topsHomeService: TopsHomeService,
    private notifyService: NotificationService,
    private snackBar: MatSnackBar,
    private unitInfoService: UnitInfoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    // console.log(this.data)
    this.topsHomeService.getUnitInfoData(this.data.organizationCode, this.data.companyCode, this.data.unit).subscribe((res) => {
      this.unitInfo = res[0];
      console.log(this.unitInfo)
      this.selectedFuelSource = this.unitInfo.fuelSource;
      this.selectedColor = this.unitInfo.color;
      console.log(this.selectedColor)
      this.unitInfoService.getFuelSource().subscribe((res) => {
        this.fuelSource = res;
        // console.log( res)       
      });

      this.topsHomeService.getUnitOutageHistory(this.data.organizationCode, this.data.frameType,
        this.data.companyCode, this.data.unit).subscribe((resOutageHistory) => {
          this.outageHistory = resOutageHistory;

          this.outageHistoryDataSource = new MatTableDataSource<spGetUnitOutageHisotry>(this.outageHistory);
        });
    });
    console.log("OnInit color:" + this.unitInfo.color)
    console.log("OnInit fuel:" + this.unitInfo.fuelSource)
  }
  onColorSelected(color: string) {
    this.selectedColor = color;
  }
  UpdateUnitInfo() {
    console.log(this.unitInfo.percentUpTime)
    console.log(this.unitInfo.firingTemperature)
    //Validations
    if (this.unitInfo.percentUpTime == 0 || this.unitInfo.percentUpTime === null) {
      this.notifyService.showError("Unit percent up time is required.", "");
    } else if (isNaN(this.unitInfo.percentUpTime)) {
      this.notifyService.showError("Invalid unit percent up time.", "");
    } else if (this.unitInfo.percentUpTime < 0 || this.unitInfo.percentUpTime > 1) {
      this.notifyService.showError("Unit percent up time must be a decimal number between 0 and 1.", "");
    } else if (!this.unitInfo.firingTemperature) {
      this.notifyService.showError("Invalid unit firing temperature.", "");
    } else {
      this.unitInfo.fuelSource = this.selectedFuelSource;
      this.unitInfo.color = this.selectedColor;
      this.unitInfoService.updateUnitInfo(this.unitInfo).subscribe(res => {
        if (res == -1) {
          this.snackBar.open('Unit Information updated successfully', 'Close', {
            duration: 3000
          });
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  selectColor(color: string): void {
    this.selectedColor = color;
  }
  onFuelSourceChange(event: any) {
    this.selectedFuelSource = event.value;
  }
  onDismiss(): void {
    this.dialogRef.close();
  }

  ShowNotesDialog(notes: string) {
    this.dialog.open(NotesComponent, {
      width: "50%",
      height: "55%",

      data: { notes: notes },
    });
  }

  maxClick() {
    this.maximize = true;
  }
  minClick() {
    this.maximize = false;
  }
}

export class UnitDialogModel {

}
