<div class="container">
    <form [formGroup]="compressorForm">
        <div class="row">
            <div class="col">
                <mat-card>
                    <mat-card-content>
                        <div class="row">
                            <div class="col">
                                <h2>business unit</h2>
                                <mat-form-field appearance="outline">
                                    <mat-label>select business unit</mat-label>
                                    <mat-select name="BU" [(ngModel)]="selectedBU"
                                        (selectionChange)="updateBusinessUnit()" formControlName="businessUnit"
                                        required>
                                        <mat-option *ngFor="let businessUnit of businessUnits" [value]="businessUnit">
                                            {{ businessUnit.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('businessUnit', 'required')">
                                        Please select a business unit.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <h2>facility</h2>
                                <mat-form-field appearance="outline">
                                    <mat-label>select facility</mat-label>
                                    <mat-select name="Facility" [(ngModel)]="selectedFacility"
                                        (selectionChange)="updateEquipment()" formControlName="facility" required>
                                        <mat-option *ngFor="let facility of facilities" [value]="facility">
                                            {{ facility.name }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('facility', 'required')">
                                        Please select a facility.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-10">
                                <h2>compressor</h2>
                                <mat-form-field appearance="outline" style="width: 95%;">
                                    <mat-label>select compressor</mat-label>
                                    <mat-select name="Compressor" [(ngModel)]="selectedCompressor"
                                        (selectionChange)="updateEventLogs(selectedCompressor.compressorEquipmentId)"
                                        formControlName="compressor" required>
                                        <mat-option *ngFor="let compressor of compressors" [value]="compressor">
                                            {{ compressor.compressorEquipmentId }}
                                        </mat-option>
                                    </mat-select>
                                    <mat-error *ngIf="hasError('compressor', 'required')">
                                        Please select a compressor.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2" style="margin-top: 63px;">
                                <span *ngIf="selectedCompressor">
                                    <a class="mx-4" href="/dgs-drawings" rel="noopener"
                                        style="font-size: 16px; font-weight: bold;">clear filters</a>
                                </span>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>

        <div class="col">
            <mat-card class="log-table">
                <mat-card-header style="justify-content: center;" *ngIf="selectedCompressor != null">
                    <mat-card-title> {{selectedCompressor.compressorEquipmentId}} drawings </mat-card-title>
                </mat-card-header>
                <mat-card-content style="overflow: auto;">
                    <table mat-table [dataSource]="drawingsDataSource" matSort>
                        <!-- Seal Drawing Number Column -->
                        <ng-container matColumnDef="sealDrawingNumber">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Seal Drawing Number </th>
                            <td mat-cell *matCellDef="let row"> {{row.sealDrawingNumber}} </td>
                            <td mat-cell *matCellDef="let row"> 1 </td>
                        </ng-container>

                        <!-- Manufacturer Column -->
                        <ng-container matColumnDef="manufacturer">
                            <th mat-header-cell *matHeaderCellDef> Manufacturer </th>
                            <td mat-cell *matCellDef="let row" class="text-center"> {{row.manufacturer}} </td>
                        </ng-container>

                        <!-- Model Column -->
                        <ng-container matColumnDef="model">
                            <th mat-header-cell *matHeaderCellDef style="width: 40% !important;">
                                <span style="padding-left: 7px; padding-right: 7px;">Model</span>
                            </th>
                            <td mat-cell *matCellDef="let row" class="text-center"> {{row.model}} </td>
                        </ng-container>

                        <!-- Seal Arrangement Column -->
                        <ng-container matColumnDef="sealArrangement">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Seal Arrangement </th>
                            <td mat-cell *matCellDef="let row" class="text-center"> {{row.sealArrangement}} </td>
                        </ng-container>

                        <!-- Shaft Diameter Column -->
                        <!-- <ng-container matColumnDef="shaftDiameter">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Shaft Diameter </th>
                <td mat-cell *matCellDef="let row"> {{row.shaftDiameter}} </td>
            </ng-container> -->

                        <!-- Shaft Diameter Unit Column -->
                        <!-- <ng-container matColumnDef="shaftDiameterUnit">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Shaft Diameter Unit </th>
                <td mat-cell *matCellDef="let row"> {{row.shaftDiameterUnit}} </td>
            </ng-container> -->

                        <!-- Primary Stationary Face Material Column -->
                        <ng-container matColumnDef="primaryStationaryFaceMaterial">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Stationary Face
                                Material
                            </th>
                            <td mat-cell *matCellDef="let row" class="text-center">
                                {{row.primaryStationaryFaceMaterial}} </td>
                        </ng-container>

                        <!-- Primary Rotating Face Material Column -->
                        <ng-container matColumnDef="primaryRotatingFaceMaterial">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Primary Rotating Face
                                Material </th>
                            <td mat-cell *matCellDef="let row" class="text-center"> {{row.primaryRotatingFaceMaterial}}
                            </td>
                        </ng-container>

                        <!-- Secondary Stationary Face Material Column -->
                        <ng-container matColumnDef="secondaryStationaryFaceMaterial">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Secondary Stationary Face
                                Material
                            </th>
                            <td mat-cell *matCellDef="let row" class="text-center">
                                {{row.secondaryStationaryFaceMaterial}} </td>
                        </ng-container>

                        <!-- Secondary Rotating Face Material Column -->
                        <ng-container matColumnDef="secondaryRotatingFaceMaterial">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Secondary Rotating Face
                                Material
                            </th>
                            <td mat-cell *matCellDef="let row" class="text-center">
                                {{row.secondaryRotatingFaceMaterial}} </td>
                        </ng-container>

                        <!-- Directional Column -->
                        <ng-container matColumnDef="directional">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Directional </th>
                            <td mat-cell *matCellDef="let row" class="text-center"> {{row.directional}} </td>
                        </ng-container>

                        <!-- Gasket Material Column -->
                        <!-- <ng-container matColumnDef="gasketMaterial">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Gasket Material </th>
                <td mat-cell *matCellDef="let row"> {{row.gasketMaterial}} </td>
            </ng-container> -->

                        <!-- Dynamic Seal Material Column -->
                        <!-- <ng-container matColumnDef="dynamicSealMaterial">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Dynamic Seal Material </th>
                <td mat-cell *matCellDef="let row"> {{row.dynamicSealMaterial}} </td>
            </ng-container> -->

                        <!-- Seal Metal Column -->
                        <!-- <ng-container matColumnDef="sealMetal">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Seal Metal </th>
                <td mat-cell *matCellDef="let row"> {{row.sealMetal}} </td>
            </ng-container> -->

                        <!-- Seperation Seal Column -->
                        <ng-container matColumnDef="seperationSeal">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Seperation Seal </th>
                            <td mat-cell *matCellDef="let row" class="text-center"> {{row.seperationSeal ? 'Yes' :
                                'No'}} </td>
                        </ng-container>

                        <!-- Seperation Seal Type Column -->
                        <ng-container matColumnDef="seperationSealType">
                            <th mat-header-cell *matHeaderCellDef mat-sort-header> Seperation Seal Type </th>
                            <td mat-cell *matCellDef="let row" class="text-center"> {{row.seperationSealType}} </td>
                        </ng-container>

                        <!-- Drawing Doc Url Column -->
                        <ng-container matColumnDef="drawingDocUrl">
                            <th mat-header-cell *matHeaderCellDef> Drawing Doc Url </th>
                            <td mat-cell *matCellDef="let row" class="text-center">
                                <a target="_blank" [attr.href]="row.drawingDocUrl"> view </a>
                            </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedDrawingsColumns"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedDrawingsColumns;"
                            [ngStyle]="{'background-color': row.rowColor}"></tr>
                    </table>
                    <mat-paginator #paginator [pageIndex]="0" [pageSize]="20" showFirstLastButtons
                        [pageSizeOptions]="[20, 40, 60, 100, 200]" aria-label="Select page of users"></mat-paginator>
                </mat-card-content>
            </mat-card>
        </div>

    </form>
</div>