import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-not-authorized-error',
  templateUrl: './not-authorized-error.page.html',
  styleUrls: ['./not-authorized-error.page.scss']
})
export class NotAuthorizedErrorPage implements OnInit {

  constructor(
  ) {
    // do nothing
  }

  ngOnInit() {
    // do nothing
  }
}