import { NgModule } from '@angular/core';

// Material Design Modules
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';

import { LoadingDialogComponent } from './loading-dialog.component';
import { LoadingDialogService } from './loading-dialog.service';

@NgModule({
  imports: [
    // Material Design
    MatProgressSpinnerModule,
    MatDialogModule
  ],
  declarations: [
    LoadingDialogComponent,
  ],
  providers: [
    LoadingDialogService,
  ],
  exports: [
  ]
})
export class LoadingDialogModule { }