import { Component, Inject } from '@angular/core';
import { UnitDialogModel } from '../unit-dialog/unit-dialog.component';
import { Router } from '@angular/router';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-unit-sections-components',
  standalone: true,
  imports: [],
  templateUrl: './unit-sections-components.component.html',
  styleUrl: './unit-sections-components.component.scss'
})

export class UnitSectionsComponentsComponent {


  public unitSectionComponentsColumns = ['section', 'order', 'actions'];

  constructor(public dialogRef: MatDialogRef<UnitDialogModel>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
  }

  onDismiss(): void {
    this.dialogRef.close();
  }
}
