import { GetOrganizations } from '#models/GetOrganizations';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { SectionsService } from '#services/http/sections.service';
import { NotificationService } from '#services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-sections',
  templateUrl: './sections.component.html',
  styleUrl: './sections.component.scss'
})

export class SectionsComponent {
  sections: GetOrganizations[] = [];
  public sectionDataSource: MatTableDataSource<GetOrganizations>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  insertSection = new spGetOrganizations();
  description: string = '';
  updatedDesc: string = '';
  public displaySectionsColumns = ['description', 'actions'];
  public editState: { [key: string]: boolean } = {}; // creating a JSON key-pair object to track which elements are in 'edit' mode 

  constructor(private notifyService: NotificationService,
    private sectionService: SectionsService) {
  }

  ngOnInit() {
    this.getSectionsData();
  }

  getSectionsData() {
    this.sectionService.getSections().subscribe({
      next:
        (data: GetOrganizations[]) => {
          this.sections = data;
          let section = new GetOrganizations();
          section.code = '0';
          section.description = '';
          this.sections.unshift(section);

          this.sectionDataSource = new MatTableDataSource<GetOrganizations>(this.sections);
          this.sectionDataSource.paginator = this.paginator;
        },
      error: ((error: HttpErrorResponse) => {
        this.notifyService.showError("Sections could not be loaded, if error persists please submit ticket", "");
        throw error;
      })
    });

    // this.sectionService.getSections().subscribe((res) => {
    //   this.sections = res;
    //   let section = new GetOrganizations();
    //   section.code = '0';
    //   section.description = '';
    //   this.sections.unshift(section);
    //   this.sectionDataSource = new MatTableDataSource<GetOrganizations>(this.sections);
    //   this.sectionDataSource.paginator = this.paginator;
    // });
  }

  public isEditing(row: spGetOrganizations): boolean {
    return this.editState[row.description] || false;
  }

  addSection(description: string) {
    if (description == undefined || description == "") {
      this.notifyService.showError("Description is required.", "");
    }
    else {
      this.insertSection.description = description;
      this.sectionService.insertSection(this.insertSection.description).subscribe(res => {
        if (res == -1) {

          this.notifyService.showSuccess("Section added successfully.", "");
          this.description = null;
          description = '';
          this.getSectionsData();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelInsertSection(description: string) {
    this.description = '';
  }

  public onEditSectionClick(row: spGetOrganizations) {
    this.updatedDesc = row.description;
    this.editState[row.description] = true;
  }

  UpdateSectionDetails(section: spGetOrganizations, updatedDesc: string) {
    section.description = updatedDesc;

    if (section.description == undefined || section.description == "") {
      this.notifyService.showError("Description is required.", "");
      this.editState[section.description] = true;
    }
    else {
      this.editState[section.description] = false;
      this.sectionService.UpdateSectionDetails(section).subscribe(res => {
        if (res == -1) {
          this.notifyService.showSuccess("Section details modified successfully.", "");
          this.getSectionsData();
        }
        else {
          this.notifyService.showError(res.toString(), "");
        }
      });
    }
  }

  cancelUpdateSection(row: spGetOrganizations) {
    this.editState[row.description] = false;
    //window.location.reload();
  }

  deleteSection(section: spGetOrganizations) {
    this.sectionService.deleteSection(section.code).subscribe(res => {

      this.notifyService.showSuccess("Section deleted successfully.", "");
      this.getSectionsData();
    });
  }

}
