import { DryGasSealDrawing } from '#models/DryGasSealDrawing';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { DgsDrawingsService } from '#services/http/dgs-drawings.service';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { BusinessUnitService } from '#services/http/business-unit.service';
import { HttpErrorResponse } from '@angular/common/http';
import { BusinessUnit } from '#models/business-unit';
import { Facility } from '#models/facility';
import { Compressor } from '#models/compressor';
import { CompressorService } from '#services/http/compressor.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MaintenanceLog } from '#models/maintenance-log';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dgs-drawings',
  templateUrl: './dgs-drawings.component.html',
  styleUrls: ['./dgs-drawings.component.scss']
})

export class DgsDrawingsComponent implements OnInit {
  public displayedDrawingsColumns = ['sealDrawingNumber', 'manufacturer', 'model', 'sealArrangement', 'primaryStationaryFaceMaterial', 'primaryRotatingFaceMaterial', 'secondaryStationaryFaceMaterial', 'secondaryRotatingFaceMaterial', 'directional', 'seperationSeal', 'seperationSealType', 'drawingDocUrl']
  public drawingsDataSource: MatTableDataSource<DryGasSealDrawing>;
  drawings: DryGasSealDrawing[] = [];
  result: DryGasSealDrawing[] = [];
  public businessUnits: BusinessUnit[];
  public selectedBU: BusinessUnit;
  public facilities: Facility[];
  public selectedFacility: Facility;
  public compressors: Compressor[];
  public selectedCompressor: Compressor;
  public maintenanceLogs: MaintenanceLog[];
  public parameterBU: String;
  public parameterFacility: String;
  public parameterCompressorId: String = '';

  compressorForm = new FormGroup({
    businessUnit: new FormControl('', Validators.required),
    facility: new FormControl({ value: '', disabled: true }, Validators.required),
    compressor: new FormControl({ value: '', disabled: true }, Validators.required),
    sealPlacement: new FormControl({ value: '', disabled: true }),
  });

  errorSnackBarConfig: MatSnackBarConfig = {
    duration: 5000,
    verticalPosition: 'bottom',
    panelClass: ['snackbar', 'snackbar-error'],
  }

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<DryGasSealDrawing>;

  constructor(private dgsDrawingsService: DgsDrawingsService,
    public dialog: MatDialog, private activeRoute: ActivatedRoute,
    private businessUnitSvc: BusinessUnitService, private snackBar: MatSnackBar,
    private compressorSvc: CompressorService,) {
  }

  ngOnInit() {
    this.activeRoute.queryParams.subscribe((params) => {
      this.parameterBU = params['buUnit'];
      this.parameterFacility = params['facility'];
      this.parameterCompressorId = params['cId']
    });

    this.getDGSDrawingsData();
    this.getBusinessUnits();

    // //   setTimeout(function () {
    // //   if (this.parameterCompressorId !== null) {
    // //   }
    // // }, 2000);
  }

  getBusinessUnits(): void {
    this.businessUnitSvc.getAllBusinessUnits()
      .subscribe({
        next:
          (data: BusinessUnit[]) => {
            this.businessUnits = [...data].sort((first, second) => (first.name > second.name ? 1 : -1));
            if (this.parameterBU !== null) {
              this.selectedBU = this.businessUnits.find(item => item.name === this.parameterBU);
              this.updateBusinessUnit();
            }
          },
        error: ((error: HttpErrorResponse) => {
          this.snackBar.open('Business units could not be loaded. Please try again later.', '', this.errorSnackBarConfig);
          throw error;
        }),
      });
  }

  getCompressors(): void {
    this.compressorSvc.getCompressors(this.selectedFacility.name.trim())
      .subscribe({
        next: (data: Compressor[]) => {
          ////console.log(data);
          this.compressors = [...data].sort((first, second) => (first.compressorEquipmentId > second.compressorEquipmentId ? 1 : -1));
          if (this.parameterCompressorId !== null) {
            this.selectedCompressor = this.compressors.find(item => item.compressorEquipmentId === this.parameterCompressorId);

            //// This code is to load Drawings table data
            this.dgsDrawingsService.getDGSDrawings().subscribe((res: DryGasSealDrawing[]) => {
              this.drawings = res;
              this.result = [];
              var compressorData = this.compressors.find(x => x.compressorEquipmentId == this.parameterCompressorId);
              var deDrawing = this.drawings.find(x => x.sealDrawingNumber == compressorData.desealNumber);
              var ndeDrawing = this.drawings.find(x => x.sealDrawingNumber == compressorData.ndesealNumber);

              if (deDrawing != null) {
                this.result.push(deDrawing);
              }

              if (ndeDrawing != null && compressorData.desealNumber != compressorData.ndesealNumber) {
                this.result.push(ndeDrawing);
              }

              this.drawingsDataSource = new MatTableDataSource<DryGasSealDrawing>(this.result);
              this.drawingsDataSource.paginator = this.paginator;
              this.sort.sort(({ id: 'date', start: 'desc' }) as MatSortable)
              this.drawingsDataSource.sort = this.sort;
            })
          }
        },
        error: ((error: HttpErrorResponse) => {
          this.snackBar.open('Equipment data could not be loaded. Please try again later.', '', this.errorSnackBarConfig);
        }),
      });
  }

  updateBusinessUnit(): void {
    this.compressorForm.get('compressor').disable();

    this.selectedFacility = undefined;
    this.selectedCompressor = undefined;

    this.updateFacility();
  }

  updateFacility(): void {
    if (this.selectedBU !== undefined) {
      this.compressorForm.get('facility').enable();

      this.facilities = [...this.selectedBU.facilities].sort((first, second) => (first.name > second.name ? 1 : -1));
      if (this.parameterFacility !== null) {
        this.selectedFacility = this.facilities.find(item => item.name === this.parameterFacility);
        this.updateEquipment();
      }
    }
  }

  updateEquipment(): void {
    this.selectedCompressor = undefined;

    if (this.selectedFacility !== undefined) {
      this.compressorForm.get('compressor').enable();
      this.getCompressors();
    }
  }

  updateEventLogs(compressorId: string): void {
    this.dgsDrawingsService.getDGSDrawings().subscribe((res: DryGasSealDrawing[]) => {
      this.drawings = res;
      this.result = [];
      var data = this.compressors.find(x => x.compressorEquipmentId == compressorId);
      var deDrawing = this.drawings.find(x => x.sealDrawingNumber == data.desealNumber);
      var ndeDrawing = this.drawings.find(x => x.sealDrawingNumber == data.ndesealNumber);

      if (deDrawing != null) {
        this.result.push(deDrawing);
      }

      if (ndeDrawing != null && data.desealNumber != data.ndesealNumber) {
        this.result.push(ndeDrawing);
      }

      this.drawingsDataSource = new MatTableDataSource<DryGasSealDrawing>(this.result);
      this.drawingsDataSource.paginator = this.paginator;
      this.sort.sort(({ id: 'date', start: 'desc' }) as MatSortable)
      this.drawingsDataSource.sort = this.sort;
    })
  }

  public hasError(controlName: string, errorName: string): boolean {
    return this.compressorForm.controls[controlName].hasError(errorName) &&
      this.compressorForm.controls[controlName].touched;
  }

  getDGSDrawingsData() {
    this.dgsDrawingsService.getDGSDrawings().subscribe((res: DryGasSealDrawing[]) => {
      this.drawings = res;

      this.drawingsDataSource = new MatTableDataSource<DryGasSealDrawing>(this.drawings);
      this.drawingsDataSource.paginator = this.paginator;
    });
  }

  openDrawing(siteUrl) {
    window.open("://" + siteUrl, '_blank');
  }

}
