<div style="overflow-y: hidden !important;">

  <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
      Unit Information
    </h1>
    <span style="background-color: antiquewhite;">
      <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">
        X
      </button>
    </span>
  </div>

  <div mat-dialog-content>
    <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
      <tbody>
        <tr>
          <td align="left" style="width: 60%;">Organization:</td>
          <td>{{ unitInfo?.organizationDescription }}</td>
        </tr>
        <tr>
          <td align="left">Company:</td>
          <td>{{ unitInfo?.companyName }}</td>
        </tr>
        <tr>
          <td align="left">Unit Designation:</td>
          <td>{{ unitInfo?.unit }}</td>
        </tr>
        <tr>
          <td align="left">Unit Type:</td>
          <td>{{ unitInfo?.frameTypeDescription }}</td>
        </tr>
      </tbody>
    </table>

    <div class="row tabbed tabbed-content-control centered" style="margin-top: 20px;">
      <div class="filter-bar tab-filter-bar">
        <mat-tab-group [selectedIndex]="0" animationDuration="0ms" [disableRipple]='true'
          disablepagination="true">

          <mat-tab label="General Info">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
                  <tbody>
                    <tr>
                      <td align="left" style="width: 69%;">Fuel Source:</td>
                      <td>{{ unitInfo?.fuelSourceDescription }}</td>
                    </tr>
                    <tr>
                      <td align="left">DLN:</td>
                      <td>
                        <input type="checkbox" class="form-check-input" [ngModel]="unitInfo?.dln"
                          [checked]="unitInfo?.dln" style="height: 15px; width: 15px;" disabled>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Peaker:</td>
                      <td>
                        <input type="checkbox" class="form-check-input" [ngModel]="unitInfo?.peaker"
                          [checked]="unitInfo?.peaker" style="height: 15px; width: 15px;" disabled>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Projected Outage Color:</td>
                      <td>{{ unitInfo?.color }}</td>
                    </tr>
                    <tr>
                      <td align="left">% Up Time:</td>
                      <td>{{ unitInfo?.percentUpTime }}</td>
                    </tr>
                    <tr>
                      <td align="left">Start Year:</td>
                      <td>{{ unitInfo?.startYear }}</td>
                    </tr>
                    <tr>
                      <td align="left">Firing Temperature:</td>
                      <td>{{ unitInfo?.firingTemperature }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Gas Turbine">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
                  <tbody>
                    <tr>
                      <td align="left">Model:</td>
                      <td>{{ unitInfo?.turbineModel }}</td>
                    </tr>
                    <tr>
                      <td align="left">Rating:</td>
                      <td>{{ unitInfo?.turbineRating }}</td>
                    </tr>
                    <tr>
                      <td align="left">Serial No.:</td>
                      <td>{{ unitInfo?.turbineSerialNumber }}</td>
                    </tr>
                    <tr>
                      <td align="left">Acc. Gear:</td>
                      <td>{{ unitInfo?.accessoryGearSerialNumber }}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <br />
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Driven Equipment">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
                  <tbody>
                    <tr>
                      <td align="left">Model</td>
                      <td>{{ unitInfo?.drivenEquipmentModel }}</td>
                    </tr>
                    <tr>
                      <td align="left">Rating</td>
                      <td>{{ unitInfo?.drivenEquipmentRating }}</td>
                    </tr>
                    <tr>
                      <td align="left">Serial No.</td>
                      <td>{{ unitInfo?.drivenEquipmentSerialNumber }}</td>
                    </tr>
                    <tr>
                      <td align="left">Load Gear</td>
                      <td>{{ unitInfo?.loadGearSerialNumber }}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <br />
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="PI Points">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
                  <tbody>
                    <tr>
                      <td align="left">Fired Hours</td>
                      <td>{{ unitInfo?.pifiredHoursPoint }}</td>
                    </tr>
                    <tr>
                      <td align="left">Fired Starts</td>
                      <td>{{ unitInfo?.pifiredStartsPoint }}</td>
                    </tr>
                    <tr>
                      <td align="left">Manual Starts</td>
                      <td>{{ unitInfo?.pimanualStartsPoint }}</td>
                    </tr>
                    <tr>
                      <td align="left">Total Starts</td>
                      <td>{{ unitInfo?.pitotalStartsPoint }}</td>
                    </tr>
                    <tr>
                      <td align="left">Emergency Stops</td>
                      <td>{{ unitInfo?.piemergencyStopsPoint }}</td>
                    </tr>
                    <tr>
                      <td align="left">Peak Fired Hours</td>
                      <td>{{ unitInfo?.pipeakFiredHoursPoint }}</td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Outage History">
            <ng-template matTabContent>
              <div style="margin-top: 25px; width: 90%;">
                <table mat-table [dataSource]="outageHistoryDataSource" matSort style="border: 1px solid grey;">
                  <!-- Date Column -->
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Date </th>
                    <td mat-cell *matCellDef="let row" class="text-center"> {{row.outageDate | date: 'MM/dd/yyyy'}}
                    </td>
                  </ng-container>

                  <!-- Outage Type Column -->
                  <ng-container matColumnDef="outageType">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Outage Type
                    </th>
                    <td mat-cell *matCellDef="let row" class="text-center"> {{row.outageType}} </td>
                  </ng-container>

                  <!-- Notes Column -->
                  <ng-container matColumnDef="notes">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Notes </th>
                    <td mat-cell *matCellDef="let row" class="text-center">
                      <a href="javascript:void(0);" (click)="ShowNotesDialog(row.comments)">
                        <img *ngIf="row.comments !== null" alt="User Guide" src="/assets/images/document.gif" /></a>
                    </td>
                  </ng-container>

                  <!-- Unit Hrs Column -->
                  <ng-container matColumnDef="unitHrs">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Unit Hrs
                    </th>
                    <td mat-cell *matCellDef="let row" class="text-center"> {{row.unitFiredHours | number : '1.2-2'}}
                    </td>
                  </ng-container>

                  <!-- Interval Hrs Column -->
                  <ng-container matColumnDef="intervalHrs">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="text-center outage-info"> Interval Hrs
                    </th>
                    <td mat-cell *matCellDef="let row" class="text-center"
                      [ngStyle]="{'color': (row.intervalHours < 0) ? 'red' : black }">
                      {{row.intervalHours | number : '1.2-2'}}
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="outageHistoryColumns"
                    style="color: white; background-color: #009dd0;"></tr>
                  <tr mat-row *matRowDef="let row; columns: outageHistoryColumns;"
                    [ngStyle]="{'background-color': row.companyColor}"></tr>
                </table>
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>