<div class="container">
  <div class="row">
    <div class="col">
      <mat-card>
        <img
          alt="User Guide"
          src="/assets/images/hazard_orange.png"
          height="140px"
          width="120px"
          class="d-flex center"
        />
        <h1 class="text-center">You are not authorised to view this page</h1>
        <h3 class="text-center">If you would like to request access please contact
          <a href="mailto: larizza.galindo@chevron.com" title="larizza.galindo@chevron.com"
            >Larizza Galindo</a
          ></h3>
      </mat-card>
    </div>
  </div>
</div>
