<div class="container" style="background-color: white;">
  <div class="mat-elevation-z8">
    <table class="BlackText">
      <tbody>
        <tr style="height: 30px;">
          <td style="padding-left: 5px; padding-right: 5px;">Legend:&nbsp;</td>
          <td width="15" style="background-color:#FAAB18"><br></td>
          <td style="padding-left: 5px; padding-right: 5px;">0 to 30 Days Over Due</td>
          <td width="15" style="background-color:#E21836;"><br></td>
          <td style="padding-left: 5px;">Over 30 Days Over Due </td>
        </tr>
      </tbody>
    </table>
    <table class="BlackText" style="width:100%">
      <tbody>
        <tr style="height: 30px; text-align: center;">
          <th colspan="8" style="background-color: #009dd0; border-bottom:1px solid white; color: white;">
            <span style="font-size: 17px; font-weight: bold;">Upcoming Outages</span>
          </th>
        </tr>
      </tbody>
    </table>

    <table mat-table [dataSource]="outagesDataSource" matSort>
      <!-- Color Column -->
      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let row" style="width: 1%;">
          <span class="glyphicon glyphicon-calendar" [ngStyle]="{'color': row.rowColor}"></span>
        </td>
        <td mat-cell *matCellDef="let row"> 1 </td>
      </ng-container>

      <!-- Outage Date Column -->
      <ng-container matColumnDef="outageDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Outage Date </th>
        <td mat-cell *matCellDef="let row">
          <a href="javascript:void(0);" (click)="ShowOutageInfoDialog(row.id)">
            {{row.outageDate | date: 'MM/dd/yyyy'}}
          </a>
        </td>
        <td mat-cell *matCellDef="let row"> 1 </td>
      </ng-container>

      <!-- Organization Column -->
      <ng-container matColumnDef="organizationCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Organization </th>
        <!-- <td mat-cell *matCellDef="let row" [ngStyle]="{'color': row.rowColor == 'red' ? 'white': 'black'}"> -->
        <td mat-cell *matCellDef="let row">
          {{row.description}}
        </td>
      </ng-container>

      <!-- Company Column -->
      <ng-container matColumnDef="companyName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
        <td mat-cell *matCellDef="let row">
          {{row.companyName}}
        </td>
      </ng-container>

      <!-- Unit Column -->
      <ng-container matColumnDef="unit">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Unit </th>
        <td mat-cell *matCellDef="let row">
          <a href="javascript:void(0);" (click)="ShowUnitInfoDialog(row)">{{row.unit}}</a>
        </td>
      </ng-container>

      <!-- Outage Type Column -->
      <ng-container matColumnDef="outageTypeDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Outage Type </th>
        <td mat-cell *matCellDef="let row">
          {{row.outageTypeDescription}}
        </td>
      </ng-container>

      <!-- Fired Hours Column -->
      <ng-container matColumnDef="unitFiredHours">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fired Hours </th>
        <td mat-cell *matCellDef="let row">
          {{row.unitFiredHours | number : '1.2-2'}}
        </td>
      </ng-container>

      <!-- Engineer(s) Column -->
      <ng-container matColumnDef="engineer">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Engineer(s) </th>
        <td mat-cell *matCellDef="let row">
          {{row.engineer}}
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedOutageColumns" class="header-back-color"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedOutageColumns;"></tr>
      <!-- [ngStyle]="{'background-color': row.rowColor}"></tr> -->
    </table>
    <mat-paginator #paginator [pageIndex]="0" [pageSize]="20" showFirstLastButtons
      [pageSizeOptions]="[20, 40, 60, 100, 200]" aria-label="Select page of users"></mat-paginator>
  </div>
</div>