import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CalAngularModule } from '@cvx/cal-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Material
import { MatButtonModule } from '@angular/material/button';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatToolbarModule } from '@angular/material/toolbar';

// Services
import { UserService } from '#services/user.service';

// Modules
import { HttpModule } from '#services/http/http.module';

// Components
import { FooterComponent } from '#components/footer/footer.component';
import { HeaderComponent } from '#components/header/header.component';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { AuthService } from './authentication/auth.service';
import { TopsHeaderComponent } from '#components/tops-header/tops-header.component';

@NgModule({
  imports: [
    CalAngularModule.forRoot(AuthService.calConfig),

    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    RouterModule,

    // Material
    MatButtonModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
  ],
  providers: [
    UserService,
    HttpErrorInterceptor
  ],
  declarations: [
    FooterComponent,
    HeaderComponent,
    TopsHeaderComponent,
  ],
  exports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    FooterComponent,
    HeaderComponent,
    HttpModule,
    TopsHeaderComponent
  ],
})
export class CoreModule { }