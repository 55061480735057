import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '#environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FileUploadAdminService {
  constructor(private http: HttpClient) { }

  uploadFile(formData: FormData) {
    return this.http.post<any>(`${environment.apiEndpoint}/FileUpload`, formData);
  }

  importCompressors(spreadsheetPath: string) {
    const params = new HttpParams().set('SpreadsheetPath', spreadsheetPath);
    return this.http.post<any>(`${environment.apiEndpoint}/FileUpload/ImportCompressors`, null, { params });
  }

  importDGSDrawing(spreadsheetPath: string) {
    const params = new HttpParams().set('SpreadsheetPath', spreadsheetPath);
    return this.http.post<any>(`${environment.apiEndpoint}/FileUpload/ImportDGSDrawing`, null, { params });
  }

  importMaintenanceLog(spreadsheetPath: string) {
    const params = new HttpParams().set('SpreadsheetPath', spreadsheetPath);
    return this.http.post<any>(`${environment.apiEndpoint}/FileUpload/ImportMaintenanceLog`, null, { params });
  }
}
