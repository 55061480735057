import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '#services/notification.service';
import { OutageSummaryService } from '#services/http/outage-summary.service';
import { spGetOutagesSummary } from '#models/spGetOutagesSummary';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { NotesComponent } from 'app/modals/notes/notes.component';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { spGetCompaniesByUnitType } from '#models/spGetCompaniesByUnitType';
import { spGetOutagesOutageTypes } from '#models/spGetOutagesOutageTypes';
import { spGetUnits } from '#models/spGetUnits';
import { OutageDialogComponent } from 'app/core/modals/outage-dialog/outage-dialog.component';
import { PartEntryService } from '#services/http/part-entry.service';
import { vOutage } from '#models/VOutage';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { outagesSummaryModels } from '#models/outages-summary-models';
import { outagesDetailModels } from '#models/outages-detail-models';
import { spGetOutagesOutageSections } from '#models/spGetOutagesOutageSections';
import { spGetOutages } from '#models/spGetOutages';
import { OutageDetailsService } from '#services/http/outage-details.service';
import { spGetUnitTypeComponents } from '#models/spGetUnitTypeComponents'
import { PartInformationDialogComponent } from 'app/core/modals/part-information-dialog/part-information-dialog.component';


@Component({
  selector: 'app-outages-details',
  templateUrl: './outages-details.component.html',
  styleUrls: ['./outages-details.component.scss']
})

export class OutagesDetailsComponent {

  public outagesDetailColumns = [];
  organizations: spGetOrganizations[] = [];
  unitTypes: spGetOrganizations[] = [];
  companies: spGetCompaniesByUnitType[] = [];
  units: spGetUnits[] = [];
  outageTypes: spGetOutagesOutageTypes[] = [];
  outageSections: spGetOutagesOutageSections[] = [];
  selectedOrg: string = '';
  selectedUnit: string = '';
  selected = 'All';
  selectedCompaniesBackEnd: string = '';
  selectedUnitsBackEnd: string = '';
  selectedOutagesTypeBackEnd: string = '';
  selectedOutageSectionBackEnd: string = '';
  allCompanies: boolean = false;
  allUnits: boolean = false;
  allOutageTypes: boolean = false;
  allOutageSections: boolean = false;
  outagesDetailsData: spGetOutages[] = [];
  public outagesDataSource: MatTableDataSource<spGetOutages>;
  outageInfo = new vOutage();
  public outagesDetailModel = new outagesDetailModels();
  strSectionDescription: string = '';
  intSection: number = 0;
  unitTypeComponents: spGetUnitTypeComponents[] = [];

  staticColumns: string[] = ['date', 'co', 'unit', 'outageType', 'notes', 'unitHrs', 'intervalHrs'];
  dynamicColumns: string[] = []; // Array to store dynamic columns
  dynamicHeaders: string[] = []; // First level headers
  subHeaders: string[][] = []; // Second level headers (In, Out for each componentName)
  thirdLevelHeaders: string[] = []; //Third level headers (In, Out for each componentName)
  columnDefinitions: string[] = [];

  public organizationCode: string = '';
  public unitType: string = '';
  public company: string = '';
  public unit: string = '';
  public outageStatus: string = null;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<spGetOutages>;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private notifyService: NotificationService, public dialog: MatDialog,
    private outSummaryService: OutageSummaryService,
    private partEntryService: PartEntryService,
    private outDetailsService: OutageDetailsService) {
  }

  ngOnInit(): void {
    this.getOrganizations();
    //load data from sessionstorage on refresh

    this.LoadFilterValuesFromSession();
  }

  async getOutageSections() {
    this.allOutageSections = false;
    const res = await this.outDetailsService.getOutageSections().toPromise();
    this.outageSections = res;
    this.outageSections = this.outageSections.filter(section =>
      this.dynamicHeaders.includes(section.description)
    );
  }

  getOrganizations() {
    this.outSummaryService.getOrganizations().subscribe((res) => {
      this.organizations = res;
    });
  }

  onOrganizationChange(event: any) {
    this.organizationCode = event.value;
    this.selectedOrg = this.organizationCode;
    this.outagesDetailModel.selectedOrg = this.selectedOrg;
    //clear other lists
    this.companies = null;
    this.units = null;
    this.outageTypes = null;
    this.selectedUnit = '';
    this.unitType = '';
    this.allCompanies = false;
    this.allUnits = false;
    this.allOutageTypes = false;
    this.outagesDetailsData = null;
    this.selectedCompaniesBackEnd = "";
    this.selectedUnitsBackEnd = "";
    this.selectedOutageSectionBackEnd = "";
    this.allOutageSections = false;

    this.getUnitTypes();
  }

  async getUnitTypes() {
    const res = await this.outSummaryService.getUnitTypes(this.organizationCode).toPromise();
    this.unitTypes = res;
  }

  onUnitTypeChange(event: any) {
    this.unitType = event.value;
    this.selectedUnit = this.unitType;
    this.outagesDetailModel.selectedUnit = this.selectedUnit;
    //clear other lists
    this.companies = null;
    this.units = null;
    this.outageTypes = null;
    this.allCompanies = false;
    this.allUnits = false;
    this.allOutageTypes = false;
    this.outagesDetailsData = null;
    this.selectedCompaniesBackEnd = "";
    this.selectedUnitsBackEnd = "";
    this.selectedOutageSectionBackEnd = "";
    this.allOutageSections = false;
    this.getCompanies();
    this.getUnitTypeComponent();
  }

  async getCompanies() {
    this.allCompanies = false;
    const res = await this.outSummaryService.getCompanies(this.organizationCode, this.unitType).toPromise();
    this.companies = res;
  }

  onCompanyChange(company: string, event: any) {

    if (company == 'All' && event.currentTarget.checked == true) {
      this.companies.forEach(element => {
        element.isSelected = true;
      });
      this.outagesDetailModel.allCompanies = true;
    }
    else {
      this.allCompanies = false;
      this.companies.forEach(element => {
        element.isSelected = false;
      });
      this.allUnits = false;
      this.outagesDetailsData = null;
      this.units = null;
      this.allOutageTypes = false;
      this.outageTypes = null;
      this.selectedCompaniesBackEnd = "";
      this.selectedOutagesTypeBackEnd = "";
      this.selectedUnitsBackEnd = "";
      this.selectedOutageSectionBackEnd = "";
      this.allOutageSections = false;
    }

    if (company != 'All') {
      this.companies.find(x => x.companyCode == company).isSelected = event.currentTarget.checked;
    }

    this.companies.forEach(element => {
      if (element.isSelected == true) {
        this.selectedCompaniesBackEnd = this.selectedCompaniesBackEnd + '|' + element.companyCode + '|';
      }
    });

    if (this.selectedCompaniesBackEnd != "") {
      this.outagesDetailModel.selectedCompaniesBackEnd = this.selectedCompaniesBackEnd;
      this.getUnits();
    }
  }

  async getUnits() {
    const res = await this.outSummaryService.getUnits(this.organizationCode, this.selectedCompaniesBackEnd, this.unitType).toPromise();
    this.units = res;
  }

  onUnitChange(unit: string, event: any) {
    this.selectedUnitsBackEnd = '';
    if (unit == 'All' && event.currentTarget.checked == true) {
      this.units.forEach(element => {
        element.isSelected = true;
      });
      this.outagesDetailModel.allUnits = true;
    }
    if (unit == 'All' && event.currentTarget.checked == false) {
      this.units.forEach(element => {
        element.isSelected = false;
      });

      this.allUnits = false;
      this.outageTypes = null;
      this.allOutageTypes = false;
      this.selectedOutagesTypeBackEnd = "";
      this.outagesDetailsData = null;
      this.selectedOutageSectionBackEnd = "";
      this.allOutageSections = false;
    }

    if (unit != 'All') {
      this.allUnits = false;
      this.outageTypes = null;
      this.allOutageTypes = false;
      this.selectedOutagesTypeBackEnd = "";
      this.outagesDetailsData = null;
      this.selectedOutageSectionBackEnd = "";
      this.allOutageSections = false;

      this.units.find(x => x.unit == unit).isSelected = event.currentTarget.checked;
    }

    this.units.forEach(element => {
      if (element.isSelected == true) {
        this.selectedUnitsBackEnd = this.selectedUnitsBackEnd + '|' + element.unit + '|';
      }
    });

    if (this.selectedUnitsBackEnd != "") {
      this.outagesDetailModel.selectedUnitsBackEnd = this.selectedUnitsBackEnd;
      this.getOutageTypes();
    }

    if (this.selectedOutagesTypeBackEnd != "") {
      this.outagesDetailsData = null;
      this.fetchDynamicColumns('All');
      this.getOutageSummaryDetails();
    }
  }

  async getOutageTypes() {
    const res = await this.outSummaryService.getOutageTypes(this.organizationCode, this.selectedCompaniesBackEnd,
      this.selectedUnitsBackEnd).toPromise();
    this.outageTypes = res;
  }

  onOutageTypeChange(outage: string, event: any) {
    this.selectedOutagesTypeBackEnd = '';
    if (outage == 'All' && event.currentTarget.checked == true) {
      this.outageTypes.forEach(element => {
        element.isSelected = true;
      });
      this.outagesDetailModel.allOutageTypes = true;
    }
    if (outage == 'All' && event.currentTarget.checked == false) {
      this.allOutageTypes = false;
      this.outageTypes.forEach(element => {
        element.isSelected = false;
      });
      this.selectedOutagesTypeBackEnd = "";
      this.outagesDetailsData = null;
      this.selectedOutageSectionBackEnd = "";
      this.allOutageSections = false;
    }

    if (outage != 'All') {
      this.allOutageTypes = false;

      this.outageTypes.find(x => x.code == outage).isSelected = event.currentTarget.checked;
    }

    this.outageTypes.forEach(element => {
      if (element.isSelected === true) {
        this.selectedOutagesTypeBackEnd = this.selectedOutagesTypeBackEnd + '|' + element.code + '|';
      }
    });

    if (this.selectedOutagesTypeBackEnd != "") {
      this.outagesDetailModel.selectedOutagesTypeBackEnd = this.selectedOutagesTypeBackEnd;
      this.outagesDetailsData = null;
      this.fetchDynamicColumns('All');
      this.getOutageSections();
      this.getOutageSummaryDetails();
    }
  }

  onOutageStatusChange(event: any) {
    if (event.value == 'All') {
      this.outageStatus = null;
    }
    else {
      this.outageStatus = event.value;
    }
    this.outagesDetailModel.outageStatus = this.selected = this.outageStatus;
    this.getOutageSummaryDetails();
  }

  onOutageSectionChange(outage: string, event: any) {
    this.selectedOutageSectionBackEnd = '';
    if (outage == 'All' && event.currentTarget.checked == true) {
      this.outageSections.forEach(element => {
        element.isSelected = true;
      });
      this.outagesDetailModel.allOutageSections = true;
    }
    if (outage == 'All' && event.currentTarget.checked == false) {
      this.allOutageSections = false;
      this.outageSections.forEach(element => {
        element.isSelected = false;
      });
      this.selectedOutageSectionBackEnd = "";
      this.outagesDetailsData = null;
    }

    if (outage != 'All') {
      this.allOutageSections = false;

      this.outageSections.find(x => x.description == outage).isSelected = event.currentTarget.checked;
    }

    this.outageSections.forEach(element => {
      if (element.isSelected === true) {
        this.selectedOutageSectionBackEnd = this.selectedOutageSectionBackEnd + '|' + element.description + '|';
      }
    });

    if (this.selectedOutageSectionBackEnd != "") {
      this.outagesDetailModel.selectedOutageSectionBackEnd = this.selectedOutageSectionBackEnd;
      this.outagesDetailsData = null;
      this.fetchDynamicColumns(this.selectedOutageSectionBackEnd);
      this.getOutageSummaryDetails();
    }
    else {
      this.outagesDetailsData = null;
      this.fetchDynamicColumns('All');
      this.getOutageSummaryDetails();
    }
  }

  async getUnitTypeComponent() {
    const res = await this.outDetailsService.getUnitTypeComponents(this.unitType).toPromise();
    this.unitTypeComponents = res;
  }

  getOutageSummaryDetails() {
    sessionStorage.removeItem('outagesDetailFilters');
    this.outagesDataSource = new MatTableDataSource<spGetOutages>;
    this.outDetailsService.getOutagesData(this.organizationCode, this.unitType,
      this.selectedCompaniesBackEnd, this.selectedUnitsBackEnd, this.selectedOutagesTypeBackEnd,
      this.outageStatus).forEach((res) => {

        this.outagesDetailsData = this.groupData(res);
        this.outagesDataSource = new MatTableDataSource<spGetOutages>(this.outagesDetailsData);
        setTimeout(() => this.outagesDataSource.paginator = this.paginator);
      });

    sessionStorage.setItem('outagesDetailFilters', JSON.stringify(this.outagesDetailModel));

  }
  groupData(data: spGetOutages[]): spGetOutages[] {
    const groupedData = [];
    const groupedMap = new Map<string, spGetOutages>();
    const tempData: spGetOutages[] = [];

    const outageTypesArray = this.selectedOutagesTypeBackEnd.split('|').filter(type => type.trim() !== '');
    outageTypesArray.forEach(outageType => {
      let tempOutage: any = {};
      data.forEach(res => {
        if (outageType === res.outageType) {
          this.unitTypeComponents.forEach(item => {
            if (item.componentCode === res.componentCode) {
              const inproperty = item.componentName + '_In';
              const outproperty = item.componentName + '_Out';
              if (!tempOutage[inproperty])
                tempOutage[inproperty] = res.setNumber;
              if (!tempOutage[outproperty])
                tempOutage[outproperty] = res.setNumberOut;
              tempOutage['outageType'] = res.outageType;
            }
          });
        }
      });
      tempData.push(tempOutage);
    });



    data.forEach(res => {
      const key = `${res.outageDate}-${res.companyCode}-${res.unit}-${res.outageType}-${res.comments}-${res.unitFiredHours}-${res.intervalHours}`;
      if (!groupedMap.has(key)) {
        const mergedOutage = tempData.find(item => item.outageType === res.outageType);
        if (mergedOutage) {
          const finalMergedOutage: spGetOutages = {
            ...mergedOutage,
            ...res
          };
          groupedMap.set(key, { ...finalMergedOutage });
        }
        //const newOutage = this.mergeOutages(res,tempData);
        //groupedMap.set(key,{...newOutage});
      }
    });
    groupedData.push(...groupedMap.values());
    return groupedData;
  }

  mergeOutages(outage1: spGetOutages, outage2: spGetOutages): spGetOutages {
    // Create a new object to store the merged result
    const mergedOutage: spGetOutages = { ...outage1 };
    //const outage2 = outage3.find(item => item.componentCode === outage1.componentCode);
    // Iterate over all keys in outage2
    Object.keys(outage2).forEach((key) => {
      if (outage2[key] !== undefined && outage2[key] !== null) {
        // Merge values; if outage1 already has a value for this key, concatenate with a comma
        if (mergedOutage[key] && typeof mergedOutage[key] === 'string' && typeof outage2[key] === 'string') {
          // Concatenate values if both are strings and already have values
          mergedOutage[key] += `, ${outage2[key]}`;
        } else {
          // Otherwise, just assign the value from outage2
          mergedOutage[key] = outage2[key];
        }
      }
    });

    return mergedOutage;
  }

  fetchDynamicColumns(outageSec: string) {
    const sectionMap = new Map<string, string[]>();
    this.dynamicHeaders = []; this.subHeaders = []; this.thirdLevelHeaders = []; this.columnDefinitions = [];
    const outageSectionsArray = outageSec.split('|').filter(Boolean);
    this.unitTypeComponents.forEach(item => {
      if (outageSec == 'All') {
        if (!sectionMap.has(item.sectionDescription)) {
          sectionMap.set(item.sectionDescription, []);
          this.dynamicHeaders.push(item.sectionDescription);//First-Level Header
        }

        const components = sectionMap.get(item.sectionDescription);
        components.push(item.componentName);
        sectionMap.set(item.sectionDescription, components);
      }
      else {
        if (outageSectionsArray.includes(item.sectionDescription)) {
          if (!sectionMap.has(item.sectionDescription)) {
            sectionMap.set(item.sectionDescription, []);
            this.dynamicHeaders.push(item.sectionDescription);//First-Level Header 
          }
          const components = sectionMap.get(item.sectionDescription);
          components.push(item.componentName);
          sectionMap.set(item.sectionDescription, components);
        }

      }



    });
    // Flatten to match the number of columns needed (In, Out for each subheader)
    sectionMap.forEach((components, section) => {
      const subHeaders = components.map(comp => comp);
      this.subHeaders.push(subHeaders);

      const thirdLevelHeaders = components.map(comp => [`${comp}_In`, `${comp}_Out`]).flat();
      this.thirdLevelHeaders = this.thirdLevelHeaders.concat(thirdLevelHeaders);
    });
    // Combine static and dynamic columns for the table
    this.columnDefinitions = [...this.staticColumns, ...this.thirdLevelHeaders];
    this.dynamicHeaders.unshift('blankHeader');
    this.subHeaders.unshift(['blankSubHeader']);
    console.log('Dynamic Columns:', this.dynamicColumns);
    console.log('Dynamic Headers:', this.dynamicHeaders);
    console.log('Sub Headers:', this.subHeaders);
    console.log('Third Level Headers:', this.thirdLevelHeaders);
  }

  ShowPartInfoDialog(row: spGetOutages, setNumber: any) {
    this.dialog.open(PartInformationDialogComponent, {
      width: "60%",
      height: "85%",
      data: { OrgCode: row.organizationCode, CompCode: row.componentCode, FrType: row.frameType, SetNo: setNumber },
    });
  }

  ShowOutageInfoDialog(row: spGetOutagesSummary) {
    this.partEntryService.getOutageInfoOutDate(row.organizationCode, row.companyCode, row.unit,
      row.outageDate.toString()).subscribe((res) => {
        this.outageInfo = res;

        if (this.outageInfo != null) {
          this.dialog.open(OutageDialogComponent, {
            width: "70%",
            height: "95%",
            data: { id: this.outageInfo.id },
          });
        }
      });
  }

  ShowNotesDialog(notes: string) {
    this.dialog.open(NotesComponent, {
      width: "50%",
      height: "55%",

      data: { notes: notes },
    });
  }

  //Function to get values from sessionstorage
  getStoredValues() {
    const storedValues = sessionStorage.getItem('outagesDetailFilters');
    if (storedValues) {
      const outageValues = JSON.parse(storedValues);
      return outageValues;
    }
    return new outagesDetailModels();
  }
  //Load dropdown values from sessionstorage on refresh in same browser session
  async LoadFilterValuesFromSession() {
    this.outagesDetailModel = this.getStoredValues();
    if (this.outagesDetailModel.selectedOrg != '') {
      this.selectedOrg = this.organizationCode = this.outagesDetailModel.selectedOrg;
      this.getUnitTypes();
    }

    if (this.outagesDetailModel.selectedUnit != '') {
      this.selectedUnit = this.unitType = this.outagesDetailModel.selectedUnit;
      await this.getCompanies();
      await this.getUnitTypeComponent();
    }

    if (this.outagesDetailModel.selectedCompaniesBackEnd != '') {
      this.selectedCompaniesBackEnd = this.outagesDetailModel.selectedCompaniesBackEnd;
      if (this.outagesDetailModel.allCompanies) {
        this.companies.forEach(element => {
          element.isSelected = true;
        });
      }
      else {
        const selectedValues = this.outagesDetailModel.selectedCompaniesBackEnd.split('|').filter(val => val);
        selectedValues.forEach(val => {
          this.companies.find(x => x.companyCode == val).isSelected = true;
        });
      }
      await this.getUnits();
    }

    if (this.outagesDetailModel.selectedUnitsBackEnd != '') {
      this.selectedUnitsBackEnd = this.outagesDetailModel.selectedUnitsBackEnd;
      if (this.outagesDetailModel.allUnits) {
        this.units.forEach(element => {
          element.isSelected = true;
        });
      }
      else {
        const selectedValues = this.outagesDetailModel.selectedUnitsBackEnd.split('|').filter(val => val);
        selectedValues.forEach(val => {
          this.units.find(x => x.unit == val).isSelected = true;
        });
      }
      await this.getOutageTypes();
    }

    if (this.outagesDetailModel.selectedOutagesTypeBackEnd != '') {
      this.selectedOutagesTypeBackEnd = this.outagesDetailModel.selectedOutagesTypeBackEnd;
      if (this.outagesDetailModel.allOutageTypes) {
        this.outageTypes.forEach(element => {
          element.isSelected = true;
        });
      }
      else {
        const selectedValues = this.outagesDetailModel.selectedOutagesTypeBackEnd.split('|').filter(val => val);
        selectedValues.forEach(val => {
          this.outageTypes.find(x => x.code == val).isSelected = true;
        });
      }
      this.fetchDynamicColumns('All');
      await this.getOutageSections();
      this.getOutageSummaryDetails();
    }

    if (this.outagesDetailModel.outageStatus != '') {
      this.selected = this.outageStatus = this.outagesDetailModel.outageStatus;
      this.getOutageSummaryDetails();
    }

    if (this.outagesDetailModel.selectedOutageSectionBackEnd != '') {
      this.selectedOutagesTypeBackEnd = this.outagesDetailModel.selectedOutageSectionBackEnd;
      if (this.outagesDetailModel.allOutageSections) {
        this.outageSections.forEach(element => {
          element.isSelected = true;
        });
        this.fetchDynamicColumns('All');
        this.getOutageSummaryDetails();
      }
      else {
        const selectedValues = this.outagesDetailModel.selectedOutageSectionBackEnd.split('|').filter(val => val);
        selectedValues.forEach(val => {
          this.outageSections.find(x => x.code == val).isSelected = true;
        });
        this.fetchDynamicColumns(this.selectedOutageSectionBackEnd);
        this.getOutageSummaryDetails();
      }

    }
  }

}