<div style="overflow-y: hidden !important;">

  <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
    cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
    <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
      Unit Information
    </h1>
    <span style="background-color: antiquewhite;">
      <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">
        X
      </button>
    </span>
  </div>

  <div mat-dialog-content>
    <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
      <tbody>
        <tr>
          <td align="left" style="width: 60%;">Organization:</td>
          <td>{{ unitInfo?.organizationDescription }}</td>
        </tr>
        <tr>
          <td align="left">Company:</td>
          <td>{{ unitInfo?.companyName }}</td>
        </tr>
        <tr>
          <td align="left">Unit Designation:</td>
          <td>{{ unitInfo?.unit }}</td>
        </tr>
        <tr>
          <td align="left">Unit Type:</td>
          <td>{{ unitInfo?.frameTypeDescription }}</td>
        </tr>
      </tbody>
    </table>

    <div class="row tabbed tabbed-content-control centered" style="margin-top: 20px;">
      <div class="filter-bar tab-filter-bar">
        <mat-tab-group [selectedIndex]="0" animationDuration="0ms" [disableRipple]='true' disablepagination="true">

          <mat-tab label="General Info">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
                  <tbody>
                    <tr>
                      <td align="left" style="width: 40%;">Fuel Source:</td>
                      <td >
                        <mat-form-field appearance="outline" >
                          <mat-select [(value)]="selectedFuelSource" (selectionChange)="onFuelSourceChange($event)">
                            <mat-option *ngFor="let row of fuelSource" [value]="row.code">{{ row.description
                              }}</mat-option>
                          </mat-select>
                        </mat-form-field>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">DLN:</td>
                      <td>
                        <input type="checkbox" class="form-check-input" [(ngModel)]="unitInfo.dln"
                          [checked]="unitInfo?.dln" style="height: 15px; width: 15px;" disabled>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Peaker:</td>
                      <td>
                        <input type="checkbox" class="form-check-input" [(ngModel)]="unitInfo.peaker"
                          [checked]="unitInfo?.peaker" style="height: 15px; width: 15px;">
                      </td>
                    </tr>
                    <tr>
                      <td align="left">Projected Outage Color:</td>
                      <td>
                        <app-color-picker [initialColor]="selectedColor"
                          (colorSelected)="onColorSelected($event)"></app-color-picker>
                      </td>
                    </tr>
                    <tr>
                      <td align="left">% Up Time:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.percentUpTime"></td>
                    </tr>
                    <tr>
                      <td align="left">Start Year:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.startYear"></td>
                    </tr>
                    <tr>
                      <td align="left">Firing Temperature:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.firingTemperature"></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Gas Turbine">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
                  <tbody>
                    <tr>
                      <td align="left" style="width: 35%;">Model:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.turbineModel"></td>
                    </tr>
                    <tr>
                      <td align="left">Rating:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.turbineRating"></td>
                    </tr>
                    <tr>
                      <td align="left">Serial No.:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.turbineSerialNumber"></td>
                    </tr>
                    <tr>
                      <td align="left">Acc. Gear:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.accessoryGearSerialNumber"></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <br />
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="Driven Equipment">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
                  <tbody>
                    <tr>
                      <td align="left" style="width: 35%;">Model:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.drivenEquipmentModel"></td>
                    </tr>
                    <tr>
                      <td align="left">Rating:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.drivenEquipmentRating"></td>
                    </tr>
                    <tr>
                      <td align="left">Serial No.:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.drivenEquipmentSerialNumber"></td>
                    </tr>
                    <tr>
                      <td align="left">Load Gear:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.loadGearSerialNumber"></td>
                    </tr>
                  </tbody>
                </table>
                <br />
                <br />
                <br />
              </div>
            </ng-template>
          </mat-tab>

          <mat-tab label="PI Points">
            <ng-template matTabContent>
              <div style="margin-top: 25px;">
                <table cellpadding="4" cellspacing="0" class="BlackText" style="color: black;">
                  <tbody>
                    <tr>
                      <td align="left" style="width: 40%;">Fired Hours:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.pifiredHoursPoint"></td>
                    </tr>
                    <tr>
                      <td align="left">Fired Starts:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.pifiredStartsPoint"></td>
                    </tr>
                    <tr>
                      <td align="left">Manual Starts:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.pimanualStartsPoint"></td>
                    </tr>
                    <tr>
                      <td align="left">Total Starts:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.pitotalStartsPoint"></td>
                    </tr>
                    <tr>
                      <td align="left">Emergency Stops:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.piemergencyStopsPoint"></td>
                    </tr>
                    <tr>
                      <td align="left">Peak Fired Hours:</td>
                      <td><input type="text" maxlength="10" style=" margin-top: 5px;" class="BlackText"
                          [(ngModel)]="unitInfo.pipeakFiredHoursPoint"></td>
                    </tr>
                  </tbody>
                </table>
                <br />
              </div>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
    <br>
    <div style="margin-top: 15px;">
      <input type="submit" value="Update" (click)="UpdateUnitInfo()" class="BlackText">
    </div>
  </div>
</div>