export class spGetPart {
    organization_Code?: string = '';
    component_Code?: string = '';
    set_Number?: string = '';
    frame_Type?: string = '';
    dln?: number = 0;
    partLifeClassification?: number = 0;
    style?: string = '';
    scrap_Date?: Date;
    comments?: string = '';
    partNumber?: string = '';
    component_Name?: string = '';
    _2055compatible: boolean = false;
    kitNumber?: string = '';
    refurbished: boolean = false;
    starting_Hours?: any;
    max_Hours?: any;
    maxNumberOfRepairs?: any;
    numberOf?: any;
    priorNumberOfRepairs?: any;
    partLifeClassificationDescription?: string = '';
    service_Date?: Date;
    organization?: string = '';
    stockId?: string = '';
    serialNumber?: string = '';
    kit: boolean = false;
    dlnDescription?: string = '';
    frameTypeDescription?: string = '';
    currentLifeTimeHours?: number = 0;
    status?: string = '';
    remainingHours?: number = 0;
    remainingRepairs?: number = 0;
    kitDescription?: string = '';
    ucWarningMessages?: string = '';
    set?: number = 0;
}