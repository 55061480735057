import { Injectable } from '@angular/core';
import { CalAngularService } from '@cvx/cal-angular';

@Injectable({
  providedIn: 'root',
})

export class AuthService {
  private readonly roleNames = {
    user: 'User',
    admin: 'Admin',
  };

  static calConfig = {
    autoSignIn: true,
    popupForLogin: false,
    cacheLocation: 'localStorage',
    instance: 'https://login.microsoftonline.com/',
    tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    clientId: AuthService.getClientId(),
    redirectUri: AuthService.getRedirectUrl(),
    oidcScopes: ['openid', 'profile', 'User.Read', 'offline_access'],
    graphScopes: ['User.Read User.Read.All Group.Read.All'],
  };

  constructor(private readonly authService: CalAngularService) {
    // do nothing
  }

  private static getClientId(): string {
    //// const hostname = window && window.location && window.location.hostname;
    const hostname = window && window.location && window.location.origin;

    const testClientId = '8fb949bf-7f42-4862-94a2-6c7603ea0efc';

    const prodClientId = 'f896caff-705e-4a63-bd2a-54b6d6bd817d';

    if (/^.*localhost.*/.test(hostname)) {
      return testClientId;
    }

    if (/^https?:\/\/rept-dev\.azure\.chevron\.com\/?/.test(hostname)) {
      return testClientId;
    }

    if (/^https?:\/\/rept-test\.azure\.chevron\.com\/?/.test(hostname)) {
      return testClientId;
    }

    if (/^https?:\/\/rept\.azure\.chevron\.com\/?/.test(hostname)) {
      return prodClientId;
    }
    console.warn(`Cannot find environment for host name ${hostname}`);
    return '';
  }

  private static getRedirectUrl(): string {
    const hostname = window && window.location && window.location.hostname;

    if (/^.*localhost.*/.test(hostname)) {
      return 'https://localhost:44300';
    }

    if (/^https?:\/\/rept-dev\.azure\.chevron\.com\/?/.test(hostname)) {
      return 'https://rept-dev.azure.chevron.com';
    }

    if (/^https?:\/\/rept-test\.azure\.chevron\.com\/?/.test(hostname)) {
      return 'https://rept-test.azure.chevron.com';
    }

    if (/^https?:\/\/rept\.azure\.chevron\.com\/?/.test(hostname)) {
      return 'https://rept.azure.chevron.com';
    }
    console.warn(`Cannot find environment for host name ${hostname}`);
    return '';
  }

  public checkAccess(): boolean {
    const signedIn = this.authService.isUserSignedIn();
    if (signedIn) {
      return true;
    }
    return false;
  }

  public getScopes(): string[] {
    return [`${AuthService.getClientId()}/user_impersonation`];
  }

  public getCurrentUser() {
    return this.authService.getAccount();
  }

  public isAuthorized(): boolean {
    const userInfo = this.getCurrentUser();
    return (
      !!userInfo.idTokenClaims
      && (userInfo.idTokenClaims['roles'].includes(this.roleNames.user)
        || userInfo.idTokenClaims['roles'].includes(this.roleNames.admin))
    );
  }

  public isAdmin(): boolean {
    const userInfo = this.getCurrentUser();
    return (
      !!userInfo.idTokenClaims
      && (userInfo.idTokenClaims['roles'].includes(this.roleNames.user)
        && userInfo.idTokenClaims['roles'].includes(this.roleNames.admin))
    );
  }

  public hasRole(role: string[]): boolean {
    const userInfo = this.getCurrentUser();
    return userInfo.idTokenClaims['roles'].includes(role[0]);
  }

}
