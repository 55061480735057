import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { SerialnoHistoryDialogComponent } from 'app/core/modals/serialno-history-dialog/serialno-history-dialog.component';
import { PartInformationDialogComponent } from 'app/core/modals/part-information-dialog/part-information-dialog.component';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { PartEntryService } from '#services/http/part-entry.service';
import { SerialNumberLookupService } from '#services/http/serial-number-loopup.service';
import { AuthService } from 'app/core/authentication/auth.service';
import { spSerialNumberLookUp } from '#models/spSerialNumberLookUp';
import { NotificationService } from '#services/notification.service';

@Component({
  selector: 'app-serial-number-lookup',
  templateUrl: './serial-number-lookup.component.html',
  styleUrls: ['./serial-number-lookup.component.scss']
})

export class SerialNumberLookupComponent {
  public displayedSerialNoColumns = ['Serial_Number', 'Organization_Description', 'Frame_Type_Description', 'Component_Description', 'DLN_Description', 'Set_Number', 'PartNumber', 'Starting_Hours', 'Start_Date', 'End_Date', 'Hours', 'LifeTimeHours', 'StatusDescription']
  public serialNumberDataSource: MatTableDataSource<spSerialNumberLookUp>;
  isColor: boolean = true;
  serialNumberData: spSerialNumberLookUp[] = [];
  searchField: string = '0';
  searchFor: string = '0';
  searchValue: string = '';
  userId: string = '';
  searchText: string = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<spSerialNumberLookUp>;

  constructor(private notifyService: NotificationService,
    private serialNumberLookupService: SerialNumberLookupService, public dialog: MatDialog,
    private authService: AuthService) {
  }

  ngOnInit() {
    const userInfo = this.authService.getCurrentUser();
    this.userId = userInfo.username;
  }

  getSerialNumberLookupData(searchValue:string) {
    if (this.searchValue == undefined || this.searchValue == "") {
      this.notifyService.showError("A portion of a serial number is required before search can be performed.", "");
    }
    else {
      this.searchText = '';
      switch (this.searchFor) {
        case '0': this.searchText = this.searchValue + '%';
          break;
        case '1': this.searchText = '%' + this.searchValue;
          break;
        default: this.searchText = '%' + this.searchValue + '%';
          break;
      }

      this.serialNumberLookupService.getSerialNumberLookup(this.searchField, this.searchText, this.userId).subscribe((res) => {
        this.serialNumberData = res;
        this.serialNumberDataSource = new MatTableDataSource<spSerialNumberLookUp>(this.serialNumberData);
        setTimeout(() => this.serialNumberDataSource.paginator = this.paginator);
      });
    }
  }

  ShowSerialNumberDialog(row: spSerialNumberLookUp) {
    this.dialog.open(SerialnoHistoryDialogComponent, {
      width: "60%",
      height: "65%",
      data: { OrgCode: row.organization_Code, FrameType: row.frame_type, CompCode: row.component_Code, Dln: row.dln, SerialNumber: row.serial_Number },
    });
  }

  ShowSetNumberDialog(row: spSerialNumberLookUp) {
    this.dialog.open(PartInformationDialogComponent, {
      width: "60%",
      height: "85%",
      data: { OrgCode: row.organization_Code, CompCode: row.component_Code, FrType: row.frame_type, SetNo: row.set_Number },
    });
  }
}