import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PartRepairService } from '#services/http/part-repair.service';
import { spRepairOpenLetter } from '#models/spRepairOpenLetter';

@Component({
  selector: 'app-repair-opening-letter',
  templateUrl: './repair-opening-letter.component.html',
  styleUrls: ['./repair-opening-letter.component.scss']
})

export class RepairOpeningLetterComponent {

  repairInfo = new spRepairOpenLetter();
  public organizationCode: string = '';
  public componentCode: string;
  public frameType: string = '';
  public setNumber: string = '';
  public startDate: any;
  
  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private partRepairService: PartRepairService) {
  }

  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params) => {
      this.organizationCode = params['OrganizationCode'];
      this.setNumber = params['SetNumber'];
      this.componentCode = params['ComponentCode'];
      this.frameType = params['FrameType'];
      this.startDate = params['StartDate'];
    });

    this.partRepairService.getRepairOpeningLetterData(this.organizationCode, this.setNumber, this.componentCode,
      this.frameType, this.startDate).subscribe((res) => {
        this.repairInfo = res;
      });
  }

}
