import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { HttpErrorInterceptor } from './core/interceptors/http-error.interceptor';
import { ReactiveFormsModule } from '@angular/forms';

// Pages
import { ErrorDialogModule } from '#services/errors/error-dialog/error-dialog.module';
import { LoadingDialogModule } from '#services/errors/loading-dialog/loading-dialog.module';
import { ErrorPageModule } from '#modules/error/error.module';
import { TokenInterceptor } from './core/interceptors/token.interceptor';
import { MaintenanceLogModule } from '#modules/maintenance-log/maintenance-log.module';
import { EditEventLogModule } from '#modules/edit-event-log/edit-event-log.module';
import { BusinessUnitService } from '#services/http/business-unit.service';
import { MaintenanceLogService } from '#services/http/maintenance-log.service';
import { DgsMetricsModule } from '#modules/dgs-metrics/dgs-metrics.module';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { HomePageModule } from '#modules/home/home.module';
import { MatListModule } from '@angular/material/list';
import { OutageDialogComponent } from './core/modals/outage-dialog/outage-dialog.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ItemsOutForRepairComponent } from './tops/items-out-for-repair/items-out-for-repair.component';
import { TopsHomeComponent } from './tops/tops-home/tops-home.component';
import { UnitDialogComponent } from './core/modals/unit-dialog/unit-dialog.component';
import { PartInformationDialogComponent } from './core/modals/part-information-dialog/part-information-dialog.component';
import { RepairInformationDialogComponent } from './core/modals/repair-information-dialog/repair-information-dialog.component';
import { DgsDrawingsComponent } from './modules/dgs-drawings/dgs-drawings.component';
import { MatInputModule } from '@angular/material/input';
import { DGSDrawingsModule } from '#modules/home/dgsDrawings.module';
import { DgsBulkUploadComponent } from './modules/dgs-admin/dgs-bulkupload/dgs-bulkupload.component';
import { DGSBulkUploadModule } from '#modules/home/dgsBulkUpload.module';
import { PartEntryComponent } from './tops/part-entry/part-entry.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatDividerModule } from '@angular/material/divider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NotesComponent } from './modals/notes/notes.component';
import { OutageEntryComponent } from './tops/outage-entry/outage-entry.component';
import { PartRepairComponent } from './tops/part-repair/part-repair.component';
import { SerialnoHistoryDialogComponent } from './core/modals/serialno-history-dialog/serialno-history-dialog.component';
import { RepairOpeningLetterComponent } from './tops/reports/repair-opening-letter/repair-opening-letter.component';
import { SerialNumberSelectionComponent } from './core/modals/serial-number-selection/serial-number-selection.component';
import { ToastrModule } from 'ngx-toastr';
import { OutagesSummaryComponent } from './tops/outages-summary/outages-summary.component';
import { SnackBarComponent } from '#components/SnackBarComponent/snack-bar.component';
import { OutagesBydateRangeComponent } from './tops/outages-bydate-range/outages-bydate-range.component';
import { OutagesDetailsComponent } from './tops/outages-details/outages-details.component';
import { OutagesOutageEntryComponent } from './tops/outages-outage-entry/outages-outage-entry.component';
import { PartsComponent } from './tops/parts/parts.component';
import { NewPartComponent } from './tops/new-part/new-part.component';
import { SerialNumberLookupComponent } from './tops/serial-number-lookup/serial-number-lookup.component';
import { RepairTrackingReportComponent } from './tops/reports/repair-tracking-report/repair-tracking-report.component';
import { OrganizationsComponent } from './tops/organizations/organizations.component';
import { SerialNumberMaintenanceComponent } from './tops/serial-number-maintenance/serial-number-maintenance.component';
import { SectionsComponent } from './tops/sections/sections.component';
import { ComponentsComponent } from './tops/components/components.component';
import { UnitTypesComponent } from './tops/unit-types/unit-types.component';
import { MatSortModule } from '@angular/material/sort';

@NgModule({
  declarations: [
    AppComponent,
    UnitDialogComponent,
    OutageDialogComponent,
    ItemsOutForRepairComponent,
    TopsHomeComponent,
    PartInformationDialogComponent,
    RepairInformationDialogComponent,
    PartEntryComponent,
    NotesComponent,
    OutageEntryComponent,
    PartRepairComponent,
    SerialnoHistoryDialogComponent,
    RepairOpeningLetterComponent,
    RepairTrackingReportComponent,
    SerialNumberSelectionComponent,
    OutagesSummaryComponent,
    SnackBarComponent,
    OutagesBydateRangeComponent,
    SnackBarComponent,
    OutagesDetailsComponent,
    OutagesOutageEntryComponent,
    PartsComponent,
    NewPartComponent,
    OutagesDetailsComponent,
    SerialNumberLookupComponent,
    OrganizationsComponent,
    SerialNumberMaintenanceComponent,
    SectionsComponent,
    ComponentsComponent,
    UnitTypesComponent
  ],
  imports: [
    CoreModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HomePageModule,
    HttpClientModule,
    LoadingDialogModule,
    ErrorDialogModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    ErrorPageModule,
    ReactiveFormsModule,
    MaintenanceLogModule,
    MatFormFieldModule,
    EditEventLogModule,
    DgsMetricsModule,
    MatToolbarModule,
    MatTableModule,
    MatPaginatorModule,
    MatDialogModule,
    MatMenuModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatTabsModule,
    MatInputModule,
    DGSDrawingsModule,
    DGSBulkUploadModule,
    MatDatepickerModule,
    MatSelectModule,
    MatDividerModule,
    DragDropModule,
    MatSortModule,
    ToastrModule.forRoot(),
  ],
  //entryComponents: [UnitDialogComponent, OutageDialogComponent, SnackBarComponent],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    BusinessUnitService,
    MaintenanceLogService,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }