<div class="container" style="background-color: white;">
    <div class="mat-elevation-z8">
        <table class="BlackText">
            <tbody>
                <tr style="height: 30px;">
                    <td style="padding-left: 5px; padding-right: 5px;">Legend:&nbsp;</td>
                    <td width="15" style="background-color:#FAAB18"><br></td>
                    <td style="padding-left: 5px; padding-right: 5px;">2 Weeks Before Due Date</td>
                    <td width="15" style="background-color:#E21836;"><br></td>
                    <td style="padding-left: 5px;">Over Due</td>
                </tr>
            </tbody>
        </table>
        <table class="BlackText" style="width:100%">
            <tbody>
                <tr style="height: 30px; text-align: center;">
                    <th colspan="8" style="background-color: #009dd0; border-bottom:1px solid white; color: white; ">
                        <span style="font-size: 17px; font-weight: bold;">Items Out for Repair</span>
                    </th>
                </tr>
            </tbody>
        </table>

        <table mat-table [dataSource]="itemsForRepairDataSource" matSort>
            <!-- Color Column -->
            <ng-container matColumnDef="color">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row" style="width: 1%;">
                    <span class="glyphicon glyphicon-calendar" style="color: red;"></span>
                </td>
                <td mat-cell *matCellDef="let row"> 1 </td>
            </ng-container>

            <!-- Organization Column -->
            <ng-container matColumnDef="organizationDescription">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Organization </th>
                <td mat-cell *matCellDef="let row"> {{row.organizationDescription}} </td>
            </ng-container>

            <!-- Frame Type Column -->
            <ng-container matColumnDef="frameTypeDescription">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Frame Type </th>
                <td mat-cell *matCellDef="let row"> {{row.frameTypeDescription}} </td>
            </ng-container>

            <!-- Component Column -->
            <ng-container matColumnDef="componentDescription">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Component </th>
                <td mat-cell *matCellDef="let row"> {{row.componentDescription}} </td>
            </ng-container>

            <!-- Set/Part Column -->
            <ng-container matColumnDef="setNumber">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Set/Part </th>
                <td mat-cell *matCellDef="let row">
                    <a href="javascript:void(0);" (click)="ShowPartInfoDialog(row)">{{row.setNumber}}</a>
                </td>
            </ng-container>

            <!-- Repair Facility Column -->
            <ng-container matColumnDef="repairFacility">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Repair Facility </th>
                <td mat-cell *matCellDef="let row"> {{row.repairFacility}} </td>
            </ng-container>

            <!-- Out Date Column -->
            <ng-container matColumnDef="startDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Out Date </th>
                <td mat-cell *matCellDef="let row"> {{row.startDate | date: 'MM/dd/yyyy'}} </td>
            </ng-container>

            <!-- Want Date Column -->
            <ng-container matColumnDef="customerWantDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Want Date </th>
                <td mat-cell *matCellDef="let row"> {{row.customerWantDate | date: 'MM/dd/yyyy'}} </td>
            </ng-container>

            <!-- Commitment Date Column -->
            <ng-container matColumnDef="repairFacilityCommitmentDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;"> Commitment Date </th>
                <td mat-cell *matCellDef="let row"> {{row.repairFacilityCommitmentDate}} </td>
            </ng-container>

            <!-- View Column -->
            <ng-container matColumnDef="View">
                <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
                <td mat-cell *matCellDef="let row">
                    <a href="javascript:void(0);" (click)="ShowRepairInfoDialog(row)">View</a>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="itemsForRepairColumns" class="header-back-color" style="color: white;">
            </tr>
            <tr mat-row *matRowDef="let row; columns: itemsForRepairColumns;"></tr>
        </table>
        <mat-paginator #paginator [pageIndex]="0" [pageSize]="20" showFirstLastButtons
            [pageSizeOptions]="[20, 40, 60, 100, 200]" aria-label="Select page of users"></mat-paginator>
    </div>
</div>