<div class="container" style="background-color: white;">
    <table cellspacing="0" style="border-collapse:collapse; margin-top: 10px;">
        <tbody>
            <tr>
                <td colspan="2">
                    <table class="BlackText" cellpadding="4" cellspacing="0">
                        <tbody>
                            <tr>
                                <td>Organization:</td>
                                <td>
                                    <span class="BlackText">{{ organizationName }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Unit Type:</td>
                                <td>
                                    <span class="BlackText">{{ unitTypeName }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>Component:</td>
                                <td>
                                    <span class="BlackText">{{ componentName }}</span>
                                </td>
                            </tr>
                            <tr>
                                <td>DLN:</td>
                                <td>
                                    <mat-form-field appearance="outline" style="height: 20px; margin-top: 2px;">
                                        <mat-select [(value)]="selectedDln">
                                            <mat-option *ngFor="let row of dln" [value]="row.id">
                                                {{ row.description }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Part Classification:</td>
                                <td>
                                    <mat-form-field appearance="outline" style="height: 20px; margin-top: 7px;">
                                        <mat-select [(value)]="selectedPart"
                                            (selectionChange)="onPartClassificationChange($event)">
                                            <mat-option value="0">Single Part</mat-option>
                                            <mat-option value="1">Set/Kit</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Clone From:</td>
                                <td>
                                    <mat-form-field appearance="outline" style="height: 20px; margin-top: 7px;">
                                        <mat-select [(value)]="selectedClone"
                                            (selectionChange)="onCloneFromChange($event)">
                                            <mat-option *ngFor="let row of partsData" [value]="row.set_Number">
                                                {{ row.set_Number }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <span class="BlackText">Part/Set</span> Number:
                                </td>
                                <td>
                                    <input type="text" maxlength="10" class="BlackText" size="30"
                                        style="margin-top: 7px;" [(ngModel)]="partInfo.set_Number">
                                </td>
                            </tr>
                            <tr>
                                <td>Number Of:</td>
                                <td>
                                    <table class="dxeButtonEditSys dxeButtonEditSysWithSpacing dxeButtonEdit BlackText"
                                        cellspacing="1" cellpadding="0" style="width:50px; margin-top: 4px;">
                                        <tbody>
                                            <tr>
                                                <td class="dxic" style="width:100%;">
                                                    <input class="dxeEditArea dxeEditAreaSys" type="number" size="30"
                                                        autocomplete="off" [(ngModel)]="partInfo.numberOf"
                                                        (change)="UpdateIntValues(partInfo, 'numberOf')">
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td>Part Life Classification:</td>
                                <td>
                                    <mat-form-field appearance="outline" style="height: 20px; margin-top: 4px;">
                                        <mat-select [(value)]="selectedPartLife"
                                            (selectionChange)="onPartLifeClassificationChange($event)">
                                            <mat-option *ngFor="let row of partLifeClassification" [value]="row.id">
                                                {{ row.description }}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Style:</td>
                                <td>
                                    <input type="text" maxlength="50" class="BlackText" size="50"
                                        style="margin-top: 7px;" [(ngModel)]="partInfo.style">
                                </td>
                            </tr>
                            <tr>
                                <td>Service Date:</td>
                                <td>
                                    <mat-form-field appearance="outline"
                                        style="height: 20px; margin-top: 5px; width: 215px;">
                                        <input matInput [matDatepicker]="picker" placeholder="MM/dd/yyyy"
                                            [(ngModel)]="partInfo.service_Date"
                                            (dateChange)="OnDateChange(partInfo, 'ServiceDate')">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Scrap Date:</td>
                                <td>
                                    <mat-form-field appearance="outline"
                                        style="height: 20px; margin-top: 7px; width: 215px;">
                                        <input matInput [matDatepicker]="picker1" [(ngModel)]="partInfo.scrap_Date"
                                            placeholder="MM/dd/yyyy" (dateChange)="OnDateChange(partInfo, 'ScrapDate')">
                                        <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Mfg Kit #:</td>
                                <td>
                                    <input type="text" maxlength="50" [(ngModel)]="partInfo.kitNumber" class="BlackText"
                                        size="50" style="margin-top: 7px;">
                                </td>
                            </tr>
                            <tr>
                                <td>Mfg Part #:</td>
                                <td>
                                    <input type="text" maxlength="50" [(ngModel)]="partInfo.partNumber"
                                        class="BlackText" size="50" style="margin-top: 5px;">
                                </td>
                            </tr>
                            <tr>
                                <td>Stock ID:</td>
                                <td>
                                    <input type="text" maxlength="50" [(ngModel)]="partInfo.stockId" class="BlackText"
                                        size="50" style="margin-top: 5px;">
                                </td>
                            </tr>
                            <tr>
                                <td>Refurbished:</td>
                                <td>
                                    <input type="checkbox" class="form-check-input" [(ngModel)]="partInfo.refurbished"
                                        [checked]="partInfo?.refurbished"
                                        style="height: 15px; width: 15px; margin-top: 7px;">
                                </td>
                            </tr>
                            <tr>
                                <td>2055 Compatible:</td>
                                <td>
                                    <input type="checkbox" class="form-check-input"
                                        [(ngModel)]="partInfo._2055compatible" [checked]="partInfo?._2055compatible"
                                        style="height: 15px; width: 15px; margin-top: 7px;">
                                </td>
                            </tr>
                            <tr>
                                <td>Max # of Repairs:</td>
                                <td>
                                    <input type="number" [(ngModel)]="partInfo.maxNumberOfRepairs" autocomplete="off"
                                        style="margin-top: 8px;" (change)="UpdateIntValues(partInfo, 'maxRepairs')">
                                </td>
                            </tr>
                            <tr>
                                <td>Prior # of Repairs:</td>
                                <td>
                                    <input type="number" [(ngModel)]="partInfo.priorNumberOfRepairs" autocomplete="off"
                                        style="margin-top: 5px;" (change)="UpdateIntValues(partInfo, 'priorRepairs')">
                                </td>
                            </tr>
                            <tr>
                                <td>Starting Hours:</td>
                                <td>
                                    <input type="number" [(ngModel)]="partInfo.starting_Hours" autocomplete="off"
                                        style="margin-top: 5px;" (change)="UpdateIntValues(partInfo, 'startingHours')">
                                </td>
                            </tr>
                            <tr>
                                <td>Max Hours:</td>
                                <td>
                                    <input type="number" [(ngModel)]="partInfo.max_Hours" autocomplete="off"
                                        style="margin-top: 5px; margin-bottom: 5px;"
                                        (change)="UpdateIntValues(partInfo, 'maxHours')">
                                </td>
                            </tr>
                            <tr>
                                <td valign="top">Comments:</td>
                                <td>
                                    <textarea rows="10" cols="80" class="BlackText"
                                        [(ngModel)]="partInfo.comments"></textarea>
                                </td>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    <input type="submit" value="Save" class="BlackText" (click)="SavePartDetails()">
                                    <input type="submit" value="Cancel" class="BlackText" style="margin-left: 5px;">
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </td>
            </tr>
        </tbody>
    </table>
</div>