import { spDeletePart } from '#models/spDeletePart';
import { spGetOrganizations } from '#models/spGetOrganizations';
import { spGetOutagesSummary } from '#models/spGetOutagesSummary';
import { spGetParts } from '#models/spGetParts';
import { spGetUnitTypeComponents } from '#models/spGetUnitTypeComponents';
import { OutageSummaryService } from '#services/http/outage-summary.service';
import { PartsService } from '#services/http/parts.service';
import { NotificationService } from '#services/notification.service';
import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { PartInformationDialogComponent } from 'app/core/modals/part-information-dialog/part-information-dialog.component';

@Component({
  selector: 'app-parts',
  templateUrl: './parts.component.html',
  styleUrls: ['./parts.component.scss']
})

export class PartsComponent {
  public partsColumns = ['set', 'component', 'dln', 'style', 'serviceDate', 'scrapDate', 'lifetimeHrs', 'location', 'delete']
  organizations: spGetOrganizations[] = [];
  unitTypes: spGetOrganizations[] = [];
  components: spGetUnitTypeComponents[] = [];
  locations: spGetOrganizations[] = [];
  selectedOrg: string = '';
  selectedUnitType: string = '';
  selectedComponent: string = '';
  selectedLocationBackEnd: string = '';
  deletePart = new spDeletePart();
  partsData: spGetParts[] = [];
  public partsDataSource: MatTableDataSource<spGetParts>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatTable) table: MatTable<spGetParts>;

  constructor(private router: Router, private activeRoute: ActivatedRoute,
    private notifyService: NotificationService, public dialog: MatDialog,
    private outSummaryService: OutageSummaryService, private partsService: PartsService,) {
  }

  ngOnInit(): void {
    this.getPartLocation();
    this.getOrganizations();
  }

  getOrganizations() {
    this.outSummaryService.getOrganizations().subscribe((res) => {
      this.organizations = res;
    });
  }

  onOrganizationChange(event: any) {
    this.selectedOrg = event.value;
    this.unitTypes = null;
    this.partsData = null;
    this.components = null;
    this.selectedUnitType = '';
    this.selectedComponent = '';

    this.getUnitTypes();
  }

  getUnitTypes() {
    this.outSummaryService.getUnitTypes(this.selectedOrg).subscribe((res) => {
      this.unitTypes = res;
    });
  }

  onUnitTypeChange(event: any) {
    this.selectedUnitType = event.value;
    this.partsData = null;
    this.components = null;
    this.selectedComponent = '';
    this.getComponent();
  }

  getComponent() {
    this.partsService.getComponents(this.selectedOrg, this.selectedUnitType).subscribe((res) => {
      this.components = res;
    });
  }

  onComponentChange(event: any) {
    this.selectedComponent = event.value;
    this.partsData = null;
    this.getPartsDetails();
  }

  getPartLocation() {
    this.locations = [
      { code: '0', description: 'Not In Use', isSelected: false },
      { code: '1', description: 'Future Use', isSelected: false },
      { code: '2', description: 'In Unit', isSelected: false },
      { code: '4', description: 'Out for Repair', isSelected: false },
      { code: '3', description: 'Scrapped', isSelected: false }]
  }

  onOutageTypeChange(location: string, event: any) {
    this.selectedLocationBackEnd = '';
    this.locations.find(x => x.code == location).isSelected = event.currentTarget.checked;

    this.locations.forEach(element => {
      if (element.isSelected === true) {
        this.selectedLocationBackEnd = this.selectedLocationBackEnd + '|' + element.code + '|';
      }
    });

    this.partsData = null;
    if (this.selectedOrg != '' && this.selectedUnitType != '' && this.selectedComponent != '') {
      this.getPartsDetails();
    }
  }

  getPartsDetails() {
    this.partsService.getPartsData(this.selectedOrg, this.selectedComponent,
      this.selectedUnitType, this.selectedLocationBackEnd).subscribe((res) => {
        this.partsData = res;

        this.partsDataSource = new MatTableDataSource<spGetParts>(this.partsData);
        setTimeout(() => this.partsDataSource.paginator = this.paginator);
      });
  }

  ShowPartInfoDialog(row: spGetParts) {
    this.dialog.open(PartInformationDialogComponent, {
      width: "65%",
      height: "95%",
      data: {
        OrgCode: row.organization_Code, CompCode: row.component_Code,
        FrType: row.frame_Type, SetNo: row.set_Number
      },
    });
  }

  onClickDelete(row: spGetParts, index: any) {

    this.deletePart.Organization_Code = row.organization_Code;
    this.deletePart.Component_Code = row.component_Code;
    this.deletePart.Set_Number = row.set_Number;
    this.deletePart.Frame_Type = row.frame_Type;

    this.partsData = this.partsData.filter(x => x.set_Number != row.set_Number);
    this.partsDataSource = new MatTableDataSource<spGetParts>(this.partsData);
    setTimeout(() => this.partsDataSource.paginator = this.paginator);

    this.partsService.deletePart(this.deletePart).subscribe((res) => {
    });
  }

  public onRepairTracking() {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['tops/repair-tracking-report'], {
        queryParams: {
          OrgCode: this.selectedOrg,
          FrameType: this.selectedUnitType,
          CompCode: this.selectedComponent
        },
      })
    );

    window.open(url, '_blank');
  }

  public onNewPartClick() {
    this.router.navigate(['tops/new-part-entry'], {
      queryParams: {
        OrgCode: this.selectedOrg,
        FrameType: this.selectedUnitType,
        CompCode: this.selectedComponent
      },
    });
  }
}
