<div >
    <div mat-dialog-title class="dialog-title" style="background-color: #009dd0; height: 30px;" cdkDrag
        cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <h1 style="color: white; margin-top: -47px; font-size: 21px !important; margin-left: 5px;">
            Unit Sections and Components
        </h1>
        <span style="background-color: antiquewhite;">
            <button class="close" mat-button (click)="onDismiss()" style="margin-top: 5px; font-size: large;">
                X
            </button>
        </span>
    </div>

    <div mat-dialog-content>
        <div style="margin-left:7px; margin-top: 5px;">
            <span class="BlackText Bold"> Unit Type: 5001-LA</span>
            <br>
            <br>

            <table mat-table matSort style="margin-top: 10px; border: 1px solid grey;" aria-describedby="">
                <!-- [dataSource]="unitTypesDataSource" -->
                <ng-container matColumnDef="section">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center">
                        Section </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                        <span *ngIf="row.code != '0'">{{ row.code }}</span>
                        <input type="text" maxlength="50" class="BlackText" size="25" *ngIf="row.code == '0'"
                            [(ngModel)]="description">
                    </td>
                    <td mat-cell *matCellDef="let row"></td>
                </ng-container>

                <ng-container matColumnDef="order">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header style="color: white;" class="text-center">
                        Order </th>
                    <td mat-cell *matCellDef="let row" class="text-left">
                        <input type="text" maxlength="50" class="BlackText" size="25" *ngIf="row.code == '0'"
                            [(ngModel)]="description">

                        <ng-container *ngIf="!isEditing(row)">{{row.description}}</ng-container>
                        <ng-container *ngIf="isEditing(row) && row.code != '0'">
                            <input type="text" [(ngModel)]="updatedDesc" maxlength="50" size="50" class="BlackText">
                        </ng-container>
                    </td>
                    <td mat-cell *matCellDef="let row"></td>
                </ng-container>

                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef scope="col" style="width: 30%;"></th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container *ngIf="!isEditing(row)">
                            <input type="submit" value="Add" class="BlackText" (click)="addSection(description)"
                                style="margin-top: 5px; margin-left: 3px;" *ngIf="row.code == '0'">
                            <input type="submit" value="Cancel" class="BlackText" style="margin-left: 3px;"
                                *ngIf="row.code == '0'" (click)="cancelInsertSection(description)">
                        </ng-container>
                    </td>
                </ng-container>

                <ng-container matColumnDef="delete">
                    <th mat-header-cell *matHeaderCellDef scope="col"></th>
                    <td mat-cell *matCellDef="let row">
                        <button mat-icon-button matTooltip="Delete Component" color="primary"
                            (click)="onDeleteComponentClick(row)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="unitSectionComponentsColumns" style="color: white; height: 20px;"
                    class="header-color">
                </tr>
                <tr mat-row *matRowDef="let row; columns: unitSectionComponentsColumns;"
                    style="height: 40px !important;">
                </tr>
            </table>

            <table>
                <tbody>
                    <tr>
                        <td valign="top">
                            <table cellpadding="4" cellspacing="0" class="BlackText Border">
                                <tbody>
                                    <tr class="Header">
                                        <th>Section</th>
                                        <th>Order</th>
                                        <th></th>
                                    </tr>
                                    <tr class="Row1">
                                        <td>
                                            <select class="BlackText">
                                                <option selected="selected" value=""></option>
                                                <option value="26">All</option>
                                                <option value="11">Catalyst UAT</option>
                                                <option value="8">Cooling System</option>
                                                <option value="6">Engine</option>
                                                <option value="7">Gas Removal System</option>
                                                <option value="5">Gear Box</option>
                                                <option value="4">Generator UAT</option>
                                                <option value="9">High Pressure Turbine</option>
                                                <option value="10">Low Pressure Turbine</option>
                                            </select>
                                        </td>
                                        <td></td>
                                        <td>
                                            <input type="submit" value="Add" class="BlackText">
                                            <input type="submit" value="Cancel" class="BlackText">
                                        </td>
                                    </tr>
                                    <tr class="Row2">
                                        <td>
                                            <a>Turbine</a>
                                        </td>
                                        <td>
                                            <span class="BlackText">&nbsp;&nbsp;</span>
                                            &nbsp;&nbsp;
                                            <a class="BlackText">▼</a>
                                        </td>
                                        <td>
                                            <input type="submit" value="Delete" class="BlackText">
                                        </td>
                                    </tr>
                                    <tr class="Row1">
                                        <td>
                                            <a>Compressor</a>
                                        </td>
                                        <td>
                                            <a class="BlackText">▲</a>&nbsp;&nbsp;
                                            <a class="BlackText">▼</a>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                        <td valign="top" width="10"><br></td>
                        <td valign="top">
                            <table cellpadding="4" cellspacing="0" class="BlackText Border">
                                <tbody>
                                    <tr class="header">
                                        <th colspan="6">Turbine Section</th>
                                    </tr>
                                    <tr class="SubHeader">
                                        <th>Component</th>
                                        <th>Section</th>
                                        <th>Classification</th>
                                        <th nowrap=""># Of</th>
                                        <th>Order</th>
                                        <th></th>
                                    </tr>
                                    <tr class="Row1">
                                        <td>
                                            <select class="BlackText">
                                                <option value=""></option>
                                                <option value="NGV1">- 1st Stage HPT Nozzle Guide Vane </option>
                                                <option value="NGV2">- 2nd Stage HPT Nozzle Guide Vane</option>
                                                <option value="AOS">- Air/Oil Seperator</option>
                                                <option value="STRA">- Air/Pnuematic Starter</option>
                                                <option value="AGB">- Angle Gear Box</option>
                                                <option value="BRR4">- Bearing Roller 4R</option>
                                                <option value="BRR5">- Bearing Roller 5R</option>
                                                <option value="BRR6">- Bearing Roller 6R</option>
                                                <option value="BRR7">- Bearing Roller 7R</option>
                                                <option value="BRT4">- Bearing Thrust 4</option>
                                                <option value="BRT7">- Bearing Thrust 7</option>
                                                <option value="CITS">- CIT Sensor</option>
                                                <option value="CDP">- Compressor Disk UAT TEST</option>
                                                <option value="CFF">- Compressor Front Frame</option>
                                                <option value="CRF">- Compressor Rear Frame</option>
                                                <option value="CR14">- Compressor Rotor Spool 14-16</option>
                                                <option value="CRS">- Compressor Rotor Spool 3-9</option>
                                                <option value="CR10">- Compressor Rotor Spool/Shaft 10-13</option>
                                                <option value="CSRC">- Compressor Stator Rear Casing</option>
                                                <option value="DAC">- Duel Annular Combustor</option>
                                                <option value="EXCT">- Exciter IGN</option>
                                                <option value="FCC">- Forward Compressor Case</option>
                                                <option value="FNOD">- Fuel Nozzle Duplex</option>
                                                <option value="FNOL">- Fuel Nozzle Liquid</option>
                                                <option value="FNOG">- Fuel Nozzle Natrural Gas</option>
                                                <option value="FNON">- Fuel Nozzle Natural Gas</option>
                                                <option value="FP">- Fuel Pump</option>
                                                <option value="FANS">- Fwd Shaft </option>
                                                <option value="HPTA">- High Pressure Turbine Assy</option>
                                                <option value="HTB1">- High Pressure Turbine Blade Stage 1</option>
                                                <option value="HTB2">- High Pressure Turbine Blade Stage 2</option>
                                                <option value="HPTR">- High Pressure Turbine Rotor</option>
                                                <option value="CD1">- HP Compressor Disk 1</option>
                                                <option value="CD2">- HP Compressor Disk 2</option>
                                                <option value="SP10">- HP Compressor Spool 1-2</option>
                                                <option value="SP39">- HP Compressor Spool 3-9</option>
                                                <option value="HTD1">- HP Turbine Disk 1</option>
                                                <option value="HTD2">- HP Turbine Disk 2</option>
                                                <option value="HTSP">- HP Turbine Spacer</option>
                                                <option value="STRH">- Hydraulic Starter</option>
                                                <option value="LPTA">- Low Pressure Turbine Assy</option>
                                                <option value="LTD6">- Low Turbine Disk 6</option>
                                                <option value="LDIA">- Lower Diaphragm</option>
                                                <option value="LBPS">- LP Balance Piston Seal</option>
                                                <option value="LCD0">- LP Compresser Disk 0</option>
                                                <option value="LCD1">- LP Compresser Disk 1</option>
                                                <option value="LCFS">- LP Compresser Fwd Shaft</option>
                                                <option value="LCS">- LP Compressor Spool 2-4</option>
                                                <option value="LTD1">- LP Turbine Disk 1</option>
                                                <option value="LTD2">- LP Turbine Disk 2</option>
                                                <option value="LTD3">- LP Turbine Disk 3</option>
                                                <option value="LTD4">- LP Turbine Disk 4</option>
                                                <option value="LTD5">- LP Turbine Disk 5</option>
                                                <option value="LTSH">- LP Turbine Shaft</option>
                                                <option value="LASP">- Lube &amp; Scavenge Pump</option>
                                                <option value="MGB">- Main Gear Box</option>
                                                <option value="MIDS">- Mid Shaft</option>
                                                <option value="PT">- Power Turbine</option>
                                                <option value="PTLS">- Power Turbine Lower Stator Assy/Case</option>
                                                <option value="PTR">- Power Turbine Rotor</option>
                                                <option value="PTUS">- Power Turbine Upper Stator Assy/Case</option>
                                                <option value="SAC">- Single Annular Combustor</option>
                                                <option value="BLSK">- Stage 0 Blisk</option>
                                                <option value="T3PR">- T3 Probe </option>
                                                <option value="TCPR">- TC Probe</option>
                                                <option value="TGB">- Transfer Gear Box</option>
                                                <option value="TMF">- Turbine Mid Frame</option>
                                                <option value="TRF">- Turbine Rear Frame</option>
                                                <option value="VSVA">- Variable Stator Vane Actuator</option>
                                                <option value="VSVC">- Variable Stator Vane Control</option>
                                                <option value="VSLA">- VSV Master Lever Arm</option>
                                                <option value="BK4">4th Stage Bucket</option>
                                                <option value="NZ4">4th Stage Nozzle</option>
                                                <option value="SH4">4th Stage Shroud</option>
                                                <option value="NZ5">5th Stage Nozzle</option>
                                                <option value="NZ6">6th Stage Nozzle</option>
                                                <option value="AWMA">Aux Water Motor A</option>
                                                <option value="AWMB">Aux Water Motor B</option>
                                                <option value="AWPA">Aux Water Pump A</option>
                                                <option value="AWPB">Aux Water Pump B-UAT</option>
                                                <option value="BRS1">Blade Ring Segments Row 1</option>
                                                <option value="BRS2">Blade Ring Segments Row 2</option>
                                                <option value="BRS3">Blade Ring Segments Row 3</option>
                                                <option value="BRS4">Blade Ring Segments Row 4</option>
                                                <option value="CWMA">Circ Water Motor A</option>
                                                <option value="CWMB">Circ Water Motor B</option>
                                                <option value="CWPA">Circ Water Pump A</option>
                                                <option value="CWPB">Circ Water Pump B</option>
                                                <option value="CS">Clam Shell</option>
                                                <option value="CO">CO Catalyst</option>
                                                <option value="CIL">Combustion Inner Liner</option>
                                                <option value="CT A">Combustion Tile Row A</option>
                                                <option value="CT B">Combustion Tile Row B</option>
                                                <option value="CT C">Combustion Tile Row C</option>
                                                <option value="CL">Combustor Basket</option>
                                                <option value="ENG">Engine</option>
                                                <option value="ES A">Entry Segment A</option>
                                                <option value="ES B">Entry Segment B</option>
                                                <option value="GB">Gear Box</option>
                                                <option value="GR">Generator</option>
                                                <option value="BRG3">Generator Bearing 3</option>
                                                <option value="BRG4">Generator Bearing 4</option>
                                                <option value="GRTR">Generator Rotor</option>
                                                <option value="HS A">Heat Shield - Row A</option>
                                                <option value="HS B">Heat Shield - Row B</option>
                                                <option value="HS C">Heat Shield - Row C</option>
                                                <option value="HGC">Hot Gas Case</option>
                                                <option value="IGB">Inlet Gear Box</option>
                                                <option value="ES C">Inlet Segment C</option>
                                                <option value="ICC">Interm Comb Case</option>
                                                <option value="ISS2">Interstage Shroud Row 2</option>
                                                <option value="ISS3">Interstage Shroud Row 3</option>
                                                <option value="ISS4">Interstage Shroud Row 4</option>
                                                <option value="JBRG">Journal Bearing</option>
                                                <option value="LRMA">Liquid Ring Vac Motor A</option>
                                                <option value="LRMB">Liquid Ring Vac Motor B</option>
                                                <option value="LRPA">Liquid Ring Vac Pump A</option>
                                                <option value="LRPB">Liquid Ring Vac Pump B</option>
                                                <option value="LRDA">LP Rear Drv Adpter</option>
                                                <option value="MCVL">Main Control Valve - Left</option>
                                                <option value="MCVR">Main Control Valve - Right</option>
                                                <option value="MSVL">Main Stop Valve - Left</option>
                                                <option value="MSVR">Main Stop Valve - Right</option>
                                                <option value="NOX">NOx Catalyst</option>
                                                <option value="PN">Pilot Nozzle</option>
                                                <option value="SH">Support Housing</option>
                                                <option value="TBRG">Thrust Bearing</option>
                                                <option value="ITPS">TP Seal Inner</option>
                                                <option value="OTPS">TP Seal Outer</option>
                                                <option value="TSI">Transition Seal Inner</option>
                                                <option value="TSO">Transition Seal Outer</option>
                                                <option value="ABRG">Turbine Axial Bearing Test</option>
                                                <option value="BRG1">Turbine Bearing 1</option>
                                                <option value="BRG2">Turbine Bearing 2</option>
                                                <option value="TBR1">Turbine Blade Ring Row 1</option>
                                                <option value="TBR2">Turbine Blade Ring Row 2</option>
                                                <option value="TBR3">Turbine Blade Ring Row 3</option>
                                                <option value="TBR4">Turbine Blade Ring Row 4</option>
                                                <option value="TB1">Turbine Blade Row 1</option>
                                                <option value="TB2">Turbine Blade Row 2</option>
                                                <option value="TB3">Turbine Blade Row 3</option>
                                                <option value="TB4">Turbine Blade Row 4</option>
                                                <option value="TB5">Turbine Blade Row 5</option>
                                                <option value="TB6">Turbine Blade Row 6</option>
                                                <option value="TCR">Turbine Compressor Rotor</option>
                                                <option value="TVC">Turbine Vane Carrier</option>
                                                <option value="UDIA">Upper Diaphragm</option>
                                                <option value="VS1">Vane Segment Row 1</option>
                                                <option value="VS2">Vane Segment Row 2</option>
                                                <option value="VS3">Vane Segment Row 3</option>
                                                <option value="VS4">Vane Segment Row 4</option>

                                            </select>
                                        </td>
                                        <td>
                                            <select disabled="disabled" class="aspNetDisabled BlackText">
                                                <option value="2">Combustion</option>
                                                <option value="1">Compressor</option>
                                                <option selected="selected" value="3">Turbine</option>
                                                <option value="13">UAT</option>
                                            </select>
                                        </td>
                                        <td>
                                            <select class="BlackText">
                                                <option selected="selected" value=""></option>
                                                <option value="0">Single Part</option>
                                                <option value="1">Set/Kit</option>
                                            </select>
                                        </td>
                                        <td>
                                            <input type="text" MaxLength="3" class="BlackText">
                                        </td>
                                        <td></td>
                                        <td>
                                            <input type="submit" value="Add" class="BlackText">
                                            <input type="submit" value="Cancel" class="BlackText">
                                        </td>
                                    </tr>

                                    <tr class="Row2">
                                        <td>
                                            Turbine Rotor
                                        </td>
                                        <td>
                                            <span>Turbine</span>
                                        </td>
                                        <td>
                                            Single Part
                                        </td>
                                        <td>

                                        </td>
                                        <td>
                                            <a class="BlackText">▲</a>&nbsp;&nbsp;
                                            <a class="BlackText">▼</a>
                                        </td>
                                        <td>
                                            <input type="submit" value="Edit" class="BlackText">
                                            <input type="submit" value="Delete" class="BlackText">
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>